import { useEffect, useState } from 'react'

import { SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from "store"
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { addSelectedToFavoritesItemsThunk, removeSelectedFromFavoritesItemsThunk } from "../../favorites/favoritesSlice"
import { timelineActions } from "../timeline/timelineSlice"
import { getFlashbackAlbumsSelector } from "../timeline/selectors"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { SDK_TYPES } from "../../../sdk/sdkConstants"

import { photoPreviewFlashbackActions } from './photoPreviewFlashbackSlice'
import {
  getCurrentPhotoPreviewFlashbackItemIndexSelector,
  getPhotoPreviewFlashbackItemsSelector,
  getPhotoPreviewFlashbackLoadingSelector
} from './selectors'
import { sortFlashbackAlbums } from "./PhotoPreviewFlashbackModal"

export const PhotoPreviewFlashbackToolbar = ({ className = '', handleSlideShow, isTimeOut, type }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isLoading = getPhotoPreviewFlashbackLoadingSelector()
  const [isLoadingVideo, setLoadingVideo] = useState(false)

  useEffect(() => {
    isLoading && type === 'video' ? setLoadingVideo(true) : setLoadingVideo(false)
  }, [isLoading])

  const items = getPhotoPreviewFlashbackItemsSelector()
  const currentIndex = getCurrentPhotoPreviewFlashbackItemIndexSelector()
  const albums = getFlashbackAlbumsSelector()

  const currentItem = items[currentIndex]

  const handleClose = () => {
    dispatch(timelineActions.setFlashbackAlbums(sortFlashbackAlbums(albums)))
    dispatch(photoPreviewFlashbackActions.resetState())
  }

  const handleRemoveFromFavorites = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_FLASHBACK_REMOVE_FAVORITE)
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: [currentItem], type: SDK_TYPES.DEFAULT }))
  }

  const handleAddToFavorites = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_FLASHBACK_ADD_FAVORITE)
    dispatch(addSelectedToFavoritesItemsThunk({ items: [currentItem], withNotification: true, type: SDK_TYPES.DEFAULT }))
  }

  const actionItems = [
    (!isLoadingVideo ? {
      handler: handleSlideShow,
      title: isTimeOut ? t('a_common_pause') : t('a_common_play'),
      iconName: isTimeOut ? 'stop' : 'play',
      orderOnMobile: 1,
    } : {
      title: 'empty',
      orderOnMobile: 1,
    }),
    (
      currentItem?.favorite ? {
        handler: handleRemoveFromFavorites,
        title: t('a_common_removeFavorite'),
        iconName: 'remove_favorite',
        orderOnMobile: 2,
      } : {
        handler: handleAddToFavorites,
        title: t('a_common_addToFavorites'),
        iconName: 'heart_empty',
        orderOnMobile: 2,
      }),
    {
      handler: handleClose,
      title: t('a_common_close'),
      iconName: 'close',
      orderOnMobile: 3,
    },
  ]


  return (
    <PhotoPreviewToolbarBox className={className}>
      {
        actionItems.map(item => (
          <ActionItem
            key={item.title}
            item={item}
          />
        ))
      }
    </PhotoPreviewToolbarBox>
  )
}

const ActionItem = ({ className = '', item }) => {
  const [popoverOpened, togglePopover] = useState(false)

  const handleClick = () => {
    if (item.popoverContent) {
      togglePopover(state => !state)

      return
    }

    item.handler()
  }

  return (
    <ActionIconBox
      onClick={handleClick}
      title={item.title}
      className={classNames(className, { 'hidden-on-mobile': item.hiddenOnMobile, 'hidden-on-desktop': item.hiddenOnDesktop })}
      orderOnMobile={item.orderOnMobile}
    >
      <ActionIcon iconName={item.iconName} />

      {!!item.popoverContent && popoverOpened && (
        <Popover>
          {item.popoverContent}
        </Popover>
      )}
    </ActionIconBox>
  )
}

const PhotoPreviewToolbarBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ActionIconBox = styled.button`
  width: 44px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .3s ease;
  position: relative;
  z-index: 2;
  color: #DADADA;
  order: ${props => props.orderOnMobile};
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background-color: rgba(255, 255, 255, .34);
  }

  &.hidden-on-mobile {
    display: none;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    order: unset;

    &.hidden-on-desktop {
      display: none;
    }

    &.hidden-on-mobile {
      display: flex;
    }
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    &:hover {
      background-color: unset;
    }
  }
`

const ActionIcon = styled(SpriteIcon)`
  && {
    path {
      fill: white;
    }
  }
`

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: auto;
  right: 0;
  background: var(--background-primary);
  border-radius: 6px;
  width: 180px;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  padding: 9px 14px;
  text-align: left;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);

  &.active {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: var(--background-primary);
    border-style: solid;
    left: auto;
    right: 11px;
    top: -21px;
    bottom: auto;
    margin-left: -11px;
    border-width: 11px;
  }
`
