import { STYLED_VARIABLES } from 'constants/styledVariables'
import { LOADING_STATUSES } from 'constants/loadingStatuses'

import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { SpriteIcon } from '@cloudike/web_ui_components'
import { DialogModal } from 'ui/DialogModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'ui'
import { getUserSettingsByPathSelector } from 'features/user/selectors'
import { USER_SETTINGS_PATHS } from 'features/user/constants'

import { getOtherCloudsLoadingStatuSelector, getUserLinkedCloudsSelector } from './selectors'
import {
  cancelImportingFromOtherCloudThunk,
  fetchLinkedCloudsSourcesThunk,
  otherCloudsActions,
  removeOtherCloudThunk,
  startImportFromOtherCloudThunk
} from './otherCloudsSlice'
import { ProfileAddAccountButtons } from './ProfileAddAccountButtons'
import { getCloudLabelByName } from './constants'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'

const getErrorTextByStatus = (status) => {
  switch (status) {
  case 402:
    return 'l_notification_notEnoughStorage'
  case 403:
    return 'l_notification_importNotPossible'
  default:
    return 'l_notification_importFailed'
  }
}

export const ProfileImportFromOtherClouds = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const navigate = useNavigate()

  const linkedClouds = getUserLinkedCloudsSelector()
  const status = getOtherCloudsLoadingStatuSelector()
  const userSettings = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.AVAILABLE_OTHER_CLOUDS)

  const isGoogleAvailable = userSettings?.enable_google_drive
  const isDropboxAvailable = userSettings?.enable_dropbox
  const isYandexAvailable = userSettings?.enable_yandex_disk

  const [taskIdForCancel, setTaskIdForCanel] = useState(null)

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [removingConfirmationModalData, setRemovingConfirmationModalData] = useState({
    type: '',
    accountId: '',
    accountName: ''
  })

  const [yandexImportConfirmationModalData, setYandexImportConfirmationModalData] = useState(null)

  const handleOpenRemovingConfirmationModal = ({ accountName, accountId, type }) => {
    setRemovingConfirmationModalData({ accountName, accountId, type })
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    setRemovingConfirmationModalData({
      type: '',
      accountId: '',
      accountName: ''
    })
  }

  const handleRemoveAccount = () => {
    dispatch(removeOtherCloudThunk({
      accountId: removingConfirmationModalData.accountId,
      type: removingConfirmationModalData.type,
      name: removingConfirmationModalData.accountName,
    }))

    handleCloseRemovingConfirmationModal()
  }

  const handleCloseYandexImportModal = () => {
    setYandexImportConfirmationModalData(null)
  }

  const handleStartImport = ({ accountId, title }) => {
    dispatch(startImportFromOtherCloudThunk({
      accountId,
      name: title
    }))
  }

  const handleStartImportFromYandex = () => {
    const { accountId, title } = yandexImportConfirmationModalData

    dispatch(startImportFromOtherCloudThunk({
      accountId,
      name: title
    }))

    setYandexImportConfirmationModalData(null)
  }

  const handleCloseImportCancellationModal = () => {
    setTaskIdForCanel(null)
  }

  const handleCallbackCancel = () => {
    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: t('l_notification_importCancelletionStarted')
    })
  }

  const handleCancelImport = () => {
    dispatch(cancelImportingFromOtherCloudThunk({ taskId: taskIdForCancel, callback: handleCallbackCancel }))
    setTaskIdForCanel(null)
  }

  useEffect(() => {
    dispatch(fetchLinkedCloudsSourcesThunk({ params: state, navigate }))

    return () => {
      otherCloudsActions.resetState()
    }
  }, [])

  if (!isGoogleAvailable && !isDropboxAvailable && !isYandexAvailable) {
    return null
  }

  return (
    <ProfileImportFromOtherCloudsBox className={className}>
      <TitleRow>
        <Title>
          {t('l_settings_importFromTitle')}
        </Title>
      </TitleRow>

      {
        status === LOADING_STATUSES.LOADING && (
          <SpinnerBox>
            <Spinner />
          </SpinnerBox>
        )
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <>
            {
              !linkedClouds.length && (
                <CloudsBox>
                  <EmptyText>
                    {t('l_settings_importFromMessage')}
                  </EmptyText>
                </CloudsBox>
              )
            }

            {
              !!linkedClouds.length && (
                <CloudsBox>
                  {linkedClouds.map(cloud => (
                    <CloudRow key={cloud.id}>
                      <CloudRowLeft>
                        {cloud.type === 'dropbox' && <CloudIcon iconName="dropbox_gray" />}

                        {cloud.type === 'google' && <CloudIcon iconName="google_gray" />}

                        {cloud.type === 'yandex' && <CloudIcon iconName="yandex_gray" />}

                        <CloudContent>
                          {cloud.name && (!cloud.task || cloud?.task?.state === 'done' || cloud?.task?.state === 'cancellation') && (
                            <AccountName>
                              {cloud.name}
                            </AccountName>
                          )}

                          {
                            cloud.name && cloud?.task?.state && cloud?.task?.state !== 'done' && cloud?.task?.state !== 'cancellation' && (
                              <AccountName>
                                <span>
                                  {t('l_common_importingFrom')}
                                </span>

                                {' '}

                                {cloud.name}
                              </AccountName>
                            )}

                          <AccountDescription>
                            {(cloud?.task?.state === 'done' || cloud?.task?.state === 'cancellation') && (
                              <>
                                {t('l_common_CopiedOutOf', {
                                  0: cloud.task.items_imported,
                                  1: cloud.task.items_count
                                })}
                              </>
                            )}

                            {
                              !cloud.task && (
                                <>
                                  {t('l_common_importNotEnabled')}
                                </>
                              )}

                            {
                              cloud?.task?.state && cloud.task.state === 'added' && (
                                <>
                                  {cloud.task.destination_path}
                                </>
                              )}

                            {
                              cloud?.task?.state && cloud.task.state === 'ready_to_download' && (
                                <>
                                  {cloud.task.destination_path}
                                </>
                              )}
                          </AccountDescription>

                          {
                            !!cloud?.task?.result?.status && cloud?.task?.result?.status !== 200 &&
                                                        (cloud?.task?.result?.status === 403 ? cloud?.task?.result?.status === 'InvalidToken' : true) && (
                              <ErrorBox className="mobile">
                                {t(getErrorTextByStatus(cloud?.task?.result?.status))}
                              </ErrorBox>
                            )}
                        </CloudContent>

                        {
                          !!cloud?.task?.result?.status && cloud?.task?.result?.status !== 200 &&
                                                    (cloud?.task?.result?.status === 403 ? cloud?.task?.result?.status === 'InvalidToken' : true) && (
                            <ErrorBox>
                              {t(getErrorTextByStatus(cloud?.task?.result?.status))}
                            </ErrorBox>
                          )}
                      </CloudRowLeft>

                      <RightBox>
                        {
                          cloud?.task?.state && cloud.task.state !== 'done' && cloud.task.state !== 'cancellation' && (
                            <ProgressBox>
                              <ProgressDetails>
                                <span>
                                  {!!cloud.task.items_count &&
                                      t('l_common_ImportingProgressBar', {
                                        COUNT: cloud.task.items_imported,
                                        TOTAL: cloud.task.items_count
                                      })}
                                </span>

                                <span>
                                  {cloud.task.progress + '%'}
                                </span>
                              </ProgressDetails>

                              <ProgressBar>
                                <ProgressInner width={cloud.task.progress} />
                              </ProgressBar>
                            </ProgressBox>
                          )}

                        <ControlsBox>
                          {
                            (!cloud?.task?.state || cloud?.task?.state === 'done' || cloud?.task.state === 'cancellation') && (
                              <Control onClick={() => handleOpenRemovingConfirmationModal({
                                accountName: getCloudLabelByName(cloud.type),
                                accountId: cloud.id,
                                type: cloud.type
                              })}
                              >
                                {t('a_settings_remove')}
                              </Control>
                            )
                          }

                          {
                            cloud?.task?.state && cloud.task.state === 'cancellation' && (
                              <CancellationControl
                                className="cancellation"
                              >
                                {t('l_notification_canceling')}
                              </CancellationControl>
                            )
                          }

                          {
                            cloud?.task?.state && cloud.task.state !== 'done' && cloud.task.state !== 'cancellation' && (
                              <Control onClick={() => setTaskIdForCanel(cloud.task.id)}
                                className="cancel"
                              >
                                {t('a_common_cancel')}
                              </Control>
                            )
                          }

                          {
                            (!cloud?.task?.state || cloud?.task?.state === 'done')
                                                        && (cloud?.task?.result?.status !== 403 || (cloud?.task?.result?.status === 403 && cloud?.task?.result?.status !== 'InvalidToken')) && (
                              <Control onClick={() => cloud.type === 'yandex' ? setYandexImportConfirmationModalData({
                                title: getCloudLabelByName(cloud.type),
                                accountId: cloud.id
                              }) : handleStartImport({
                                title: getCloudLabelByName(cloud.type),
                                accountId: cloud.id
                              })}
                              className="primary"
                              >
                                {t('a_settings_importFiles')}
                              </Control>
                            )
                          }
                        </ControlsBox>
                      </RightBox>
                    </CloudRow>
                  ))}
                </CloudsBox>
              )
            }

            <StyledAddAccount />
          </>
        )
      }

      <DialogModal
        isOpened={!!taskIdForCancel}
        title={t('l_import_cancel')}
        onClose={handleCloseImportCancellationModal}
        okText={t('a_common_ok')}
        onOk={handleCancelImport}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseImportCancellationModal}
      >
        <TextInModalBox>
          {t('l_import_cancelMessage')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_removeAccount')}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveAccount}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_settings_deleteAccountMessage', { NAME: removingConfirmationModalData.accountName })}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={!!yandexImportConfirmationModalData}
        title={t('l_common_areYouSure')}
        onClose={handleCloseYandexImportModal}
        okText={t('a_settings_startImport')}
        onOk={handleStartImportFromYandex}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseYandexImportModal}
      >
        <SYandexModalText>
          {t('l_loading_yandex')}
        </SYandexModalText>
      </DialogModal>
    </ProfileImportFromOtherCloudsBox>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const ProfileImportFromOtherCloudsBox = styled.div`
  margin-top: 33px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: block;
  }
`
const TitleRow = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  order: 1;
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const CloudsBox = styled.div`
  order: 3;
  margin-top: 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 0;
  }
`

const CloudRow = styled.div`
  background: var(--background-secondary);
  border: 1px solid var(--divider-primary);
  border-radius: 10px;
  padding: 24px;

  & ~ & {
    margin-top: 16px;
  }

  &.no-bg {
    background: unset;
    border: unset;
    padding: 0;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: flex;
    align-items: center;
    min-height: 69px;
    background: unset;
    border: unset;
    padding: 0;

    & ~ & {
      margin-top: 0;
    }
  }
`

const CloudRowLeft = styled.div`
  display: flex;
  align-items: center;
`

const CloudIcon = styled(SpriteIcon)`
  && {
    color: var(--text-secondary);
    width: 32px;
    height: 32px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    && {
      width: 24px;
      height: 24px;
    }
  }
`

const CloudContent = styled.div`
  margin-left: 12px;
`

const AccountName = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: var(--text-primary);

  span {
    display: none;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    font-size: 14px;

    span {
      display: unset;
    }
  }
`

const AccountDescription = styled.h5`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--text-secondary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    font-size: 12px;
  }
`

const RightBox = styled.div`
  margin-top: 10px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 0;
    margin-left: auto;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
`

const ProgressBox = styled.div`
  width: 100%;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: unset;
  }
`

const ProgressDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 152.34%;
  letter-spacing: 0.04em;
  color: var(--text-primary);
`

const ProgressBar = styled.div`
  margin-top: 2px;
  width: 100%;
  height: 4px;
  background: #EFEFF4;
  border-radius: 100px;
  overflow: hidden;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 8px;
    width: 325px;
  }
`

const ProgressInner = styled.div`
  background: var(--brand-progress-line);
  border-radius: 100px;
  height: 100%;
  width: ${props => props.width}%;
`

const ControlsBox = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: unset;
    width: unset;
  }
`

const Control = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--accent-normal);
  cursor: pointer;
  width: 48%;
  height: 32px;
  border: 1px solid var(--stroke-primary);
  border-radius: 4px;

  &.primary {
    background: var(--button-primary-default);
    border: 1px solid var(--button-primary-default);
    color: var(--button-primary-text);
  }

  &.cancel {
    width: 100%;
  }

  &.cancellation {
    color: var(--transformer-button-primary-text-disabled);
    cursor: unset;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    font-size: 14px;
    margin-left: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--accent-normal);
    width: unset;
    border: unset;

    &.primary {
      width: unset;
      border: unset;
      background: unset;
      color: var(--accent-normal);
    }
  }
`

const CancellationControl = styled(Control)`
  animation: blink 1s infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

const EmptyText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-primary);
  display: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: block;
  }
`

const StyledAddAccount = styled(ProfileAddAccountButtons)`
  margin-top: 0;
  order: 2;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 20px;
  }
`

const SpinnerBox = styled.div`
  display: flex;
  justify-content: center;
`

const ErrorBox = styled.div`
  margin-left: 20px;
  background: var(--background-tertiary);
  border-radius: 4px;
  padding: 0 8px;
  height: 24px;
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  &.mobile {
    margin-left: 0;
    margin-top: 2px;
    display: flex;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: flex;

    &.mobile {
      display: none;
    }
  }
`

const SYandexModalText = styled.p`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 572px;
`
