import { getModalsRootElement } from 'constants/modals'
import { formatImgUrl } from 'constants/configs/theme.config'
import APP_CONFIG from "constants/configs/app.config"
import { NOTIFICATION_IDS } from 'constants/notifications'

import React, { useCallback, useEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'
import { PageOverlay, PrimaryButton, SpriteIcon } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { getFileContentUrl } from 'utils/files'
import { getDocumentsMasterKeySelector } from 'features/documents/selectors'
import { generateDocumentFileUrl } from 'features/documents/documentsSlice'
import { insertStyleToIframe } from 'utils/iframe'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { useDocumentKeyDown } from 'features/common/hooks/useDocumentKeyDown'
import _ from "lodash"
import { IItemSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumItem"
import { t } from "i18next"
import classNames from "classnames"
import { Tooltip } from 'react-tooltip'
import { useLocation, useNavigate } from "react-router-dom"
import { InfoTagModal } from 'features/common/info-tag-modal/InfoTagModal'

import {
  getPhotoPreviewDuplicateItemsSelector,
  getPhotoPreviewDuplicateModeSelector
} from "../photo-preview-duplicate/selectors"
import { DuplicateLine } from "../photo-preview-duplicate/DuplicateLine"
import { photoPreviewDuplicateActions } from "../photo-preview-duplicate/photoPreviewDuplicateSlice"
import { useMobileDetection } from "../../common/hooks"
import {
  ArrowBox,
  ArrowContainer,
  ArrowIcon,
  FilePreview,
  Iframe,
  ImageBox,
  Img,
  MainContent,
  PreviewModalBox,
  SHiddenImg,
  StyledSpinner,
  TopRow,
} from "../../common/preview-modal/styles"
import { VideoPreview } from "../../common/preview-modal/Video"
import { getFaceBoxSize } from "../../common/preview-modal/getFaceBoxSize"
import { ContentPlaceholder } from "../../common/preview-modal/ContentPlaceholder"
import { getPhotoSdk } from "../../../sdk/photo"
import { NOTIFICATION_TYPES, showNotification, hideNotificationById } from "../../common/notifications"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { getIsSomeSpecialModalOpened } from "../../common/selectors"

import { LivePhotoPreview } from "./LivePhotoPreview"
import {
  getCurrentItemExtensionThunk,
  getCurrentItemFacesDataThunk,
  getLivePhotoPreviewContentDataUrlThunk,
  getTagsThunk,
  handleNextPhotoThunk,
  handlePrevPhotoThunk,
  photoPreviewActions,
  PREVIEW_TYPES,
} from './photoPreviewSlice'
import {
  getAreFacesVisibleSelector,
  getCurrentFacesDate,
  getCurrentItemExtensionsSelector,
  getCurrentLivePhotoPreviewContentDataUrl,
  getCurrentPhotoPreviewItemIndexSelector,
  getCurrentPhotoPreviewModalStatusSelector, getCurrentPhotoPreviewSdkTypeSelector,
  getCurrentPhotoPreviewTypeSelector,
  getIsLivePhotoPlaying,
  getIsLoadingFaces,
  getLivePhotoPreviewContentUrlSelector,
  getPhotoPreviewErrorSelector,
  getPhotoPreviewItemsSelector,
  getPhotoPreviewLoadingMoreSelector,
  getPhotoPreviewLoadingSelector,
  getPhotoPreviewTotalItemsCountSelector
} from './selectors'
import { PhotoPreviewToolbar } from './PhotoPreviewToolbar'


export const PhotoPreviewModal = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isVisibleTag = APP_CONFIG.isVisibleTag

  const isSomeSpecialModalOpened = getIsSomeSpecialModalOpened()
  const isMobile = useMobileDetection()
  const items = getPhotoPreviewItemsSelector()
  const totalItemsCount = getPhotoPreviewTotalItemsCountSelector()
  const currentIndex = getCurrentPhotoPreviewItemIndexSelector()
  const facesData = getCurrentFacesDate()
  const isLoadingFaces = getIsLoadingFaces()
  const isModalOpened = getCurrentPhotoPreviewModalStatusSelector()
  const isLoading = getPhotoPreviewLoadingSelector()
  const isLoadingMore = getPhotoPreviewLoadingMoreSelector()
  const hasError = getPhotoPreviewErrorSelector()
  const type = getCurrentPhotoPreviewTypeSelector()
  const sdkType = getCurrentPhotoPreviewSdkTypeSelector()
  const areFacesVisible = getAreFacesVisibleSelector()

  const masterKey = getDocumentsMasterKeySelector()

  const isEnabledPreviewDuplicateMode = getPhotoPreviewDuplicateModeSelector()
  const duplicateItems = getPhotoPreviewDuplicateItemsSelector()
  const selectDuplicateItem = duplicateItems.find(item => item.isSelect)

  const [previewUrl, setPreviewUrl] = useState(null)
  const isLivePhotoPlaying = getIsLivePhotoPlaying()

  const [currentItem, setCurrentItem] = useState<any>(items[currentIndex])
  const [currentItemFacesData, setCurrentItemFacesData] = useState<any>()
  const [currentItemHeightIsGreater, setCurrentItemHeightIsGreater] = useState<any>(false)
  const [toggleResize, setToggleResize] = useState(false)
  const [resizeInProgress, setResizeInProgress] = useState(false)
  const imgRef = useRef(null)
  const facesTooltipRef = useRef(null)
  const [firstViewFaceBox , setFirstViewFaceBox] = useState(true)

  useEffect(() => {
    if (isEnabledPreviewDuplicateMode && !!duplicateItems.length) return

    if (!items[currentIndex] && currentIndex > 0) {
      dispatch(photoPreviewActions.setCurrentItemIndex(items.length - 1))
    }

    setCurrentItem(items[currentIndex])
  }, [currentIndex, items])

  useEffect(() => {
    const handleResize = () => {
      setToggleResize(prev => !prev)
      dispatch(photoPreviewActions.setAreFacesVisible(false))
      const windowWidth = window.innerWidth
      const timer = setTimeout(() => {
        if(windowWidth === window.innerWidth) {
          dispatch(photoPreviewActions.setAreFacesVisible(true))
          clearTimeout(timer)
          setResizeInProgress(false)
        } else if(resizeInProgress)  {
          clearTimeout(timer)
        } else {
          setResizeInProgress(true)
        }
      }, 200)
    }


    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    let timer
    if (!isLoading && !isLoadingMore && !isLoadingFaces) {
      setFirstViewFaceBox(true)
      const currentFaceItemSizeObj = getFaceFormatData(imgRef)
      setCurrentItemFacesData(currentFaceItemSizeObj)
      if(!!timer) {
        clearTimeout(timer)
      }
      timer = areFacesVisible && setTimeout(() => {
        setFirstViewFaceBox(false)
      }, 100)
    }
  }, [facesData, isLoading, isLoadingMore, isLoadingFaces, currentItemHeightIsGreater,areFacesVisible,toggleResize])


  useEffect(() => {
    items[currentIndex] && setCurrentItemHeightIsGreater(items[currentIndex].height < items[currentIndex].width)
  }, [currentItem])

  useEffect(() => {
    if (isEnabledPreviewDuplicateMode && !duplicateItems.length) {
      dispatch(photoPreviewDuplicateActions.setDuplicateMode(false))
    }
  },[currentItem])

  useEffect(() => {
    if (!!selectDuplicateItem) {
      setCurrentItem(selectDuplicateItem)
      setPreviewUrl(selectDuplicateItem._links.image_preview.href)
    }
  },[selectDuplicateItem])

  useEffect(() => {
    if (isEnabledPreviewDuplicateMode && !duplicateItems.length) {
      dispatch(photoPreviewActions.resetState())
      dispatch(photoPreviewDuplicateActions.resetState())
    }
  },[duplicateItems])

  const livePhotoPreviewContentUrl = getLivePhotoPreviewContentUrlSelector()
  const itemExtensions = getCurrentItemExtensionsSelector()
  const livePhotoPreviewContentDataUrl = getCurrentLivePhotoPreviewContentDataUrl()

  const loadingTimeoutRef = useRef(null)
  const abortControllerRef = useRef(null)

  useEffect(() => {
    dispatch(photoPreviewActions.clearLivePhotoPreviewContentDataUrl())
    if(!_.isNil(itemExtensions) && itemExtensions.length > 0) {
      dispatch(getLivePhotoPreviewContentDataUrlThunk(livePhotoPreviewContentUrl))
    }
  },[itemExtensions])

  const handleFinishLoading = () => {
    dispatch(photoPreviewActions.setLoading(false))
    clearTimeout(loadingTimeoutRef.current)
  }

  const handleCloseDuplicate = () => {
    dispatch(photoPreviewDuplicateActions.resetState())
    setCurrentItem(items[currentIndex])
    setPreviewUrl(items[currentIndex]._links.image_preview.href)
  }

  const handleClose = () => {
    if (isSomeSpecialModalOpened) {
      return
    }

    hideFeedbackNotification()

    dispatch(photoPreviewActions.resetState())
    dispatch(photoPreviewDuplicateActions.resetState())
  }

  const handleToggleFacesVisibility = () => {
    dispatch(photoPreviewActions.setAreFacesVisible(!areFacesVisible))
  }

  let timerHidden

  const handleRerenderFaceBox = () => {
    dispatch(photoPreviewActions.setAreFacesVisible(false))
    if(!!timerHidden) {
      clearTimeout(timerHidden)
    }

    timerHidden = setTimeout(() => {
      dispatch(photoPreviewActions.setAreFacesVisible(true))
    }, 400)
  }

  const hideFeedbackNotification = () => {
    hideNotificationById(NOTIFICATION_IDS.PHOTO_AI_FEEDBACK)
  }

  const handleNext = useCallback(() => {
    if (isEnabledPreviewDuplicateMode) return

    hideFeedbackNotification()

    if (currentIndex >= totalItemsCount - 1 || !isModalOpened || isSomeSpecialModalOpened) {
      return
    }
    handleRerenderFaceBox()
    dispatch(handleNextPhotoThunk())

    if (type === PREVIEW_TYPES.DOCUMENTS) {
      analytics.push(ANALYTICS_EVENTS.WEB_DOCUMENTS_PREVIEW_SWIPE)
    }
  }, [currentIndex, totalItemsCount, isModalOpened, isEnabledPreviewDuplicateMode, currentItemHeightIsGreater, isSomeSpecialModalOpened])

  const handlePrev = useCallback(() => {
    if (isEnabledPreviewDuplicateMode) return

    hideFeedbackNotification()

    if (currentIndex <= 0 || !isModalOpened || isSomeSpecialModalOpened) {
      return
    }

    handleRerenderFaceBox()

    dispatch(handlePrevPhotoThunk())

    if (type === PREVIEW_TYPES.DOCUMENTS) {
      analytics.push(ANALYTICS_EVENTS.WEB_DOCUMENTS_PREVIEW_SWIPE)
    }
  }, [currentIndex, isModalOpened, isEnabledPreviewDuplicateMode, currentItemHeightIsGreater, isSomeSpecialModalOpened])

  useDocumentKeyDown(37, handlePrev)
  useDocumentKeyDown(39, handleNext)
  useDocumentKeyDown(27, handleClose)

  const handleError = () => {
    dispatch(photoPreviewActions.setLoading(false))
    dispatch(photoPreviewActions.setError(true))
  }

  const handlePausedLivePhotoPlaying = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_LIVEPHOTO_PAUSE)
    dispatch(photoPreviewActions.pausedLivePhotoPlaying())
  }

  const handleEndedLivePhotoPlaying = () => {
    dispatch(photoPreviewActions.pausedLivePhotoPlaying())
  }

  const handleStartLivePhotoPlaying = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_LIVEPHOTO_PLAY)
    dispatch(photoPreviewActions.startLivePhotosPlaying())
  }

  const handleCanLivePhotoPlay = () => {
    dispatch(photoPreviewActions.setLivePhotoCanPlay(true))
  }

  const handleResetLivePhotoCanPlay = () => {
    dispatch(photoPreviewActions.setLivePhotoCanPlay(false))
  }

  useEffect(() => {
    if (!isModalOpened) {
      dispatch(photoPreviewActions.resetState())
    }
  }, [isModalOpened])

  useEffect(() => {
    clearTimeout(loadingTimeoutRef.current)
    setPreviewUrl(null)

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
      abortControllerRef.current = null
    }

    const fetchFileData = async () => {
      dispatch(photoPreviewActions.setError(false))
      dispatch(photoPreviewActions.setLoading(true))

      try {
        abortControllerRef.current = new AbortController()

        if (type === PREVIEW_TYPES.DOCUMENTS) {
          if (currentItem.type === 'image') {
            const url = await generateDocumentFileUrl(currentItem, masterKey, 'preview', abortControllerRef.current)
            setPreviewUrl(url)
          }

          if (currentItem.type === 'pdf') {
            const url = await generateDocumentFileUrl(currentItem, masterKey, 'content', abortControllerRef.current)
            setPreviewUrl(url)
          }
        } else if (currentItem.type === 'video') {
          const url = await getFileContentUrl(currentItem._links.content.href, abortControllerRef.current)

          setPreviewUrl(url)
        } else if (currentItem.type === 'image') {
          setPreviewUrl(currentItem._links.image_preview.href)
          if (currentItem._links.image_preview.href === previewUrl) return handleFinishLoading()
        }

        clearTimeout(loadingTimeoutRef.current)

        loadingTimeoutRef.current = setTimeout(() => {
          dispatch(photoPreviewActions.setLoading(false))
          dispatch(photoPreviewActions.setError(true))
        }, 60000)
      } catch (error) {
        if (error?.name === 'AbortError') {
          return
        }
        dispatch(photoPreviewActions.setLoading(false))
        dispatch(photoPreviewActions.setError(true))
      }
    }

    if (currentItem) {
      fetchFileData()

      if (type !== PREVIEW_TYPES.TRASH_BIN) {
        dispatch(getCurrentItemExtensionThunk({ item: currentItem as IItemSchema }))
        dispatch(getCurrentItemFacesDataThunk({ item: currentItem as IItemSchema }))
      }

      setCurrentItemHeightIsGreater(currentItem.height < currentItem.width)
    }
  }, [currentItem])

  const prevImageUrl = items[currentIndex - 1]?.type === 'image' ? items[currentIndex - 1]?._links?.image_preview?.href : ''
  const nextImageUrl = items[currentIndex + 1]?.type === 'image' ? items[currentIndex + 1]?._links?.image_preview?.href : ''


  const getFaceFormatData = (img) => {
    if (!img.current || !currentItem) return
    else {
      return facesData.map((face) => {
        let personId

        if (face?._links?.person_album?.href) {
          const splittedLink = face?._links?.person_album?.href.split('/')

          personId = splittedLink[splittedLink.length - 2]
        }

        return {
          ...getFaceBoxSize({
            naturalImgWidth: currentItem?.width,
            naturalImgHeight: currentItem?.height,
            imgParentWidth: img.current?.parentElement.clientWidth,
            imgParentHeight: img.current?.parentElement.clientHeight,
            faceBorderCoordinate: {
              top: face?.coordinates[0],
              right: face?.coordinates[1],
              bottom: face?.coordinates[2],
              left: face?.coordinates[3]
            }
          }),
          description: face.description,
          personId
        }
      })

    }
  }

  const handleDownloading = () => {
    const photoSdk = getPhotoSdk()
    const link = currentItem._links.content.href
    if(link) {
      photoSdk.timeline
        .getItemContentByLink(link)
        .then(({ data }) => {
          const url = data?._links?.data?.href
          if(url) {
            window.location.href = url
          }
        }).catch(() => {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_common_wentWrong')
          })
        })
    }
  }

  const handlePersonClick = (event, id: string) => {
    event.preventDefault()
    event.stopPropagation()

    if (!id || location.pathname.includes(id) ) {
      return
    }

    const albumPath = sdkType === SDK_TYPES.DEFAULT ? `/photos/albums/people/${id}` : `/family/photos/albums/people/${id}`

    navigate(albumPath)
  }

  const handleTagAction = async () => {
    dispatch(getTagsThunk({ id: currentItem.id, callback: () => setOpenedTagModal(true) }))
  }

  const handleCloseModal = () => {
    setOpenedTagModal(false)
  }

  const [isOpenedTagModal, setOpenedTagModal] = useState(false)

  return ReactDOM.createPortal(
    <>
      {
        isModalOpened && (
          <PhotoPreviewModalBox alignItems={isEnabledPreviewDuplicateMode && isMobile && 'flex-start'}>
            <SHiddenImg src={prevImageUrl} />

            <SHiddenImg src={nextImageUrl} />

            <TopRow>
              {!!currentItem && (
                <PhotoPreviewToolbar />
              )}
            </TopRow>

            <MainContentBox height={isEnabledPreviewDuplicateMode ?
              isMobile ? 'calc(100% - 128px)' : 'calc(100% - 256px)'
              : isMobile ? 'calc(100%)' : 'calc(100% - 120px)'}
            >

              <ArrowContainer>
                {
                  !isEnabledPreviewDuplicateMode && currentIndex > 0 && (
                    <ArrowBox
                      onClick={handlePrev}
                    >
                      <ArrowIcon iconName="arrow_left" />
                    </ArrowBox>
                  )
                }
              </ArrowContainer>

              <ImageBox
                onClick={handleToggleFacesVisibility}
              >
                {!(isLoading || isLoadingMore || isLoadingFaces) && areFacesVisible && !!imgRef.current && !!currentItemFacesData && currentItemFacesData.map((item) => {
                  const uniqId = Math.random()
                  return (
                    <>
                      <FaceBox
                        className={classNames({ 'firstPosition':firstViewFaceBox })}
                        key={item.top + item.left}
                        top={item.top.toString() + 'px'}
                        left={item.left.toString() + 'px'}
                        width={item.width.toString() + 'px'}
                        height={item.height.toString() + 'px'}
                        firstTop={item.firstTop.toString() + 'px'}
                        firstLeft={item.firstLeft.toString() + 'px'}
                        firstWidth={item.firstWidth.toString() + 'px'}
                        firstHeight={item.firstHeight.toString() + 'px'}
                        data-tooltip-id={item.description ? `person-name-tooltip` + uniqId : undefined}
                        data-tooltip-content={item.description}
                        data-tooltip-place="bottom"

                        onClick={(event) => handlePersonClick(event, item.personId)}
                      />


                      <STooltip
                        id={`person-name-tooltip` + uniqId}
                        openOnClick={isMobile}
                        defaultIsOpen={true}
                        isOpen={true}
                        clickable
                        ref={facesTooltipRef}
                        render={({ content }) => (
                          <STooltipContent>
                            <span onClick={(event) => handlePersonClick(event, item.personId)}>
                              {content}
                            </span>

                            <SCloseTooltipIcon iconName={'close'} />
                          </STooltipContent>
                        )}
                      />
                    </>
                  )})
                }

                {
                  !!currentItem && currentItem.type === 'image' && !!previewUrl && !hasError && !livePhotoPreviewContentDataUrl && (
                    <Img
                      ref={imgRef}
                      src={previewUrl}
                      onLoad={handleFinishLoading}
                      onError={handleError}
                      fetchPriority='high'
                      className={classNames({ 'not-loaded' : isLoading })}
                    />
                  )
                }

                {
                  !!currentItem && currentItem.type === 'image' && !!previewUrl && !hasError && !!livePhotoPreviewContentDataUrl && (
                    <LivePhotoPreview
                      posterUrl={previewUrl}
                      onResetCanPlay={handleResetLivePhotoCanPlay}
                      onCanPlay={handleCanLivePhotoPlay}
                      onPlay={handleStartLivePhotoPlaying}
                      onPaused={handlePausedLivePhotoPlaying}
                      onEnded={handleEndedLivePhotoPlaying}
                      src={livePhotoPreviewContentDataUrl}
                      isPlaying={isLivePhotoPlaying}
                      onLoad={handleFinishLoading}
                      onError={handleError}
                    />
                  )
                }

                {
                  !!currentItem && currentItem.type === 'video' && !!previewUrl && !hasError && (
                    <VideoPreview
                      src={previewUrl + '#t=0.001'}
                      onCanPlay={handleFinishLoading}
                      onError={handleError}
                    />
                  )
                }

                {
                  !!currentItem && currentItem.type === 'pdf' && !!previewUrl && !hasError && (
                    <Pdf onLoad={handleFinishLoading}
                      src={formatImgUrl(`/pdf-viewer/web/viewer.html?file=${previewUrl}`)}
                    />
                  )
                }

                {
                  hasError && (
                    <FilePreview>
                      <ContentPlaceholder
                        currentItem={currentItem}
                        onClickDownload={handleDownloading}
                      />
                    </FilePreview>
                  )}

                {(isLoading || isLoadingMore) && <StyledSpinner color='#E1E1E2' />}

                {isMobile && <ShadowLayerTop />}
              </ImageBox>

              <ArrowContainer className="right">
                {
                  !isEnabledPreviewDuplicateMode && currentIndex < totalItemsCount - 1 && (
                    <ArrowBox
                      className="rotated"
                      onClick={handleNext}
                    >
                      <ArrowIcon iconName="arrow_left" />
                    </ArrowBox>
                  )
                }
              </ArrowContainer>
            </MainContentBox>

            { isEnabledPreviewDuplicateMode &&  <DuplicateLine /> }

            { isEnabledPreviewDuplicateMode && (
              <CloseDuplicateIcon iconName={'close'}
                onClick={handleCloseDuplicate}
              />
            )}

            { isVisibleTag && sdkType === SDK_TYPES.DEFAULT && ( 
              <StyledTegPrimaryButton
                key={'buttonTegAction'}
                actionName={'Tags'}
                onAction={handleTagAction}
              />
            ) 
            }

            { isVisibleTag && sdkType === SDK_TYPES.DEFAULT && ( 
              <InfoTagModal
                handleCloseModal={handleCloseModal}
                isOpened={isOpenedTagModal}
              />
            ) 
            }


          </PhotoPreviewModalBox>
        )}

      <PageOverlay
        active={isModalOpened}
        backgroundColor="rgba(0, 0, 0, 0.8)"
      />


    </>, getModalsRootElement()
  )
}

const Pdf = ({ src, onLoad }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const styles = [
      'body {background-image: none !important}',
      '#openFile, #viewBookmark {display: none !important}',
      '#secondaryOpenFile, #secondaryDownload, #secondaryViewBookmark {display: none !important}',
      '#secondaryToolbarButtonContainer #firstPage {display: none !important}',
      '#secondaryToolbarButtonContainer #lastPage {display: none !important}',
      '#secondaryToolbarButtonContainer #toggleHandTool {display: none !important}',
      '#secondaryToolbarButtonContainer #documentProperties {display: none !important}',
      '#secondaryToolbarButtonContainer .horizontalToolbarSeparator {display: none !important}',
      '#toolbarViewerLeft {display: none !important}',
      '#toolbarViewerRight {right: 36px !important}',
      '#editorModeButtons {display: none !important}',
      '#editorModeSeparator {display: none !important}',
      '#secondaryToolbarToggle {display: none !important}',
      '.verticalToolbarSeparator {display: none !important}'
    ].join('')

    setTimeout(() => insertStyleToIframe(iframeRef.current.contentWindow, styles), 300)
  }, [])

  return (
    <Iframe id="pdf-js-viewer"
      src={src}
      title="webviewer"
      frameBorder="0"
      onLoad={onLoad}
      ref={iframeRef}
    />
  )
}

const FaceBox = styled.div`
  position: absolute;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.50);
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.width};
  height: ${props => props.height};
  cursor: pointer;
  transition: all ease-in-out 500ms;
  
  &.firstPosition {
    top: ${props => props.firstTop};
    left: ${props => props.firstLeft};
    width: ${props => props.firstWidth};
    height: ${props => props.firstHeight};
  }
`

const MainContentBox = styled(MainContent)`
  height: ${props => props.height};
`

const PhotoPreviewModalBox = styled(PreviewModalBox)`
  align-items: ${props => props.alignItems}
`

const CloseDuplicateIcon = styled(SpriteIcon)`
  position: absolute;
  bottom: 134px;
  right: 20px;
  cursor: pointer;
  color: #FFFFFF;
`

const ShadowLayerTop = styled.div`
  position: absolute;
  top: 0;
  height: 142px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
`

const STooltip = styled(Tooltip)`
  && {
    min-height: 36px;
    word-wrap: break-word;
    max-width: 320px;
    border-radius: 6px !important;
    z-index: 3;
    display: flex;
    align-items: center;
    overflow: hidden;

    .react-tooltip-content-wrapper {
      width: 100%;
    }
  }
`

const STooltipContent = styled.div`
  display: flex;
  align-items: flex-end;
  line-height: 1;
  font-size: 14px;
  width: 100%;

  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  span {
    cursor: pointer;
  }
`

const SCloseTooltipIcon = styled(SpriteIcon)`
  margin-left: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: #FFFFFF;
`

const StyledTegPrimaryButton = styled(PrimaryButton)`
  position: fixed;
  bottom: 20px;
  left: 20px;

`
