import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { sharedWithMeAlbumsSelectors } from "./sharedWithMeAlbumsSlice"

export const getSharedWithMeAlbumsLoadingStatusSelector = () => useAppSelector(state => state.sharedWithMeAlbums.status)

export const getSharedWithMeAlbumsItemsRawSelector = state => sharedWithMeAlbumsSelectors.selectAll(state.sharedWithMeAlbums)

export const getSharedWithMeAlbumsItemsSelector = () => useAppSelector(getSharedWithMeAlbumsItemsRawSelector)

export const getSharedWithMeAlbumsSelectedItemsIdsRawSelector = state =>
  state.sharedWithMeAlbums.selectedItemsIds

export const getSharedWithMeAlbumsSelectedItemsIdsSelector = () => useAppSelector(getSharedWithMeAlbumsSelectedItemsIdsRawSelector)

export const getSharedWithMeAlbumsSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getSharedWithMeAlbumsItemsRawSelector,
    getSharedWithMeAlbumsSelectedItemsIdsRawSelector,
    (items, selectedItemsIds) =>
      items.filter(item => selectedItemsIds.includes(item.id))
  )
)
