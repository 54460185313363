import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { getPhotoSdk } from 'sdk/photo'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { getAlbumsSdkByType } from 'sdk/albums'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { getErrorData } from 'utils/getErrorData'
import i18n from 'i18n'
import { request } from 'api/request'
import { RootState } from 'store'

const adapter = createEntityAdapter<IAlbumSchema>()

export const sharedWithMeAlbumsSelectors = adapter.getSelectors()

const sortByDate = (items: IAlbumSchema[]) => items.sort((a, b) => {
  if (b.last_photo_created && !a.last_photo_created) {
    return 1
  }

  if (a.last_photo_created && !b.last_photo_created) {
    return -1
  }

  if (!a.last_photo_created && !b.last_photo_created) {
    return a.created - b.created
  }

  return b.last_photo_created - a.last_photo_created
})

export const loadSharedWithMeAlbumsFirstTimeThunk = createAsyncThunk(
  'sharedWithMeAlbums/loadSharedWithMeAlbumsFirstTimeThunk',
  async function() {
    const sdk = getPhotoSdk()
    const response = await sdk.publicLinksService.getAllSharedWithMe()

    return {
      items: response.data._embedded.albums
    }
  }
)

export const downloadSharedWithMeAlbumThunk = createAsyncThunk(
  'sharedWithMeAlbums/downloadAlbumThunk',
  async function({ id }: {id: string }, { dispatch, getState }) {
    try {
      const state = getState() as RootState
      const userId = state.user.userData.id

      showGlobalProgressLoader()
      const publicLinksService = getPhotoSdk().publicLinksService

      const response = await publicLinksService.getPublicShare(id, userId)
      const data = response.data

      const notMyResource = data?._links?.not_my_resource?.href

      const notMyResourceResponse = await request('GET', notMyResource, {}, { host: null })

      const {
        data: {
          _links: {
            zip_stream: { href },
          },
        },
      } = await publicLinksService.createSharedWithMeItemsZipByLink(notMyResourceResponse._links.items_downloads.href, {})

      window.location.href = href
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const copySharedWithMeAlbumThunk = createAsyncThunk(
  'sharedWithMeAlbums/copySharedWithMeAlbumThunk',
  async function({ sourceUrl }: { sourceUrl: string }, { dispatch }) {
    try {
      const albumsSdk = getAlbumsSdkByType(SDK_TYPES.DEFAULT)

      showGlobalProgressLoader()

      await albumsSdk.copyAlbum(sourceUrl)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_copiedToPersonalCloud')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const renameSharedWithMeAlbumThunk = createAsyncThunk(
  'sharedWithMeAlbums/renameAlbumThunk',
  async function({ id, name } : { name: string, id: string }, { dispatch }) {
    try {
      const publicLinksService = getPhotoSdk().publicLinksService
      
      showGlobalProgressLoader()

      await publicLinksService.updateSharedWithMe(id, { description: name })

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumRenamed')
      })

      dispatch(actions.updateItem({ id, description: name }))
      dispatch(actions.unselectAll())
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteSharedWithMeAlbumThunk = createAsyncThunk(
  'sharedWithMeAlbums/deleteAlbumThunk',
  async function({ id }: { id: string }, { dispatch }) {
    try {
      const publicLinksService = getPhotoSdk().publicLinksService

      showGlobalProgressLoader()

      await publicLinksService.deleteSharedWithMe(id)

      dispatch(actions.deleteItem(id))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_linkRemoved')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const sharedWithMeAlbumsSlice = createSlice({
  name: 'sharedWithMeAlbums',
  initialState: adapter.getInitialState({
    type: null,
    status: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    error: '',
    totalItemsCount: 0,
    selectedItemsIds: []
  }),
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setPublicLinksType: (state, action) => {
      state.type = action.payload
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedItemsIds = [id]
      } else {
        state.selectedItemsIds = []
      }
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    deleteItem: (state, action) => {
      adapter.removeOne(state, action.payload)
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, sortByDate(action.payload))
    },
    unselectItems: (state, action) => {
      state.selectedItemsIds = state.selectedItemsIds.filter(id => !action.payload.includes(id))
    },
    unselectAll: (state) => {
      state.selectedItemsIds = []
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      state.selectedItemsIds = []
      adapter.removeAll(state)
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadSharedWithMeAlbumsFirstTimeThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(loadSharedWithMeAlbumsFirstTimeThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
      })
      .addCase(loadSharedWithMeAlbumsFirstTimeThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

const {
  reducer, actions
} = sharedWithMeAlbumsSlice

export { reducer as sharedWithMeAlbumsReducer, actions as sharedWithMeAlbumsActions }
