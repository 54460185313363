import { useEffect } from "react"

import { useNavigate } from "react-router-dom"

import { useLoginByKeyMutation } from "../../api/loginByKey"
import { CLIENT_KEY_IN_STORAGE, USER_DATA_KEY_IN_STORAGE } from "../../features/user/constants"
import { redirectToExternalSSO } from "../../utils/externalSSO"
import { isQA } from "../../features/common/api-switcher/apiSwitcherService"

const SignIn = () => {
  const navigate = useNavigate()
  const [loginByKey, loginByKeyResult] = useLoginByKeyMutation()

  const clearUserData = () => {
    const checkIsQA = isQA()

    clearLocalStorage(checkIsQA)
    clearSessionStorage()
  }

  const clearSessionStorage = () => {
    sessionStorage.clear()
  }

  const clearLocalStorage = (isQA: boolean) => {
    // if it's not QA clear all local storage
    if (!isQA) {
      const savedTimeOfHideMobileAppPopup = localStorage.getItem('timeOfHideMobileAppPopup')
      localStorage.clear()

      if (savedTimeOfHideMobileAppPopup) {
        localStorage.setItem('timeOfHideMobileAppPopup', savedTimeOfHideMobileAppPopup)
      }
    } else {
      // if api switcher is visible don't clear 'API_KEY' key from local storage
      localStorage.removeItem(USER_DATA_KEY_IN_STORAGE)
      localStorage.removeItem(CLIENT_KEY_IN_STORAGE)
    }
  }

  const setUserData = (token) => {
    localStorage.setItem(USER_DATA_KEY_IN_STORAGE, JSON.stringify({ CurrentUser: { token } }))
    localStorage.setItem(CLIENT_KEY_IN_STORAGE, JSON.stringify({ token }))
  }

  const saveToken = (token) => {
    clearUserData()
    setUserData(token)
  }

  const redirect = (keyParam: string, toUrlParam: string) => {
    if (!!keyParam) return loginByKey(keyParam)
    if (!!toUrlParam) {
      const toUrlParse = new URL(toUrlParam)
      return navigate(toUrlParse.pathname + toUrlParse.search)
    }
    navigate('/drive')
  }

  useEffect(() => {
    const url = new URL(window.location.href)
    const keyParam = url.searchParams.get('key')
    const token = url.searchParams.get('token')
    const toUrlParam = url.searchParams.get('to')

    if (!!token) {
      saveToken(token)
    }

    redirect(keyParam, toUrlParam)

  }, [])

  useEffect(() => {
    const { isSuccess, isError, data } = loginByKeyResult

    if (isSuccess) {
      const token = data.token

      if (!!token) {
        saveToken(token)

        navigate('/drive')
      }
    }

    if (isError) {
      redirectToExternalSSO()
    }
  }, [loginByKeyResult.isSuccess, loginByKeyResult.isError, loginByKeyResult.data])

  return (<></>)
}

export default SignIn
