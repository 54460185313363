import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'
import { ALBUMS_COUNT_PER_PAGE } from 'constants/pagination'

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { getPhotoSdk, getPhotosWS } from 'sdk/photo'
import { AlbumType } from '@cloudike/web_photos'
import { AlbumsPaginator } from '@cloudike/web_photos/dist/types/lib/Paginator/AlbumsPaginator'
import { RootState } from 'store'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { getAlbumsSdkByType } from 'sdk/albums'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { getErrorData } from 'utils/getErrorData'
import i18n from 'i18n'
import _ from 'lodash'

import { publicLinksApi } from "../../api/publicLinksApi"
import { TOTAL_COUNT_HEADER } from "../../constants/headers"

export enum PUBLIC_LINKS_TYPES {
  FILES,
  ALBUMS,
  FAMILY_ALBUMS
}

const adapter = createEntityAdapter<IAlbumSchema>()

export const publicLinksSelectors = adapter.getSelectors()

let paginator: AlbumsPaginator | null = null

const sortByDate = (items: IAlbumSchema[]) => items.sort((a, b) => {
  if (b.last_photo_created && !a.last_photo_created) {
    return 1
  }

  if (a.last_photo_created && !b.last_photo_created) {
    return -1
  }

  if (!a.last_photo_created && !b.last_photo_created) {
    return a.created - b.created
  }

  return b.last_photo_created - a.last_photo_created
})

export const initPaginator = (type: PUBLIC_LINKS_TYPES, pageSize) => {
  if (type === PUBLIC_LINKS_TYPES.ALBUMS) {
    paginator = getPhotoSdk().albums.getAlbumsPaginator(pageSize, { only_shared: true, total_count: true })
  }

  if (type === PUBLIC_LINKS_TYPES.FAMILY_ALBUMS) {
    paginator = getPhotoSdk().familyAlbums.getAlbumsPaginator(pageSize, { only_shared: true })
  }

  return paginator
}

export const subscribePublicLinksToWSThunk = createAsyncThunk(
  'publicLinks/subscribePublicLinksToWSThunk',
  async function(_, { dispatch, getState }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED, ({ output, group }) => {
      const state = getState() as RootState
      const type = state.publicLinks.type
      const currentEntities = adapter.getSelectors().selectEntities(state.publicLinks)
      const totalCount = state.publicLinks.totalItemsCount
      dispatch(actions.unselectItems([output.id]))

      if (type === PUBLIC_LINKS_TYPES.ALBUMS && group !== 'photos' || type === PUBLIC_LINKS_TYPES.FAMILY_ALBUMS && group !== 'family') {
        debouncedCheckPublicLinksTotalItemsCounts(dispatch)
        return
      }

      if (!output.shared_hash) {
        dispatch(actions.deleteItem(output.id))
        debouncedCheckPublicLinksTotalItemsCounts(dispatch)
      }

      if (currentEntities[output.id]) {
        dispatch(actions.updateItem(output))

        return
      }

      if ([AlbumType.SHARED, AlbumType.HIDDEN_SHARED].includes(output.type)) {
        const currentItems = publicLinksSelectors.selectAll(state.publicLinks)
        const sortedItems = sortByDate([...currentItems, output])

        if (
          sortedItems[sortedItems.length - 1].id !== output.id ||
          currentItems.length % ALBUMS_COUNT_PER_PAGE !== 0 ||
          currentItems.length === totalCount
        ) {
          dispatch(actions.setAllItems(sortedItems))
        }
      }

      debouncedCheckPublicLinksTotalItemsCounts(dispatch)
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CREATED, ({ output, group }) => {
      const state = getState() as RootState
      const type = state.publicLinks.type
      const totalCount = state.publicLinks.totalItemsCount

      if (type === PUBLIC_LINKS_TYPES.ALBUMS && group !== 'photos' || type === PUBLIC_LINKS_TYPES.FAMILY_ALBUMS && group !== 'family') {
        debouncedCheckPublicLinksTotalItemsCounts(dispatch)

        return
      }

      if ([AlbumType.SHARED, AlbumType.HIDDEN_SHARED].includes(output.type)) {
        const currentItems = publicLinksSelectors.selectAll(state.publicLinks)
        const sortedItems = sortByDate([...publicLinksSelectors.selectAll(state.publicLinks), output])

        if (
          sortedItems[sortedItems.length - 1].id !== output.id ||
          currentItems.length % ALBUMS_COUNT_PER_PAGE !== 0 ||
          currentItems.length === totalCount
        ) {
          dispatch(actions.setAllItems(sortedItems))
        }
      }

      debouncedCheckPublicLinksTotalItemsCounts(dispatch)
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_DELETED, ({ output }) => {
      dispatch(actions.deleteItem(output.id))
      dispatch(actions.unselectItems([output.id]))
      debouncedCheckPublicLinksTotalItemsCounts(dispatch)
    })
  }
)

export const unsubscribePublicLinksFromWSThunk = createAsyncThunk(
  'publicLinks/unsubscribePublicLinksFromWSThunk',
  async function() {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CREATED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_DELETED)
  }
)

export const loadPublicLinksItemsFirstTimeThunk = createAsyncThunk(
  'publicLinks/loadPublicLinksItemsFirstTimeThunk',
  async function({ type }: { type: PUBLIC_LINKS_TYPES }, { dispatch }) {
    const paginator = initPaginator(type, ALBUMS_COUNT_PER_PAGE)
    const response = await paginator.next()
    const responseTotalCount = Number(response.headers[TOTAL_COUNT_HEADER])

    dispatch(actions.setPublicLinksType(type))
    dispatch(checkPublicLinksTotalItemsCountsThunk())

    return {
      items: response.data._embedded.albums,
      totalCount: responseTotalCount
    }
  }
)

export const loadMorePublicLinksItemsThunk = createAsyncThunk(
  'publicLinks/loadMorePublicLinksItemsThunk',
  async function() {
    const response = await paginator.next()

    return response.data._embedded.albums
  }
)

export const checkPublicLinksTotalItemsCountsThunk = createAsyncThunk(
  'publicLinks/checkPublicLinksTotalItemsCountsThunk',
  async function(_, { dispatch, getState }) {
    const sdk = getPhotoSdk()
    const state = getState() as RootState
    const type = state.publicLinks.type
    const user = state.user.userData

    const getAlbumsTotalCount = async () => {
      const albumsResponse = await sdk.albums
        .getAlbums({ total_count: true, limit: 1, only_shared: true })

      return Number(albumsResponse.headers[TOTAL_COUNT_HEADER])
    }

    const getFamilyAlbumsTotalCount = async () => {
      if (!user.family_user_id) {
        return 0
      }

      const familyPhotosItemsResponse = await sdk.familyAlbums
        .getAlbums({ total_count: true, limit: 1, only_shared: true })

      return Number(familyPhotosItemsResponse.headers[TOTAL_COUNT_HEADER])
    }

    const getFilesTotalCount = async () => {
      const response = await publicLinksApi.getSharedWithMeNodesTotalCount(user.id)

      return response.headers[TOTAL_COUNT_HEADER]
    }

    const albumsTotalCount = await getAlbumsTotalCount()
    const familyAlbumsTotalCount = await getFamilyAlbumsTotalCount()
    const filesTotalCount = await getFilesTotalCount()

    dispatch(actions.setAlbumsCount(albumsTotalCount))
    dispatch(actions.setFamilyAlbumsCount(familyAlbumsTotalCount))
    dispatch(actions.setFilesCount(filesTotalCount))

    const totalCount = type === PUBLIC_LINKS_TYPES.ALBUMS ?
      albumsTotalCount : type === PUBLIC_LINKS_TYPES.FAMILY_ALBUMS ?
        familyAlbumsTotalCount : filesTotalCount

    dispatch(actions.setTotalCount(totalCount))
  }
)

export const debouncedCheckPublicLinksTotalItemsCounts = _.debounce((dispatch) => dispatch(checkPublicLinksTotalItemsCountsThunk()), 500)

export const downloadAlbumThunk = createAsyncThunk(
  'publicLinks/downloadAlbumThunk',
  async function({ type, albumId }: { type: SDK_TYPES, albumId: string }, { dispatch }) {
    try {
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      const response = await albumsSdk.createAlbumZipStream(albumId)

      window.location.href = response
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const renameAlbumThunk = createAsyncThunk(
  'publicLinks/renameAlbumThunk',
  async function({ type, id, name } : { type: SDK_TYPES, name: string, id: string }, { dispatch }) {
    try {
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.renameAlbum(id, name)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumRenamed')
      })

      dispatch(actions.updateItem({ id, description: name }))
      dispatch(actions.unselectAll())
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const removeAlbumsThunk = createAsyncThunk(
  'publicLinks/removeAlbumsThunk',
  async function ({ ids, type }: { ids: string[], type: SDK_TYPES }) {
    try {
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.removeAlbums(ids)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumDeleted', { number: ids.length })
      })
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }

    return ids
  }
)

export const publicLinksSlice = createSlice({
  name: 'publicLinks',
  initialState: adapter.getInitialState({
    type: null,
    status: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    error: '',
    totalItemsCount: 0,
    selectedItemsIds: [],
    filesCount: 0,
    albumsCount: 0,
    familyAlbumsCount: 0
  }),
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setPublicLinksType: (state, action) => {
      state.type = action.payload
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedItemsIds = [...state.selectedItemsIds, id]
      } else {
        state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
      }
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    deleteItem: (state, action) => {
      adapter.removeOne(state, action.payload)
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, sortByDate(action.payload))
    },
    unselectItems: (state, action) => {
      state.selectedItemsIds = state.selectedItemsIds.filter(id => !action.payload.includes(id))
    },
    unselectAll: (state) => {
      state.selectedItemsIds = []
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      state.selectedItemsIds = []
      adapter.removeAll(state)
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    },
    setAlbumsCount: (state, action) => {
      state.albumsCount = action.payload
    },
    setFamilyAlbumsCount: (state, action) => {
      state.familyAlbumsCount = action.payload
    },
    setFilesCount: (state, action) => {
      state.filesCount = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadPublicLinksItemsFirstTimeThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(loadPublicLinksItemsFirstTimeThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalItemsCount = action.payload.totalCount
      })
      .addCase(loadPublicLinksItemsFirstTimeThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(loadMorePublicLinksItemsThunk.pending, (state) => {
        state.loadingMoreStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadMorePublicLinksItemsThunk.fulfilled, (state, action) => {
        state.loadingMoreStatus = LOADING_STATUSES.SUCCEEDED
        adapter.addMany(state, action.payload)
      })
  },
})

const {
  reducer, actions
} = publicLinksSlice

export { reducer as publicLinksReducer, actions as publicLinksActions }
