import { useEffect, useState } from "react"

import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { PrimaryButton, SpriteIcon, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { useNavigate } from "react-router-dom"
import { getIsLeftMenuHidden } from "features/common/selectors"

import { FAMILY_ONBOARDING_CLASSES } from "../../constants/familyOnboarding"
import { useMobileDetection } from "../common/hooks"
import { useAppDispatch } from "../../store"
import { appActions } from "../../store/app"
import { getTimelineItemsSelector, getTimelineLoadingStatusSelector } from "../photo/timeline/selectors"

import { getIsOnboardingActiveSelector, getOnboardingStepSelector } from "./selectors"
import { familyActions, finishOnboardingThunk } from "./familySlice"

interface IStepParams {
  position: {top?: number, left?: number, bottom?: number},
  path: string,
  trianglePosition: 'left' | 'right' | 'top' | 'bottom'
}

const generateClipPath = (path) => {
  return `0 0, 100% 0, 100% 100%, 0% 100%, 0 0, ${path}`
}

export const Onboarding = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isOnboardingActive = getIsOnboardingActiveSelector()
  const onboardingStep = getOnboardingStepSelector()
  const isMobile = useMobileDetection()
  const items = getTimelineItemsSelector()
  const isLoading = getTimelineLoadingStatusSelector()
  const isLeftMenuHidden = getIsLeftMenuHidden()

  const [stepsParams, setStepsParams] = useState<IStepParams[]>([])

  const currentStepIndex = onboardingStep - 1

  const stepsTexts = [
    {
      title: t('l_familycloud_onboardingWhatIsTitle'),
      text: t('l_familycloud_onboardingWhatIsMessage')
    },
    {
      title: t('l_familycloud_onboardingAddYoursTitle'),
      text: t('l_familycloud_onboardingAddMessage')
    },
    {
      title: t('l_familycloud_onboardingManageTitle'),
      text: t('l_familycloud_onboardingManageMessage')
    },
  ]

  useEffect(() => {
    if (!isOnboardingActive) {
      return
    }

    const getOnboardingStepsParams = () => {
      const isMobile = window.innerWidth < 1024

      try {
        if (!isMobile) {
          const step1Elem = document.querySelector(`.${FAMILY_ONBOARDING_CLASSES.STEP_1}`)
          const { top: top1, left: left1, right: right1, bottom: bottom1 } = step1Elem?.getBoundingClientRect()
          const step1Path = generateClipPath(`${left1}px ${top1}px, ${right1 + 6}px ${top1}px, ${right1 + 6}px ${bottom1 + 10}px, ${left1}px ${bottom1 + 10}px, ${left1}px ${top1}px`)
          const step1InfoBoxPosition = {
            top: top1,
            left: right1 + 20
          }

          const step2Elem = document.querySelector(`.${FAMILY_ONBOARDING_CLASSES.STEP_2}`)
          const { top: top2, left: left2, right: right2, bottom: bottom2 } = step2Elem?.getBoundingClientRect()
          const step2Path =
            generateClipPath(`${left2 - 25}px ${top2 - 25}px, ${right2 + 25}px ${top2 - 25}px, ${right2 + 25}px ${bottom2 + 25}px, ${left2 - 25}px ${bottom2 + 25}px, ${left2 - 25}px ${top2 - 25}px`)
          const step2InfoBoxPosition = {
            top: top2 - 25,
            left: left2 - 454 - 45
          }

          const step3Elem = document.querySelector(`.${FAMILY_ONBOARDING_CLASSES.STEP_3}`)
          const { top: top3, left: left3, right: right3, bottom: bottom3 } = step3Elem?.getBoundingClientRect()
          const step3Path = generateClipPath(`${left3}px ${top3 - 8}px, ${right3 + 6}px ${top3 - 8}px, ${right3 + 6}px ${bottom3 + 8}px, ${left3}px ${bottom3 + 8}px, ${left3}px ${top3}px`)
          const step3InfoBoxPosition = {
            top: top3 - 60,
            left: right3 + 20
          }

          setStepsParams([
            {
              path: step1Path,
              position: step1InfoBoxPosition,
              trianglePosition: 'left'
            },
            {
              path: step2Path,
              position: step2InfoBoxPosition,
              trianglePosition: 'right'
            },
            {
              path: step3Path,
              position: step3InfoBoxPosition,
              trianglePosition: 'left'
            },
          ])

          return
        }

        const step1Path = generateClipPath(`0 0, 100% 0, 100% 80px, 0 80px, 0 0`)
        const step1InfoBoxPosition = {
          top: 100,
          left: 16
        }

        const step2Elem = document.querySelector(`.${FAMILY_ONBOARDING_CLASSES.MOBILE_STEP_2}`)
        const { top = 0, bottom = 0 } = (onboardingStep !== 3 && !!step2Elem ? step2Elem?.getBoundingClientRect() : {})
        const step2Path = generateClipPath(`0 ${top - 30}px, 100% ${top - 30}px, 100% ${bottom + 30}px, 0 ${bottom + 30}px, 0 ${top - 30}px`)
        const step2InfoBoxPosition = {
          bottom: window.innerHeight - bottom + 125,
        }

        const notEmptyStep2Path = generateClipPath(`0 ${window.innerHeight - 96}px, 100% ${window.innerHeight - 96}px, 100% 100%, 0 100%, 0 ${window.innerHeight - 96}px`)
        const notEmptyStep2InfoBoxPosition = {
          top: window.innerHeight - 106 - 257,
          left: 16
        }

        const step3Path = generateClipPath(`0 88px, 100% 88px, 100% 196px, 0 196px, 0 88px`)
        const step3InfoBoxPosition = {
          top: 214,
          left: 16
        }

        setStepsParams([
          {
            path: step1Path,
            position: step1InfoBoxPosition,
            trianglePosition: 'top'
          },
          items.length ?
            {
              path: notEmptyStep2Path,
              position: notEmptyStep2InfoBoxPosition,
              trianglePosition: 'bottom'
            } :
            {
              path: step2Path,
              position: step2InfoBoxPosition,
              trianglePosition: 'bottom'
            },
          {
            path: step3Path,
            position: step3InfoBoxPosition,
            trianglePosition: 'top'
          },
        ])
      } catch (error) {
        setTimeout(() => getOnboardingStepsParams(), 700)
      }
    }


    const width = window.innerWidth

    if (width < 1280 && width >= 1024) {
      dispatch(appActions.toggleLeftMenuOnMobile(true))
      setTimeout(() => getOnboardingStepsParams(), 300)
    } else {
      getOnboardingStepsParams()
    }

    const resizeHandler = () => {
      const newWidth = window.innerWidth

      if (newWidth < 1280 && newWidth >= 1024) {
        dispatch(appActions.toggleLeftMenuOnMobile(true))
        setTimeout(() => getOnboardingStepsParams(), 300)
      } else {
        dispatch(appActions.toggleLeftMenuOnMobile(false))
        setTimeout(() => getOnboardingStepsParams(), 300)
      }
    }

    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [isOnboardingActive, isLeftMenuHidden, isLoading])

  const stepParams = stepsParams[currentStepIndex]

  const handleNextStep = () => {
    if (onboardingStep === stepsParams.length) {
      dispatch(familyActions.setIsOnboardingActive(false))
      dispatch(finishOnboardingThunk())

      if (isMobile) {
        navigate('/family/photos', { replace: true })
      }

      return
    }

    dispatch(familyActions.setOnboardingStep(onboardingStep + 1))

    if (isMobile && onboardingStep === 2) {
      navigate('/family/manage', { replace: true })
    }
  }

  const handleClose = () => {
    dispatch(familyActions.setIsOnboardingActive(false))
    dispatch(finishOnboardingThunk())

    if (isMobile && onboardingStep === 3) {
      navigate('/family/photos', { replace: true })
    }
  }

  if (!isOnboardingActive) {
    return null
  }

  return (
    !!isOnboardingActive && !!stepsParams.length && (
      <>
        <STransparentOverlay />

        <SOverlay stepPath={stepParams.path}>
          <SInfoBox
            top={stepParams.position.top}
            left={stepParams.position.left}
            bottom={stepParams.position.bottom}
            className={classNames({
              'with-right-triangle': stepParams.trianglePosition === 'right',
              'with-left-triangle': stepParams.trianglePosition === 'left',
              'with-top-triangle': stepParams.trianglePosition === 'top',
              'with-bottom-triangle': stepParams.trianglePosition === 'bottom',
            })}
          >
            <STitleRow>
              {stepsTexts[currentStepIndex].title}
            </STitleRow>

            <CloseIcon iconName="close"
              onClick={handleClose}
            />

            <SText>
              {stepsTexts[currentStepIndex].text}
            </SText>

            <SDivider />

            <SBottomRow>
              <SStepsText>
                {onboardingStep === 1 && t('l_familycloud_onboardingStepOne')}

                {onboardingStep === 2 && t('l_familycloud_onboardingStepTwo')}

                {onboardingStep === 3 && t('l_familycloud_onboardingStepThree')}
              </SStepsText>

              <SPrimaryBtn actionName={onboardingStep === 3 ? t('a_common_done') : t('a_common_next')}
                onAction={handleNextStep}
              />
            </SBottomRow>
          </SInfoBox>
        </SOverlay>
      </>
    )
  )
}

const STransparentOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 16;
`

const SOverlay = styled.div<{ stepPath: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(30, 50, 64, 0.50);
  z-index: 17;

  clip-path: polygon(evenodd, ${(props) => props.stepPath});
  backface-visibility: hidden;
`

const SInfoBox = styled.div<{top?: number, left?: number, bottom?: number}>`
  width: 454px;
  padding-top: 24px;
  background: var(--background-secondary, #ffffff);
  position: fixed;
  top: ${props => props.top}px;
  bottom: ${props => props.bottom}px;
  left: ${props => props.left}px;
  border-radius: 4px;
  
  &.with-right-triangle {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 76px;
      left: 100%;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid var(--modal-primary, #ffffff);
    }
  }

  &.with-left-triangle {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 76px;
      left: -8px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid var(--modal-primary, #ffffff);
    }
  }

  &.with-top-triangle {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid var(--modal-primary, #ffffff);
    }
  }

  &.with-bottom-triangle {
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--modal-primary, #ffffff);
    }
  }
  
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: calc(100vw - 32px);
    left: 16px;
  }
`

const STitleRow = styled.div`
  color: var(--text-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-left: 24px;
`

const SText = styled.p`
  margin-top: 24px;
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0 24px;
`

const SDivider = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background: var(--divider-primary);
`

const SBottomRow = styled.div`
  height: 88px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
`

const SStepsText = styled.span`
  color: var(--text-secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`

const SPrimaryBtn = styled(PrimaryButton)`
  margin-left: auto;
`

const CloseIcon = styled(SpriteIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--icon-primary);
  cursor: pointer;
`
