import { getModalsRootElement } from 'constants/modals'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import React, { useEffect, useState } from 'react'

import {
  ConfirmationModal,
  ConfirmationModalTypes,
  CreateNewFolderForm,
  MenuItem,
  MenuWithActionItems
} from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { DialogModal } from 'ui/DialogModal'
import { appActions } from 'store/app'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { deleteSharedLinkThunk, getPublicLinkAndCopyThunk, sharingActions } from 'features/sharing/sharingSlice'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { useNavigate, useParams } from "react-router-dom"
import classNames from 'classnames'
import { IFsNodeSchema } from "@cloudike/web_files"
import { downloadSharedWithMeFilesThunk, publicLinkFilesActions, removeSharedWithMeLink } from 'features/public-link-files/publicLinkFilesSlice'
import { isMobileDevice } from 'utils/isMobileDevice'

import { parsePathFromParams } from "../common/parsePathFromParams/parsePathFromParams"
import { getUiTheme } from "../user/selectors"
import { getIsBusy } from "../sharing/selectors"
import { MobileToolbarCounter } from "../common/mobile-toolbar-counter/MobileToolbarCounter"

import {
  getCurrentFolderIdSelector,
  getCurrentFolderSelector,
  getFilesSelectedItemsIdsSelector,
  getFilesSelectedItemsSelector,
  getSearchTypeSelector,
} from './selectors'
import {
  checkAndUploadFilesThunk,
  createFolderThunk,
  deleteNodesThunk,
  downloadNodesThunk,
  filesActions,
  uploadFolderThunk
} from './filesSlice'
import { validationSchemaForFileItem } from './validationSchemaForFileItem'
import { CopyMoveModal, CopyMoveModalType } from './copy-move-modal/CopyMoveModal'
import { generateRemovingText, pushCreateEditItemPublicLink } from './filesUtils'

interface FilesToolbarProps {
    className?: string,
}

export const FilesToolbar: React.FC<FilesToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const selectedItemsIds = getFilesSelectedItemsIdsSelector()
  const selectedItems = getFilesSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  const catalogId = getCurrentFolderIdSelector()
  const currentFolder = getCurrentFolderSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [newFolderModalOpened, toggleNewFolderModal] = useState(false)
  const [copyMoveModalType, setCopyMoveModalType] = useState(null)
  const [publicLinkDataForDelete, setPublicLinkDataForDelete] = useState(null)
  const [permission, setPermission] = useState(null)

  const params = useParams()
  const paths = parsePathFromParams(params, 'drive')
  const currentFileFolder = paths.length === 0 ? '' : paths[paths.length - 1]
  const isBusy = getIsBusy()
  const searchType = getSearchTypeSelector()
  const isActiveSearchSharedWithMe = searchType?.type === 'shared_with_me'

  const theme = getUiTheme()

  useEffect(() => {
    selectedItems.length === 1 && isActiveSearchSharedWithMe && setPermission(selectedItems[0]['_embedded']?.share.permission)
  }, [selectedItemsCount])

  const handleOpenPublicLinkRemovingModal = (item) => {
    dispatch(appActions.toggleRightMenuOnMobile(false))
    setPublicLinkDataForDelete(item)
  }

  const handleClosePublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(null)
  }

  const handleCallback = () => {
    dispatch(filesActions.unselectAll())
    dispatch(filesActions.deleteItems(selectedItems.map(item => item.id)))
  }

  const handleDeletePublicLink = () => {
    isActiveSearchSharedWithMe ? dispatch(removeSharedWithMeLink({ nodeId: publicLinkDataForDelete.id, successCallback: handleCallback })) 
      : dispatch(deleteSharedLinkThunk({ data: publicLinkDataForDelete, type: SDK_TYPES.FILES }))
    setPublicLinkDataForDelete(null)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenNewFolderModal = () => {
    toggleNewFolderModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseNewFolderModal = () => {
    toggleNewFolderModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadSelected = () => {
    dispatch(downloadNodesThunk({ ids: selectedItemsIds }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveSelected = () => {
    dispatch(deleteNodesThunk({ ids: selectedItemsIds }))
    handleCloseRemovingConfirmationModal()
  }

  const handleUploadFiles = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const callback = () => {
        event.target.value = ''
      }

      dispatch(checkAndUploadFilesThunk({ files, callback }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const handleUploadFolder = (event) => {
    const files = event.target?.files

    if (files.length === 0) {
      return
    }

    const callback = () => {
      event.target.value = ''
    }

    dispatch(uploadFolderThunk({ files, callback }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameNode = () => {
    dispatch(filesActions.setRenamingItemId(selectedItemsIds[0]))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCreateFolder = (name: string) => {
    if (name) {
      dispatch(
        createFolderThunk({
          name,
          parentId: catalogId,
        })
      )
    }
    toggleNewFolderModal(false)
  }

  const handleShareSelected = (item: IFsNodeSchema) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))

    dispatch(sharingActions.setSharedItemData(selectedItems[0]))
    pushCreateEditItemPublicLink(item)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleShareCurrentFolder = () => {
    if (isBusy) return

    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(currentFolder))
    dispatch(appActions.toggleRightMenuOnMobile(false))

  }

  const handleCloseCopyMoveModal = () => {
    setCopyMoveModalType(null)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCopyPublicLink = (item) => {
    dispatch(getPublicLinkAndCopyThunk({ data: item }))
  }

  const handleDownloadSelectedSharedWithMe = () => {
    dispatch(downloadSharedWithMeFilesThunk({ item: selectedItems[0], navigate }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount && (searchType?.type === 'shared_my_public_link' || searchType?.type === 'shared_with_me')) return items

    if (!selectedItemsCount) {
      items = !!currentFolder ? [
        !isMobileDevice() && {
          label: t('a_files_uploadFolder'),
          iconName: 'upload_folder',
          type: 'file',
          onClickItem: handleUploadFolder,
          accept: '*',
          acceptDirectory: true
        },
        {
          label: t('a_files_createFolder'),
          iconName: 'create_new_folder',
          onClickItem: handleOpenNewFolderModal
        },
        {
          label: t('a_common_share') ,
          iconName: 'share',
          onClickItem: handleShareCurrentFolder
        },
        {
          label: t('a_common_copyPublicLink'),
          iconName: 'copy_link',
          onClickItem: () => {
            dispatch(appActions.toggleRightMenuOnMobile(false))
            handleCopyPublicLink(currentFolder)
          }
        },
        currentFolder.is_shared && {
          label: t('a_common_deleteLink'),
          iconName: 'unlink',
          onClickItem: () => {
            handleOpenPublicLinkRemovingModal(currentFolder)
          }
        }
      ] : [
        !isMobileDevice() && {
          label: t('a_files_uploadFolder'),
          iconName: 'upload_folder',
          type: 'file',
          onClickItem: handleUploadFolder,
          accept: '*',
          acceptDirectory: true
        },
        {
          label: t('a_files_createFolder'),
          iconName: 'create_new_folder',
          onClickItem: handleOpenNewFolderModal
        },
      ]
    }

    if (selectedItemsCount === 1) {
      items.push({
        label: t('a_common_share') ,
        iconName: 'share',
        onClickItem: () => handleShareSelected(selectedItems[0])
      },
      {
        label: t('a_common_copyPublicLink'),
        iconName: 'copy_link',
        onClickItem: () => {
          dispatch(filesActions.unselectAll())
          dispatch(appActions.toggleRightMenuOnMobile(false))
          handleCopyPublicLink(selectedItems[0])
        }
      },
      selectedItems[0].is_shared && {
        label: t('a_common_deleteLink'),
        iconName: 'unlink',
        onClickItem: () => {
          dispatch(filesActions.unselectAll())
          handleOpenPublicLinkRemovingModal(selectedItems[0])
        }
      },
      {
        label: t('a_common_move'),
        iconName: 'move_icon',
        onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
      },
      {
        label: t('a_common_copy'),
        iconName: 'copy',
        onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
      },
      {
        label: t('a_common_rename'),
        iconName: 'pencil',
        onClickItem: handleRenameNode
      },
      {
        label: t('a_common_delete'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      })
    }

    if (selectedItemsCount > 1) {
      items.push(
        {
          label: t('a_common_move'),
          iconName: 'move_icon',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.MOVE)
        },
        {
          label: t('a_common_copy'),
          iconName: 'copy',
          onClickItem: () => setCopyMoveModalType(CopyMoveModalType.COPY)
        },
        {
          label: t('a_common_delete'),
          iconName: 'remove_icon',
          onClickItem: handleOpenRemovingConfirmationModal
        })
    }

    return items.filter(item => !!item)
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (!selectedItemsCount && (searchType?.type === 'shared_my_public_link' || searchType?.type === 'shared_with_me')) return items

    const filteredMenuItems = menuItems.filter(item => item.label !== t('a_files_uploadFolder'))

    if (!selectedItemsCount) {
      items = [{
        label: t('a_files_uploadFiles'),
        iconName: 'upload',
        type: 'file',
        onClickItem: handleUploadFiles,
        accept: '*'
      }, ...filteredMenuItems]
    } else {
      items = [{
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadSelected,
      }, ...filteredMenuItems
      ]
    }

    return items
  })()

  const handleOpenCopyToPersonalModal = () => {
    setCopyMoveModalType(CopyMoveModalType.COPY_TO_PERSONAL)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameSharedWithMeNode = () => {
    dispatch(publicLinkFilesActions.setRenamingItemId(selectedItems[0]['node_id']))
    dispatch(filesActions.setRenamingItemId(selectedItemsIds[0]))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItemsSharedWithMe = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (selectedItemsCount === 1) {
      items = [
        {
          label: t('a_common_addToMyCloud'),
          iconName: 'add_photo_alternate_1',
          onClickItem: handleOpenCopyToPersonalModal,
        },
        {
          label: t('a_common_removeLink'),
          iconName: 'unlink',
          onClickItem: () => {
            handleOpenPublicLinkRemovingModal(selectedItems[0])
          }
        },
        permission === 'write' && {
          label: t('a_common_rename'),
          iconName: 'pencil',
          onClickItem: handleRenameSharedWithMeNode
        },
      ]
    }

    return items.filter(Boolean)
  })()

  const mobileMenuItemSharedWithMe = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if (selectedItemsCount === 1) {
      items = [{
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadSelectedSharedWithMe,
      },]
    }

    if (selectedItemsCount > 1) {
      items = [{
        label: t('a_common_addToMyCloud'),
        iconName: 'add_photo_alternate_1',
        onClickItem: handleOpenCopyToPersonalModal,
      },]
    }

    return [...items, ...menuItemsSharedWithMe]
  })()

  const headerContent = () => {
    if (!!selectedItemsCount) {
      return (
        <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
      )
    }
  }

  const SharedWithMeButtons = () => {
    return ((!!selectedItemsCount && selectedItemsCount === 1) ? (
      <StyledPrimaryButton
        onClick={handleDownloadSelectedSharedWithMe}
      > 
        {t('a_common_download')}
      </StyledPrimaryButton>
    ) : (
      <StyledPrimaryButton
        onClick={handleOpenCopyToPersonalModal}
      > 
        {t('a_common_addToMyCloud')}
      </StyledPrimaryButton>
    ))
  }

  return (
    <>
      <FilesToolbarBox className={classNames(className, 'js-files-toolbar')}>
        {
          !!selectedItemsCount && !isActiveSearchSharedWithMe && (
            <StyledPrimaryButton
              onClick={handleDownloadSelected}
            >
              {t('a_common_download')}
            </StyledPrimaryButton>
          )
        }

        {isActiveSearchSharedWithMe && !!selectedItemsCount && (<SharedWithMeButtons />)}

        {
          !selectedItemsCount && (searchType?.type !== 'shared_my_public_link' && searchType?.type !== 'shared_with_me') && (
            <StyledPrimaryButton
              actionName={t('a_files_uploadFiles')}
              htmlFor="upload-btn"
            >
              {t('a_files_uploadFiles')}

              <HiddenFilesInput
                id="upload-btn"
                onChange={handleUploadFiles}
                type="file"
                multiple
                accept="*"
              />
            </StyledPrimaryButton>
          )
        }

        <MenuWithActionItems 
          menuItems={isActiveSearchSharedWithMe ? menuItemsSharedWithMe : menuItems} 
          isBorder={!(menuItems.length === 0 && menuItemsSharedWithMe.length === 0)} 
        />
      </FilesToolbarBox>

      <MobileToolbar
        items={isActiveSearchSharedWithMe ? mobileMenuItemSharedWithMe : mobileMenuItems}
        headContent={headerContent()}
        className="js-files-mobile-toolbar"
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={generateRemovingText(selectedItems, t).title}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_delete')}
        onOk={handleRemoveSelected}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
        type={ConfirmationModalTypes.danger}
      >
        <TextInModalBox>
          {generateRemovingText(selectedItems, t).text}
        </TextInModalBox>
      </DialogModal>

      {
        newFolderModalOpened && (
          <CreateNewFolderForm
            theme={theme}
            texts={{
              TITLE: t('l_common_createFolderTitle'),
              SUB_TITLE: t('l_common_folderName_field'),
              PLACEHOLDER: t('l_common_folderName_field'),
              NAME_BUTTON_ACTION: t('a_common_create'),
              NAME_BUTTON_CANCEL: t('a_common_close'),
              TITLE_ICON: 'create_new_folder',
            }}
            onCreateFolder={handleCreateFolder}
            onCloseModal={handleCloseNewFolderModal}
            isShown={true}
            parentBlock={getModalsRootElement()}
            validationSchema={validationSchemaForFileItem}
            defaultValue={t('l_files_createFolderPlaceholder')}
          />
        )}

      {
        !!copyMoveModalType && (
          <CopyMoveModal
            selectedItems={selectedItems}
            type={copyMoveModalType}
            currentFileFolder={isActiveSearchSharedWithMe ? '' : currentFileFolder}
            onClose={handleCloseCopyMoveModal}
            isSharedWithMeToDrive={isActiveSearchSharedWithMe}
          />
        )
      }

      <ConfirmationModal title={isActiveSearchSharedWithMe ? t('l_publicLink_removeConfirmTitle') : t('l_publicLink_deleteConfirmTitle')}
        isOpened={!!publicLinkDataForDelete}
        onClose={handleClosePublicLinkRemovingModal}
        parentBlock={getModalsRootElement()}
        onOk={handleDeletePublicLink}
        okText={isActiveSearchSharedWithMe ? t('a_publicLinks_remove') : t('a_common_delete')}
        onCancel={handleClosePublicLinkRemovingModal}
        cancelText={t('a_common_cancel')}
        type={ConfirmationModalTypes.danger}
      >
        <SRemovingLinkConfirmationModalText>
          {isActiveSearchSharedWithMe ? t('l_publicLink_removeSharedConfirmMessage') : t('l_publicLink_deleteConfirmMessage')}
        </SRemovingLinkConfirmationModalText>
      </ConfirmationModal>
    </>
  )
}

const SRemovingLinkConfirmationModalText = styled.p`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 572px;
`

const TextInModalBox = styled.span`
  max-width: 500px;
  font-size: 14px;
  word-wrap: anywhere;
`

const FilesToolbarBox = styled.div`
  padding-right: 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled.label`
  background: var(--button-primary-default);
  border: 0;
  border-radius: 4px;
  color: var(--brand-text);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s linear;

  &:hover {
    background: var(--button-primary-hover);
  }

  &:focus, &:active {
    background: var(--button-primary-hover);
  }
`

const HiddenFilesInput = styled.input`
  display: none;
`
