import { useEffect, useRef } from "react"

import { FamilyMemberTableHeader, FamilyMemberTableRow, FilesTableTypes } from "@cloudike/web_ui_components"
import { useTranslation } from "react-i18next"
import { useWindowVirtualizer } from "@tanstack/react-virtual"
import styled from 'styled-components'

import { getUiTheme } from "../user/selectors"
import { formatFileName } from "../files/filesUtils"
import { validationSchemaForFileItem } from "../files/validationSchemaForFileItem"

export const FamilyMembersList = ({ items,
  columns,
  selectType,
  checkboxVisibility,
  onChangeFolder,
  onToggleItem,
  onRenameItem,
  onSortingChange,
  onClickAllCheckbox,
  onClickCheckbox,
  selectedFilesIds,
  renamingFileId = null,
  type = FilesTableTypes.default,
  fileForScrollingId = null,
  withoutContextMenu = false,
  contextMenuItems = [],
  onRightClick,
  onEditPublicLink 
}) => {
  const { t } = useTranslation()
  const theme = getUiTheme()
  const listRef = useRef(null)
  const rowVirtualizer = useWindowVirtualizer({
    count: items.length,
    estimateSize: () => 56,
    overscan: 5,
  })
  const isAnyMembersSelectable = items.some(item => item.isSelectable)

  useEffect(() => {
    if (!fileForScrollingId || !items.length) {
      return
    }

    const index = items.findIndex((item) => item.id === fileForScrollingId)

    if (index !== -1) {
      rowVirtualizer.scrollToIndex(index)
    }
  }, [fileForScrollingId, items])

  const handleRenameItem = (_, newName: string) => {
    onRenameItem(newName)
  }

  const filesTableMobileBreakpoint = 767

  const familyMembersList = rowVirtualizer.getVirtualItems().map((virtualItem) => {
    const item = items[virtualItem.index]
    return (
      <FamilyMemberTableRowBox
        theme={theme}
        onClickCheckbox={() => onClickCheckbox(item, items)}
        mobileBreakpoint={filesTableMobileBreakpoint}
        menuItems={contextMenuItems}
        sharedPromptText={item?.is_shared ? t('a_common_editPublicLink') : t('a_common_createPublicLink')}
        onRightClick={() => {
          onRightClick(item)
        }}
        item={item}
        formattedName={formatFileName(item.name)}
        onOpenedItem={() => onChangeFolder(item)}
        onSelectedItem={() => onToggleItem(item, items)}
        onRenamedItem={handleRenameItem}
        operationModifiers={{
          selected: selectedFilesIds.includes(item.id),
          renamed: item.id === renamingFileId ? 'renaming' : null
        }}
        validationSchema={validationSchemaForFileItem}
        type={type as FilesTableTypes}
        onEditPublicLink={onEditPublicLink}
        withoutContextMenu={withoutContextMenu}
        key={virtualItem.key}
        height={`${virtualItem.size}px`}
        transform={`translateY(${virtualItem.start}px)`}
        withVisibleCheckbox={selectedFilesIds.length > 0}
      />
    )
  })
  return (
    <>
      <FileListBox checkboxVisibility={checkboxVisibility}
        className="js-files-list"
      >
        <FamilyMemberTableHeader
          columns={columns}
          selectType={selectType}
          onSelectItems={() => {
            onClickAllCheckbox(items)
          }}
          onSortingChange={onSortingChange}
          isCheckboxHidden={!isAnyMembersSelectable}
          withVisibleCheckbox={selectedFilesIds.length > 0}
        />

        <FileListItemsWrapper ref={listRef}>
          <FileListItemsInnerWrapper height={rowVirtualizer.getTotalSize() + 'px'}>
            {familyMembersList}
          </FileListItemsInnerWrapper>
        </FileListItemsWrapper>
      </FileListBox>
    </>
  )

}

const FileListBox = styled.div`
  display: flex;
  flex-flow: column;

  & .tableRow__check-hover {
    visibility: ${props => props.checkboxVisibility};
  }
`

const FileListItemsWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`

const FileListItemsInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height};
`

const FamilyMemberTableRowBox = styled(FamilyMemberTableRow)`
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.height};
  transform: ${props => props.transform};
`
