import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AlbumsMemoriesGrid } from '../common/AlbumsMemoriesGrid'
import { AlbumsMemoriesCard } from '../common/AlbumsMemoriesCard'


export const AlbumsSeasonsList = ({ className = '', countAlbums, albums, onCardClick, onSelect, cardsWithOnlyTitle = false, showFullName = false }) => {
  const { t } = useTranslation()

  return (
    <>
      <AlbumsBox className={className}>
        <AlbumsMemoriesGrid>
          {
            albums.slice(0, countAlbums).map(album => (
              <AlbumsMemoriesCard
                key={album.id}
                id={album.id}
                albumName={album.description}
                albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
                description={album.description}
                onSelect={onSelect}
                isSelected={album.selected}
                imgUrls={album?._embedded?.cover_items}
                onClick={() => onCardClick(album)}
                onlyTitle={cardsWithOnlyTitle}
                showFullName={showFullName}
              />
            ))
          }
        </AlbumsMemoriesGrid>
      </AlbumsBox>
    </>
  )
}

const AlbumsBox = styled.div`
  width: 100%;
  padding-bottom: 40px;
`
