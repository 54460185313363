import { LOADING_STATUSES } from 'constants/loadingStatuses'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import * as yup from 'yup'
import {
  ConfirmationModal,
  ConfirmationModalTypes,
  FileTableContainer,
  MenuItem,
  PublicLinksSection,
  SearchInput,
  SelectType,
  STYLED_VARIABLES
} from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  getAllFilesNodesSelector,
  getCheckboxVisibility,
  getFilesLoadingStatusSelector,
  getFilesRenamingItemIdSelector,
  getFilesSelectedItemsCountSelector,
  getFilesSelectedItemsIdsSelector,
  getFilesSelectedItemsSelector,
  getFilesSortSelector,
  getIsActiveSearchSelector,
  getReplaceFilesItemModalData,
  getSearchTypeSelector,
  getSelectType,
} from 'features/files/selectors'
import { useAppDispatch } from "store"
import { useGetFilesTableColumns } from 'features/files/useGetFilesTableColumns'
import { mapFileItemsToTableRow } from 'features/files/filesUtils'
import { FilesList } from 'features/files/FilesList'
import {
  cancelFileReplacingThunk,
  filesActions,
  keepBothFileVersionsThunk,
  replaceFileThunk,
  ReplaceModalTypes,
  fetchPublicLinksWithMeNodesThunk,
} from 'features/files/filesSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { EmptyFolderPlaceholder } from 'features/files/EmptyFolderPlaceholder'
import { PageTopbar } from 'features/common/page-topbar'
import { useMobileDetection } from 'features/common/hooks'
import classNames from 'classnames'
import { FileItemsTypes } from 'features/files/types'
import { filesPreviewActions, openFilesPreviewThunk } from 'features/files/files-preview/filesPreviewSlice'
import { usePageTitle } from 'features/common/hooks/usePageTitle'
import { useFilesPreviewItemsUpdate } from 'features/files/files-preview/useFilesPreviewItemsUpdate'
import { FilesSkeleton } from 'features/files/FilesSkeleton'
import { ReplaceFilesItemModal } from 'features/files/ReplaceFilesItemModal'
import _ from 'lodash'
import { MobileToolbarMenuToggle } from 'features/common/right-sidebar/MobileToolbarMenuToggle'
import { FileVersionsModal } from 'features/files/file-versions/FileVersionsModal'
import { NodeTypes } from '@cloudike/web_files'
import { ITypeProps } from "@cloudike/web_ui_components/dist/Components/SearchInput/SearchInput"
import { downloadSharedWithMeFilesThunk, publicLinkFilesActions, removeSharedWithMeLink, renameSharedWithMeFileNodeThunk } from 'features/public-link-files/publicLinkFilesSlice'

import { parsePathFromParams } from "../../../features/common/parsePathFromParams/parsePathFromParams"
import { appActions } from "../../../store/app"
import { CopyMoveModal, CopyMoveModalType } from "../../../features/files/copy-move-modal/CopyMoveModal"
import { getModalsRootElement } from "../../../constants/modals"

const debounceGetSearchNodes = _.debounce((dispatch) => {
  return dispatch(fetchPublicLinksWithMeNodesThunk())
}, 300)

function FilesPage() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [itemsRightClick, setItemsRightClick] = useState(null)
  const [itemsRightClickIds, setItemsRightClickIds] = useState(null)
  const [copyMoveModalType, setCopyMoveModalType] = useState(null)
  const [publicLinkDataForDelete, setPublicLinkDataForDelete] = useState(null)

  const nodes = getAllFilesNodesSelector()
  const sortState = getFilesSortSelector()
  const selectedItemsCount = getFilesSelectedItemsCountSelector()
  const status = getFilesLoadingStatusSelector()
  const selectType = getSelectType()
  const checkboxVisibility = getCheckboxVisibility()
  const replaceModalData = getReplaceFilesItemModalData()
  const isActiveSearch = getIsActiveSearchSelector()

  const selectedFilesIds = getFilesSelectedItemsIdsSelector()
  const selectedFiles = getFilesSelectedItemsSelector()
  const renamingFileId = getFilesRenamingItemIdSelector()

  const params = useParams()
  const navigate = useNavigate()
  const paths = parsePathFromParams(params, 'drive')
  const [arrColumns, handleSortByColumn] = useGetFilesTableColumns(
    onChangeSorting,
    sortState
  )

  const isMobile = useMobileDetection()

  const [withPressedCtrl, setWithPressedCtrl] = useState(false)
  const [withPressedShift, setWithPressedShift] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const searchType = getSearchTypeSelector()
  const currentFileFolder = paths.length === 0 ? '' : paths[paths.length - 1]


  const selectStartListener = useCallback((e) => {
    e.preventDefault()

    return false
  }, [])

  function dispatchKeyupEvent(key, code, keyCode) {
    const keyupEvent = new KeyboardEvent('keyup', {
      key: key,
      code: code,
      keyCode: keyCode,
      charCode: 0,
      bubbles: true,
      cancelable: true
    })

    document.dispatchEvent(keyupEvent)
  }

  function downHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(true)
    }

    if (key === 'Shift') {
      setWithPressedShift(true)

      document.addEventListener('selectstart', selectStartListener)
    }
  }

  function upHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(false)
    }

    if (key === 'Shift') {
      setWithPressedShift(false)

      document.removeEventListener('selectstart', selectStartListener)
    }
  }

  function blurWindow() {
    setWithPressedCtrl(false)
    setWithPressedShift(false)

    dispatchKeyupEvent('Shift', 'ShiftLeft', 16)
    dispatchKeyupEvent('Control', 'ControlLeft', 17)
    dispatchKeyupEvent('Meta', 'MetaLeft', 91)

    document.removeEventListener('selectstart', selectStartListener)
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    window.addEventListener('blur', blurWindow)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
      window.removeEventListener('blur', blurWindow)
    }
  }, [])

  usePageTitle('a_photos_searchMyPublicLinks')

  useEffect(() => {
    dispatch(filesActions.setActiveSearch(true))
    dispatch(filesActions.setSearchType({ name: t('a_photos_searchSharedToMe'), iconName: 'shared_to_me', type: 'shared_with_me' }))
    debounceGetSearchNodes(dispatch)

    return () => {
      dispatch(filesPreviewActions.resetState())
      dispatch(filesActions.resetState({ isActiveSearch: true }))
    }
  }, [])

  useEffect(() => {
    const createListener = (classes, callback) => {
      return (event) => {
        const elements = classes.map((className) => document.querySelector(`.${className}`)).filter(Boolean)

        if (!elements.some(element => element.contains(event.target))) {
          callback()
        }
      }
    }

    const listener = createListener(
      ['js-files-list', 'js-files-toolbar', 'js-mobile-toolbar-menu-toggle', 'js-files-mobile-toolbar', 'modal-dialog__container', 'js-upload-widget'],
      () => {
        dispatch(filesActions.unselectAll())
      }
    )

    document.addEventListener('click', listener, { capture: true })

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  const previewNodes = useMemo(() => nodes.filter(node => node.type === NodeTypes.FILE), [nodes])

  useFilesPreviewItemsUpdate(previewNodes)

  const handleChangeFolder = async (node) => {
    dispatch(filesActions.unselectAll())

    if (node.type !== FileItemsTypes.DIR) {
      dispatch(openFilesPreviewThunk({
        items: previewNodes as any,
        currentItemId: node.id,
        totalItemsCount: previewNodes.length
      }))

      return
    }

    dispatch(filesActions.setStatus(LOADING_STATUSES.LOADING))

    if (node.type === FileItemsTypes.DIR) {
      dispatch(filesActions.setActiveSearch(false))
      return navigate(`/drive/shared-with-me/${node.id}`)
    }
  }

  function onChangeSorting(sortState) {
    dispatch(filesActions.setSort(sortState))
  }

  const handleSelectNode = (node, items) => {
    if (withPressedShift) {
      dispatch(filesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    if (withPressedCtrl) {
      dispatch(filesActions.selectItemWithPressedCtrl({ id: node.id, items }))

      return
    }

    dispatch(filesActions.selectItem({ id: node.id, items }))
  }

  const handleElementsSelection = (node, items) => {
    if (withPressedShift) {
      dispatch(filesActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    dispatch(filesActions.selectItemWithPressedCtrl({ id: node.id, items }))
  }

  const handleCancelSelection = () => {
    dispatch(filesActions.unselectAll())
  }

  const handleRenameItem = (name) => {
    dispatch(renameSharedWithMeFileNodeThunk({ name, item: !!itemsRightClick ? itemsRightClick : selectedFiles[0], navigate }))
  }

  const handleCallback = () => {
    dispatch(filesActions.deleteItems([publicLinkDataForDelete.id]))
    dispatch(filesActions.unselectAll())
  }

  const handleDeletePublicLink = () => {
    dispatch(removeSharedWithMeLink({ nodeId: publicLinkDataForDelete.id, successCallback: handleCallback }))
    setPublicLinkDataForDelete(null)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleSelectAll = (items) => {
    dispatch(filesActions.selectAll(items))
  }

  const handleClickCheckbox = (allFileIds) => {
    if (selectType === SelectType.ALL) {
      handleCancelSelection()
    } else {
      handleSelectAll(allFileIds)
    }
  }

  const handleOpenPublicLinkRemovingModal = (item) => {
    setPublicLinkDataForDelete(item)
  }

  const handleClosePublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(null)
  }

  const validationSchemaInputSearch = yup.object().shape({
    name: yup
      .string()
      .required('ValidationMessage.REQUIRED')
      .max(255, 'ValidationMessage.TOO_LONG_STRING')
      .min(1, 'ValidationMessage.TOO_SHORT_STRING')
  })

  const arraySharedLinks = [
    { name: t('a_photos_searchMyPublicLinks'), iconName: 'shared_my_public_link', type: 'shared_my_public_link' },
    { name: t('a_photos_searchSharedToMe'), iconName: 'shared_to_me', type: 'shared_with_me' },
  ]

  const textsSearch = {
    titleTextSearch: t('l_common_searchByType'),
    textPlaceholder: t('l_common_search'),
    textRecent: t('l_common_recent'),
    textCancel: t('a_common_cancel'),
    folders: t('a_search_folders'),
    textTitleLinks: t('a_nav_publicLinks'),
    textSubtitelSharedWithMe: t('l_common_sharedWithMeFilesInfo'),
    textSubtitelSharedMyPublicLinks: t('l_common_myPublicLinksFilesInfo'),
  }

  const handleSearchText = (value: string) => {
    setSearchValue(value)
  }

  const handleSearchType = (type: ITypeProps) => {
    dispatch(filesActions.setSearchType(type))
  }

  const handleSwitchActive = () => {
    dispatch(filesActions.setActiveSearch(false))
    navigate('/drive')
  }

  const handleClearInput = () => {
    setSearchValue('')
    dispatch(filesActions.setSearchType(null))
    navigate('/drive')
  }

  const handleReplaceCancel = () => {
    dispatch(filesActions.setReplaceFilesNodeModalData({ opened: false }))
    dispatch(cancelFileReplacingThunk())
  }

  const handleKeepBoth = () => {
    dispatch(filesActions.setReplaceFilesNodeModalData({ opened: false, type: ReplaceModalTypes.KEEP_BOTH }))
    dispatch(keepBothFileVersionsThunk())
  }

  const handleReplace = () => {
    dispatch(filesActions.setReplaceFilesNodeModalData({ opened: false, type: ReplaceModalTypes.REPLACE }))
    dispatch(replaceFileThunk())
  }

  const handleRightClick = (node) => {
    if (selectedItemsCount > 1) {
      setItemsRightClick(selectedFiles)
      setItemsRightClickIds(selectedFilesIds)
    } else {
      setItemsRightClick(node)
      setItemsRightClickIds([node.id])
    }
  }

  const handleCloseCopyMoveModal = () => {
    setCopyMoveModalType(null)
  }

  const handleDownloadSelectedSharedWithMe = () => {
    dispatch(downloadSharedWithMeFilesThunk({ item: itemsRightClick, navigate }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenCopyToPersonalModal = () => {
    setCopyMoveModalType(CopyMoveModalType.COPY_TO_PERSONAL)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameSharedWithMeNode = () => {
    dispatch(publicLinkFilesActions.setRenamingItemId(itemsRightClick.node_id))
    dispatch(filesActions.setRenamingItemId(itemsRightClickIds[0]))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const contextMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []

    if ( [itemsRightClick].length === 1 ) {
      items = [
        {
          label: t('a_common_download'),
          iconName: 'download_empty',
          onClickItem: handleDownloadSelectedSharedWithMe,
        },
        {
          label: t('a_common_addToMyCloud'),
          iconName: 'add_photo_alternate_1',
          onClickItem: handleOpenCopyToPersonalModal,
        },
        {
          label: t('a_common_removeLink'),
          iconName: 'unlink',
          onClickItem: () => {
            handleOpenPublicLinkRemovingModal(itemsRightClick)
          }
        },
        itemsRightClick?._embedded?.share.permission === 'write' && {
          label: t('a_common_rename'),
          iconName: 'pencil',
          onClickItem: handleRenameSharedWithMeNode
        },
      ]
    }

    if (selectedItemsCount > 1) {
      items = [
        {
          label: t('a_common_addToMyCloud'),
          iconName: 'add_photo_alternate_1',
          onClickItem: handleOpenCopyToPersonalModal,
        },
      ]
    }

    return items.filter(Boolean)
  })()

  return (
    <SScrollBox overflowY={'unset'}
      overflowX={'unset'}
      isActiveSerch={isActiveSearch}
    >
      {status !== LOADING_STATUSES.LOADING && (
        <WithSearchBox className={classNames({ 'no-flex': !!selectedItemsCount, opened: isActiveSearch })}>
          {(!isActiveSearch || (isActiveSearch && !!selectedItemsCount)) && (
            <PageHeaderBox>
              <StyledPageTopbar
                title={t('a_photos_searchSharedToMe')}
                selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
                selectedItemsCount={selectedItemsCount}
                onCancelSelection={handleCancelSelection}
                className={classNames({ 'with-bigger-height': !!selectedItemsCount })}
              />

            </PageHeaderBox>
          )}

          <SSearchInput
            validationSchema={validationSchemaInputSearch}
            textsSearch={textsSearch}
            onSearchText={handleSearchText}
            onSearchType={handleSearchType}
            onSwitchActive={handleSwitchActive}
            isActiveSearch={isActiveSearch}
            onClearInput={handleClearInput}
            isMobile={isMobile}
            type={searchType}
            value={searchValue}
            hideInput={!!selectedItemsCount}
            withoutGrayLine
            withoutFoldersFilter
            isHiddenSlotBeetwen={nodes.length === 0}
            slotBeetwen={(
              <PublicLinksSection
                arraySharedLinks={arraySharedLinks}
                onSearchType={handleSearchType}
                textSubtitleShared={t('l_common_sharedWithMeFilesInfo')}
                textTitleLinks={ t('a_nav_publicLinks')}
              />
            )}
          />

        </WithSearchBox>
      )}

      {
        !nodes.length && status !== LOADING_STATUSES.LOADING ? (
          <SFileTableContainerEmpty
            isActiveSearch={isActiveSearch}
          >
            <EmptyFolderPlaceholder
              texts={{
                title: t('l_files_searchResultTitle'),
                description: t('l_common_sharedWithMeFilesEmpty'),
              }}
              isFolderImage={!isActiveSearch}
            />

            <ReplaceFilesItemModal
              data={replaceModalData}
              onCancel={handleReplaceCancel}
              onKeepBoth={handleKeepBoth}
              onReplace={handleReplace}
            />
          </SFileTableContainerEmpty>
        ) : (
          <SFileTableContainer
            isActiveSearch={isActiveSearch}
          >

            {status === LOADING_STATUSES.LOADING && <FilesSkeleton />}

            {!!nodes.length && status !== LOADING_STATUSES.LOADING && (
              <FilesList
                isActiveSearch={isActiveSearch}
                columns={arrColumns}
                selectType={selectType}
                checkboxVisibility={checkboxVisibility}
                items={mapFileItemsToTableRow(nodes, t)}
                onRenameItem={handleRenameItem}
                onSortingChange={handleSortByColumn}
                onToggleItem={handleSelectNode}
                onChangeFolder={handleChangeFolder}
                onClickCheckbox={handleElementsSelection}
                onRightClick={handleRightClick}
                onEditPublicLink={_.noop()}
                onDeletePublicLink={handleOpenPublicLinkRemovingModal}
                onCopyPublicLink={_.noop()}
                selectedFilesIds={selectedFilesIds}
                renamingFileId={renamingFileId}
                onClickAllCheckbox={handleClickCheckbox}
                contextMenuItems={contextMenuItems}
                searchValue={searchValue}
                isRemoveLinkText
                isShared
              />
            )}

            <ReplaceFilesItemModal
              data={replaceModalData}
              onCancel={handleReplaceCancel}
              onKeepBoth={handleKeepBoth}
              onReplace={handleReplace}
            />

            <FileVersionsModal />

            {!(isActiveSearch && !selectedItemsCount) && (
              <MobileToolbarMenuToggle
                iconName={selectedItemsCount > 0 ? 'more_horiz' : 'add'}
                className="js-mobile-toolbar-menu-toggle"
              />
            )}
          </SFileTableContainer>
        )}

      {
        !!copyMoveModalType && (
          <CopyMoveModal
            selectedItems={itemsRightClick.length > 1 ? itemsRightClick : [itemsRightClick]}
            type={copyMoveModalType}
            onClose={handleCloseCopyMoveModal}
            currentFileFolder={currentFileFolder}
            isSharedWithMeToDrive
          />
        )
      }

      <ConfirmationModal title={t('l_publicLink_removeConfirmTitle')}
        isOpened={!!publicLinkDataForDelete}
        onClose={handleClosePublicLinkRemovingModal}
        parentBlock={getModalsRootElement()}
        onOk={handleDeletePublicLink}
        okText={t('a_publicLinks_remove')}
        onCancel={handleClosePublicLinkRemovingModal}
        cancelText={t('a_common_cancel')}
        type={ConfirmationModalTypes.danger}
      >
        <SRemovingLinkConfirmationModalText>
          {t('l_publicLink_removeSharedConfirmMessage')}
        </SRemovingLinkConfirmationModalText>
      </ConfirmationModal>
    </SScrollBox>
  )
}

const SFileTableContainerEmpty = styled(FileTableContainer)`
  display: flex;
  height: 90%;
`

const SFileTableContainer = styled(FileTableContainer)`
  height: 100%;
  margin-bottom: 30px;
`

const SScrollBox = styled.div`
  height: 100%;
  overflow-y: ${(props: any) => props.overflowY};
  overflow-x: ${(props: any) => props.overflowX};
  padding: ${(props:any) => {return props.isActiveSerch ? '20px 16px' : ''}};
  
  @supports (-moz-appearance: none) {
    scrollbar-color: var(--scroll-color) transparent;
  }
  
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    padding: ${(props:any) => {return props.isActiveSerch ? '20px 24px' : ''}}
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    padding: ${(props:any) => {return props.isActiveSerch ? '20px 16px 0 24px' : ''}}
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    padding: ${(props:any) => {return props.isActiveSerch ? '20px 36px 0px' : ''}};
  }
  
  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

const PageHeaderBox = styled.div`
  display: flex;
  flex-flow: column;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin: 0;
  }
`

const StyledPageTopbar = styled(PageTopbar)`
  && {
    min-height: 40px;
  }
`

const WithSearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  
  &.opened {
    padding-bottom: 0;
  }

  &.no-flex {
    display: block;
  }
`

const SRemovingLinkConfirmationModalText = styled.p`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 572px;
`

const SSearchInput = styled(SearchInput)`
`

export default FilesPage
