import React, { useEffect, useState, useRef } from "react"

import { SpriteIcon, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import styled from "styled-components"
import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch } from "store"
import { useMobileDetection } from "features/common/hooks"

import photoPlaceholderImg from '../../../assets/moments-album-placeholder.png'

import { getAlbumsMemoriesAlbumsSelector, getCurrentMemoriesAlbumDataSelector } from "./selectors"
import { albumsMemoriesActions } from "./albumsMemoriesSlice"

const calculatePrevSlideIndex = (currentSlideIndex: number, totalSlides: number) => {
  if (currentSlideIndex === 0) {
    return totalSlides - 1
  }

  return currentSlideIndex - 1
}

const calculateNextSlideIndex = (currentSlideIndex: number, totalSlides: number) => {
  if (currentSlideIndex === totalSlides - 1) {
    return 0
  }

  return currentSlideIndex + 1
}

const getImageSrc = (album: IAlbumSchema) => {
  const imgUrls = album?._embedded?.cover_items

  return imgUrls && !!imgUrls.length && !!imgUrls[0] ? (imgUrls[0]._links as any)?.image_middle?.href : photoPlaceholderImg
}

const formatTitle = (title: string) => {
  if (!title) {
    return ''
  }

  return title.length > 50 ? title.slice(0, 47) + '...' : title
}

export const MemoriesAlbumsSlider = React.memo(({ onRepeat }: { onRepeat: () => void }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const isMobile = useMobileDetection()

  const albums = getAlbumsMemoriesAlbumsSelector()
  const currentAlbumData = getCurrentMemoriesAlbumDataSelector()

  const indexOfCurrentAlbum = albums.findIndex(album => album.id === currentAlbumData.id)

  const [currentSlideIndex, setCurrentSlideIndex] = useState(indexOfCurrentAlbum === albums.length - 1 ? 0 : indexOfCurrentAlbum + 1)

  const prevSlideData = albums[calculatePrevSlideIndex(currentSlideIndex, albums.length)]
  const currentSlideData = albums[currentSlideIndex]
  const nextSlideData = albums[calculateNextSlideIndex(currentSlideIndex, albums.length)]

  const handlePrev = () => {
    setCurrentSlideIndex(state => calculatePrevSlideIndex(state, albums.length))
  }

  const handleNext = () => {
    setCurrentSlideIndex(state => calculateNextSlideIndex(state, albums.length))
  }

  const handleMemoryAlbumClick = () => {
    if (indexOfCurrentAlbum === currentSlideIndex) {
      onRepeat()

      return
    }

    const from = (location?.state as any)?.from

    dispatch(albumsMemoriesActions.setCurrentAlbumData(currentSlideData))
    dispatch(albumsMemoriesActions.setPlayerState({
      isPlaying: true,
      currentTime: 0,
      finished: false
    }))
    navigate(`/photos/memories/${currentSlideData.id}/modal`, { state: { from }, replace: true })
  }

  const handleDotClick = (index: number) => {
    setCurrentSlideIndex(index)
  }

  const handleRepeat = () => {
    onRepeat()
  }

  const handleMobileClickNext = () => {
    if (isMobile)
      handleNext()
  }

  const handleMobileClickPrev = () => {
    if (isMobile) {
      handlePrev()
    }
  }

  const sliderRef = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    let touchStartX = 0
    let touchEndX = 0
  
    const handleTouchStart = (e: TouchEvent) => {
      touchStartX = e.touches[0].clientX
      touchEndX = touchStartX
    }
    
    const handleTouchMove = (e: TouchEvent) => {
      touchEndX = e.touches[0].clientX
    }
    
    const handleTouchEnd = () => {
      const swipeThreshold = 50
      const swipeDistance = touchEndX - touchStartX
      
      if (Math.abs(swipeDistance) < 10) {
        return
      }
      
      if (swipeDistance > swipeThreshold) {
        handlePrev()
      } else if (swipeDistance < -swipeThreshold) {
        handleNext()
      }
    }
    
    const sliderElement = sliderRef.current
    
    if (sliderElement) {
      sliderElement.addEventListener('touchstart', handleTouchStart)
      sliderElement.addEventListener('touchmove', handleTouchMove)
      sliderElement.addEventListener('touchend', handleTouchEnd)
      
      return () => {
        sliderElement.removeEventListener('touchstart', handleTouchStart)
        sliderElement.removeEventListener('touchmove', handleTouchMove)
        sliderElement.removeEventListener('touchend', handleTouchEnd)
      }
    }
  }, [albums.length])
  
  return (
    <SSlider>
      <SArrowContainer onClick={handlePrev}>
        <SArrowIcon iconName="arrow_left" />
      </SArrowContainer>

      <SSliderInner ref={sliderRef}>
        <SSlide className="slider-touch-area"
          onClick={handleMobileClickPrev}
        >
          {
            (albums.length > 2 || (albums.length === 2 && indexOfCurrentAlbum !== currentSlideIndex)) && (
              <>
                <SSlideImg src={getImageSrc(prevSlideData)} />

                <SSlideTitle>
                  {formatTitle(prevSlideData?.description)}
                </SSlideTitle>
              </>
            )
          }
        </SSlide>

        <SSlide className="active"
          onClick={handleMemoryAlbumClick}
        >
          <SSlideImg src={getImageSrc(currentSlideData)} />

          <SSlideTitle>
            {formatTitle(currentSlideData?.description)}
          </SSlideTitle>
        </SSlide>
        
        <SSlide onClick={handleMobileClickNext}>
          {
            (albums.length > 2 || (albums.length === 2 && indexOfCurrentAlbum === currentSlideIndex)) && (
              <>
                <SSlideImg src={getImageSrc(nextSlideData)} />

                <SSlideTitle>
                  {formatTitle(nextSlideData?.description)}
                </SSlideTitle>
              </>
            )
          }
        </SSlide>

        <SDots>
          {albums.map((album, index) => (
            <SDot
              key={album.id}
              className={index === currentSlideIndex ? 'active' : ''}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </SDots>
      </SSliderInner>

      <SArrowContainer onClick={handleNext}>
        <SArrowIcon iconName="arrow_right" />
      </SArrowContainer>

      <SMobileBottomContainer>
        <SMobileTitle>
          {formatTitle(currentAlbumData?.description)}
        </SMobileTitle>

        <SMobileRepeatButton onClick={handleRepeat}>
          <SMobileRepeatButtonIcon iconName="refresh_2" />
        </SMobileRepeatButton>
      </SMobileBottomContainer>
    </SSlider>
  )
})

const SSlider = styled.div`
  height: 504px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  user-select: none;
  z-index: 2;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    height: 400px;
  }
`

const SSliderInner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  position: relative;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    gap: 20px;
  }
`

const SSlide = styled.div`
  height: 424px;
  width: 292px;
  position: relative;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 190px;
    background: linear-gradient(0, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &.active {
    height: 504px;
    width: 372px;
    cursor: pointer;

    &::after {
      display: none;
    }
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 273px;
    height: 400px;

    &.active {
      width: 273px;
      height: 400px;
    }
  }
`

const SArrowContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: rgba(39, 39, 46, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.rotated {
    transform: rotate(180deg);
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: none;
  }
`

const SArrowIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  color: white;
`

const SSlideImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`

const SSlideTitle = styled.p`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  color: white;
  word-break: break-all;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: calc(100% - 20px);
    font-size: 18px;
    line-height: 24px;
    left: 10px;
  }
`

const SDots = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: none;
  }
`

const SDot = styled.div`
  width: 7px;
  height: 7px;
  background: rgba(54, 54, 61, 1);
  border-radius: 50%;
  cursor: pointer;

  &.active {
    background: #97979B;
  }
`

const SMobileBottomContainer = styled.div`
  display: none;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 20px;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

const SMobileTitle = styled.p`
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  color: rgba(225, 225, 226, 1);
`

const SMobileRepeatButton = styled.button`
  display: none;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
  }
`

const SMobileRepeatButtonIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
`
