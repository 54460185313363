import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AlbumCard, AlbumsGrid, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { getAlbumDateStr } from 'features/albums/utils/albumDate'
import { IExtendedAlbumSchema } from 'features/albums/albums-list/albumsSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { EmptyPublicLinksPlaceholder } from 'features/public-links-albums/EmptyPublicLinksPlaceholder'
import { useAppDispatch } from 'store'

import { getSharedWithMeAlbumsItemsSelector, getSharedWithMeAlbumsSelectedItemsIdsSelector } from './selectors'
import { sharedWithMeAlbumsActions } from './sharedWithMeAlbumsSlice'

interface PublicAlbumsProps {
  className?: string
}

export const SharedWithMeAlbums: React.FC<PublicAlbumsProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const items = getSharedWithMeAlbumsItemsSelector()
  const selectedItemsIds = getSharedWithMeAlbumsSelectedItemsIdsSelector()

  const handleCardClick = (album: IExtendedAlbumSchema) => {
    const link = `/photos/shared-with-me/albums/${album.shared_hash}`

    navigate(link, { state: { from: location.pathname } })
  }

  const handleSelectItem = (id: string) => {
    dispatch(sharedWithMeAlbumsActions.selectItem(id))
  }

  if (!items.length) {
    return (
      <SSharedWithMeAlbums
        className={className}
      >
        <EmptyPublicLinksPlaceholder
          texts={{
            title: t('l_files_searchResultTitle'),
            description: t('l_common_sharedWithMeEmpty')
          }}
        />
      </SSharedWithMeAlbums>
    )
  }

  return (
    <SSharedWithMeAlbums
      className={className}
    >
      <SDescription>
        {t('l_common_sharedWithMeInfo')}
      </SDescription>
            
      <AlbumItemsBox>
        <AlbumsGrid>
          {items.map(album => (
            <AlbumCard
              key={album.id}
              id={album.id}
              albumName={album.description || t('l_common_untitledAlbum')}
              albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
              description={album.description}
              onSelect={() => handleSelectItem(album.id)}
              isSelected={selectedItemsIds.includes(album.id)}
              imgUrl={(album?._embedded?.cover_item?._links as any)?.image_middle?.href}
              albumModified={getAlbumDateStr(album)}
              onClick={() => handleCardClick(album)}
            />
          ))}
        </AlbumsGrid>
      </AlbumItemsBox>
    </SSharedWithMeAlbums>
  )
}

const SSharedWithMeAlbums = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 40px;
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  
  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    padding-right: 0;
  }
`

const AlbumItemsBox = styled.div`
  width: 100%;
`

const SDescription = styled.div`
  margin-bottom: 18px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: var(--text-primary);

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    margin-top: 8px;
  }
`