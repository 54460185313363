import { STORAGE_CONSTANTS } from 'constants/storage'

import React, { useEffect } from 'react'

import styled from "styled-components"
import {
  AlbumCard,
  AlbumPlaceCard,
  AlbumsGrid,
  AlbumsPlacesGrid,
  PeopleCard,
  PeopleList,
  PhotosGrid,
  SearchInput,
  SpriteIcon,
  STYLED_VARIABLES
} from "@cloudike/web_ui_components"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { useNavigate } from "react-router-dom"
import _ from "lodash"
import { getIsMainContainerWithFullWidth } from 'features/common/selectors'
import classNames from 'classnames'
import { getNavigationPathname } from 'utils/navigationStorage'

import { useMobileDetection } from "../common/hooks"
import { LOADING_STATUSES } from "../../constants/loadingStatuses"
import { getNumberBreakpoint } from "../albums/albums-list/Albums"
import { AlbumsPlacesTitle } from "../albums/albums-places/AlbumsPlacesTitle"
import { useFilters } from "../common/hooks/useFilters"
import { analytics, ANALYTICS_EVENTS } from "../common/analytics"
import { SDK_TYPES } from "../../sdk/sdkConstants"
import { getAlbumsPlacesItemsSelector, getAlbumsPlacesLoadingStatusSelector } from "../albums/albums-places/selectors"
import { getPreviewAlbumLink } from "../../utils/getPreviewAlbumLink"
import { albumsPlacesActions, fetchPlacesAlbumsThunk } from "../albums/albums-places/albumsPlacesSlice"
import { getAlbumsPersonStatus, getAlbumsPersonWithNoneZeroContent } from "../albums/albums-person/selectors"

import {
  getAlbumsPersonWithSearchSelector,
  getPhotoSearchLoadingStatusSelector,
  getPhotoSearchSuggestionsSelector,
} from "./selectors"
import { getSearchSuggestionsThunk, photoSearchActions } from "./photoSearchSlice"
import { EmptySearchPlaceholder } from "./EmptySearchPlaceholder"

export enum SearchActiveStatuses {
  active = '1',
  inactive = '2'
}

export enum SearchTypes {
  publicLinks = '1',
  sharedWithMe = '2'
}

export type SearchType = SearchTypes | null

interface IWithSearchProps {
  children: React.ReactNode,
  isSearchHidden?: boolean,
  defaultPageContent: React.ReactNode,
  type?: SDK_TYPES,
  isChildrenVisible?: boolean,
  defaultFilters?: {
    s_active: SearchActiveStatuses,
    s_text: string,
    s_type: SearchType
  }
}

const debouncedGetSearchSuggestions = _.debounce((dispatch, text) => {
  dispatch(getSearchSuggestionsThunk({ text: text }))
}, 500)

export const WithSearch: React.FC<IWithSearchProps> = ({ children, isSearchHidden, defaultPageContent, type = SDK_TYPES.DEFAULT, defaultFilters, isChildrenVisible = false }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobile = useMobileDetection()
  const navigate = useNavigate()

  const [filters, setFilters] = useFilters<{s_active: SearchActiveStatuses, s_text: string, s_type: SearchType}>(defaultFilters || { s_active: SearchActiveStatuses.inactive, s_text: '', s_type: null })

  const suggestions = getPhotoSearchSuggestionsSelector()
  const status = getPhotoSearchLoadingStatusSelector()

  const placeAlbums = getAlbumsPlacesItemsSelector()
  const statusPlaceAlbums = getAlbumsPlacesLoadingStatusSelector()
  const albumsPerson = getAlbumsPersonWithNoneZeroContent()
  const albumsPersonStatus = getAlbumsPersonStatus()
  const resultAlbumsPerson = getAlbumsPersonWithSearchSelector()
  const isMainContainerWithFullWidth = getIsMainContainerWithFullWidth()

  const isSearchActive = filters.s_active === SearchActiveStatuses.active
  const searchText = filters.s_text
  const needToShowContent = !isSearchActive || filters.s_type

  const searchInputTypes = {
    [SearchTypes.publicLinks]: {
      name: t('a_photos_searchMyPublicLinks'),
      iconName: 'shared_my_public_link'
    },
    [SearchTypes.sharedWithMe]: {
      name: t('a_photos_searchSharedToMe'),
      iconName: 'shared_to_me'
    }
  }

  useEffect(() => {
    return () => {
      dispatch(albumsPlacesActions.resetState())
    }
  }, [])

  useEffect(() => {
    dispatch(albumsPlacesActions.setStatus(LOADING_STATUSES.IDLE))

    if (!isSearchActive) {
      dispatch(photoSearchActions.setSearchText(''))

      return
    }

    if (searchText.length === 0) {
      dispatch(albumsPlacesActions.setCurrentAlbumsType(type))
      dispatch(fetchPlacesAlbumsThunk())
    }

    dispatch(photoSearchActions.setSearchType(type))
    dispatch(photoSearchActions.setLoadingStatus(LOADING_STATUSES.LOADING))

    debouncedGetSearchSuggestions(dispatch, searchText)
  }, [searchText, isSearchActive])

  const validationSchemaInputSearch = yup.object().shape({
    name: yup
      .string()
      .required('ValidationMessage.REQUIRED')
      .max(255, 'ValidationMessage.TOO_LONG_STRING')
      .min(1, 'ValidationMessage.TOO_SHORT_STRING')
  })

  const textsSearch = {
    titleTextSearch: t('l_common_searchByType'),
    textPlaceholder: t('l_common_search'),
    textRecent: t('l_common_recent'),
    textCancel: t('a_common_cancel'),
  }

  const handleSearchText = (value: string) => {
    setFilters({ s_text: value })
  }

  const handleSwitchActive = (isActive) => {
    if (filters.s_type && !isActive) {
      const beforePhotosSearchPathname = getNavigationPathname(STORAGE_CONSTANTS.BEFORE_PHOTOS_SEARCH_PATHNAME)

      navigate(`${beforePhotosSearchPathname}`)

      return
    }

    setFilters({ s_active: isActive ? SearchActiveStatuses.active : null, s_text: isActive ? searchText : null })
  }

  const handleClearInput = () => {
    dispatch(photoSearchActions.setLoadingStatus(LOADING_STATUSES.LOADING))

    if (filters.s_type) {
      const beforePhotosSearchPathname = getNavigationPathname(STORAGE_CONSTANTS.BEFORE_PHOTOS_SEARCH_PATHNAME)

      navigate(`${beforePhotosSearchPathname}?s_active=${SearchActiveStatuses.active}`)

      return
    }

    if (!searchText) {
      setFilters({ s_text: null, s_type: null })
      dispatch(getSearchSuggestionsThunk({ text: '' }))
    } else {
      setFilters({ s_text: null })
    }
  }

  const handlePlaceAlbumClick = (id) => {
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_SEARCH_ALBUM_PLACES_CLICK)

    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums/places/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    } else {
      navigate(`/photos/albums/places/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    }
  }

  const handleMomentsAlbumClick = (id) => {
    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums/moments/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    } else {
      navigate(`/photos/albums/moments/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    }
  }

  const handleAlbumClick = (id) => {
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_SEARCH_ALBUMS_CLICK)
    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    } else {
      navigate(`/photos/albums/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    }
  }

  const handleDatesAlbumClick = (id) => {
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_SEARCH_ALBUM_DATES_CLICK)

    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/search/photos/albums/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    } else {
      navigate(`/search/photos/albums/${id}`, { state: { from: `${window.location.pathname}${window.location.search}` } })
    }
  }

  const handleSeeAllPlacesClick = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_SEARCH_ALBUM_PLACES_SEE_ALL_CLICK)

    navigate('/search/places', { state: { from: `${window.location.pathname}${window.location.search}` } })
  }

  const handleSeeAllMomentsClick = () => {
    navigate('/search/moments', { state: { from: `${window.location.pathname}${window.location.search}` } })
  }

  const handleSeeAllPersonsClick = () => {
    const path = type === SDK_TYPES.DEFAULT ? '/photos/albums/people/' : '/family/photos/albums/people/'
    navigate(path)
  }

  const BREAKPOINTS = {
    POINT_767: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.PHONE_90),
    POINT_1024: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.TABLET),
    POINT_1280: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.LAPTOP),
    POINT_1440: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.DESKTOP)
  }
  const countsByBreakpoints = [
    [BREAKPOINTS.POINT_767, 2],
    [BREAKPOINTS.POINT_1024, 4],
    [BREAKPOINTS.POINT_1280, 5],
    [BREAKPOINTS.POINT_1440, 4]
  ]

  const getPlacesCount = () => {
    const widthDisplay = document.documentElement.clientWidth

    for (const [threshold, count] of countsByBreakpoints) {
      if (widthDisplay <= threshold) {
        return count
      }
    }
    return 6
  }

  const nothingFound = (() => {
    return !suggestions.address_suggestions?.length && !suggestions.calendar_suggestions?.length && !suggestions.album_suggestions.length && !resultAlbumsPerson.length && !suggestions.moment_suggestions?.length
  })()

  const handleClickAlbum = (id: string) => {
    const path = type === SDK_TYPES.DEFAULT ? `/photos/albums/people/${id}` : `/family/photos/albums/people/${id}`
    navigate(path)
  }

  const peopleListTexts = {
    title: t('l_search_people'),
    seeAll: t('a_albums_seeAll')
  }

  const handleClickPublicLinks = () => {
    if (type === SDK_TYPES.FAMILY) {
      navigate(`/photos/family/shared-by-me`)
    } else {
      navigate(`/photos/shared-by-me`)
    }
  }

  const handleClickSharedWithMe = () => {
    navigate(`/photos/shared-with-me`)
  }

  return (
    <>
      <SWithSearchHeader className={classNames({ 'with-padding-on-mobile': isMainContainerWithFullWidth })}>
        {(!isSearchActive || isChildrenVisible) && children}

        {!isSearchHidden && (
          <SSearchInput
            validationSchema={validationSchemaInputSearch}
            textsSearch={textsSearch}
            onSearchText={handleSearchText}
            onSwitchActive={handleSwitchActive}
            isActiveSearch={isSearchActive}
            onClearInput={handleClearInput}
            isMobile={isMobile}
            type={searchInputTypes[filters.s_type]}
            value={searchText}
            withoutGrayLine
            maxLength={255}
            withoutFoldersFilter
          />
        )}
      </SWithSearchHeader>

      {!!needToShowContent && defaultPageContent}

      {isSearchActive && !needToShowContent && (
        <SSearchContent className={classNames({ 'with-padding-on-mobile': isMainContainerWithFullWidth })}>
          {
            !searchText && (
              <>
                <SCategoryTitle className='m-t-32'>
                  {t('a_nav_publicLinks')}
                </SCategoryTitle>

                <SAlbumsNamesTable>
                  <SAlbumRow onClick={handleClickPublicLinks}>
                    <SAlbumIcon iconName='shared_my_public_link' />

                    {t('a_photos_searchMyPublicLinks')}

                    <SAlbumArrowIcon iconName='arrow_right' />
                  </SAlbumRow>

                  {type !== SDK_TYPES.FAMILY && (
                    <SAlbumRow onClick={handleClickSharedWithMe}>
                      <SAlbumIcon iconName='shared_to_me' />

                      {t('a_photos_searchSharedToMe')}

                      <SAlbumArrowIcon iconName='arrow_right' />
                    </SAlbumRow>
                  )}
                </SAlbumsNamesTable>
              </>
            )
          }

          {
            !!nothingFound && status !== LOADING_STATUSES.LOADING && statusPlaceAlbums !== LOADING_STATUSES.LOADING &&
            <EmptySearchPlaceholder isEmptyByDefault={!searchText} />
          }

          {
            (status === LOADING_STATUSES.LOADING || statusPlaceAlbums === LOADING_STATUSES.LOADING) &&
            <Skeleton />
          }

          { !!suggestions.moment_suggestions?.length && searchText.length > 0 && (
            <>
              <AlbumsPlacesTitle
                totalCount={suggestions.moment_suggestions.length}
                textTitle={t('l_albums_moments')}
                countAlbums={getPlacesCount()}
                textButton={t('a_albums_seeAll')}
                handleSeeAllClick={handleSeeAllMomentsClick}
              />

              <SAlbumsPlacesGrid key={getPlacesCount()}
                isAlbumsPage={true}
              >
                {suggestions.moment_suggestions.slice(0, getPlacesCount()).map(album => (
                  <AlbumPlaceCard
                    key={album.id}
                    id={album.id}
                    albumName={album.name || t('l_common_untitledAlbum')}
                    albumPhotoCount={t('l_common_countElements', { number: album.live_refs_count })}
                    imgUrl={getPreviewAlbumLink(album,'middle')}
                    onClick={() => handleMomentsAlbumClick(album.id)}
                  />
                ))}
              </SAlbumsPlacesGrid>
            </>
          )}

          {
            status !== LOADING_STATUSES.LOADING && albumsPersonStatus !== LOADING_STATUSES.LOADING
            && albumsPerson.length > 0 && searchText.length === 0 && (
              <>
                <PeopleList
                  texts={peopleListTexts}
                  onClickSeeAll={handleSeeAllPersonsClick}
                >
                  {albumsPerson.map((item) => {
                    return item._embedded && (
                      <PeopleCard
                        isLittle={true}
                        key={item.id}
                        id={item.id}
                        img={getPreviewAlbumLink(item, 'album')}
                        onClick={handleClickAlbum}
                        name={item.description}
                        itemsCounter={undefined}
                      />
                    )
                  })
                  }
                </PeopleList>
              </>
            )
          }

          {status !== LOADING_STATUSES.LOADING && albumsPersonStatus !== LOADING_STATUSES.LOADING
            && resultAlbumsPerson.length > 0 && searchText.length > 0 && (
            <>
              <PeopleList
                texts={peopleListTexts}
                onClickSeeAll={handleSeeAllPersonsClick}
              >
                {resultAlbumsPerson.map((item) => {
                  return item._embedded && (
                    <PeopleCard
                      isLittle={true}
                      key={item.id}
                      id={item.id}
                      img={getPreviewAlbumLink(item, 'album')}
                      onClick={handleClickAlbum}
                      name={item.description}
                      itemsCounter={undefined}
                    />
                  )
                })
                }
              </PeopleList>
            </>
          )}

          {
            status !== LOADING_STATUSES.LOADING && statusPlaceAlbums !== LOADING_STATUSES.LOADING && (
              <>
                {
                  !!placeAlbums.length && searchText.length === 0 && (
                    <>
                      <AlbumsPlacesTitle
                        totalCount={suggestions.address_suggestions.length}
                        textTitle={t('l_photos_searchPlaces')}
                        countAlbums={getPlacesCount()}
                        textButton={t('a_albums_seeAll')}
                        handleSeeAllClick={handleSeeAllPlacesClick}
                      />

                      <SAlbumsPlacesGrid key={getPlacesCount()}
                        isAlbumsPage={true}
                      >
                        {placeAlbums.slice(0, getPlacesCount()).map(album => (
                          <AlbumPlaceCard
                            key={album.id}
                            id={album.id}
                            albumName={album.description || t('l_common_untitledAlbum')}
                            albumPhotoCount={t('l_common_countElements', { number: album.items_count })}
                            imgUrl={getPreviewAlbumLink(album,'middle')}
                            onClick={() => handlePlaceAlbumClick(album.id)}
                          />
                        ))}
                      </SAlbumsPlacesGrid>
                    </>
                  )}

                {
                  !!suggestions.address_suggestions?.length && searchText.length > 0 && (
                    <>
                      <AlbumsPlacesTitle
                        totalCount={suggestions.address_suggestions.length}
                        textTitle={t('l_photos_searchPlaces')}
                        countAlbums={getPlacesCount()}
                        textButton={t('a_albums_seeAll')}
                        handleSeeAllClick={handleSeeAllPlacesClick}
                      />

                      <SAlbumsPlacesGrid key={getPlacesCount()}
                        isAlbumsPage={true}
                      >
                        {suggestions.address_suggestions.slice(0, getPlacesCount()).map(album => (
                          <AlbumPlaceCard
                            key={album.id}
                            id={album.id}
                            albumName={album.name || t('l_common_untitledAlbum')}
                            albumPhotoCount={t('l_common_countElements', { number: album.live_refs_count })}
                            imgUrl={(album?._embedded?.cover_item?._links as any)?.image_middle?.href}
                            onClick={() => handlePlaceAlbumClick(album.id)}
                          />
                        ))}
                      </SAlbumsPlacesGrid>
                    </>
                  )}

                {
                  !!suggestions.album_suggestions?.length && (
                    <>
                      <SCategoryTitle>
                        {t('l_albums_albumsTitle')}
                      </SCategoryTitle>

                      {
                        !!searchText && (
                          <AlbumsGrid>
                            {
                              suggestions.album_suggestions.map(album => (
                                <AlbumCard
                                  key={album.id}
                                  id={album.id}
                                  albumName={album.name || t('l_common_untitledAlbum')}
                                  albumPhotoCount={t('l_common_countElements', { number: album.live_refs_count })}
                                  description={album.description}
                                  isSelected={false}
                                  imgUrl={(album?._embedded?.cover_item?._links as any)?.image_middle?.href}
                                  albumModified={''}
                                  onClick={() => handleAlbumClick(album.id)}
                                  onSelect={() => false}
                                  withoutCheckbox
                                />
                              ))
                            }
                          </AlbumsGrid>
                        )
                      }

                      {
                        !searchText && (
                          <SAlbumsNamesTable>
                            {suggestions.album_suggestions.slice(0, 10).map(album => (
                              <SAlbumRow key={album.id}
                                onClick={() => handleAlbumClick(album.id)}
                              >
                                <SAlbumIcon iconName='picture' />

                                {album.name || t('l_common_untitledAlbum')}

                                <SAlbumArrowIcon iconName='arrow_right' />
                              </SAlbumRow>
                            ))}
                          </SAlbumsNamesTable>
                        )
                      }
                    </>
                  )}

                {
                  !!suggestions.calendar_suggestions?.length && (
                    <>
                      <SCategoryTitle>
                        {t('l_photos_searchDates')}
                      </SCategoryTitle>

                      {
                        !!searchText && (
                          <AlbumsGrid>
                            {
                              suggestions.calendar_suggestions.map(album => (
                                <AlbumCard
                                  key={album.id}
                                  id={album.id}
                                  albumName={album.name || t('l_common_untitledAlbum')}
                                  albumPhotoCount={t('l_common_countElements', { number: album.live_refs_count })}
                                  description={album.description}
                                  isSelected={false}
                                  imgUrl={(album?._embedded?.cover_item?._links as any)?.image_middle?.href}
                                  albumModified={''}
                                  onClick={() => handleDatesAlbumClick(album.id)}
                                  onSelect={() => false}
                                  withoutCheckbox
                                />
                              ))
                            }
                          </AlbumsGrid>
                        )
                      }

                      {
                        !searchText && (
                          <SAlbumsNamesTable>
                            {suggestions.calendar_suggestions.slice(0, 3).map(album => (
                              <SAlbumRow key={album.id}
                                onClick={() => handleDatesAlbumClick(album.id)}
                              >
                                <SAlbumIcon iconName='calendarm' />

                                {album.name || t('l_common_untitledAlbum')}

                                <SAlbumArrowIcon iconName='arrow_right' />
                              </SAlbumRow>
                            ))}
                          </SAlbumsNamesTable>
                        )}

                    </>
                  )}
              </>
            )}
        </SSearchContent>
      )}
    </>
  )
}

const SWithSearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.with-padding-on-mobile {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
`

const SSearchInput = styled(SearchInput)`
  /* .search-input-box {
    margin: 0;
    align-items: center;
    
    .search-cancel-btn {
      margin-top: 0;
      margin-right: 0;
    }
  } */
`

const SSearchContent = styled.div`
  height: calc(100% - 81px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  margin-right: -23px;
  
  @supports (-moz-appearance: none) {
    scrollbar-color: var(--scroll-color) transparent;
  }
  
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    margin-right: unset;
  }

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.with-padding-on-mobile {
      padding: 0 16px;
    }
  }
`

const SCategoryTitle = styled.p`
  margin-bottom: 18px;
  color: var(--text-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  &.m-t-32 {
    margin-top: 32px;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.m-t-32 {
      margin-top: 12px;
    }
  }
`

const SAlbumsPlacesGrid = styled(AlbumsPlacesGrid)`
  padding: 0;
  margin-bottom: 40px;
`

const SAlbumsNamesTable = styled.div`
  margin-top: 18px;
  margin-bottom: 40px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    margin-top: 12px;
    margin-bottom: 24px;
  }
`

const SAlbumRow = styled.div`
  height: 56px;
  width: 100%;
  border-top: 1px solid var(--divider-primary);
  display: flex;
  align-items: center;
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;

  &:first-of-type {
    border-top: none;
  }
  
  &:last-of-type {
    border-bottom: none;
  }
`

const SAlbumIcon = styled(SpriteIcon)`
  margin-right: 12px;
  width: 24px;
  height: 24px;
  color: var(--ink-light);
`

const SAlbumArrowIcon = styled(SpriteIcon)`
  margin-left: auto;
  width: 24px;
  height: 24px;
  color: var(--icon-secondary);
`

const Skeleton = () => {
  return (
    <>
      <SCategoryTitleSkeleton />

      <PhotosGrid>
        {Array.from({ length: 12 }).map((_, index) => {
          return (
            <SSkeletonCard key={index} />
          )
        })}
      </PhotosGrid>

      <SCategoryTitleSkeleton />

      <PhotosGrid>
        {Array.from({ length: 12 }).map((_, index) => {
          return (
            <SSkeletonCard key={index} />
          )
        })}
      </PhotosGrid>
    </>
  )
}

const SCategoryTitleSkeleton = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 120px;
  height: 34px;
  background: var(--background-tertiary);
`

const SSkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

