import { useAppSelector } from "store"

import { albumsMomentsSelectors } from "./albumsMomentsSlice"

export const getAlbumsMomentsLoadingStatusSelector = () => useAppSelector(state =>
  state.albumsMoments.status
)

export const getAlbumsMomentsTotalCountSelector = () => useAppSelector(state =>
  state.albumsMoments.totalCount
)

export const getAlbumsMomentsItemsSelector = () => useAppSelector(state => albumsMomentsSelectors.selectAll(state.albumsMoments).filter(album => album.live_items_count > 0))

export const getEmptyAlbumsCountSelector = () => useAppSelector(state => albumsMomentsSelectors.selectAll(state.albumsMoments).filter(album => album.live_items_count  === 0).length)
