import { STYLED_VARIABLES } from 'constants/styledVariables'
import { getModalsRootElement } from 'constants/modals'

import { useState } from 'react'

import { ConfirmationModalTypes, MenuWithActionItems, PrimaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { appActions } from 'store/app'
import { DialogModal } from 'ui/DialogModal'
import { RenameAlbumModal } from 'features/albums/album/RenameAlbumModal'

import { getSharedWithMeAlbumsSelectedItemsSelector } from './selectors'
import { copySharedWithMeAlbumThunk, deleteSharedWithMeAlbumThunk, downloadSharedWithMeAlbumThunk, renameSharedWithMeAlbumThunk } from './sharedWithMeAlbumsSlice'

interface SharedWithMeAlbumsToolbarProps {
  className?: string,
}

export const SharedWithMeAlbumsToolbar: React.FC<SharedWithMeAlbumsToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItems = getSharedWithMeAlbumsSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length

  const [publicLinkDataForDelete, setPublicLinkDataForDelete] = useState(null)
  const [renameModalOpened, toggleRenameModal] = useState(false)
  const [newAlbumName, setNewAlbumName] = useState('')

  const selectedItem = selectedItems[0]
  const hasEditPermission = selectedItem?.shared_edit

  const handleAddAlbumToMyCloud = () => {
    dispatch(copySharedWithMeAlbumThunk({ sourceUrl: selectedItems[0]._links.self.href }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenPublicLinkRemovingModal = (item) => {
    setPublicLinkDataForDelete(item)
  }

  const handleClosePublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(null)
  }

  const handleRemoveAlbums = () => {
    dispatch(deleteSharedWithMeAlbumThunk({ id: selectedItems[0].id }))
    setPublicLinkDataForDelete(null)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadAlbum = () => {
    dispatch(downloadSharedWithMeAlbumThunk({ id: selectedItem.id }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRenameModal = () => {
    setNewAlbumName(selectedItem.description || '')
    toggleRenameModal(true)
  }

  const handleCloseRenameModal = () => {
    toggleRenameModal(false)
  }

  const handleRenameAlbum = (name) => {
    dispatch(renameSharedWithMeAlbumThunk({ id: selectedItem.id, name: name.trim() }))
    handleCloseRenameModal()
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    const items = [
      selectedItems.length === 1 && {
        label: t('a_common_addToMyCloud'),
        iconName: 'add_photo_alternate_1',
        onClickItem: handleAddAlbumToMyCloud,
      },
      selectedItems.length === 1 && {
        label: t('a_common_removeLink'),
        iconName: 'unlink',
        onClickItem: () => {
          handleOpenPublicLinkRemovingModal(selectedItems[0])
        }
      },
      selectedItemsCount === 1 && hasEditPermission && {
        label: t('a_common_rename'),
        iconName: 'pencil',
        onClickItem: handleOpenRenameModal
      },
    ].filter(Boolean)

    return items
  })()

  const mobileMenuItems = (() => {
    const items = [
      selectedItems.length === 1 && {
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadAlbum,
      },
      selectedItems.length === 1 && {
        label: t('a_common_addToMyCloud'),
        iconName: 'add_photo_alternate_1',
        onClickItem: handleAddAlbumToMyCloud,
      },
      selectedItems.length === 1 && {
        label: t('a_common_removeLink'),
        iconName: 'unlink',
        onClickItem: () => {
          handleOpenPublicLinkRemovingModal(selectedItems[0])
        }
      },
      selectedItemsCount === 1 && hasEditPermission && {
        label: t('a_common_rename'),
        iconName: 'pencil',
        onClickItem: handleOpenRenameModal
      },
    ].filter(Boolean)

    return items
  })()

  return (
    <>
      <PublicLinksBox className={className}>
        {
          selectedItemsCount === 1 && (
            <StyledPrimaryButton
              actionName={t('a_common_download')}
              onAction={handleDownloadAlbum}
            />
          )
        }
        
        {
          selectedItemsCount === 1 &&
          <MenuWithActionItems menuItems={menuItems} />
        }
      </PublicLinksBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={null}
      />

      <DialogModal
        isOpened={!!publicLinkDataForDelete}
        title={t('l_publicLink_removeConfirmTitle')}
        icon='warning'
        onClose={handleClosePublicLinkRemovingModal}
        okText={t('a_common_remove')}
        onOk={handleRemoveAlbums}
        cancelText={t('a_common_cancel')}
        onCancel={handleClosePublicLinkRemovingModal}
        type={ConfirmationModalTypes.danger}
      >
        <TextInModalBox>
          {t('l_publicLink_removeSharedConfirmMessage')}
        </TextInModalBox>
      </DialogModal>

      <RenameAlbumModal
        isShown={renameModalOpened}
        albumNameBeforeRenaiming={newAlbumName}
        parentBlock={getModalsRootElement()}
        onCloseModal={handleCloseRenameModal}
        onRenameAlbum={handleRenameAlbum}
        texts={{
          TITLE: t('a_albums_renameAlbum'),
          PLACEHOLDER: t('l_common_untitledAlbum'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          SUB_TITLE: t('l_albums_albumNameField'),
          TITLE_ICON: 'edit'
        }}
      />
    </>
  )
}

const PublicLinksBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  transition: all 0.4s linear;

  :hover {
    background-color: var(--button-primary-hover);
  }
`

const TextInModalBox = styled.span`
  font-size: 14px;
  max-width: 560px;
  color: var(--text-secondary);
`
