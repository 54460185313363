import { useEffect, useState } from "react"

import { calculateRowItemsCountByMainWidth } from "utils/timelineDigests"
import { debounce } from "lodash"

import { SORT_FORMATS } from "./timelineSlice"

export const useCardsPerRow = (sortedBy = SORT_FORMATS.sortFormat.day) => {

  const [cardsPerRow, setCardsPerRow] = useState(0)

  const cardWidth = sortedBy === SORT_FORMATS.sortFormat.month ? 120 : sortedBy === SORT_FORMATS.sortFormat.year ? 92 : 172

  useEffect(() => {
    const mainElement = document.querySelector('main')

    const debouncedResize = debounce(() => {
      const cardsPerRow = calculateRowItemsCountByMainWidth(cardWidth)
    
      setCardsPerRow(cardsPerRow)
    }, 100)

    const resizeObserver = new ResizeObserver(() => {
      debouncedResize()
    })
    
    resizeObserver.observe(mainElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [cardWidth])

  return cardsPerRow
}