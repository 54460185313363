import styled, { keyframes } from 'styled-components'

export const AITransformationSkeleton = () => {
  return (
    <Content>
      <MainBlock />

      <SecondBlock />

      <BottomBlock />
    </Content>
  )
}

const blink = keyframes`
  0% {
    background-color: var(--transformer-background-secondary);
  }
  100% {
    background-color: var(--transformer-background-tertiary);
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
`

const MainBlock = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: var(--transformer-background-secondary);
  animation: ${blink} 700ms ease-in infinite alternate;
  margin-bottom: 20px;
`

const SecondBlock = styled.div`
  width: 152px;
  height: 12px;
  background-color: var(--transformer-background-secondary);
  animation: ${blink} 700ms ease-in infinite alternate;
  margin-bottom: 12px;
`

const BottomBlock = styled.div`
  width: 200px;
  height: 80px;
  border-radius: 100px;
  background-color: var(--transformer-background-secondary);
  animation: ${blink} 700ms ease-in infinite alternate;
`