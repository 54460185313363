import APP_CONFIG from 'constants/configs/app.config'

import { useEffect } from 'react'

import { useAppDispatch } from  "store"
import { useTranslation } from 'react-i18next'
import { ModalPublicLink } from '@cloudike/web_ui_components'
import { SharePermissionType } from '@cloudike/web_photos'
import moment from 'moment'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { albumActions } from 'features/albums/album/albumSlice'
import { getCredentialsSelector } from 'features/user/selectors'

import { analytics, ANALYTICS_EVENTS } from "../common/analytics"
import { getUserLogin } from "../../utils/user"
import { appActions } from "../../store/app"
import { formatDate } from "../../utils/formatDate"
import { useMobileDetection } from "../common/hooks"

import {
  getAlbumsSharingAlbumDataSelector,
  getAlbumsSharingConfigSelector,
  getAlbumsSharingDataCollaboratorsSelector,
  getAlbumsSharingDataSelector,
  getAvailableTypesPublicLinkSelector,
  getIsBusy
} from './selectors'
import {
  addAvailableTypesPublicLinkThunk,
  addCollaboratorsThunk,
  deleteSharedLinkThunk,
  fetchShareDataThunk,
  ICreateMyShareSchema,
  removeCollaboratorsThunk,
  saveSharedDataThunk,
  sharingActions,
  updateCollaboratorsThunk,
  updateSharedDataThunk
} from './sharingSlice'

export const SharingModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isMobile = useMobileDetection()

  const sharedItemData = getAlbumsSharingAlbumDataSelector()
  const sharingData = getAlbumsSharingDataSelector()
  const config = getAlbumsSharingConfigSelector()
  const collaborators = getAlbumsSharingDataCollaboratorsSelector()
  const availableTypesPublicLink = getAvailableTypesPublicLinkSelector()
  const credentials = getCredentialsSelector()
  const isBusy = getIsBusy()
  const prefix = APP_CONFIG.user_phone_prefix

  const opened = sharedItemData && sharingData

  useEffect(() => {
    if (opened) {
      dispatch(appActions.setIsSomeSpecialModalOpened(true))
    } else {
      dispatch(appActions.setIsSomeSpecialModalOpened(false))
    }
  }, [opened])

  useEffect(() => {
    if (availableTypesPublicLink.length === 0) {
      dispatch(addAvailableTypesPublicLinkThunk())
    }

    if (!sharedItemData) {
      return
    }

    dispatch(fetchShareDataThunk())
  }, [sharedItemData?._links?.share + sharedItemData?.id])

  if (!sharedItemData || !sharingData) {
    return null
  }

  const link = config.sdkType === SDK_TYPES.FILES ?
    sharingData._links.public_url.href :
    `${window.location.origin}/links/pa/${sharedItemData.shared_hash}`
  const startTypeLink = sharingData.access_type
  const startPermission = sharingData.permission
  const startDateExpired = sharingData.expires || ''

  const typeInputCollaborators = APP_CONFIG.user_login_type
  const mask = APP_CONFIG.user_phone_mask
  const minMaskPhoneNumber = APP_CONFIG.min_mask_phone_number

  const ownerNumberPhoneOrEmail = getUserLogin(credentials)

  const pickItems = config.isNewAlbum ?
    Array.from({ length: config.albumElementsCount }).map(() => ({
      type: 'photo_album',
      name: sharedItemData.description,
      iconName: 'photo_album'
    })) :
    [{ type: 'photo_album', name: sharedItemData.description || sharedItemData.name, iconName: 'photo_album' }]

  const handleCloseModal = () => {
    dispatch(sharingActions.resetState())
  }

  const handleSaveSharedData = (typeLink?: string, dateExpired?: string, permissionRadioButton?: string, password?: string) => {
    dispatch(albumActions.unselectAll())
    dispatch(saveSharedDataThunk({
      data: {
        access_type: typeLink,
        password,
        expires: dateExpired,
        permission: permissionRadioButton
      } as ICreateMyShareSchema,
      closeAfterUpdate: true
    }))
    dispatch(albumActions.unselectAll())
  }

  const handleDeletePassword = () => {
    dispatch(updateSharedDataThunk({
      data: {
        access_type: 'all',
      } as any,
      closeAfterUpdate: false
    }))
  }

  const handleAddCollaborators = (collaborators, successCallback, errorCallback) => {
    dispatch(addCollaboratorsThunk({ collaborators, successCallback, errorCallback }))
  }

  const handleRemoveCollaborator = (phone_or_email) => {
    dispatch(removeCollaboratorsThunk({ phone_or_email }))
  }

  const handleUpdateCollaborator = (permission: SharePermissionType, phone_or_email: string) => {
    dispatch(updateCollaboratorsThunk({ phone_or_email, permission }))
  }

  const handleDeleteLink = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PUBLIC_DELETE_LINK)
    dispatch(deleteSharedLinkThunk({}))
    analytics.push(ANALYTICS_EVENTS.WEB_PUBLIC_DELETE_LINK)
  }

  function handleFormatDate(date: Date) {
    return formatDate(date.valueOf(), t('l_publicLink_lifetimeMask'))
  }

  const weekDays = [...moment.weekdaysShort().slice(1), moment.weekdaysShort()[0]]

  const textsModalPublicLink = {
    textsHeader: {
      TITLE: `${t('a_common_share')} ${sharedItemData.name ? ` - ${sharedItemData.name}` : ''}`,
      SUBTITLE: t('l_common_nSelectedItems'),
    },
    textsRadioButtons: {
      VIEW: t('l_common_viewOnly'),
      EDIT: t('l_common_allowEditing')
    },
    textsFooter: {
      SEND: t('a_common_send'),
      DONE: t('a_common_done'),
      COPY: t('a_common_copyLink')
    },
    textsDropDownMenuTypeLink: {
      ALL: t('l_common_sharingViewMessage'),
      COLLABORATORS: t('l_common_linkUsersMessage'),
      PASSWORD: t('l_common_sharingPassViewMessage'),
      DELETE: t('a_common_deleteLink')
    },
    textsDropDownMenuTypePermission: {
      VIEWER: t('l_common_viewer'),
      EDITOR: t('l_common_editor'),
      DELETE: t('a_common_delete')
    },
    textsDataPicker: {
      NAME_BUTTON_CANCEL: t('a_common_cancel'),
      NAME_BUTTON_OK: t('a_common_ok'),
      TEXT_NO_DATE: t('a_common_setLinkLifetime'),
      TEXT_WITH_DATE: t('l_common_linkValidUntil'),
      WEEK_DAYS: moment.weekdays(),
      WEEK_DAYS_SHORT: weekDays,
      MONTHS: moment.months(),
      CLOCK_PLACEHOLDER: t('l_common_setTime'),
      DESCRIPTION: t('l_notification_afterLinkExpired'),
      INVALID_TIME_ERROR: t('l_notification_invalidLinkLifetime'),
      TOOLTIP: t('l_notification_linkLifetime'),
      ALWAYS: t('a_common_always'),
      SELECT_DATE: t('a_common_selectTimeDate'),
      DAY: t('a_common_day'),
      WEEK: t('a_common_week'),
      MONTH: t('a_common_month'),
      APPLY: t('a_common_apply')
    },
    textsInputPassword: {
      BUTTON_COPY: t('a_common_copyPassword'),
      BUTTON_SET: t('a_common_setPassword'),
      BUTTON_DELETE: t('a_common_deletePassword'),
      GENERATE_TEXT: t('l_notification_rememberPassword'),
      INPUT_TEXT: t('l_notification_rememberPassword'),
      INPUT_PLACEHOLDER: t('l_common_enterPasswordField'),
      OLD_PASSWORD_TEXT: t('l_notification_securityOfPassword'),
      BUTTON_RESET: t('l_common_resetPassword'),
      BUTTON_GENERATE: t('a_common_autoGeneratePass'),
      PASSWORD_ADDED: t('l_common_passwordAdded')
    },
    textsInputCollaborators: {
      PHONE_PLACEHOLDER: t('l_common_inviteOthers'),
      EMAIL_PLACEHOLDER: t('l_common_inviteOthers'),
      DESCRIPTION: t('l_common_numberConnection'),
      COLLABORATORS_TITLE: t('l_publicLink_usersWithAccessN', { number: collaborators.length }),
      INVITE_BUTTON: t('a_common_invite')
    },
    textsModalSubmitDeletePassword: {
      TITLE: t('l_common_deletePassConfirmation'),
      CLOSE: t('a_common_cancel'),
      SUBMIT: t('a_common_yes'),
      CONTENT: t('l_common_deletePassConfirmationMessage'),
    },
    textsModalSubmitChangePassword: {
      TITLE: t('l_common_setPassConfirmation'),
      CLOSE: t('a_common_cancel'),
      SUBMIT: t('a_common_yes'),
      CONTENT: t('l_common_setPassConfirmationMessage'),
    },
    textsModalSubmitDiscardChanges: {
      TITLE: t('l_common_discardChanges'),
      CLOSE: t('a_common_cancel'),
      SUBMIT: t('a_common_yes'),
      CONTENT: t('l_common_changesMadeNo'),
    },
    textsModalDeleteLink:{
      TITLE: t('l_publicLink_deleteConfirmTitle'),
      CLOSE: t('a_common_cancel'),
      SUBMIT: t('a_common_delete'),
      CONTENT: t('l_publicLink_deleteConfirmMessage'),
    },
    textsNotification: {
      ADD_YOURSELF: t('l_notification_cannotAddYourself'),
      INCORRECT_PHONE: t('l_common_incorrectPhoneNumberError'),
      INCORRECT_EMAIL: t('l_notification_incorrectEmailError'),
      REPEAT_USER: t('l_notification_userAdded'),
      PICK_DATE: t('l_notification_afterLinkExpired'),
      INCORRECT_TIME_OR_DATE: t('l_notification_invalidLinkLifetime'),
      EXPIRED_TIME: t('l_notification_invalidLinkLifetime'),
      ERROR_PASSWORD_MIN_COUNT: t('l_notification_passwordNoLess'),
      ERROR_PASSWORD_REQUIRED: t('l_notification_passwordEmpty'),
      ERROR_PASSWORD_MAX_COUNT: t('l_notification_passwordNoMore'),
      COPY_LINK: t('l_notification_linkCopied'),
      ERROR_COPY_LINK: t('l_common_wentWrong'),
      COPY_PASSWORD: t('l_notification_passwordCopied'),
      ERROR_COPY_PASSWORD: t('l_common_wentWrong'),
    },
    textsRights: {
      viewTitle: t('l_common_view'),
      viewDescription: t('l_publicLink_viewDescription'),
      editTitle: t('l_common_edit'),
      editDescription: t('l_publicLink_editDescription'),
    },
    showMore: t('l_common_showMore'),
    showLess: t('a_common_showLess'),
    deleteLink: t('a_common_deleteLink'),
    linkLifetime: t('a_common_linkLifetime')
  }

  const handleVisibleModal = (value:boolean) => {
    dispatch(appActions.setVisibleModal(value))
  }

  return (
    <ModalPublicLink
      setIsModalVisible={handleVisibleModal}
      isBusy={isBusy}
      publicLink={link}
      startTypeLink={startTypeLink}
      startPermission={startPermission}
      startDateExpired={startDateExpired}
      mask={mask}
      ownerNumberPhoneOrEmail={ownerNumberPhoneOrEmail}
      startListCollaborators={[...collaborators]}
      typeInputCollaborators={typeInputCollaborators}
      pickItems={pickItems}
      onDeletePassword={handleDeletePassword}
      onClickDone={handleSaveSharedData}
      onDeleteLink={handleDeleteLink}
      texts={textsModalPublicLink}
      isOpened
      onCloseModal={handleCloseModal}
      onAddCollaborators={handleAddCollaborators}
      onChangePermission={handleUpdateCollaborator}
      onRemoveCollaborator={handleRemoveCollaborator}
      onFormatDate={handleFormatDate}
      availableTypeLink={availableTypesPublicLink}
      minMaskPhoneNumber={minMaskPhoneNumber}
      prefix={prefix}
      isMobile={isMobile}
    />
  )
}
