import { STYLED_VARIABLES } from 'constants/styledVariables'
import { getModalsRootElement } from 'constants/modals'

import { useState } from 'react'

import { ConfirmationModal, ConfirmationModalTypes, MenuWithActionItems, PrimaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { deleteSharedLinkThunk, getPublicLinkAndCopyThunk, sharingActions } from 'features/sharing/sharingSlice'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { AlbumType } from '@cloudike/web_photos'
import { appActions } from 'store/app'
import { RenameAlbumModal } from 'features/albums/album/RenameAlbumModal'
import { DialogModal } from 'ui/DialogModal'
import { MobileToolbarCounter } from 'features/common/mobile-toolbar-counter/MobileToolbarCounter'

import { getPublicLinksSelectedItemsSelector } from './selectors'
import { downloadAlbumThunk, removeAlbumsThunk, renameAlbumThunk } from './publicLinksSlice'

interface PublicLinksToolbarProps {
  className?: string,
  type: SDK_TYPES
}

export const PublicLinksToolbar: React.FC<PublicLinksToolbarProps> = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItems = getPublicLinksSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length

  const [renameModalOpened, toggleRenameModal] = useState(false)
  const [publicLinkDataForDelete, setPublicLinkDataForDelete] = useState(null)
  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const handleEditPublicLink = () => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: type,
      sharedAlbumType: AlbumType.SHARED
    }))
    dispatch(sharingActions.setSharedItemData(selectedItems[0]))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadAlbum = () => {
    dispatch(downloadAlbumThunk({ type, albumId: selectedItems[0].id }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRenameModal = () => {
    toggleRenameModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseRenameModal = () => {
    toggleRenameModal(false)
  }

  const handleRenameAlbum = (name) => {
    dispatch(renameAlbumThunk({ type, name: name.trim(), id: selectedItems[0].id }))
    toggleRenameModal(false)
  }

  const handleOpenPublicLinkRemovingModal = (item) => {
    setPublicLinkDataForDelete(item)
  }

  const handleClosePublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(null)
  }

  const handleDeletePublicLink = () => {
    dispatch(deleteSharedLinkThunk({ data: publicLinkDataForDelete, type: SDK_TYPES.SHARED }))
    setPublicLinkDataForDelete(null)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCopyPublicLink = (item) => {
    dispatch(getPublicLinkAndCopyThunk({ data: item }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleRemoveAlbums = () => {
    dispatch(removeAlbumsThunk({ ids: selectedItems.map(item => item.id), type }))
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    const items = [
      selectedItems.length === 1 && {
        label: t('a_common_share'),
        iconName: 'share',
        onClickItem: handleEditPublicLink
      },
      selectedItems.length === 1 && {
        label: t('a_common_copyPublicLink'),
        iconName: 'copy_link',
        onClickItem: () => {
          handleCopyPublicLink(selectedItems[0])
        }
      },
      selectedItems.length === 1 && {
        label: t('a_common_deleteLink'),
        iconName: 'unlink',
        onClickItem: () => {
          handleOpenPublicLinkRemovingModal(selectedItems[0])
        }
      },
      selectedItems.length === 1 && {
        label: t('a_common_rename'),
        iconName: 'pencil',
        onClickItem: handleOpenRenameModal
      },
      selectedItems.length === 1 && {
        label: t('a_common_remove'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      }
    ].filter(Boolean)

    return items
  })()

  const mobileMenuItems = (() => {
    const items = [
      selectedItems.length === 1 && {
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadAlbum,
      },
      ...menuItems,
      selectedItems.length > 1 && {
        label: t('a_common_remove'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      }
    ]

    return items.filter(Boolean)
  })()

  const headerContent = () => {
    if (!!selectedItemsCount && selectedItemsCount > 1) {
      return (
        <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
      )
    }
  }

  return (
    <>
      <PublicLinksBox className={className}>
        {
          selectedItemsCount === 1 && (
            <StyledPrimaryButton
              actionName={t('a_common_download')}
              onAction={handleDownloadAlbum}
            />
          )
        }

        {
          selectedItemsCount > 1 && (
            <StyledPrimaryButton
              actionName={t('a_common_remove')}
              onAction={handleOpenRemovingConfirmationModal}
            />
          )
        }
        
        {
          selectedItemsCount === 1 &&
          <MenuWithActionItems menuItems={menuItems} />
        }
      </PublicLinksBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={headerContent()}
      />

      <RenameAlbumModal
        isShown={renameModalOpened}
        albumNameBeforeRenaiming={selectedItems[0]?.description}
        parentBlock={getModalsRootElement()}
        onCloseModal={handleCloseRenameModal}
        onRenameAlbum={handleRenameAlbum}
        texts={{
          TITLE: selectedItems[0]?.description ? t('a_albums_renameAlbum') : t('l_common_addName'),
          PLACEHOLDER: t('l_common_untitledAlbum'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          SUB_TITLE: t('l_albums_albumNameField'),
          TITLE_ICON: selectedItems[0]?.description ? 'edit' : 'add_name'
        }}
      />

      <ConfirmationModal title={t('l_publicLink_deleteConfirmTitle')}
        isOpened={!!publicLinkDataForDelete}
        onClose={handleClosePublicLinkRemovingModal}
        parentBlock={getModalsRootElement()}
        onOk={handleDeletePublicLink}
        okText={t('a_common_delete')}
        onCancel={handleClosePublicLinkRemovingModal}
        cancelText={t('a_common_cancel')}
        type={ConfirmationModalTypes.danger}
      >
        <SRemovingLinkConfirmationModalText>
          {t('l_publicLink_deleteConfirmMessage')}
        </SRemovingLinkConfirmationModalText>
      </ConfirmationModal>

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemoveAlbumName', { number: selectedItemsCount, albumname: selectedItems[0]?.description || t('l_common_untitledAlbum') })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveAlbums}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>
    </>
  )
}

const PublicLinksBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const SRemovingLinkConfirmationModalText = styled.p`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 572px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  transition: all 0.4s linear;

  :hover {
    background-color: var(--button-primary-hover);
  }
`

const TextInModalBox = styled.span`
  font-size: 14px;
`
