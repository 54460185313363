import React, { useCallback, useState, useRef, useEffect } from 'react'

import styled from 'styled-components'
import { Virtualizer } from '@tanstack/react-virtual'
import { IDigestYear } from 'utils/timelineDigests'
import { SpriteIcon } from '@cloudike/web_ui_components'

import { getTimelineDigestsSelector } from "./selectors"

interface DigestsScrollbarProps {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  className?: string;
  heightBeforeScroll?: number;
}

export const DigestsScrollbarMobile: React.FC<DigestsScrollbarProps> = ({ virtualizer, className = '', heightBeforeScroll = 0 }) => {
  const digests = getTimelineDigestsSelector()
  const [tooltipText, setTooltipText] = useState('')
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [isDraggingThumb, setIsDraggingThumb] = useState(false)
  const scrollbarRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const [thumbPosition, setThumbPosition] = useState(0)
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)

  const showTooltip = useCallback(() => {
    setTooltipVisible(true)

    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current)
    }

    tooltipTimeoutRef.current = setTimeout(() => {
      setTooltipVisible(false)
    }, 1500)
  }, [])

  const getScrollInfo = useCallback(() => {
    const totalSize = virtualizer.getTotalSize()
    const viewportHeight = virtualizer.options.getScrollElement()?.clientHeight || 0
    const scrollableHeight = totalSize - viewportHeight
    const scrollbarHeight = scrollbarRef.current?.offsetHeight || 0
    const thumbHeight = thumbRef.current?.offsetHeight || 0
    const maxThumbPosition = scrollbarHeight - thumbHeight
    const additionalHeight = heightBeforeScroll

    return { totalSize, viewportHeight, scrollableHeight, scrollbarHeight, thumbHeight, maxThumbPosition, additionalHeight }
  }, [virtualizer])

  const updatePositions = useCallback(() => {
    const { scrollableHeight, maxThumbPosition } = getScrollInfo()
    const scrollOffset = virtualizer.scrollOffset

    if (scrollOffset < heightBeforeScroll) {
      setIsScrollbarVisible(false)

      if (thumbRef.current) {
        thumbRef.current.style.top = '0px'
      }
      setThumbPosition(0)
      return
    }

    setIsScrollbarVisible(true)

    const adjustedScrollOffset = scrollOffset - heightBeforeScroll
    const adjustedScrollableHeight = scrollableHeight - heightBeforeScroll

    let scrollPercentage = (adjustedScrollOffset / adjustedScrollableHeight) * 100
    scrollPercentage = Math.min(scrollPercentage, 100)

    const newThumbPosition = (maxThumbPosition * scrollPercentage) / 100

    if (thumbRef.current) {
      thumbRef.current.style.top = `${newThumbPosition}px`
    }

    setThumbPosition(newThumbPosition)
    updateTooltipPosition(newThumbPosition)
    showTooltip()
  }, [virtualizer, isDraggingThumb, getScrollInfo, showTooltip, digests])

  useEffect(() => {
    const scrollElement = virtualizer.scrollElement

    if (scrollElement) {
      scrollElement.addEventListener('scroll', updatePositions)
      return () => scrollElement.removeEventListener('scroll', updatePositions)
    }
  }, [virtualizer, updatePositions])

  const updateTooltipContent = useCallback((position: number) => {
    const {  maxThumbPosition } = getScrollInfo()

    const percentage = (position / maxThumbPosition) * 100

    let currentYear, currentMonth

    for (const digest of digests) {
      if (percentage >= digest.startPercent && percentage <= digest.startPercent + digest.percentage) {
        currentYear = digest.year
        for (const month of digest.months) {
          if (percentage >= month.startPercent && percentage <= month.startPercent + month.percentage) {
            currentMonth = month.month
            break
          }
        }
        break
      }
    }

    if (currentYear && currentMonth) {
      setTooltipText(`${currentMonth} ${currentYear}`)
      setTooltipVisible(true)
    }
  }, [digests, getScrollInfo])

  const updateTooltipPosition = useCallback((position: number) => {
    if (tooltipRef.current) {
      tooltipRef.current.style.top = `${position}px`
    }
    updateTooltipContent(position)
  }, [updateTooltipContent])

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollbarRef.current) return

    const { top } = scrollbarRef.current.getBoundingClientRect()
    const { scrollableHeight, maxThumbPosition } = getScrollInfo()

    const newPosition = Math.max(0, Math.min(e.clientY - top, maxThumbPosition))

    if (newPosition < (maxThumbPosition * 0.1) && virtualizer.scrollOffset < heightBeforeScroll) {
      virtualizer.scrollToOffset(0, { behavior: 'auto' })
      return
    }

    const scrollPercentage = (newPosition / maxThumbPosition) * 100
    const adjustedScrollableHeight = scrollableHeight - heightBeforeScroll
    let newScrollOffset = (adjustedScrollableHeight * scrollPercentage) / 100 + heightBeforeScroll

    if (scrollPercentage >= 99.5) {
      newScrollOffset = scrollableHeight + heightBeforeScroll
    }

    virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
    updateTooltipPosition(newPosition)
    showTooltip()
  }, [virtualizer, updateTooltipPosition, getScrollInfo, showTooltip])

  const isElementHidden = useCallback((elementPosition: number, elementHeight: number) => {
    const scrollbarHeight = scrollbarRef.current?.offsetHeight || 0
    const tooltipHeight = tooltipRef.current?.offsetHeight || 0
    const tooltipPosition = thumbPosition

    if (elementPosition + elementHeight < 0 || elementPosition > scrollbarHeight) {
      return true
    }

    const tooltipTop = tooltipPosition - tooltipHeight / 2
    const tooltipBottom = tooltipPosition + tooltipHeight / 2

    return (
      (elementPosition >= tooltipTop && elementPosition < tooltipBottom) ||
      (elementPosition + elementHeight > tooltipTop && elementPosition + elementHeight <= tooltipBottom) ||
      (elementPosition <= tooltipTop && elementPosition + elementHeight >= tooltipBottom)
    )
  }, [thumbPosition])

  const handleYearClick = (e: React.MouseEvent<HTMLDivElement>, year: IDigestYear) => {
    e.stopPropagation()
    e.preventDefault()

    const { scrollableHeight } = getScrollInfo()
    const scrollPercentage = year.startPercent
    const newScrollOffset = (scrollableHeight * scrollPercentage) / 100 + heightBeforeScroll

    virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
  }

  const handleThumbTouchStart = useCallback(() => {
    setIsDraggingThumb(true)
  }, [])

  const handleThumbTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    if (!isDraggingThumb || !scrollbarRef.current) return

    const { top } = scrollbarRef.current.getBoundingClientRect()
    const { scrollableHeight, maxThumbPosition } = getScrollInfo()

    const newPosition = Math.max(0, Math.min(e.touches[0].clientY - top, maxThumbPosition))

    if (newPosition < (maxThumbPosition * 0.1) && virtualizer.scrollOffset < heightBeforeScroll) {
      virtualizer.scrollToOffset(0, { behavior: 'auto' })
      return
    }

    const scrollPercentage = (newPosition / maxThumbPosition) * 100

    const adjustedScrollableHeight = scrollableHeight - heightBeforeScroll
    let newScrollOffset = (adjustedScrollableHeight * scrollPercentage) / 100 + heightBeforeScroll

    if (scrollPercentage >= 99.5) {
      newScrollOffset = scrollableHeight + heightBeforeScroll
    }

    virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
    updateTooltipPosition(newPosition)
    showTooltip()
  }, [isDraggingThumb, getScrollInfo, virtualizer, updateTooltipPosition, showTooltip])

  const handleThumbTouchEnd = useCallback(() => {
    setIsDraggingThumb(false)
  }, [])

  useEffect(() => {
    if (isDraggingThumb) {
      document.addEventListener('touchmove', handleThumbTouchMove as unknown as EventListener, { passive: false })
      document.addEventListener('touchend', handleThumbTouchEnd)
    } else {
      document.removeEventListener('touchmove', handleThumbTouchMove as unknown as EventListener)
      document.removeEventListener('touchend', handleThumbTouchEnd)
    }

    return () => {
      document.removeEventListener('touchmove', handleThumbTouchMove as unknown as EventListener)
      document.removeEventListener('touchend', handleThumbTouchEnd)
    }
  }, [isDraggingThumb, handleThumbTouchMove, handleThumbTouchEnd])

  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current)
      }
    }
  }, [])

  return (
    <SDigestsScrollbarMobile
      onClick={handleClick}
      ref={scrollbarRef}
      className={className}
      isVisible={isScrollbarVisible}
    >
      {digests.map((digest) => {
        const scrollbarHeight = scrollbarRef.current?.offsetHeight || 0
        const yearPosition = (digest.startPercent / 100) * scrollbarHeight
        const yearHeight = 24
        const isYearHidden = isElementHidden(yearPosition, yearHeight)
        const isElementOutside = scrollbarRef.current?.offsetHeight - yearPosition - yearHeight < 10

        return (
          <React.Fragment key={digest.year}>
            {!isYearHidden && !digest.isHidden && !isElementOutside && (
              <SYear top={digest.startPercent}
                onClick={(e) => handleYearClick(e, digest)}
              >
                {digest.year}
              </SYear>
            )}
          </React.Fragment>
        )
      })}

      <SScrollbarThumb
        ref={thumbRef}
        onTouchStart={handleThumbTouchStart}
        onTouchMove={handleThumbTouchMove}
        onTouchEnd={handleThumbTouchEnd}
      >
        <SThumbIcon iconName='cursor_move' />
      </SScrollbarThumb>

      {tooltipVisible && (
        <STooltip ref={tooltipRef}>
          {tooltipText}
        </STooltip>
      )}
    </SDigestsScrollbarMobile>
  )
}

const SDigestsScrollbarMobile = styled.div<{ isVisible: boolean }>`
  width: 36px;
  height: calc(100% - 74px);
  position: fixed;
  top: 86px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  z-index: 10;
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${props => props.isVisible ? 'auto' : 'none'};
`

const SYear = styled.div`
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--text-primary);
  position: absolute;
  right: 40px;
  top: ${props => props.top}%;
  background-color: var(--background-primary);
  border-radius: 4px;
`

const STooltip = styled.div`
  min-width: 85px;
  height: 32px;
  border-radius: 4px;
  padding: 0 6px;
  background: var(--Background-Primary, #FFF);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  position: absolute;
  right: 40px;
  transform: translateY(-50%);
  background: var(--background-primary);
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none;
  cursor: default;
  user-select: none;
  will-change: transform;
  transform: translateZ(0) translateY(-50%);
  text-transform: capitalize;
  touch-action: none;
`

const SScrollbarThumb = styled.div`
  width: 40px;
  height: 40px;
  background: var(--background-primary);
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px 0px rgba(0, 0, 0, 0.20);
  border-radius: 100px;
  position: absolute;
  pointer-events: auto;
  cursor: grab;
  right: -8px;
  transform: translateZ(0) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    cursor: grabbing;
  }
`

const SThumbIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
`
