import { STYLED_VARIABLES } from "constants/styledVariables"

import { useState } from "react"

import styled from "styled-components"
import classNames from "classnames"
import { SpriteIcon } from "@cloudike/web_ui_components"
import { Tooltip } from "react-tooltip"

interface ToolbarActionButtonProps {
  className?: string
  item: {
    title: string
    iconName: string
    handler: (event: MouseEvent) => void
    hiddenOnDesktop?: boolean
    hiddenOnTablet?: boolean
    hiddenOnMobile?: boolean
    orderOnMobile?: number
    popoverContent?: React.ReactNode
    tooltipOff?: boolean
  }
}

export const ToolbarActionButton: React.FC<ToolbarActionButtonProps> = ({ className = '', item }) => {
  const [popoverOpened, togglePopover] = useState(false)
  
  const handleClick = (event: MouseEvent) => {
    item.handler(event)
  }
  
  return (
    <>
      <SActionIconButton
        onClick={handleClick}
        onMouseEnter={() => togglePopover(true)}
        onMouseLeave={() => togglePopover(false)}
        data-tooltip-id={item.iconName + '_tooltipe'}
        data-tooltip-content={item.title}
        className={classNames(className, { 'hidden-on-desktop':  item.hiddenOnDesktop, 'hidden-on-tablet': item.hiddenOnTablet, 'hidden-on-mobile': item.hiddenOnMobile })}
        orderOnMobile={item.orderOnMobile}
      >
        <SActionIcon iconName={item.iconName} />
  
        {!!item.popoverContent && popoverOpened ? (
          <SPopover>
            {item.popoverContent}
          </SPopover>
        ) :
          !item.tooltipOff && (
            <Tooltip id={item.iconName + '_tooltipe'}
              style={{
                backgroundColor: "var(--background-primary)",
                color: "var(--text-primary)",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "160%",
                userSelect: "none"
              }}
            />
          )}
      </SActionIconButton>
    </>
  )
}

const SPopover = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: auto;
  right: 0;
  background: #fff;
  border-radius: 6px;
  width: 180px;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  padding: 9px 14px;
  text-align: left;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: #fff;
    border-style: solid;
    left: auto;
    right: 11px;
    top: -21px;
    bottom: auto;
    margin-left: -11px;
    border-width: 11px;
  }
`

const SActionIcon = styled(SpriteIcon)`
  && {
    path {
      fill: white;
    }
  }
`

const SActionIconButton = styled.button`
  width: 44px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .3s ease;
  position: relative;
  z-index: 2;
  color: #DADADA;
  order: ${props => props.orderOnMobile};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  
  &:hover {
    background-color: rgba(255, 255, 255, .34);
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.hidden-on-mobile {
      display: none;
    }
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    &.hidden-on-tablet {
      display: none;
    }

    &:hover {
      background-color: unset;
    }
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {

    &.hidden-on-tablet {
      display: flex;
    }

    &.hidden-on-desktop{
      display: none;
    }
  }
`