import { useEffect } from "react"

import { AlbumsMemoriesModal } from "features/albums/albums-memories/AlbumsMemoriesModal"
import { subscribeMemoriesAlbumsToWSThunk } from "features/albums/albums-memories/albumsMemoriesSlice"
import { useAppDispatch } from "store"

const AlbumMemoryModalPage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(subscribeMemoriesAlbumsToWSThunk())
  }, [])

  return (
    <>
      <AlbumsMemoriesModal />
    </>
  )
}

export default AlbumMemoryModalPage