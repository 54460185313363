import { getModalsRootElement } from 'constants/modals'

import { ModalDialog, ModalFooter, PrimaryButton, SecondaryButton, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAppDispatch } from 'store'
import { useKeyDownTransformer } from 'features/common/hooks/useKeyDownTransformer'
import _ from 'lodash'

import onbordingBeforeImg from '../../../assets/icons/ai_onbording_before.png'
import onbordingAfterImg from '../../../assets/icons/ai_onbording_after.png'

import { photoPreviewTransformerActions } from './photoPreviewTransformerSlice'
import { getOnbordingModalOpenSelector } from './selectors'

export const AITransformationOnboardingModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isOnbordingModalOpen = getOnbordingModalOpenSelector()

  const handleClose = () => {
    dispatch(photoPreviewTransformerActions.setOnbordingModalOpen(false))
  }

  const handleTransform = () => {
    dispatch(photoPreviewTransformerActions.setOnbordingModalOpen(false))
    dispatch(photoPreviewTransformerActions.setPreviewTransformerOpen(true))
  }

  const handleVisibleModal = (value: boolean) => {
    if (!value) {
      handleClose()
    }
  }

  useKeyDownTransformer(37, _.noop, isOnbordingModalOpen)
  useKeyDownTransformer(39, _.noop, isOnbordingModalOpen)
  useKeyDownTransformer(27, handleClose, isOnbordingModalOpen)
  useKeyDownTransformer(13, handleTransform, isOnbordingModalOpen)

  if (!isOnbordingModalOpen) return null

  return (
    <StyledModalDialog
      setIsModalVisible={handleVisibleModal}
      isOpened={isOnbordingModalOpen}
      parentBlock={getModalsRootElement()}
      isCloseModalOnEsc={false}
      footer={(
        <ModalFooter
          withoutShadowLine
          withLongFooter
          buttonList={[
            <StyledSecondaryButton
              key="cancel"
              onAction={handleClose}
              actionName={t('a_common_cancel')}
            />,
            <StyledPrimaryButton
              key="transform"
              onAction={handleTransform}
              actionName={t('a_photos_AItransform')}
            />
          ]}
        />
      )}
      onCloseModal={handleClose}
    >
      <Content>
        <ImageContainer>
          <Image src={onbordingBeforeImg}
            alt={t('l_common_before')}
            draggable={false}
          />

          <Image src={onbordingAfterImg}
            alt={t('l_common_after')}
            draggable={false}
          />

          <BeforeLabel>
            {t('l_common_before')}
          </BeforeLabel>

          <AfterLabel>
            {t('l_common_after')}
          </AfterLabel>
        </ImageContainer>

        <Description>
          {t('l_photos_AiTransformation')}
        </Description>

        <Hint>
          {t('l_photos_AITransformMsg')}
        </Hint>

        <Hint>
          {t('l_photos_AITransformMsgNote')}
        </Hint>
      </Content>
    </StyledModalDialog>
  )
}

const StyledModalDialog = styled(ModalDialog)`
  & .modal-container {
    max-width: 460px;
  }

  & .modal-content {
    margin-right: unset;
    padding: unset;
  }
`

const Content = styled.div`
  padding: 24px;
  margin-right: unset;
  max-height: 75vh;
  overflow-y: auto;
`

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    height: 330px;
  }
`

const Label = styled.span`
  position: absolute;
  bottom: 12px;
  padding: 4px 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 100px;
  font-size: 14px;
  transform: translateX(-50%); // Центрирование по горизонтали
`

const BeforeLabel = styled(Label)`
  left: 25%;
`

const AfterLabel = styled(Label)`
  left: 75%;
`

const Description = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: var(--text-primary);
  margin-bottom: 16px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-bottom: 10px;
  }
`

const Hint = styled.p`
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

const StyledPrimaryButton = styled(PrimaryButton)`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-left: 16px;
`
