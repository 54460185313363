import { IUploaderListState } from "@cloudike/web_ui_components"
import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { filesUploadingSelectors, UPLOADER_LIST_TYPES } from "./filesUploadingSlice"

export const getUploadingFilesRawSelector = state => filesUploadingSelectors.selectAll(state.filesUploading)

export const getUploadingFilesSelector = () => useAppSelector(getUploadingFilesRawSelector)

export const getUploadedFilesCountSelector = () =>
  useAppSelector(
    createSelector(
      getUploadingFilesRawSelector,
      (files) => files.filter(file => file.state.isCompleted).length
    )
  )

export const getUploadingFilesCountSelector = () =>
  useAppSelector(
    createSelector(
      getUploadingFilesRawSelector,
      (files) => files.length
    )
  )


export const uploadingFilesState = () => useAppSelector<IUploaderListState>(state => state.filesUploading.listState)

export const getFilesUploadingStatusSelector = () => useAppSelector(state => state.filesUploading.status)

export const getIsSizeQuotaExceedErrorSelector = () => useAppSelector(
  createSelector(
    getUploadingFilesRawSelector,
    (files) => files.some(hasSizeQuotaExceedError)
  )
)

export const getIsPublicLinkOwnerSizeQuotaExceedErrorSelector = () =>
  useAppSelector(
    createSelector(
      getUploadingFilesRawSelector,
      (files) => files.some(hasPublicLinkOwnerSizeQuotaExceedError))
  )


export const getFilesWithUnsupportedMediaTypeCountSelector = () => useAppSelector(
  createSelector(
    getUploadingFilesRawSelector,
    (files) => files.filter(hasUnsupportedMediaTypeError).length
  )
)

function hasSizeQuotaExceedError(item): boolean {
  if (item.state) {
    const { state } = item
    const { error } = state

    if (error?.response &&
      ![UPLOADER_LIST_TYPES.SHARED_ALBUM, UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR, UPLOADER_LIST_TYPES.FAMILY_ALBUM_COLLABORATOR, UPLOADER_LIST_TYPES.SHARED_WITH_ME_FILES]
        .includes(item.uploadingSourceType)) {
      const { error:{ response:{ status } } } = state

      return status === 402
    }
  }
}

function hasPublicLinkOwnerSizeQuotaExceedError(item): boolean {
  if (item.state) {
    const { state } = item
    const { error } = state

    if (error?.response &&
      [UPLOADER_LIST_TYPES.SHARED_ALBUM, UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR, UPLOADER_LIST_TYPES.FAMILY_ALBUM_COLLABORATOR, UPLOADER_LIST_TYPES.SHARED_WITH_ME_FILES]
        .includes(item.uploadingSourceType)) {
      const { error:{ response:{ status } } } = state

      return status === 402
    }
  }
}

function hasUnsupportedMediaTypeError(item): boolean {
  if (item.state) {
    const { state } = item
    const { error } = state

    if (error?.response) {
      const { error:{ response:{ status } } } = state

      return status === 415
    }
  }
}

export const getCurrentUploadingTypeSelector = () => useAppSelector(state => state.filesUploading.currentUploadingType)
