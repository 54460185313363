import { NOTIFICATION_IDS } from 'constants/notifications'

import { UploaderBase, UploaderList } from '@cloudike/web_core'
import { createAsyncThunk, createEntityAdapter, createSlice, } from '@reduxjs/toolkit'
import { getAlbumsSdkByType } from 'sdk/albums'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { getFavoriteSdkByType, getTimelineSdkByType } from 'sdk/timeline'
import { RootState } from 'store'
import { converFileListToFileObjectList } from 'utils/converFileListToFileObjectList'
import { IUploadingFile } from "@cloudike/web_ui_components"
import { getPhotoSdk } from 'sdk/photo'
import { request } from 'api/request'
import { t } from 'i18next'
import { updateUserDataThunk } from 'features/user/userSlice'
import { splitFilesByAccept } from 'utils/files'
import { getFilesSdk } from 'sdk/files'
import { photoPreviewTransformerActions } from 'features/photo/photo-transformer-preview/photoPreviewTransformerSlice'
import { photosApi } from 'api/photos'
import { getErrorData } from 'utils/getErrorData'
import { photoPreviewActions } from 'features/photo/photo-preview/photoPreviewSlice'
import { TIMELINE_FILTERS } from 'features/photo/timeline/timelineSlice'
import { addSelectedToFavoritesItemsThunk } from 'features/favorites/favoritesSlice'

import i18n from "../../../i18n"
import { NOTIFICATION_TYPES, showNotification } from '../notifications'
import { toMb } from "../../../utils/utils"
import { analytics, ANALYTICS_EVENTS } from '../analytics'

const UPLOAD_BATCH_SIZE = 5

let filesUploaderListObject: UploaderList
let sharedFilesUploaderListObject: UploaderList
let timelineUploaderListObject: UploaderList
let albumUploaderListObject: UploaderList
let sharedAlbumUploaderListObject: UploaderList

const UPLOADED_ITEM_INITIAL_STATE = {
  isUploading: false,
  isCompleted: false,
  isError: false,
  progress: 0,
  error: null,
}

enum UPLOADER_LIST_EVENTS {
  UPDATE = 'update',
  ERROR = 'error',
}

export enum UPLOADING_STATUSES {
  IDLE = 'IDLE',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR'
}

export enum UPLOADER_LIST_TYPES {
  FILES = 'FILES',
  TIMELINE = 'TIMELINE',
  SHARED_WITH_ME_FILES = 'SHARED_WITH_ME_FILES',
  FAMILY_TIMELINE = 'FAMILY_TIMELINE',
  FAMILY_TIMELINE_COLLABORATOR = 'FAMILY_TIMELINE_COLLABORATOR',
  ALBUM = 'ALBUM',
  FAMILY_ALBUM = 'FAMILY_ALBUM',
  FAMILY_ALBUM_COLLABORATOR = 'FAMILY_ALBUM_COLLABORATOR',
  SHARED_ALBUM = 'SHARED_ALBUM',
}

interface IExtendedUploadingFile extends IUploadingFile {
  uploadingSourceType: UPLOADER_LIST_TYPES,
  uploadedItem: any,
  uploadId?: string
}

const tempEntitiesWithoutUploadId = {}
const uploadIdToUploadingSourceTypeMap = {}
let areUploaderListEventsHandlersBinded = false

const bindUploaderListEvents = (uploaderListObject: UploaderList, dispatch, getState) => {
  if (areUploaderListEventsHandlersBinded) {
    return
  }

  uploaderListObject.on(UPLOADER_LIST_EVENTS.UPDATE, uploaderListUpdateHandler.bind(null, { dispatch, getState }))

  uploaderListObject.on(UPLOADER_LIST_EVENTS.ERROR, uploaderListErrorHandler.bind(null, { dispatch, getState }))

  areUploaderListEventsHandlersBinded = true
}

const adapter = createEntityAdapter<IExtendedUploadingFile>()

export const filesUploadingSelectors = adapter.getSelectors()

const mapItemToEntity = ({ item, index, isInitial, uploadingSourceType }): IExtendedUploadingFile => {
  const name = isInitial ? item.name : item.file.name
  const size: string = isInitial ? toMb(item.size) : toMb(item.file.size)
  const type = isInitial ? item.type : item.file.type
  const id = index
  let state = UPLOADED_ITEM_INITIAL_STATE
  if (item?.currentUpload?.state) {
    state = { ...item?.currentUpload?.state }
    if (state.error !== null) {
      const { error } = state
      if (error) {
        const { error: { status } } = state
        // if status === 405 it means item has been already uploaded
        if (status !== 405) {
          state.error = {
            response: { status }
          }
        } else {
          state.error = null
          state.isCompleted = true
          state.isError = false
        }
      } else if (error?.message) {
        state.error = { message: error.message }
      }
    }
  }

  return {
    id,
    name,
    size,
    type,
    state,
    uploadedItem: item?.currentUpload?.upload,
    uploadingSourceType,
    uploadId: item?.options?.uploadId || null
  }
}

export const calcMb = (number) => {
  return Math.trunc(number / Math.pow(1024, 2))
}

const showNotificationsForRejectedFiles = (rejectedFiles) => {
  rejectedFiles.forEach(file => {
    showNotification({
      type: NOTIFICATION_TYPES.WARNING,
      title: t('l_notification_fileNotUploaded', { FILE_NAME: file.name })
    })
  })
}

export const initialState = {
  currentUploadingType: UPLOADER_LIST_TYPES.FILES,
  status: UPLOADING_STATUSES.IDLE,
  listState: {
    uploaded: 0,
    total: 0,
    hasErrors: false,
    isUploading: false,
    isCompleted: false
  }
}

export const uploadFilesThunk = createAsyncThunk(
  'filesUploading/uploadFilesThunk',
  async ({ files, parentId, callback, modificators = [] }: { files: FileList, parentId?: string, callback?: () => void, modificators?: any[] },
    { dispatch, getState }) => {
    const filesSdk = getFilesSdk()
    const state = getState() as RootState
    const rejectedFiles = []
    const acceptedFiles = []
    const maxFileSize = Number(state.user.maxFileSize)

    const uploadingSourceType: UPLOADER_LIST_TYPES = UPLOADER_LIST_TYPES.TIMELINE
    dispatch(actions.setCurrentUploadingType(UPLOADER_LIST_TYPES.FILES))

    const filesArray = Array.from(files)

    filesArray.forEach(file => {
      if (!!maxFileSize && file.size > maxFileSize) {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: i18n.t('l_notification_largeError', { size: calcMb(maxFileSize) })
        })
      } else {
        acceptedFiles.push(file)
      }
    })

    showNotificationsForRejectedFiles(rejectedFiles)

    if (!acceptedFiles.length) {
      return
    }

    dispatch(actions.updateStatus(UPLOADING_STATUSES.IN_PROGRESS))
    dispatch(actions.updateItems({
      entities: acceptedFiles.map((elem, index) =>
        mapItemToEntity({ item: elem, index, isInitial: true, uploadingSourceType }))
    }))

    acceptedFiles.forEach(file => {
      tempEntitiesWithoutUploadId[file.name] = uploadingSourceType
    })

    const uploaderListObject = await filesSdk.folderOperationsService.addToUploadList(
      converFileListToFileObjectList(acceptedFiles, modificators),
      { multipartUpload: true, completeDelay: 400, uploadBatchSize: UPLOAD_BATCH_SIZE },
      { parent_id: parentId }
    )

    bindUploaderListEvents(uploaderListObject as any, dispatch, getState)

    const { state: listState } = uploaderListObject.getList()

    if (!listState.isUploading || listState.isCompleted) {
      uploaderListObject.start()
    }

    if (callback) {
      callback()
    }

    if (filesUploaderListObject) {
      return
    }

    filesUploaderListObject = uploaderListObject as any
  }
)

export const uploadSharedFilesThunk = createAsyncThunk(
  'filesUploading/uploadSharedFilesThunk',
  async ({ files, parentId, callback, modificators = [], sharedId, idsUrl, token }:
    { files: FileList, parentId?: string, callback?: () => void, modificators?: any[], sharedId: string, idsUrl: string, token?: string },
  { dispatch, getState }) => {
    const filesSdk = getFilesSdk()
    const state = getState() as RootState
    const rejectedFiles = []
    const acceptedFiles = []
    const maxFileSize = Number(state.user.maxFileSize)

    const uploadingSourceType: UPLOADER_LIST_TYPES = UPLOADER_LIST_TYPES.SHARED_WITH_ME_FILES
    dispatch(actions.setCurrentUploadingType(UPLOADER_LIST_TYPES.SHARED_WITH_ME_FILES))

    const filesArray = Array.from(files)

    filesArray.forEach(file => {
      if (!!maxFileSize && file.size > maxFileSize) {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: i18n.t('l_notification_largeError', { size: calcMb(maxFileSize) })
        })
      } else {
        acceptedFiles.push(file)
      }
    })

    showNotificationsForRejectedFiles(rejectedFiles)
    
    if (!acceptedFiles.length) {
      return
    }

    dispatch(actions.updateStatus(UPLOADING_STATUSES.IN_PROGRESS))
    dispatch(actions.updateItems({
      entities: acceptedFiles.map((elem, index) =>
        mapItemToEntity({ item: elem, index, isInitial: true, uploadingSourceType }))
    }))

    acceptedFiles.forEach(file => {
      tempEntitiesWithoutUploadId[file.name] = uploadingSourceType
    })

    const uploaderListObject = await filesSdk.publicLinksService.addToUploadList(
      sharedId,
      idsUrl,
      converFileListToFileObjectList(acceptedFiles, modificators),
      { multipartUpload: true, completeDelay: 400, uploadBatchSize: UPLOAD_BATCH_SIZE },
      { parent_id: parentId },
      token
    )

    bindUploaderListEvents(uploaderListObject as any, dispatch, getState)

    const { state: listState } = uploaderListObject.getList()

    if (!listState.isUploading || listState.isCompleted) {
      uploaderListObject.start()
    }

    if (callback) {
      callback()
    }

    if (sharedFilesUploaderListObject) {
      return
    }

    sharedFilesUploaderListObject = uploaderListObject as any
  }
)

export const uploadTransformerThunk = createAsyncThunk(
  'filesUploading/uploadTransformerThunk',
  async ({ file }: { file: any }, { dispatch, getState }) => {
    const state = getState() as RootState
    const userId = state.user.userData?.id
    const ids = state.timeline.ids

    dispatch(photoPreviewTransformerActions.setUploadLoading(true))
    dispatch(actions.setCurrentUploadingType(UPLOADER_LIST_TYPES.FILES))

    try {
      const { _links: { link_upload_item: { href } } } = await photosApi.getPhotosData(userId)

      const rspUpload = await photosApi.uploadPhotoByLink(href, file)

      checkStatusPhotoByLink(rspUpload._links?.self?.href, dispatch, state.timeline.filter, ids)
    } catch (e) {
      dispatch(photoPreviewTransformerActions.setUploadLoading(false))

      if (e.data.code === 'SizeQuotaExceeded') {
        dispatch(photoPreviewTransformerActions.setPreviewTransformerOpen(false))

        return showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          ...getErrorData(e)
        })
      }

      dispatch(photoPreviewTransformerActions.setError(true))
    }
  }
)

const checkStatusPhotoByLink = (url, dispatch, filter, ids) => {

  const checkStatus = async () => {
    try {
      const resultUpload = await photosApi.getUploadStatusPhotoByLink(url)

      if (resultUpload.status === 'done') {
        dispatch(photoPreviewTransformerActions.setUploadLoading(false))

        if (resultUpload.backend_response.status < 400) {
          const successCallback = () => {
            dispatch(photoPreviewActions.setCurrentItemTransform({ ...resultUpload.backend_response?.json, favorite: filter === TIMELINE_FILTERS.favorites }))
            dispatch(photoPreviewTransformerActions.setPreviewTransformerOpen(false))
          } 

          const errorCallback = () => {
            dispatch(photoPreviewTransformerActions.setPreviewTransformerOpen(false))
          } 

          if (ids.includes(resultUpload.backend_response?.json?.id)) {
            showNotification({
              type: NOTIFICATION_TYPES.SUCCESS,
              typeError: 'TranformerCopySuccess',
              title: t('l_notification_AICopyError')
            })
          } else {
            // showNotification({
            //   type: NOTIFICATION_TYPES.SUCCESS,
            //   typeError: 'TranformerSuccess',
            //   title: t('l_notification_transformedPhotoSaved')
            // })

            const handlePositiveFeedback = () => {
              analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_TRANSFORM_LIKE)
            }

            const handleNegativeFeedback = () => {
              analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_TRANSFORM_DISLIKE)
            }

            const handleCallbackAfterHide = () => {
              analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_TRANSFORM_SKIP_FEEDBACK)
            }

            showNotification({
              type: NOTIFICATION_TYPES.FEEDBACK,
              title: t('l_notification_AIQuestion'),
              message: t('l_notification_AIThanks'),
              id: NOTIFICATION_IDS.PHOTO_AI_FEEDBACK,
              timeoutToHide: 10000,
              positiveFeedbackCallback: handlePositiveFeedback,
              negativeFeedbackCallback: handleNegativeFeedback,
              isCallbackAfterHide: true,
              callback: handleCallbackAfterHide
            })
  
            if (filter === TIMELINE_FILTERS.favorites) {
              return dispatch(addSelectedToFavoritesItemsThunk({ items: [resultUpload.backend_response?.json], withNotification: false, type : SDK_TYPES.DEFAULT, successCallback , errorCallback }))
            }
          }

          successCallback()
          return
        }

        if (resultUpload.backend_response.status > 400) {
          return dispatch(photoPreviewTransformerActions.setError(true))
        }

      } else {
        setTimeout (() => {
          checkStatus()
        },1000)
      }
    } catch (error) {
      dispatch(photoPreviewTransformerActions.setError(true))
      console.log(error)
    }
  }

  checkStatus()
}

export const uploadTimelineItemsThunk = createAsyncThunk(
  'filesUploading/uploadTimelineItemsThunk',
  async ({ files, type, callback }: { files: FileList, type: SDK_TYPES, callback?: () => void }, { dispatch, getState }) => {
    const state = getState() as RootState
    const timelineSdk = getTimelineSdkByType(type)
    const availableExtensions = state.app.extensionAvailableForUploading
    const maxPhotoSize = Number(state.user.maxPhotoSize)

    let uploadingSourceType: UPLOADER_LIST_TYPES = UPLOADER_LIST_TYPES.TIMELINE

    const { acceptedFiles, rejectedFiles } = splitFilesByAccept(files, availableExtensions, maxPhotoSize)

    showNotificationsForRejectedFiles(rejectedFiles)

    if (!acceptedFiles.length) {
      return
    }

    if (type === SDK_TYPES.FAMILY) {
      const userLinks = await request('GET', '/api/2/users')
      const userLink = userLinks?._links?.current_user?.href

      const response = await request('GET', userLink, {}, { host: null })

      const familiesResponse = await request('GET', response._links.families.href, { limit: 1 }, { host: null })
      const familyData = familiesResponse._embedded.families?.[0]
      const familyId = familyData?.shared_user_id

      if (!familyId) {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_somethingWrongTryAgain')
        })

        if (callback) {
          callback()
        }

        dispatch(updateUserDataThunk({ family_user_id: null, is_owner_family: null }))

        return
      }
    }

    if (type === SDK_TYPES.FAMILY && state.family.familyData.owner_id === state.user.userData.id) {
      uploadingSourceType = UPLOADER_LIST_TYPES.FAMILY_TIMELINE
    }

    if (type === SDK_TYPES.FAMILY && state.family.familyData.owner_id !== state.user.userData.id) {
      uploadingSourceType = UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR
    }
    
    dispatch(actions.setCurrentUploadingType(uploadingSourceType))
    dispatch(actions.updateStatus(UPLOADING_STATUSES.IN_PROGRESS))
    dispatch(actions.updateItems({
      entities: acceptedFiles.map((elem, index) =>
        mapItemToEntity({ item: elem, index, isInitial: true, uploadingSourceType }))
    }))

    acceptedFiles.forEach(file => {
      tempEntitiesWithoutUploadId[file.name] = uploadingSourceType
    })

    const uploaderListObject = await timelineSdk.addToUploadList(converFileListToFileObjectList(acceptedFiles), { multipartUpload: true, completeDelay: 400, uploadBatchSize: UPLOAD_BATCH_SIZE })

    bindUploaderListEvents(uploaderListObject as any, dispatch, getState)

    const { state: listState } = uploaderListObject.getList()

    if (!listState.isUploading || listState.isCompleted) {
      uploaderListObject.start()
    }

    if (callback) {
      callback()
    }

    if (timelineUploaderListObject) {
      return
    }

    timelineUploaderListObject = uploaderListObject as any
  }
)

export const uploadFavoriteItemsThunk = createAsyncThunk(
  'filesUploading/uploadFavoriteItemsThunk',
  async ({ files, callback, type = SDK_TYPES.DEFAULT }: { files: FileList, callback?: () => void, type?: SDK_TYPES }, { dispatch, getState }) => {
    const state = getState() as RootState
    const favoritesSdk = getFavoriteSdkByType(type)
    const availableExtensions = state.app.extensionAvailableForUploading

    let uploadingSourceType: UPLOADER_LIST_TYPES

    if (type === SDK_TYPES.FAMILY && state.family.familyData.owner_id === state.user.userData.id) {
      uploadingSourceType = UPLOADER_LIST_TYPES.FAMILY_TIMELINE
    }

    if (type === SDK_TYPES.FAMILY && state.family.familyData.owner_id !== state.user.userData.id) {
      uploadingSourceType = UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR
    }
    
    dispatch(actions.setCurrentUploadingType(uploadingSourceType))

    const { acceptedFiles, rejectedFiles } = splitFilesByAccept(files, availableExtensions, state.user.maxPhotoSize)

    showNotificationsForRejectedFiles(rejectedFiles)

    if (!acceptedFiles.length) {
      return
    }

    dispatch(actions.updateStatus(UPLOADING_STATUSES.IN_PROGRESS))
    dispatch(actions.updateItems({
      entities: acceptedFiles.map((elem, index) =>
        mapItemToEntity({ item: elem, index, isInitial: true, uploadingSourceType }))
    }))

    acceptedFiles.forEach(file => {
      tempEntitiesWithoutUploadId[file.name] = uploadingSourceType
    })

    const uploaderListObject = await favoritesSdk.addToUploadList(converFileListToFileObjectList(acceptedFiles), { multipartUpload: true, completeDelay: 400, uploadBatchSize: UPLOAD_BATCH_SIZE })

    bindUploaderListEvents(uploaderListObject as any, dispatch, getState)

    const { state: listState } = uploaderListObject.getList()

    if (!listState.isUploading || listState.isCompleted) {
      const initialUploadedItemsCount = listState.uploaded

      uploaderListObject.start().finally(() => {
        const uploadedCount = uploaderListObject.getList().state.uploaded

        if (uploadedCount - initialUploadedItemsCount) {
          showNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: i18n.t('l_notification_addedToFavorites', { number: uploadedCount - initialUploadedItemsCount })
          })
        }
      })
    }

    if (callback) {
      callback()
    }

    if (timelineUploaderListObject) {
      return
    }

    timelineUploaderListObject = uploaderListObject as any
  }
)


export const uploadAlbumItemsThunk = createAsyncThunk(
  'filesUploading/uploadAlbumItemsThunk',
  async ({ files, type, id }: { files: FileList, type: SDK_TYPES, id: string }, { dispatch, getState }) => {
    const state = getState() as RootState
    const albumsSdk = getAlbumsSdkByType(type)
    const availableExtensions = state.app.extensionAvailableForUploading

    const { acceptedFiles, rejectedFiles } = splitFilesByAccept(files, availableExtensions, state.user.maxPhotoSize)

    showNotificationsForRejectedFiles(rejectedFiles)

    if (!acceptedFiles.length) {
      return
    }

    let uploadingSourceType: UPLOADER_LIST_TYPES = UPLOADER_LIST_TYPES.ALBUM

    if (type === SDK_TYPES.FAMILY && state.family.familyData.owner_id === state.user.userData.id) {
      uploadingSourceType = UPLOADER_LIST_TYPES.FAMILY_ALBUM
    }

    if (type === SDK_TYPES.FAMILY && state.family.familyData.owner_id !== state.user.userData.id) {
      uploadingSourceType = UPLOADER_LIST_TYPES.FAMILY_ALBUM_COLLABORATOR
    }

    dispatch(actions.updateStatus(UPLOADING_STATUSES.IN_PROGRESS))
    dispatch(actions.updateItems({
      entities: acceptedFiles.map((elem, index) =>
        mapItemToEntity({ item: elem, index, isInitial: true, uploadingSourceType }))
    }))

    acceptedFiles.forEach(file => {
      tempEntitiesWithoutUploadId[file.name] = uploadingSourceType
    })

    const uploaderListObject = await albumsSdk.addToUploadList(id, converFileListToFileObjectList(acceptedFiles), { multipartUpload: true, completeDelay: 400, uploadBatchSize: UPLOAD_BATCH_SIZE })

    bindUploaderListEvents(uploaderListObject as any, dispatch, getState)

    const { state: listState } = uploaderListObject.getList()

    if (!listState.isUploading || listState.isCompleted) {
      uploaderListObject.start()
    }

    if (albumUploaderListObject) {
      return
    }

    albumUploaderListObject = uploaderListObject as any
  }
)

export const uploadSharedAlbumItemsThunk = createAsyncThunk(
  'filesUploading/uploadSharedAlbumItemsThunk',
  async ({ files, url, token }: { files: FileList, url: string, token?: string }, { dispatch, getState }) => {
    const state = getState() as RootState
    const publicLinksService = getPhotoSdk().publicLinksService

    dispatch(actions.setCurrentUploadingType(UPLOADER_LIST_TYPES.SHARED_ALBUM))

    const availableExtensions = state.app.extensionAvailableForUploading

    const { acceptedFiles, rejectedFiles } = splitFilesByAccept(files, availableExtensions, state.user.maxPhotoSize)

    showNotificationsForRejectedFiles(rejectedFiles)

    if (!acceptedFiles.length) {
      return
    }

    dispatch(actions.updateStatus(UPLOADING_STATUSES.IN_PROGRESS))
    dispatch(actions.updateItems({
      entities: acceptedFiles.map((elem, index) =>
        mapItemToEntity({ item: elem, index, isInitial: true, uploadingSourceType: UPLOADER_LIST_TYPES.SHARED_ALBUM }))
    }))

    acceptedFiles.forEach(file => {
      tempEntitiesWithoutUploadId[file.name] = UPLOADER_LIST_TYPES.SHARED_ALBUM
    })

    const uploaderListObject = await publicLinksService.addSharedWithMeItemToUploadListByLink(
      url,
      converFileListToFileObjectList(acceptedFiles),
      { multipartUpload: true, completeDelay: 400 },
      token
    )

    bindUploaderListEvents(uploaderListObject as any, dispatch, getState)

    const { state: listState } = uploaderListObject.getList()

    if (!listState.isUploading || listState.isCompleted) {
      uploaderListObject.start()
    }

    if (sharedAlbumUploaderListObject) {
      return
    }

    sharedAlbumUploaderListObject = uploaderListObject as any
  }
)


function uploaderListUpdateHandler({ dispatch, getState }, { list, state }) {
  const entities = list.map((elem, index) => {
    let uploadingSourceType
    const uploadId = elem?.options?.uploadId

    if (uploadId && uploadIdToUploadingSourceTypeMap[uploadId]) {
      uploadingSourceType = uploadIdToUploadingSourceTypeMap[uploadId]
    } else {
      uploadingSourceType = tempEntitiesWithoutUploadId[elem.file.name]

      if (uploadId) {
        uploadIdToUploadingSourceTypeMap[uploadId] = uploadingSourceType

        delete tempEntitiesWithoutUploadId[elem.file.name]
      }
    }

    return mapItemToEntity({ item: elem, index, isInitial: false, uploadingSourceType })
  })

  dispatch(actions.updateItems({ entities }))
  dispatch(actions.updateListState(state))

  const allEntities = adapter.getSelectors().selectAll((getState() as RootState).filesUploading)
  const filesInProgressExist = allEntities.some(file => !file.state.isCompleted)

  if (!filesInProgressExist) {
    // commented out to prevent closing after uploading all items
    // dispatch(actions.updateStatus(UPLOADING_STATUSES.IDLE))
  }
}

function uploaderListErrorHandler({ dispatch, getState }) {
  const listState = getState().filesUploading.listState

  dispatch(actions.updateListState({
    ...listState,
    hasErrors: true,
  }))
}

export const removeFileFromUploadingThunk = createAsyncThunk(
  'filesUploading/removeFileFromUploadingThunk',
  async (file: IUploadingFile) => {
    if (albumUploaderListObject) {
      const listFromUploader = albumUploaderListObject.getList().list
      albumUploaderListObject.remove(listFromUploader[file.id] as File | UploaderBase)
    }

    if (timelineUploaderListObject) {
      const listFromUploader = timelineUploaderListObject.getList().list
      timelineUploaderListObject.remove(listFromUploader[file.id] as File | UploaderBase)
    }

    if (sharedAlbumUploaderListObject) {
      const listFromUploader = sharedAlbumUploaderListObject.getList().list
      sharedAlbumUploaderListObject.remove(listFromUploader[file.id] as File | UploaderBase)
    }

    if (filesUploaderListObject) {
      const listFromUploader = filesUploaderListObject.getList().list
      filesUploaderListObject.remove(listFromUploader[file.id] as File | UploaderBase)
    }
  }
)

export const reloadFileUploadingThunk = createAsyncThunk(
  'filesUploading/reloadFileUploadingThunk',
  async () => {
    timelineUploaderListObject.resume()
  }
)

export const resumeFileUploadingThunk = createAsyncThunk(
  'filesUploading/resumeFileUploadingThunk',
  async () => {
    if (timelineUploaderListObject) {
      timelineUploaderListObject.resume()
    }

    if (albumUploaderListObject) {
      albumUploaderListObject.resume()
    }

    if (sharedAlbumUploaderListObject) {
      sharedAlbumUploaderListObject.resume()
    }

    if (sharedFilesUploaderListObject) {
      sharedFilesUploaderListObject.resume()
    }

    if (filesUploaderListObject) {
      filesUploaderListObject.resume()
    }
  }
)

export const restartFileUploadingThunk = createAsyncThunk(
  'filesUploading/restartFileUploadingThunk',
  async (item: any) => {
    if (timelineUploaderListObject) {
      const listFromUploader = timelineUploaderListObject.getList().list
      timelineUploaderListObject.restart(listFromUploader[item.id] as UploaderBase)
    }

    if (albumUploaderListObject) {
      const listFromUploader = albumUploaderListObject.getList().list
      albumUploaderListObject.restart(listFromUploader[item.id] as UploaderBase)
    }

    if (sharedAlbumUploaderListObject) {
      const listFromUploader = sharedAlbumUploaderListObject.getList().list
      sharedAlbumUploaderListObject.restart(listFromUploader[item.id] as UploaderBase)
    }

    if (filesUploaderListObject) {
      const listFromUploader = filesUploaderListObject.getList().list
      filesUploaderListObject.restart(listFromUploader[item.id] as UploaderBase)
    }

    if (sharedFilesUploaderListObject) {
      const listFromUploader = sharedFilesUploaderListObject.getList().list
      sharedFilesUploaderListObject.restart(listFromUploader[item.id] as UploaderBase)
    }
  }
)

export const closeWidgetThunk = createAsyncThunk(
  'filesUploading/closeWidgetThunk',
  async (_, { dispatch }) => {
    if (timelineUploaderListObject) {
      timelineUploaderListObject.cancel()
      timelineUploaderListObject.clear()
    }

    if (albumUploaderListObject) {
      albumUploaderListObject.cancel()
      albumUploaderListObject.clear()
    }

    if (sharedAlbumUploaderListObject) {
      sharedAlbumUploaderListObject.cancel()
      sharedAlbumUploaderListObject.clear()
    }

    if (filesUploaderListObject) {
      filesUploaderListObject.cancel()
      filesUploaderListObject.clear()
    }

    if (sharedFilesUploaderListObject) {
      sharedFilesUploaderListObject.cancel()
      sharedFilesUploaderListObject.clear()
    }

    dispatch(actions.updateStatus(UPLOADING_STATUSES.IDLE))
  }
)

export const filesUploadingSlice = createSlice({
  name: 'filesUploading',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    updateItems: (state, action) => {
      adapter.setAll(state, action.payload.entities)
    },
    clear: (state) => {
      adapter.removeAll(state)
    },
    removeItem: (state, action) => {
      adapter.removeOne(state, action.payload)
    },
    updateStatus: (state, action) => {
      state.status = action.payload
    },
    updateListState: (state, action) => {
      state.listState = action.payload
    },
    setCurrentUploadingType: (state, action) => {
      state.currentUploadingType = action.payload
    }
  },
})

const {
  reducer, actions
} = filesUploadingSlice

export { reducer as filesUploadingReducer, actions as filesUploadingActions }
