import { useEffect } from 'react'

import { SpriteIcon } from '@cloudike/web_ui_components'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'

interface SwitcherProps {
  leftOption: string;
  rightOption: string;
  isLeftActive: boolean;
  onChange: () => void;
  className?: string;
}

interface SliderProps {
  isLeft: boolean;
}

export const AITransformationSwitcher: React.FC<SwitcherProps> = ({
  leftOption,
  rightOption,
  isLeftActive,
  onChange,
  className = ''
}) => {

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        !isLeftActive && onChange()
      }

      if (e.key === 'ArrowRight') {
        isLeftActive && onChange()
      }

      e.stopImmediatePropagation()
      e.preventDefault()
    }

    window.addEventListener('keydown', handleKeyDown, true)
    return () => window.removeEventListener('keydown', handleKeyDown, true)
  }, [onChange])
  
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => !isLeftActive && onChange(),
    onSwipedRight: () => isLeftActive && onChange(),
    preventScrollOnSwipe: true,
    trackMouse: false
  })

  return (
    <Container className={className}>
      <Labels>
        <Label isActive={isLeftActive}>
          {leftOption}
        </Label>

        <Label isActive={!isLeftActive}>
          {rightOption}
        </Label>
      </Labels>

      <SwitcherContainer onClick={onChange}
        {...swipeHandlers}
      >
        <Slider isLeft={isLeftActive} />

        <OptionLeft isActive={isLeftActive}>
          <Icon isActive={isLeftActive}
            iconName="AI_transformer"
          />
        </OptionLeft>

        <OptionRight isActive={!isLeftActive}>
          <Icon isActive={!isLeftActive}
            iconName="gallery"
          />
        </OptionRight>
      </SwitcherContainer>
    </Container>
  )
}

const SwitcherContainer = styled.div`
  display: flex;
  background: var(--transformer-background-tertiary);
  border-radius: 100px;
  position: relative;
  width: 200px;
  height: 80px;
  cursor: pointer;
`

const Slider = styled.div<SliderProps>`
  position: absolute;
  width: 68px;
  height: 68px;
  background: var(--white);
  border-radius: 100px;
  top: 6px;
  left: 0;
  transition: all 0.2s linear;
  transform: translateX(${props => props.isLeft ? '6px' : '126px'});
  will-change: transform;
`

const OptionLeft = styled.div<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 28px; 
  z-index: 1;
`

const OptionRight = styled.div<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 28px; 
  z-index: 1;
`

const Icon = styled(SpriteIcon)<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  color: ${props => props.isActive ? 'var(--transformer-icon-primary)' : 'var(--transformer-icon-secondary)'};
`

const Container = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const Labels = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  padding: 0 10px 8px;
`

const Label = styled.div<{ isActive: boolean }>`
  font-size: 14px;
  color: var(--transformer-text-primary);
  transition: color 0.3s ease;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
`