import { getModalsRootElement } from 'constants/modals'
import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { formatImgUrl, THEME_COLORS_DARK } from 'constants/configs/theme.config'
import { STORAGE_CONSTANTS } from 'constants/storage'
import { PRELOADED_IMAGES } from 'constants/preloadedImages'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import ReactDOM from 'react-dom'
import { PageOverlay, SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from 'store'
import { Spinner } from 'ui/Spinner'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ToolbarActionButton } from 'features/common/preview-modal/ToolbarActionButton'
import { appActions } from 'store/app'
import { getNavigationPathname } from 'utils/navigationStorage'
import { Tooltip } from 'react-tooltip'
import { useMobileDetection } from 'features/common/hooks'

import { 
  getAlbumsMemoriesAlbumsSelector,
  getCurrentMemoriesAlbumDataSelector,
  getCurrentMemoriesAlbumItemsLoadingStatusSelector, 
  getCurrentMemoriesAlbumItemsSelector, 
  getCurrentMemoriesAlbumPhotoIndexSelector, 
  getCurrentMemoriesAlbumPlayerStateSelector, 
  getCurrentMemoriesAlbumSavedPlayerStateBeforeActionSelector
} from './selectors'
import { albumsMemoriesActions, fetchMemoriesAlbumDataThunk, loadAllMemoriesAlbumsThunk, loadMemoriesAlbumItemsThunk, renameMemoriesAlbumThunk } from './albumsMemoriesSlice'
import { RenameMemoriesAlbumModal } from './RenameMemoriesAlbumModal'
import { MemoriesAlbumsSlider } from './MemoriesAlbumsSlider'
import { EmptyMemoriesAlbumPlaceholder } from './EmptyMemoriesAlbumPlaceholder'


const SECONDS_PER_PHOTO = 2.8

const formatTitle = (title: string) => {
  if (!title) {
    return ''
  }

  return title.length > 50 ? title.slice(0, 47) + '...' : title
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

export const AlbumsMemoriesModal = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const isMobile = useMobileDetection()
  
  const currentAlbumData = getCurrentMemoriesAlbumDataSelector()
  const currentPhotoIndex = getCurrentMemoriesAlbumPhotoIndexSelector()
  const currentAlbumItems = getCurrentMemoriesAlbumItemsSelector()
  const itemsLoadingStatus = getCurrentMemoriesAlbumItemsLoadingStatusSelector()
  const savedPlayerStateBeforeAction = getCurrentMemoriesAlbumSavedPlayerStateBeforeActionSelector()
  const albums = getAlbumsMemoriesAlbumsSelector()

  const indexOfCurrentAlbum = albums.findIndex(album => album.id === currentAlbumData.id)
  const isLastAlbum = indexOfCurrentAlbum === albums.length - 1

  const currentPhoto = currentAlbumItems[currentPhotoIndex]

  const currentPhotoSrc = currentPhoto?._links?.image_preview?.href

  const playerState = getCurrentMemoriesAlbumPlayerStateSelector()
  const playerStateRef = useRef(playerState)
  const albumsLengthRef = useRef(albums.length)
  const longTapTimeoutRef = useRef(null)
  const initialTouchPositionRef = useRef<{ x: number, y: number } | null>(null)

  albumsLengthRef.current = albums.length

  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const [areControlsVisible, setAreControlsVisible] = useState(false)
  const [isImgLoading, setIsImgLoading] = useState(false)

  const setPlayerState = useCallback((data) => {
    dispatch(albumsMemoriesActions.setPlayerState(data))
  }, [])

  const [renameModalOpened, setRenameModalOpened] = useState(false)

  const anotherImagesForPreload = useMemo(() => {
    if (currentPhotoIndex < 5) {
      return currentAlbumItems.slice(0, 10)
    }

    if (currentPhotoIndex > 5) {
      return currentAlbumItems.slice(currentPhotoIndex - 5, currentPhotoIndex + 5)
    }

    return currentAlbumItems.slice(currentPhotoIndex - 5, currentPhotoIndex + 5)
  }, [currentAlbumItems, currentPhotoIndex])

  useEffect(() => {
    setIsImgLoading(true)
  }, [currentPhotoSrc])

  useEffect(() => {
    const offlineHandler = () => {
      dispatch(albumsMemoriesActions.setItemsLoadingStatus(LOADING_STATUSES.FAILED))
    }

    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('offline', offlineHandler)
    }
  }, [])

  useEffect(() => {
    dispatch(appActions.setIsMemoriesModalVisible(true))

    return () => {
      dispatch(appActions.setIsMemoriesModalVisible(false))
    }
  }, [])

  useEffect(() => {
    playerStateRef.current = playerState
  }, [playerState])

  useEffect(() => {
    if (id !== currentAlbumData?.id || !currentAlbumData?.id) {
      dispatch(fetchMemoriesAlbumDataThunk({ id }))
      dispatch(albumsMemoriesActions.setCurrentAlbumItems([]))
      dispatch(albumsMemoriesActions.setCurrentPhotoIndex(0))
      dispatch(albumsMemoriesActions.setItemsLoadingStatus(LOADING_STATUSES.LOADING))

      setPlayerState({
        isPlaying: false,
        currentTime: 0,
        duration: 0,
        finished: false
      })

      dispatch(loadAllMemoriesAlbumsThunk())
    } 

    dispatch(loadMemoriesAlbumItemsThunk({ id }))
    dispatch(loadAllMemoriesAlbumsThunk())
  }, [currentAlbumData?.id, id])

  useEffect(() => {
    const newDuration = currentAlbumItems.length * SECONDS_PER_PHOTO

    const newPlayerState = {
      ...playerStateRef.current,
      duration: newDuration,
    }

    if (playerStateRef.current.finished && playerStateRef.current.duration < newDuration) {
      newPlayerState.finished = false
    }

    if (playerStateRef.current.currentTime > newDuration || (playerStateRef.current.currentTime !== 0 && newDuration !== playerStateRef.current.duration)) {
      newPlayerState.currentTime = 0
    }

    setPlayerState(newPlayerState)
  }, [currentAlbumItems.length])

  useEffect(() => {
    if (itemsLoadingStatus === LOADING_STATUSES.SUCCEEDED && playerStateRef.current.currentTime === 0) {
      setPlayerState({
        isPlaying: true,
      })
    }
  }, [itemsLoadingStatus])

  useEffect(() => {
    if (playerState.isPlaying) {
      const interval = setInterval(() => {
        const newCurrentTime = playerStateRef.current.currentTime + 0.1

        if (playerStateRef.current.currentTime >= playerStateRef.current.duration && playerStateRef.current.duration) {
          setIsOverlayVisible(true)

          setTimeout(() => {
            setPlayerState({
              currentTime: playerStateRef.current.duration,
              finished: true,
              isPlaying: false,
            })

            if (albumsLengthRef.current > 1) {
              setIsOverlayVisible(false)
            }
          }, 500)

          clearInterval(interval)
        } else {
          setPlayerState({
            currentTime: newCurrentTime,
          })
        }
      }, 100)

      return () => clearInterval(interval)
    }
  }, [playerState.isPlaying])

  useEffect(() => {
    const calculatedIndex = Math.min(Math.floor(playerState.currentTime / SECONDS_PER_PHOTO), currentAlbumItems.length - 1)

    if (calculatedIndex !== currentPhotoIndex && !playerStateRef.current.finished) {
      dispatch(albumsMemoriesActions.setCurrentPhotoIndex(calculatedIndex))
    }
  }, [playerState.currentTime, currentAlbumItems.length])

  useEffect(() => {
    let timeoutToHideControls

    if (isMobile) {
      return
    }

    const listener = () => {
      setAreControlsVisible(true)
      clearTimeout(timeoutToHideControls)

      timeoutToHideControls = setTimeout(() => {
        setAreControlsVisible(false)
      }, 3000)
    }

    window.addEventListener('mousemove', listener)
    window.document.addEventListener('click', listener)
    
    return () => {
      window.removeEventListener('mousemove', listener)
      window.document.removeEventListener('click', listener)

      clearTimeout(timeoutToHideControls)
    }
  }, [isMobile])

  const handleNext = () => {
    if (currentPhotoIndex < currentAlbumItems.length - 1) {
      const newIndex = currentPhotoIndex + 1

      dispatch(albumsMemoriesActions.setCurrentPhotoIndex(newIndex))

      setPlayerState({
        currentTime: newIndex * SECONDS_PER_PHOTO + 0.0001,
      })
    }
  }

  const handlePrev = () => {
    if (currentPhotoIndex > 0) {
      const newIndex = currentPhotoIndex - 1
      
      dispatch(albumsMemoriesActions.setCurrentPhotoIndex(newIndex))

      setPlayerState({
        currentTime: newIndex * SECONDS_PER_PHOTO + 0.0001,
      })
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseModal()
      }

      if (event.key === 'Enter') {
        if (playerStateRef.current.finished) {
          return
        }

        dispatch(albumsMemoriesActions.setPlayerState({
          isPlaying: !playerStateRef.current.isPlaying,
        }))
      }

      if (event.key === 'ArrowRight') {
        if (playerStateRef.current.finished) {
          return
        }

        handleNext()
      }

      if (event.key === 'ArrowLeft') {
        if (playerStateRef.current.finished) {
          setPlayerState({
            isPlaying: false,
            finished: false,
            currentTime: (currentAlbumItems.length - 1) * SECONDS_PER_PHOTO + 0.0001,
          })

          return
        }

        handlePrev()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [currentPhotoIndex, currentAlbumItems.length])

  useEffect(() => {
    const handleBlur = () => {
      dispatch(albumsMemoriesActions.setSavedPlayerStateBeforeAction(playerStateRef.current))
    }

    const handleFocus = () => {
      dispatch(albumsMemoriesActions.setPlayerState(savedPlayerStateBeforeAction))
    }

    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
    }
  }, [savedPlayerStateBeforeAction])

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      const touch = event.touches[0]
      initialTouchPositionRef.current = { x: touch.clientX, y: touch.clientY }

      longTapTimeoutRef.current = setTimeout(() => {
        dispatch(albumsMemoriesActions.setPlayerState({
          isPlaying: !playerStateRef.current.isPlaying,
        }))
      }, 500)
    }

    const handleTouchEnd = () => {
      clearTimeout(longTapTimeoutRef.current)
    }

    const handleTouchMove = (event: TouchEvent) => {
      if (!initialTouchPositionRef.current) return

      const touch = event.touches[0]
      const deltaX = Math.abs(touch.clientX - initialTouchPositionRef.current.x)
      const deltaY = Math.abs(touch.clientY - initialTouchPositionRef.current.y)

      if (deltaX > 10 || deltaY > 10) {
        if (longTapTimeoutRef.current) {
          clearTimeout(longTapTimeoutRef.current)
          longTapTimeoutRef.current = null
        }
      }
    }

    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchend', handleTouchEnd)
    window.addEventListener('touchmove', handleTouchMove)

    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchend', handleTouchEnd)
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [])

  const handleCloseModal = () => {
    dispatch(albumsMemoriesActions.resetModalState())
    const from = getNavigationPathname(STORAGE_CONSTANTS.BEFORE_MEMORIES_MODAL_PATHNAME)

    if (from) {
      navigate(from, { replace: true })

      return
    } else {
      navigate('/photos/albums')
    }
  }

  const handlePreviewClick = (index: number) => {
    dispatch(albumsMemoriesActions.setCurrentPhotoIndex(index))

    setPlayerState({
      currentTime: index * SECONDS_PER_PHOTO,
    })
  }

  const handlePause = () => {
    setPlayerState({
      isPlaying: false,
    })
  }

  const handlePlay = () => {
    setPlayerState(playerState.currentTime === playerState.duration ? playerState : ({
      ...playerState,
      isPlaying: true,
    }))
  }

  const handleToggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      document.documentElement.requestFullscreen()
    }
  }

  const handleOpenRenameModal = () => {
    dispatch(albumsMemoriesActions.setSavedPlayerStateBeforeAction(playerState))
    dispatch(albumsMemoriesActions.setPlayerState({
      isPlaying: false
    }))

    setRenameModalOpened(true)
  }

  const handleCloseRenameModal = () => {
    dispatch(albumsMemoriesActions.setPlayerState(savedPlayerStateBeforeAction))

    setRenameModalOpened(false)
  }

  const handleRenameAlbum = (newName: string) => {
    const successCallback = () => {
      handleCloseRenameModal()
    }

    dispatch(renameMemoriesAlbumThunk({ id: currentAlbumData?.id, name: newName, successCallback }))
  }

  const handleAddToMemory = () => {
    dispatch(albumsMemoriesActions.setSavedPlayerStateBeforeAction(playerStateRef.current))

    setPlayerState({
      isPlaying: false
    })

    navigate(`/photos/memories/${currentAlbumData?.id}/add`, { state: { from: window.location.pathname } })
  }

  const handleOpenAlbum = () => {
    dispatch(albumsMemoriesActions.setSavedPlayerStateBeforeAction(playerState))
    
    setPlayerState({
      isPlaying: false
    })

    navigate(`/photos/memories/${currentAlbumData?.id}`, { state: { from: window.location.pathname } })
  }

  const handleWatchAgain = () => {
    dispatch(albumsMemoriesActions.setCurrentPhotoIndex(0))
    setIsOverlayVisible(false)

    setPlayerState({
      currentTime: 0,
      finished: false,
      isPlaying: true,
    })
  }

  const handleToggleControlsVisibilityOnMobile = () => {
    if (isMobile) {
      setAreControlsVisible(!areControlsVisible)
    }
  }

  const handleFinishImgLoading = () => {
    setIsImgLoading(false)
  }

  const actionItems = [
    {
      handler: handleOpenRenameModal,
      title: t('a_photos_editTitle'),
      label: t('a_photos_editTitle'),
      iconName: 'pencil'
    },
    {
      handler: handleAddToMemory,
      title: t('a_common_addToMemory'),
      label: t('a_common_addToMemory'),
      iconName: 'add_to_album_2'
    },
    {
      handler: handleCloseModal,
      title: t('a_common_close'),
      label: t('a_common_close'),
      iconName: 'close_2',
    },
  ]

  const closeActionItem = {
    handler: handleCloseModal,
    title: t('a_common_close'),
    label: t('a_common_close'),
    iconName: 'close_2',
  }

  return ReactDOM.createPortal(
    <>
      {
        <SModalBox>
          {
            playerState.finished && albums.length > 1 && !isLastAlbum && itemsLoadingStatus !== LOADING_STATUSES.FAILED && (
              <>
                <STopControls>
                  <STopControlsPart className="hidden-on-mobile" />

                  <STopControlsPart>
                    <STopTitle className="visible-on-mobile">
                      {t('l_photos_nextMemory')}
                    </STopTitle>
                  </STopControlsPart>

                  <STopControlsPart className="right">
                    <SToolbarActionButton
                      item={closeActionItem}
                    />
                  </STopControlsPart>
                </STopControls>

                <MemoriesAlbumsSlider
                  onRepeat={handleWatchAgain}
                />
              </>
            )
          }

          {
            !currentAlbumItems.length && itemsLoadingStatus === LOADING_STATUSES.SUCCEEDED && (
              <EmptyMemoriesAlbumPlaceholder
                texts={{
                  title: t('l_common_emptyMemoryTitile'),
                  description: t('l_common_emptyMemoryMsg'),
                  addPhotosBtn: t('a_common_addPhotos')
                }}
                onButtonClick={handleAddToMemory}
                imageSrc={formatImgUrl("/assets/placeholders-images-black/no-photos.svg")}
              />
            )
          }

          {
            itemsLoadingStatus === LOADING_STATUSES.FAILED && (
              <EmptyMemoriesAlbumPlaceholder
                texts={{
                  title: t('l_error_dataLoadTitile'),
                  description: t('l_error_dataLoadMessage'),
                  addPhotosBtn: t('a_common_backToAlbums')
                }}
                onButtonClick={handleCloseModal}
                imageSrc={PRELOADED_IMAGES.noInternetConnectionBlack}
              />
            )
          }

          {
            (!playerState.finished || albums.length <= 1 || isLastAlbum) && itemsLoadingStatus !== LOADING_STATUSES.FAILED && (
              <>
                {isImgLoading && !!currentPhotoSrc && <SSpinner color='#E1E1E2' />}

                {!!currentPhotoSrc && (
                  <SImage 
                    src={currentPhotoSrc}
                    fetchPriority="high"
                    onLoad={handleFinishImgLoading}
                    className={classNames({ hidden : isImgLoading })}
                  />
                )}

                {anotherImagesForPreload.map(item => (
                  <SHiddenImg
                    src={item._links.image_preview.href}
                    key={item.id}
                    fetchPriority="high"
                  />
                ))}

                <STopControls className={classNames({ hidden: !areControlsVisible })}>
                  <STopControlsPart className="hidden-on-mobile" />

                  <STopControlsPart>
                    <STopTitle className="visible-on-mobile">
                      {formatTitle(currentAlbumData?.description)}
                    </STopTitle>
                  </STopControlsPart>

                  <STopControlsPart className="right">
                    {actionItems.map(item => (
                      <SToolbarActionButton
                        key={item.iconName}
                        item={item}
                      />
                    ))}
                  </STopControlsPart>
                </STopControls>

                {itemsLoadingStatus === LOADING_STATUSES.LOADING && <SSpinner />}

                <SBigTitle className={classNames({ hidden: currentPhotoIndex > 0 || (itemsLoadingStatus === LOADING_STATUSES.SUCCEEDED && !currentAlbumItems.length) })}>
                  {formatTitle(currentAlbumData?.description)}
                </SBigTitle>

                {!!currentAlbumItems.length && !isMobile && (
                  <SBottomControls className={classNames({ hidden: !areControlsVisible || (isLastAlbum && playerState.finished) })}>
                    <SBottomControlsSmallPart>
                      {playerState.duration > 0 && (
                        <>
                          <STimer data-tooltip-id={'play-pause-tooltip'}
                            data-tooltip-content={playerState.isPlaying ? t('a_common_pause') : t('a_common_play')}
                            onClick={playerState.isPlaying ? handlePause : handlePlay}
                          >
                            {playerState.isPlaying ? (
                              <STimerButton
                                iconName="player_pause"
                              />
                            ) : (
                              <STimerButton
                                iconName="player_play"
                              />
                            )}

                            <STimerText>
                              {formatTime(playerState.currentTime)}

                              {' / '}

                              {formatTime(playerState.duration)}
                            </STimerText>
                          </STimer>

                          <STooltip id={'play-pause-tooltip'} />
                        </>
                      )}
                    </SBottomControlsSmallPart>

                    {!!currentPhoto && (
                      <SPreviewsOuterContainer>
                        <SPreviewsInnerContainer offset={currentPhotoIndex * 34}>
                          {currentAlbumItems.map((item, index) => (
                            <SPreview 
                              src={item._links.image_small.href}
                              key={item.id}
                              className={classNames({ active: item.id === currentPhoto.id })}
                              onClick={() => handlePreviewClick(index)}
                            />
                          ))}
                        </SPreviewsInnerContainer>
                      </SPreviewsOuterContainer>
                    )}

                    <SBottomControlsSmallPart>
                      <SBottomRightControls>
                        <SBottomControlButton 
                          data-tooltip-id={'manage-photos-tooltip'}
                          data-tooltip-content={t('a_photos_managePhotos')}
                          onClick={handleOpenAlbum}
                        >
                          <SBottomControlButtonIcon
                            iconName="grid"
                          />
                        </SBottomControlButton>

                        <SBottomControlButton 
                          data-tooltip-id={'full-screen-tooltip'}
                          data-tooltip-content={t('a_common_fullScreen')}
                          data-tooltip-place="top"
                          data-tooltip-position-strategy="fixed"
                          onClick={handleToggleFullScreen}
                        >
                          <SBottomControlButtonIcon
                            iconName="full_screen"
                          />
                        </SBottomControlButton>

                        <STooltip id={'full-screen-tooltip'}
                          place="top"
                        />

                        <STooltip id={'manage-photos-tooltip'} />
                      </SBottomRightControls>
                    </SBottomControlsSmallPart>
                  </SBottomControls>
                )}

                {isMobile && currentAlbumItems.length > 0 && (
                  <SBottomControlsMobile className={classNames({ hidden: !areControlsVisible })}>
                    {!!currentPhoto && (
                      <SPreviewsOuterContainer>
                        <SPreviewsInnerContainer offset={currentPhotoIndex * 34}>
                          {currentAlbumItems.map((item, index) => (
                            <SPreview 
                              src={item._links.image_small.href}
                              key={item.id}
                              className={classNames({ active: item.id === currentPhoto.id })}
                              onClick={() => handlePreviewClick(index)}
                            />
                          ))}
                        </SPreviewsInnerContainer>
                      </SPreviewsOuterContainer>
                    )}

                    <SBottomControlsMobileBottomRow>
                      <SBottomControlButton
                        onClick={handleOpenAlbum}
                      >
                        <SBottomControlButtonIcon iconName="grid" />
                      </SBottomControlButton>
                      
                      <STimer
                        onClick={playerState.isPlaying ? handlePause : handlePlay}
                      >
                        {playerState.isPlaying ? (
                          <STimerButton
                            iconName="player_pause"
                          />
                        ) : (
                          <STimerButton
                            iconName="player_play"
                          />
                        )}

                        <STimerText>
                          {formatTime(playerState.currentTime)}

                          {' / '}

                          {formatTime(playerState.duration)}
                        </STimerText>
                      </STimer>

                      <SBottomControlButton
                        onClick={handleToggleFullScreen}
                      >
                        <SBottomControlButtonIcon iconName="full_screen" />
                      </SBottomControlButton>
                    </SBottomControlsMobileBottomRow>
                  </SBottomControlsMobile>
                )}
              </>
            )
          }

          <SMobileOverlayForToggleControls 
            onClick={handleToggleControlsVisibilityOnMobile}
          />

          <SOverlayBeforeMemoriesSlider className={classNames({ visible: isOverlayVisible || (isLastAlbum && playerState.finished) })}>
            {(albums.length <= 1 || isLastAlbum) && (
              <SRepeatContainer>
                <SRepeatBigBtn onClick={handleWatchAgain}>
                  <SRepeatBigBtnIcon iconName="refresh_2" />
                </SRepeatBigBtn>
    
                <SRepeatTitle>
                  {formatTitle(currentAlbumData?.description)}
                </SRepeatTitle>
    
                <SRepeatSmallBtn onClick={handleWatchAgain}>
                  {t('a_common_watchAgain')}
                </SRepeatSmallBtn>
              </SRepeatContainer>
            )}
          </SOverlayBeforeMemoriesSlider>

          <LeftAreaForClick onClick={handlePrev} />

          <RightAreaForClick onClick={handleNext} />
        </SModalBox>
      }

      <PageOverlay
        active
        backgroundColor="#030303"
      />

      <RenameMemoriesAlbumModal
        isShown={renameModalOpened}
        albumNameBeforeRenaiming={currentAlbumData?.description}
        parentBlock={getModalsRootElement()}
        onCloseModal={handleCloseRenameModal}
        onRenameAlbum={handleRenameAlbum}
        texts={{
          TITLE: t('l_photos_renameMemory'),
          PLACEHOLDER: t('l_common_untitledAlbum'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          SUB_TITLE: t('l_common_name'),
          TITLE_ICON: 'edit'
        }}
      />
    </>, getModalsRootElement()
  )
}

export const SHiddenImg = styled.img`
  display: none;
`

export const SModalBox = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 16;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`

const SSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;

  div {
    border-color: #E1E1E2 transparent transparent transparent;
    border-width: 4px;
  }
`

const SBigTitle = styled.p`
  font-weight: 500;
  font-size: 56px;
  line-height: 56px;
  letter-spacing: 0%;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  bottom: 104px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 690px;
  z-index: 2;
  transition: opacity 0.3s ease-in-out;
  word-break: break-word;

  &.hidden {
    opacity: 0;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 100%;
    bottom: 144px;
    font-size: 32px;
    line-height: 40px;
    padding: 0 16px;
    text-align: left;
  }
`

const SImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
`

const STopControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 4;
  transition: opacity 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding: 0 16px;
  }
`

const STopControlsPart = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  &.right {
    justify-content: flex-end;
    gap: 16px;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    flex: unset;

    &.hidden-on-mobile {
      display: none;
    }
  }
`

const STopTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  color: #E1E1E2;
  word-break: break-word;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: none;

    &.visible-on-mobile {
      display: block;
      max-width: calc(100vw - 170px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

const SBottomControls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 142px;
  padding: 74px 24px 24px;
  background: linear-gradient(0, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  z-index: 2;
  transition: opacity 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
  }
`

const SBottomControlsMobile = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 148px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;
  padding-top: 20px;

  &.hidden {
    opacity: 0;
  }
`

const SBottomControlsMobileBottomRow = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`

const SBottomControlsSmallPart = styled.div`
  width: 120px;
`

const SPreviewsOuterContainer = styled.div`
  flex: 1;
  overflow: hidden;
`

const SPreviewsInnerContainer = styled.div<{ offset: number }>`
  display: flex;
  align-items: center;
  gap: 6px;
  transform: ${props => `translateX(calc(50% - ${props.offset}px))`};
  transition: transform 0.3s ease-in-out;
`

const SPreview = styled.img`
  width: 28px;
  min-width: 28px;
  height: 44px;
  border-radius: 4px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;

  &.active {
    margin: 0 6px;
    width: 44px;
    min-width: 44px;
  }
`

const STimer = styled.div`
  width: 121px;
  height: 40px;
  background: rgba(255, 255, 255, 0.24);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  border-radius: 100px;
  transition: background 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.32);
  }
`

const STimerButton = styled(SpriteIcon)`
  width: 20px;
  height: 20px;
  color: white;
`

const STimerText = styled.p`
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: white;
`

const SBottomRightControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`

const SBottomControlButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.32);
  }
`

const SBottomControlButtonIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  color: white;
`

const SToolbarActionButton = styled(ToolbarActionButton)`
  width: 32px;
  height: 32px;
  border-radius: 4px;
`

const SOverlayBeforeMemoriesSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms ease-in-out;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  &.visible {
    z-index: 3;
    opacity: 1;
  }
`

const SMobileOverlayForToggleControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  display: none;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: block;
  }
`

const SRepeatContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

const SRepeatBigBtn = styled.div`
  width: 148px;
  height: 148px;
  background: rgba(23, 23, 30, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  cursor: pointer;
`

const SRepeatBigBtnIcon = styled(SpriteIcon)`
  width: 56px;
  height: 56px;
  color: rgba(151, 151, 155, 1);
`

const SRepeatTitle = styled.p`
  margin-top: 56px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: center;
  color: rgba(225, 225, 226, 1);
`

const SRepeatSmallBtn = styled.button`
  margin-top: 40px;
  height: 40px;
  padding: 0 16px;
  background: ${THEME_COLORS_DARK['button-primary-default']};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  color: ${THEME_COLORS_DARK['button-primary-text']};
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background: ${THEME_COLORS_DARK['button-primary-hover']};
  }
`

const LeftAreaForClick = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: 100%;
  z-index: 1;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 80px;
  }
`

const RightAreaForClick = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  height: 100%;
  z-index: 1;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 80px;
  }
`

const STooltip = styled(Tooltip)`
  && {
    background-color: var(--background-primary);
    color: var(--text-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    user-select: none;
  }
`