import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'
import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { PHOTOS_COUNT_PER_PAGE } from 'constants/pagination'

import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { getPhotosWS } from 'sdk/photo'
import {
  getCurrentTimelinePaginator,
  getFamilyTimelineSdk, getFavoriteSdkByType,
  getPhotoFavoriteSdk,
  getTimelineSdkByType,
  initTimelinePaginator
} from 'sdk/timeline'
import { RootState } from 'store'
import i18n from 'i18n'
import { getErrorData } from 'utils/getErrorData'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { SDK_TYPES } from 'sdk/sdkConstants'
import _ from 'lodash'
import { downloadItemByLink } from 'utils/utils'

import { photoPreviewActions } from "../photo/photo-preview/photoPreviewSlice"
import {
  photoPreviewDuplicateActions,
  photoPreviewDuplicateSelectors
} from "../photo/photo-preview-duplicate/photoPreviewDuplicateSlice"
import { TOTAL_COUNT_HEADER } from "../../constants/headers"

const adapter = createEntityAdapter<IItemSchema>()

export const favoritesSelectors = adapter.getSelectors()

const getCurrentTimelineType = (state: RootState) => state.favorites.type

export const subscribeFavoritesToWSThunk = createAsyncThunk(
  'favorites/subscribeFavoritesToWSThunk',
  async function(_, { dispatch, getState }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE, ({ action, output }) => {
      if (action === 'add_favorite') {
        dispatch(loadJustUploadedFavoritesItemsThunk())
      }

      if (action === 'delete_items' || action === 'delete_favorite') {
        const state = getState() as RootState
        const allItemsLength = adapter.getSelectors().selectIds(state.favorites).length

        if (output.length === allItemsLength) {
          dispatch(actions.setStatus(LOADING_STATUSES.LOADING))
          dispatch(loadFavoritesItemsFirstTimeThunk())
        } else {
          dispatch(actions.deleteItems(output.map(item => item.detail.item_id)))
          dispatch(actions.decreaseTotalCount(output.length))
        }

        dispatch(actions.unselectAll())

        hideGlobalProgressLoader()
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED, ({ output }) => {
      const state = getState() as RootState
      const currentEntities = adapter.getSelectors().selectEntities(state.favorites)

      dispatch(photoPreviewDuplicateActions.updateItem(output))

      if (!output.id || !output.favorite) {
        return
      }

      if (currentEntities[output.id]) {
        dispatch(actions.updateItem(output))
        dispatch(photoPreviewActions.updateItem(output))
        return
      }

      dispatch(actions.addItem(output))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH, ({ output }) => {
      const state = getState() as RootState
      const array = photoPreviewDuplicateSelectors.selectAll(state.photoPreviewDuplicate)
      const currentItem = array.find(item => item.id === output.item_id)

      if(state.photoPreviewDuplicate.duplicateMode && !!currentItem) {
        if (currentItem.isOrigin) {
          dispatch(photoPreviewDuplicateActions.resetState())
          dispatch(photoPreviewActions.resetState())
        } else {
          dispatch(photoPreviewDuplicateActions.deleteItem(output.item_id))
        }
      }
    })
  }
)

export const unsubscribeFavoritesFromWSThunk = createAsyncThunk(
  'favorites/unsubscribeFavoritesFromWSThunk',
  async function() {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH)
  }
)

export const loadFavoritesItemsFirstTimeThunk = createAsyncThunk(
  'favorites/loadFavoritesItemsFirstTimeThunk',
  async function(_, { getState }) {
    const type = getCurrentTimelineType(getState() as RootState)
    const paginator = initTimelinePaginator(type, PHOTOS_COUNT_PER_PAGE, { favorite: true, total_count: true })

    const response = await paginator.next()

    const asd = response.data._embedded.items.filter((item) => item.favorite === true)

    return {
      items: asd,
      totalCount: parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0,
    }
  }
)

export const loadJustUploadedFavoritesItemsThunk = createAsyncThunk(
  'favorites/loadJustUploadedFavoritesItemsThunk',
  async function(_, { getState, dispatch }) {
    const state = getState() as RootState
    const type = getCurrentTimelineType(state)
    const timelineSdk = getTimelineSdkByType(type)

    const response = await timelineSdk.getTimelineItems({ offset: 0, limit: 1, total_count: true, favorite: true })

    dispatch(actions.setTotalCount(response.headers[TOTAL_COUNT_HEADER]))
    dispatch(actions.setStatus(LOADING_STATUSES.SUCCEEDED))
  }
)

export const loadMoreFavoritesItemsThunk = createAsyncThunk(
  'favorites/loadMoreFavoritesItemsThunk',
  async function(_, { getState }) {
    const type = getCurrentTimelineType(getState() as RootState)
    const paginator = getCurrentTimelinePaginator(type)

    const response = await paginator.next()

    return response.data._embedded.items
  }
)

export const removeSelectedItemsThunk = createAsyncThunk(
  'favorites/removeTimelineItemsThunk',
  async function({ items, type }: { items: IItemSchema[], type: SDK_TYPES}, { dispatch }) {
    try {
      const timelineSdk = getTimelineSdkByType(type)

      showGlobalProgressLoader()

      await timelineSdk.removeItems(items)

      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_itemsDeletedforWeb', { number: items.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const removeSelectedFromFavoritesItemsThunk = createAsyncThunk(
  'favorites/removeSelectedFavoritesItemsThunk',
  async function({ items, type }: { items: IItemSchema[], type: SDK_TYPES }, { dispatch }) {
    try {
      const favoriteSdk = getFavoriteSdkByType(type)

      showGlobalProgressLoader()

      await favoriteSdk.removeItemsFromFavorite(items)

      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_removedFromFavorites', { number: items.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const addSelectedToFavoritesItemsThunk = createAsyncThunk(
  'favorites/addSelectedToFavoritesItemsThunk',
  async function({ items, withNotification, type, successCallback, errorCallback } : 
    { items: IItemSchema[], withNotification: boolean, type: SDK_TYPES, successCallback?: () => void, errorCallback?: () => void }) {
    try {
      const favoriteSdk = getFavoriteSdkByType(type)

      showGlobalProgressLoader()

      const filteredItems = items.filter(item => !item.favorite)

      await favoriteSdk.addItemsToFavorite(filteredItems)

      if (withNotification) showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_addedToFavorites', { number: filteredItems.length })
      })

      if (!!successCallback) {
        successCallback()
      }

    } catch (error) {
      
      if (!!errorCallback) {
        errorCallback()
      }
      
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()

    }
  }
)

export const addSelectedToFavoritesItemsADDThunk = createAsyncThunk(
  'favorites/addSelectedToFavoritesItemsADDThunk',
  async function({ items, callback, type = SDK_TYPES.DEFAULT } : { items: IItemSchema[], callback: () => void, type?: SDK_TYPES }, { dispatch }) {
    try {
      const favoriteSdk = getFavoriteSdkByType(type)

      showGlobalProgressLoader()

      await favoriteSdk.addItemsToFavorite(items)

      dispatch(actions.unselectAll())

      callback()

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_addedToFavorites', { number: items.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const downloadFavoritesItemsThunk = createAsyncThunk(
  'favorites/downloadFavoritesItemsThunk',
  async function({ items, type }: { items: IItemSchema[], type: SDK_TYPES }, { dispatch }) {
    try {
      const timelineSdk = getTimelineSdkByType(type)
      let href

      showGlobalProgressLoader()

      if (items.length === 1) {
        const {
          _links: {
            content: { href: contentLink }
          }
        } = items[0]

        href = await downloadItemByLink(contentLink)
      } else {
        href = await timelineSdk.createItemsZipStream(items)
      }

      window.location.href = href
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const copyFavoritesToFamilyThunk = createAsyncThunk(
  'favorites/copyFavoritesToFamilyThunk',
  async function(items:IItemSchema[], { dispatch }) {
    try {
      const familyTimelineSdk = getFamilyTimelineSdk()

      showGlobalProgressLoader()

      await familyTimelineSdk.addItemsToTimeline(items)

      dispatch(favoritesSlice.actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_common_photoToFamilyOk', { number: items.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: adapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    error: '',
    type: SDK_TYPES.DEFAULT,
    totalItemsCount: 0,
    selectedItemsIds: []
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedItemsIds = [...state.selectedItemsIds, id]
      } else {
        state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
      }
    },
    addItem: (state, action) => {
      adapter.addOne(state, action.payload)
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    unselectAll: (state) => {
      state.selectedItemsIds = []
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      adapter.removeMany(state, action.payload)
    },
    selectAllItemsInGroup: (state, action) => {
      state.selectedItemsIds = _.uniq([...state.selectedItemsIds, ...action.payload])
    },
    unselectAllItemsInGroup: (state, action) => {
      state.selectedItemsIds = _.difference(state.selectedItemsIds, action.payload)
    },
    setCurrentTimelineType: (state, action) => {
      state.type = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      state.selectedItemsIds = []
      adapter.removeAll(state)
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    },
    decreaseTotalCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = state.totalItemsCount - action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadFavoritesItemsFirstTimeThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(loadFavoritesItemsFirstTimeThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalItemsCount = action.payload.totalCount
      })
      .addCase(loadFavoritesItemsFirstTimeThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(loadMoreFavoritesItemsThunk.pending, (state) => {
        state.loadingMoreStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadMoreFavoritesItemsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.addMany(state, action.payload)
      })
  },
})

const {
  reducer, actions
} = favoritesSlice

export { reducer as favoritesReducer, actions as favoritesActions }
