
import { useEffect, useRef, useState } from 'react'

import { useAppDispatch } from  "store"
import styled from 'styled-components'

import {
  ImageBox,
  Img,
  StyledSpinner,
} from "../../common/preview-modal/styles"

import { getAIEditorActiveSelector, getPhotoPreviewTransformerErrorSelector, getPhotoPreviewTransformerItemsSelector, getPhotoPreviewTransformerLoadingSelector, getStartArraySelector } from './selectors'
import { photoPreviewTransformerActions } from './photoPreviewTransformerSlice'

export const AITransformationPhotos = () => {
  const dispatch = useAppDispatch()

  const originalItems = getStartArraySelector()
  const transformedItems = getPhotoPreviewTransformerItemsSelector()
  const isLoading = getPhotoPreviewTransformerLoadingSelector()
  const hasError = getPhotoPreviewTransformerErrorSelector()
  const isAIEditorActive = getAIEditorActiveSelector()

  const [previewUrls, setPreviewUrls] = useState({
    ai: null,
    original: null
  })

  const imgRef = useRef(null)

  const handleFinishLoading = () => {
    dispatch(photoPreviewTransformerActions.setLoading(false))
  }

  const handleError = () => {
    dispatch(photoPreviewTransformerActions.setLoading(false))
    dispatch(photoPreviewTransformerActions.setError(true))
  }

  useEffect(() => {
    setPreviewUrls({
      ai: null,
      original: null
    })

    const aiImage = transformedItems[0].results[0]
    const originalImage = originalItems[0]._links?.image_preview?.href

    const preloadImages = async () => {
      dispatch(photoPreviewTransformerActions.setError(false))
      dispatch(photoPreviewTransformerActions.setLoading(true))

      const loadImage = (src) => {
        return new Promise((resolve) => {
          const img = new Image()
          img.src = src
          img.onload = () => resolve(src)
        })
      }

      const timeoutId = setTimeout(() => {
        dispatch(photoPreviewTransformerActions.setLoading(false))
        dispatch(photoPreviewTransformerActions.setError(true))
      }, 60000)

      try {
        const [aiUrl, originalUrl] = await Promise.all([
          loadImage(aiImage),
          loadImage(originalImage)
        ])

        setPreviewUrls({
          ai: aiUrl,
          original: originalUrl
        })
      } catch (error) {
        console.log(error)
        dispatch(photoPreviewTransformerActions.setError(true))
      } finally {
        clearTimeout(timeoutId)
        dispatch(photoPreviewTransformerActions.setLoading(false))
      }
    }

    if (aiImage && originalImage) {
      preloadImages()
    }

  }, [transformedItems])

  return(
    <ImageBox>
      {!hasError && !isLoading && (
        <>
          <Img
            ref={imgRef}
            src={isAIEditorActive ? previewUrls.ai : previewUrls.original}
            onLoad={handleFinishLoading}
            onError={handleError}
            fetchPriority='high'
          />

          <HiddenImg src={isAIEditorActive ? previewUrls.original : previewUrls.ai} />
        </>
      )}

      {isLoading && <StyledSpinner color='var(--transformer-button-primary-default)' />}

    </ImageBox>
  )
}

const HiddenImg = styled.img`
  display: none;
`
