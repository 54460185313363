import { formatImgUrl } from 'constants/configs/theme.config'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PrimaryButton } from '@cloudike/web_ui_components'
import { useAppDispatch } from 'store'

import { photoPreviewTransformerActions } from './photoPreviewTransformerSlice'

export const AITransformationError = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleAction = () => {
    dispatch(photoPreviewTransformerActions.setLoadingAITransformationStatus(false))
    dispatch(photoPreviewTransformerActions.setUploadLoading(false))
    dispatch(photoPreviewTransformerActions.setPreviewTransformerOpen(false))
  }

  return (
    <Content>
      <ErrorImage src={formatImgUrl("/assets/placeholders-images-black/internet-connection.svg")}
        alt="Error"
      />
      
      <Title>
        {t('l_error_dataLoadTitile')}
      </Title>

      <Description>
        {t('l_error_dataLoadMsg')}
      </Description>

      <StyledPrimaryButton
        actionName={t('a_photos_backToOriginal')}
        onAction={handleAction}
      />
    </Content>
  )
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 100px;
  text-align: center;
  overflow-y: auto;
`

const ErrorImage = styled.img`
  width: 240px;
  height: 240px;
  margin-bottom: 24px;
`

const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--transformer-text-primary);
  margin-bottom: 12px;
`

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: var(--transformer-text-primary);
  margin-bottom: 40px;
  max-width: 520px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: var(--transformer-button-primary-default);
  color: var(--transformer-button-primary-text);
  padding: 12px 24px;

  :hover {
    background-color: var(--transformer-button-primary-hover);
  }
`