import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { Album } from "features/albums/album/Album"
import { useNavigate, useParams } from "react-router-dom"
import {
  albumActions,
  fetchAlbumDataThunk,
  loadAlbumItemsFirstTimeThunk,
  subscribeAlbumToWSThunk,
  unsubscribeAlbumFromWSThunk
} from "features/albums/album/albumSlice"
import {
  getAlbumDataLoadingStatusSelector,
  getAlbumDataSelector,
  getAlbumItemsLoadingStatusSelector,
  getAlbumSelectedItemsCountSelector,
  getAlbumTotalItemsCountSelector,
} from "features/albums/album/selectors"
import { getAlbumDateStr } from "features/albums/utils/albumDate"
import { SDK_TYPES } from "sdk/sdkConstants"
import { AlbumPageTopbar, PhotosGrid } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { appActions } from "store/app"
import { albumsActions } from "features/albums/albums-list/albumsSlice"
import { timelineActions } from "features/photo/timeline/timelineSlice"
import { photoPreviewActions } from "features/photo/photo-preview/photoPreviewSlice"


const MemoryAlbumPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams<{ id?: string }>()

  const albumDataLoadingStatus = getAlbumDataLoadingStatusSelector()
  const selectedItemsCount = getAlbumSelectedItemsCountSelector()
  const albumData = getAlbumDataSelector()
  const status = getAlbumItemsLoadingStatusSelector()
  const totalItemsCount = getAlbumTotalItemsCountSelector()

  const isMobile = useMobileDetection()

  usePageTitle('l_albums_memories')

  useEffect(() => {
    dispatch(appActions.setIsLeftSidebarVisible(false))
    dispatch(albumActions.setCurrentAlbumId(id))
    dispatch(albumsActions.setCurrentAlbumsType(SDK_TYPES.DEFAULT))
    dispatch(albumActions.setCurrentAlbumsType(SDK_TYPES.DEFAULT))
    dispatch(timelineActions.setCurrentTimelineType(SDK_TYPES.DEFAULT))
    dispatch(subscribeAlbumToWSThunk())
    dispatch(fetchAlbumDataThunk())
    dispatch(loadAlbumItemsFirstTimeThunk())

    return () => {
      dispatch(unsubscribeAlbumFromWSThunk())
      dispatch(appActions.setIsLeftSidebarVisible(true))
      dispatch(albumActions.resetState())
      dispatch(photoPreviewActions.resetState())
    }
  }, [id])

  useEffect(() => {
    if (status === LOADING_STATUSES.FAILED) {
      navigate('/photos/albums')
    }
  }, [status])

  const handleCancelSelection = useCallback(() => {
    dispatch(albumActions.unselectAll())
  }, [])

  const handleBack = () => {
    navigate(`/photos/memories/${id}/modal`, { replace: true })
  }

  const getTexts = () => ({
    dates: getAlbumDateStr(albumData),
    photosCount: !!totalItemsCount ? t('l_common_countElements', { number: totalItemsCount }) : ''
  })

  const handleAddPhotos = () => {
    navigate(`/photos/memories/${albumData.id}/add`, { state: { from: `${window.location.pathname}${window.location.search}` } })

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  return (
    <>
      {
        albumDataLoadingStatus !== LOADING_STATUSES.LOADING && (
          <AlbumPageTopbar
            title={albumData.description}
            selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
            selectedItemsCount={selectedItemsCount}
            placeholder={isMobile ? t('l_albums_enterAlbumNameShort') : t('l_albums_enterAlbumName')}
            isEditing={false}
            onCancelSelection={handleCancelSelection}
            onStartEdit={() => null}
            onFinishEdit={() => null}
            onBack={handleBack}
            texts={getTexts()}
            badgeText={albumData.hide_items ? t('l_hidden_masked') : ''}
          />
        )}

      {
        status !== LOADING_STATUSES.LOADING && albumDataLoadingStatus !== LOADING_STATUSES.LOADING && (
          <Album
            id={id}
            type={SDK_TYPES.DEFAULT}
            isDropZoneDisabled={false}
            isMemory
          />
        )
      }

      {
        (status === LOADING_STATUSES.LOADING || albumDataLoadingStatus === LOADING_STATUSES.LOADING) &&
        <Skeleton />
      }

      <MobileToolbarMenuToggle
        iconName={!selectedItemsCount ? 'plus' : 'more_horiz'}
        onClick={!selectedItemsCount ? handleAddPhotos : undefined}
      />

      {/* <MobileToolbarMenuToggle /> */}
    </>
  )
}

const Skeleton = () => {
  return (
    <>
      <PageTopbarSkeleton />

      <PhotosGrid>
        {Array.from({ length: 12 }).map((_, index) => {
          return (
            <SkeletonCard key={index} />
          )
        })}
      </PhotosGrid>
    </>
  )
}

const SkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

const PageTopbarSkeleton = styled.div`
  margin-top: 20px;
  margin-bottom: 18px;
  background: var(--background-tertiary);
  height: 58px;
  width: 300px;
`

export default MemoryAlbumPage
