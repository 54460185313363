import { useEffect } from "react"

import { ModalDialog, ModalHeader,SpriteIcon } from "@cloudike/web_ui_components"
import styled from 'styled-components'
import { getTags } from "features/photo/photo-preview/selectors"
import { photoPreviewActions } from "features/photo/photo-preview/photoPreviewSlice"

import { useAppDispatch } from "../../../store"
import { appActions } from "../../../store/app"

export const InfoTagModal = ({ handleCloseModal, isOpened }) => {
  const parentBlock = document.getElementsByTagName('body')[0]
  const dispatch = useAppDispatch()
  const tags = getTags()
  const modelVersion = tags.model_version
  const rawTags = tags.raw_tags

  useEffect(() => {
    return () => {
      dispatch(photoPreviewActions.clearTags())
    }
  },[])

  const handleVisibleModal = (value:boolean) => {
    dispatch(appActions.setVisibleModal(value))
  }

  return(
    <ModalDialog
      setIsModalVisible={handleVisibleModal}
      header={(
        <StyledHeaderBox>
          <StyledModalHeader title={`TAGS Model Version:${modelVersion}`} />

          <StyledHeaderCloseButton iconName="close"
            onClick={handleCloseModal}
          />
        </StyledHeaderBox>
      )}
      footer={<></>}
      parentBlock={parentBlock}
      isOpened={isOpened}
      onCloseModal={handleCloseModal}
    >
      <InfoText>
        {rawTags.length === 0 ? (
          <strong>
            No Tags
          </strong>
        ) : (
          rawTags.map((tag, index) => (
            <TegBox key={index}>
              {`Tag ID: ${tag.tag_id}`}

              <br />

              {`Confidence: ${tag.confidence}`}
            </TegBox>
          ))
        )}
      </InfoText>
    </ModalDialog>
  )
}

//пустой массив тегов обработать

const TegBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;

`

const InfoText = styled.div`
  margin-bottom: 24px;
  height: 500px; 
  overflow-y: auto;
  margin-top: 20px;
  gap: 10px;
`

const StyledHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
`

const StyledHeaderCloseButton = styled(SpriteIcon)`
  color: var(--icon-secondary);
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
`

const StyledModalHeader = styled(ModalHeader)`
  align-items: center;
`
