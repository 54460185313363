import { useEffect } from "react"

export const useKeyDownTransformer = (keyCode, handler, isVisibleComponent) => {
  useEffect(() => {
    const listener = (event) => {

      if (event.keyCode === keyCode && isVisibleComponent) {
        event.stopPropagation()
        event.preventDefault()
        handler()
      }
    }

    window.addEventListener('keydown', listener, true)

    return () => {
      window.removeEventListener('keydown', listener, true)
    }
  }, [keyCode, handler, isVisibleComponent])
}