import React from 'react'

import { useTranslation } from "react-i18next"
import styled from 'styled-components'

const BetaVersionNotice = () => {
  const { t } = useTranslation()

  return (
    <BetaVersionNoticeBox>
      <NoticeText>
        {t('l_common_desktopTestMode')}
      </NoticeText>
    </BetaVersionNoticeBox>
  )
}

const BetaVersionNoticeBox = styled.div`
  margin: 12px 16px 0 16px;
`

const NoticeText = styled.span`
  font-size: 12px;
  color: var(--text-secondary);
  font-weight: 400;
`

export default BetaVersionNotice