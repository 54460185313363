import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IExtendedAlbumSchema } from '../albums-list/albumsSlice'

import { AlbumsMemoriesCard } from './AlbumsMemoriesCard'
import { AlbumsMemoriesGrid } from "./AlbumsMemoriesGrid"

interface AlbumsMemoriesListProps {
  className?: string
  countAlbums: number
  albums: IExtendedAlbumSchema[]
  onCardClick: (album: IExtendedAlbumSchema) => void
  onSelect?: (id: string, isSelected: boolean) => void
  cardsWithOnlyTitle?: boolean
  showFullName?: boolean
  selectedAlbumsIds?: string[]
  withOnlyOneRow?: boolean
}

export const AlbumsMemoriesList: React.FC<AlbumsMemoriesListProps> = 
({ className = '', countAlbums, albums, onCardClick, onSelect, cardsWithOnlyTitle = false, showFullName = false, selectedAlbumsIds = [], withOnlyOneRow = false }) => {
  const { t } = useTranslation()

  return (
    <>
      <AlbumsBox className={className}>
        <AlbumsMemoriesGrid withOnlyOneRow={withOnlyOneRow}>
          {
            albums.slice(0, countAlbums).map(album => (
              <AlbumsMemoriesCard
                key={album.id}
                id={album.id}
                albumName={album.description}
                albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
                description={album.description}
                onSelect={onSelect}
                isSelected={selectedAlbumsIds.includes(album.id)}
                imgUrls={album?._embedded?.cover_items}
                onClick={() => onCardClick(album)}
                onlyTitle={cardsWithOnlyTitle}
                showFullName={showFullName}
              />
            ))
          }
        </AlbumsMemoriesGrid>
      </AlbumsBox>
    </>
  )
}

const AlbumsBox = styled.div`
  width: 100%;
  padding-bottom: 40px;
`
