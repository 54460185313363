import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { PHOTOS_COUNT_PER_PAGE } from 'constants/pagination'
import { ACCEPTED_FILES_TYPES_FOR_DOCUMENTS } from 'constants/acceptedFileTypes'

import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { documentsWalletApi } from 'api/documentsWalletApi'
import { RootState } from 'store'
import imageCompression from 'browser-image-compression'
import { generateRandomHex } from 'utils/randomHex'
import { generateImageFromPdf, generatePngFromHeic, splitFilesByAccept } from 'utils/files'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { t } from 'i18next'
import { getDocumentsSdk } from 'sdk/documents'
import { goToSubscriptionsPage } from 'utils/subscriptions'

import { personsApi } from "../../api/personsApi"
import { getHighestLevelErrorByFieldName } from "../../utils/utils"
import { TOTAL_COUNT_HEADER } from "../../constants/headers"

import {
  DOCUMENT_ITEMS_ACTION_STATUSES,
  DocumentItem,
  DOCUMENTS_WALLET_STATUSES,
  DocumentTypeWithPreview,
  EnhancedDocumentItem,
  PreviewSize,
  UserDocumentsWalletSettings
} from './types'
import { personsActions, personsItemsSelectors } from "./persons/personsSlice"

const argon2 = require('argon2-browser')
const aesjs = require('aes-js')

export const sessionStorageKeys = {
  USER_SETTING: 'userSetting',
  MASTER_KEY: 'masterKey',
  ALGORITHMS: 'algorithms',
  PREVIEW_SIZE: 'previewSizes',
  TYPES: 'types'
}

const clearSessionStorageDocuments = () => {
  Object.values(sessionStorageKeys).forEach((item) => sessionStorage.removeItem(item))
}

const documentItemsAdapter = createEntityAdapter<EnhancedDocumentItem>()

export const documentItemsSelectors = documentItemsAdapter.getSelectors()

const defaultIv = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
const ACCESS_TIME_IN_MINUTES = 10

let accessTimeout = null

interface State {
  settings: UserDocumentsWalletSettings,
  algorithms: any[],
  previewSizes: PreviewSize[],
  types: DocumentTypeWithPreview[],
  status: DOCUMENTS_WALLET_STATUSES,
  masterKey: string,
  signInErrorData: {
    error: string,
    seconds: number | null
  }

  documentItems: ReturnType<typeof documentItemsAdapter.getInitialState>,
  selectedDocumentItemsIds: string[],
  documentItemsLoadingStatus: LOADING_STATUSES,
  documentItemsActionsStatus: DOCUMENT_ITEMS_ACTION_STATUSES,
  currentDocumentTypeId: string
  notificationModalText: string

  typeModal: string,
  isShowCreateModal: boolean,
  isDocumentNameEditing: boolean,
  totalItemsCountDocument: number,
  isDocumentTypesLoadingDuringPersonChange: boolean
}

export const generateDocumentFileUrl = async (document, masterKeyHex, size = 'content', abortController = undefined) => {
  const masterKeyBytes = aesjs.utils.hex.toBytes(masterKeyHex)
  const encryptedDocumentKey = document.key
  const encryptedDocumentKeyBytes = aesjs.utils.hex.toBytes(encryptedDocumentKey)

  const ivBytes = aesjs.utils.hex.toBytes(document.iv)

  const href = document._links[size].href

  const filePreviewResponse = await fetch(href, { signal: abortController?.signal })
  const fileData = await filePreviewResponse.blob()
  const fileBuffer = await fileData.arrayBuffer()
  const fileBytesArray = new Uint8Array(fileBuffer)

  const documentKeyAesCtr = new aesjs.ModeOfOperation.ctr(masterKeyBytes, ivBytes)
  const decryptedDocumentKeyBytes = documentKeyAesCtr.decrypt(encryptedDocumentKeyBytes)

  const fileAesCtr = new aesjs.ModeOfOperation.ctr(decryptedDocumentKeyBytes, ivBytes)
  const decryptedFileBytes = fileAesCtr.decrypt(fileBytesArray)

  const metaData = document.metaData || {}

  const decryptedFile = new File([new Blob([decryptedFileBytes])], metaData?.name || '', metaData)

  return URL.createObjectURL(decryptedFile)
}

const decryptMetaData = async (encryptedMetaData, encryptedDocumentKey, masterKeyHex, iv) => {
  if (!encryptedMetaData) {
    return {}
  }

  const encryptedMetaDataBytes = aesjs.utils.hex.toBytes(encryptedMetaData)

  const masterKeyBytes = aesjs.utils.hex.toBytes(masterKeyHex)
  const encryptedDocumentKeyBytes = aesjs.utils.hex.toBytes(encryptedDocumentKey)

  const ivBytes = aesjs.utils.hex.toBytes(iv)

  const documentKeyAesCtr = new aesjs.ModeOfOperation.ctr(masterKeyBytes, ivBytes)
  const decryptedDocumentKeyBytes = documentKeyAesCtr.decrypt(encryptedDocumentKeyBytes)

  const metaDataAesCtr = new aesjs.ModeOfOperation.ctr(decryptedDocumentKeyBytes, ivBytes)
  const decryptedMetaDataBytes = metaDataAesCtr.decrypt(encryptedMetaDataBytes)
  const decryptedMetaDataJson = aesjs.utils.utf8.fromBytes(decryptedMetaDataBytes)

  return JSON.parse(decryptedMetaDataJson)
}

export const resetDocumentsAccessTimerThunk = createAsyncThunk(
  'documents/resetDocumentsAccessTimerThunk',
  async function(_, { dispatch }) {
    clearTimeout(accessTimeout)

    accessTimeout = setTimeout(() => {
      clearSessionStorageDocuments()
      dispatch(actions.resetUserState())
      dispatch(actions.logoutFromDocuments())
    }, 1000 * 60 * ACCESS_TIME_IN_MINUTES)
  }
)

export const fetchDocumentsUserSettingsThunk = createAsyncThunk(
  'documents/fetchDocumentsUserSettingsThunk',
  async function(_, { getState, dispatch }) {
    const state = getState() as RootState
    const userId = state.user.userData.id

    try {
      const response = await documentsWalletApi.getUserSettings(userId)

      sessionStorage.setItem(sessionStorageKeys.USER_SETTING, JSON.stringify(response))
      dispatch(actions.setUserSettings(response))

      if (response.is_enabled) {
        dispatch(actions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.SIGN_IN))
      } else {
        dispatch(actions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.CREATION))
      }
      return response
    } catch (error) {
      console.log(error)
    }
  }
)

export const fetchCryptoAlgorithmsThunk = createAsyncThunk(
  'documents/fetchCryptoAlgorithmsThunk',
  async function(_, { dispatch }) {
    try {
      const response = await documentsWalletApi.getCryptoAlgorithms()

      sessionStorage.setItem(sessionStorageKeys.ALGORITHMS, JSON.stringify(response._embedded.algorithms))
      dispatch(actions.setAlgorithms(response._embedded.algorithms))
    } catch (error) {

    }
  }
)

export const fetchPreviewSizesThunk = createAsyncThunk(
  'documents/fetchPreviewSizesThunk',
  async function(_, { dispatch }) {
    try {
      const response = await documentsWalletApi.getPreviewSizes()

      sessionStorage.setItem(sessionStorageKeys.PREVIEW_SIZE, JSON.stringify(response.sizes))
      dispatch(actions.setPreviewSizes(response.sizes))
    } catch (error) {

    }
  }
)

export const deleteDocumentsWalletThunk = createAsyncThunk(
  'documents/deleteDocumentsWalletThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id

    try {
      dispatch(actions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.DELETING_IN_PROGESS))
      await documentsWalletApi.deleteDocumentsWallet(userId)
      dispatch(actions.resetUserState())
      dispatch(personsActions.resetPersonsState())
      clearSessionStorageDocuments()
      clearTimeout(accessTimeout)

    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    }
  }
)

export const createDocumentsWalletPasswordThunk = createAsyncThunk(
  'documents/createDocumentsWalletPasswordThunk',
  async function({ password }: { password: string}, { dispatch, getState }) {
    const state = getState() as RootState
    const { id: userId, lang } = state.user.userData
    const algorithms = state.documents.algorithms
    const settings = state.documents.settings

    const { payload } = await dispatch(fetchDocumentsUserSettingsThunk())

    if(!!payload.is_enabled) {
      return showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_DWcreated')
      })
    }

    dispatch(actions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.CREATION_IN_PROGRESS))

    try {
      const { p, m, t: time, l } = algorithms[0]?.params || {}
      const { name } = algorithms[1]

      const masterKeyCrypto = await crypto.subtle.generateKey({
        name: 'AES-CTR',
        length: 128,
      }, true, ['encrypt', 'decrypt'])

      const exportedMasterKey = await crypto.subtle.exportKey('raw', masterKeyCrypto)
      const exportedMasterKeyBytes = new Uint8Array(exportedMasterKey)

      const { hash } = await argon2.hash({ pass: password, salt: settings.salt, hashLen: l, mem: m, parallelism: p, time, type: argon2.ArgonType.Argon2id })

      const firstHalfOfHash = hash.slice(0, 16)
      const secondHalfOfHash = hash.slice(16)

      const aesCtr = new aesjs.ModeOfOperation.ctr(firstHalfOfHash, defaultIv)
      const encryptedPassword = aesCtr.encrypt(exportedMasterKeyBytes)

      const encryptedMasterKeyHex = aesjs.utils.hex.fromBytes(encryptedPassword)
      const secondHalfOfHashHex = aesjs.utils.hex.fromBytes(secondHalfOfHash)

      await documentsWalletApi.setPassword({ userId: userId, data: {
        keys: [
          {
            alg_name: name,
            encrypted_key: encryptedMasterKeyHex
          }
        ],
        validation_token: secondHalfOfHashHex
      }
      })

      const masterKeyHex = aesjs.utils.hex.fromBytes(exportedMasterKeyBytes)

      const documentTypes = await documentsWalletApi.getDocumentTypes(userId, lang)

      sessionStorage.setItem(sessionStorageKeys.MASTER_KEY, JSON.stringify(masterKeyHex))
      dispatch(actions.setMasterKey(masterKeyHex))

      sessionStorage.setItem(sessionStorageKeys.TYPES, JSON.stringify(documentTypes._embedded.types))
      dispatch(actions.setTypes(documentTypes._embedded.types))
      dispatch(actions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADED))
      dispatch(resetDocumentsAccessTimerThunk())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_DWcreated')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    }
  }
)

export const createDocumentTypeThunk = createAsyncThunk(
  'documents/createDocumentTypeThunk',
  async function({ name, callback } : { name: string, callback: (id) => void  }, { dispatch, getState }) {

    dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.CREATING))

    try {
      const state = getState() as RootState
      const { id: userId } = state.user.userData
      const persons = personsItemsSelectors.selectAll(state.persons)
      const selectPerson = persons.find(item => item.isSelect === true)
      const personId = selectPerson?.id !== 'owner' ? selectPerson?.id : ''

      const response = !!personId
        ? await personsApi.createPersonDocumentType(userId, personId, name)
        : await documentsWalletApi.createDocumentType(userId, name)

      dispatch(actions.addType({ ...response }))

      callback(response.id)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_docsFolderCreated'),
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.IDLE))
    }
  }
)

export const removeDocumentTypeThunk = createAsyncThunk(
  'documents/removeDocumentTypeThunk',
  async function({ items, callback } : { items: any[], callback? : () => void } , { dispatch, getState }) {
    const ids = items.map(item => item.id)
    dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.REMOVING))

    try {
      const state = getState() as RootState
      const { id: userId } = state.user.userData
      const persons = personsItemsSelectors.selectAll(state.persons)
      const selectPerson = persons.find(item => item.isSelect === true)
      const personId = selectPerson?.id !== 'owner' ? selectPerson?.id : ''

      await Promise.all(ids.map( id => !!personId
        ? personsApi.removePersonDocumentType(userId, personId, id )
        : documentsWalletApi.removeDocumentType(userId, id)))

      !!callback && callback()

      dispatch(actions.removeTypes(ids))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_docsFolderDeleted', { number : ids.length }),
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.IDLE))
      dispatch(actions.unselectAllTypes())
    }
  }
)

export const renameDocumentTypeThunk = createAsyncThunk(
  'documents/renameDocumentTypeThunk',
  async function( { id, name }: { id: string, name: string}, { dispatch, getState }) {
    dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.RENAMING))

    try {
      const state = getState() as RootState
      const { id: userId } = state.user.userData
      const persons = personsItemsSelectors.selectAll(state.persons)
      const selectPerson = persons.find(item => item.isSelect === true)
      const personId = selectPerson?.id !== 'owner' ? selectPerson?.id : ''

      const response =  !!personId
        ? await personsApi.renamePersonDocumentType(userId, personId, id, name )
        : await documentsWalletApi.renameDocumentType(userId, id, name)

      dispatch(actions.updateType(response))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_docsFolderRenamed'),
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.IDLE))
      dispatch(actions.unselectAllTypes())
    }
  }
)

export const signInDocumentsWalletThunk = createAsyncThunk(
  'documents/signInDocumentsWalletThunk',
  async function({ password }: { password: string}, { dispatch, getState }) {
    const state = getState() as RootState
    const { id: userId, lang } = state.user.userData
    const algorithms = state.documents.algorithms
    const settings = state.documents.settings

    try {
      const { p, m, t: time, l } = algorithms[0]?.params || {}

      const { hash } = await argon2.hash({ pass: password, salt: settings.salt, hashLen: l, mem: m, parallelism: p, time, type: argon2.ArgonType.Argon2id })

      const firstHalfOfHash = hash.slice(0, 16)
      const secondHalfOfHash = hash.slice(16)

      const secondHalfOfHashHex = aesjs.utils.hex.fromBytes(secondHalfOfHash)

      const response = await documentsWalletApi.checkPassword({ userId, token: secondHalfOfHashHex })

      const encryptedMasterKey = response.keys[0].encrypted_key
      const masterKeyBytes = aesjs.utils.hex.toBytes(encryptedMasterKey)

      const aesCtr = new aesjs.ModeOfOperation.ctr(firstHalfOfHash, defaultIv)
      const decryptedMasterKeyBytes = aesCtr.decrypt(masterKeyBytes)
      const masterKeyHex = aesjs.utils.hex.fromBytes(decryptedMasterKeyBytes)

      sessionStorage.setItem(sessionStorageKeys.MASTER_KEY, JSON.stringify(masterKeyHex))
      dispatch(actions.setMasterKey(masterKeyHex))
      dispatch(actions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADING))

      const documentTypesResponse = await documentsWalletApi.getDocumentTypes(userId, lang)
      const types = documentTypesResponse._embedded.types

      for (const type of types) {
        const coverDocument = type?._embedded?.cover_document

        if (coverDocument) {
          type.previewUrl = await generateDocumentFileUrl(coverDocument, masterKeyHex, 'middle')
        }
      }

      sessionStorage.setItem(sessionStorageKeys.TYPES, JSON.stringify(types))
      dispatch(actions.setTypes(types))
      dispatch(actions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADED))
      dispatch(resetDocumentsAccessTimerThunk())
    } catch (error) {
      if (error?.code === 'WrongValidationToken') {
        dispatch(actions.setSignInErrorData({
          seconds: null,
          error: t('l_notification_incorrectPassword')
        }))
      }

      if (error?.code === 'TooManyIncorrectAttempts') {
        dispatch(actions.setSignInErrorData({
          seconds: error?.data?.detail?.retry_after,
          error: t('l_notification_incorrectPassword')
        }))
      }
    }
  }
)

export const uploadDocumentWalletFilesThunk = createAsyncThunk(
  'documents/uploadDocumentWalletFilesThunk',
  async function({ files, id, callback }: { files: FileList, id: string, callback?: () => void }, { dispatch, getState }) {
    dispatch(resetDocumentsAccessTimerThunk())

    const state = getState() as RootState
    const algorithms = state.documents.algorithms
    const masterKey = state.documents.masterKey
    const previewSizes = state.documents.previewSizes
    const types = state.documents.types
    const persons = personsItemsSelectors.selectAll(state.persons)

    const selectPerson = persons.find(item => item.isSelect === true)
    const personId = selectPerson?.id !== 'owner' ? selectPerson?.id : ''

    const type = types.find(t => t.id === id)

    const { name: algName } = algorithms[1]

    const { acceptedFiles, rejectedFiles } = splitFilesByAccept(files, ACCEPTED_FILES_TYPES_FOR_DOCUMENTS, state.user.maxPhotoSize)

    rejectedFiles.forEach(file => {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_fileNotUploaded', { FILE_NAME: file.name })
      })
    })

    if (!acceptedFiles.length) {
      return
    }

    dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.UPLOADING))

    try {
      await Promise.all(Array.from(acceptedFiles).map(async (file) => {
        const operationId = generateRandomHex(24)
        const iv = generateRandomHex(32)

        const ivBytes = aesjs.utils.hex.toBytes(iv)

        //document key generating and encrypting
        const documentKeyCrypto = await crypto.subtle.generateKey({
          name: 'AES-CTR',
          length: 128,
        }, true, ['encrypt', 'decrypt'])

        const documentKey = await crypto.subtle.exportKey('raw', documentKeyCrypto)
        const documentKeyBytes = new Uint8Array(documentKey)
        const masterKeyBytes = aesjs.utils.hex.toBytes(masterKey)

        const documentAesCtr = new aesjs.ModeOfOperation.ctr(masterKeyBytes, ivBytes)
        const encryptedDocumentKey = documentAesCtr.encrypt(documentKeyBytes)
        const encryptedDocumentKeyHex = aesjs.utils.hex.fromBytes(encryptedDocumentKey)

        //file meta data encrypting
        const fileMetaData = {
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified
        }

        const fileMetaDataJson = JSON.stringify(fileMetaData)
        const fileMetaDataJsonBytes = aesjs.utils.utf8.toBytes(fileMetaDataJson)

        const metaDataAesCtr = new aesjs.ModeOfOperation.ctr(documentKeyBytes, ivBytes)
        const encryptedFileMetaData = metaDataAesCtr.encrypt(fileMetaDataJsonBytes)
        const encryptedFileMetaDataHex = aesjs.utils.hex.fromBytes(encryptedFileMetaData)

        //file encrypting
        const fileBuffer = await file.arrayBuffer()
        const fileBytesArray = new Uint8Array(fileBuffer)

        const fileAesCtr = new aesjs.ModeOfOperation.ctr(documentKeyBytes, ivBytes)
        const encryptedFileBytes = fileAesCtr.encrypt(fileBytesArray)

        let fileForPreview = file

        if (fileMetaData.type === 'application/pdf') {
          fileForPreview = await generateImageFromPdf(fileBytesArray)
        }

        if (fileMetaData.type === 'image/heic') {
          fileForPreview = await generatePngFromHeic(fileBytesArray)
        }

        const previewFiles = await Promise.all(previewSizes.map(size => {
          return imageCompression(fileForPreview, {
            maxWidthOrHeight: size.width
          })
        }))

        const encryptedFile = new File([new Blob([encryptedFileBytes])], '')

        const sdk = getDocumentsSdk()

        const uploadMainFile = async () => {
          return await sdk.uploadFile({
            data: encryptedFile,
            size: encryptedFile.size,
            type: encryptedFile.type,
            file_name: encryptedFile.name,
            name: encryptedFile.name
          }, true, {
            operation_id: operationId,
            source_size: fileMetaData.size,
            key: encryptedDocumentKeyHex,
            key_alg: algName,
            iv,
            meta_data: {
              client_data: {
                meta: encryptedFileMetaDataHex
              }
            }
          } as any,
          { type_id: type.id, person_id: personId }
          )
        }

        const uploadPreviews = () => {
          return previewFiles.map(async (blob, index) => {
            const file = new File([blob], fileMetaData.name)

            const fileBuffer = await file.arrayBuffer()
            const fileBytesArray = new Uint8Array(fileBuffer)

            const fileAesCtr = new aesjs.ModeOfOperation.ctr(documentKeyBytes, ivBytes)
            const encryptedFileBytes = fileAesCtr.encrypt(fileBytesArray)
            const encryptedFile = new File([new Blob([encryptedFileBytes])], '')

            return await sdk.uploadFile({
              data: encryptedFile,
              size: encryptedFile.size,
              type: encryptedFile.type,
              file_name: encryptedFile.name,
              name: encryptedFile.name
            }, true, {
              operation_id: operationId,
              preview_size_name: previewSizes[index].size_name,
              iv
            } as any,
            { type_id: type.id, person_id: personId }
            )
          })
        }

        const responses = await Promise.all([uploadMainFile(), ...uploadPreviews()]) as { _embedded?: { built_document: DocumentItem } }[]
        const responseWithFileData = responses.find(response => !!response._embedded)

        const previewUrl = URL.createObjectURL(previewFiles[1])

        dispatch(actions.addItem({
          ...responseWithFileData._embedded.built_document,
          previewUrl,
          type: fileMetaData.type === 'application/pdf' ? 'pdf' : 'image',
          metaData: fileMetaData
        }))

        dispatch(actions.increaseTypeDocumentsCount(type.id))

        if (!type.previewUrl) {
          dispatch(actions.updateType({ id: type.id, previewUrl }))
        }

        if (callback) {
          callback()
        }

        dispatch(fetchHeaderDocumentItems({ typeId: type.id }))
      }))
    } catch (error) {
      const code = getHighestLevelErrorByFieldName(error, 'code')

      if (code === 'SizeQuotaExceeded') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          isPermanent: true,
          message: t('l_notification_uploadFilesError'),
          title: t('l_notification_spaceError'),
          typeError: 'SizeQuotaExceeded',
          callback: () => {
            goToSubscriptionsPage()
          }
        })

        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.IDLE))
    }
  }
)

export const downloadSelectedDocumentItems = createAsyncThunk(
  'documents/downloadSelectedDocumentItems',
  async function({ items } : { items: EnhancedDocumentItem[] }, { dispatch, getState }) {
    dispatch(resetDocumentsAccessTimerThunk())

    const state = getState() as RootState
    const masterKey = state.documents.masterKey

    dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.DOWNLOADING))

    try {
      await Promise.all(items.map(async item => {
        const href = await generateDocumentFileUrl(item, masterKey)

        const link = document.createElement('a')
        link.href = href
        link.download = item?.metaData?.name || ''
        link.click()
      }))
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.IDLE))
      dispatch(actions.unselectAll())
    }
  }
)

export const fetchHeaderDocumentItems = createAsyncThunk(
  'documents/fetchDocumentItems',
  async function({ typeId } : { typeId: string }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id
    const type = state.documents.types.find(t => t.id === typeId)
    const personId = !!type.person_id ? type.person_id : ''

    try {
      const response = personId ? await personsApi.getHeaderPersonDocumentItems({ userId, personId, typeId })
        : await documentsWalletApi.getHeaderDocumentItems({ userId, typeId })

      dispatch(actions.setDocumentItemsLoadingStatus(LOADING_STATUSES.SUCCEEDED))
      dispatch(actions.setTotalItemsCountDocument(response.headers[TOTAL_COUNT_HEADER]))

    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    }
  }
)

export const fetchDocumentItems = createAsyncThunk(
  'documents/fetchDocumentItems',
  async function({ typeId } : { typeId: string }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id
    const masterKey = state.documents.masterKey
    const type = state.documents.types.find(t => t.id === typeId)
    const personId = !!type.person_id ? type.person_id : ''

    try {
      const response = personId ? await personsApi.getPersonDocumentItems({ userId, personId, typeId, offset: 0, limit: PHOTOS_COUNT_PER_PAGE })
        : await documentsWalletApi.getDocumentItems({ userId, typeId, offset: 0, limit: PHOTOS_COUNT_PER_PAGE })

      const items = response._embedded.documents

      for (const item of items) {
        item.metaData = await decryptMetaData(item.client_data?.meta, item.key, masterKey, item.iv)
        item.type = item.metaData?.type === 'application/pdf' ? 'pdf' : 'image'
      }

      dispatch(actions.setAllItems(items))
      dispatch(actions.setDocumentItemsLoadingStatus(LOADING_STATUSES.SUCCEEDED))
      dispatch(actions.setTotalItemsCountDocument(response.headers[TOTAL_COUNT_HEADER]))

      items.forEach(async item => {
        const previewUrl = await generateDocumentFileUrl(item, masterKey, 'middle')

        dispatch(actions.updateItem({ ...item, previewUrl }))
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    }
  }
)

export const fetchMoreDocumentItems = createAsyncThunk(
  'documents/fetchMoreDocumentItems',
  async function({ typeId, offset } : { typeId: string, offset: number }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id
    const masterKey = state.documents.masterKey
    const type = state.documents.types.find(t => t.id === typeId)
    const personId = !!type.person_id ? type.person_id : ''

    try {
      const response = personId ? await personsApi.getPersonDocumentItems({ userId, personId, typeId, offset, limit: PHOTOS_COUNT_PER_PAGE })
        : await documentsWalletApi.getDocumentItems({ userId, typeId, offset, limit: PHOTOS_COUNT_PER_PAGE })

      const items = response._embedded.documents

      for (const item of items) {
        item.metaData = await decryptMetaData(item.client_data?.meta, item.key, masterKey, item.iv)
        item.type = item.metaData?.type === 'application/pdf' ? 'pdf' : 'image'
      }

      dispatch(actions.addItems(items))

      items.forEach(async item => {
        const previewUrl = await generateDocumentFileUrl(item, masterKey, 'middle')

        dispatch(actions.updateItem({ ...item, previewUrl }))
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    }
  }
)

export const deleteDocumentItems = createAsyncThunk(
  'documents/deleteDocumentItems',
  async function({ itemsIds } : { itemsIds: string[] }, { dispatch, getState }) {
    dispatch(resetDocumentsAccessTimerThunk())

    const state = getState() as RootState
    const userId = state.user.userData.id
    const types = state.documents.types
    const typeId = state.documents.currentDocumentTypeId
    const type = types.find(t => t.id === typeId)
    const personId = !!type.person_id ? type.person_id : ''

    dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.REMOVING))

    try {
      await Promise.all(itemsIds.map(async id => {
        personId ? await personsApi.deletePersonDocumentItem({ userId, personId, typeId: type.id, id })
          : await documentsWalletApi.deleteDocumentItem({ userId, typeId: type.id, id })
      }))

      dispatch(actions.deleteItems(itemsIds))
      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsDeletedforWeb', { number: itemsIds.length })
      })

      const newItemsCount = type.documents_count - itemsIds.length

      const newTypeData: Partial<DocumentTypeWithPreview> = {
        id: type.id,
        documents_count: newItemsCount
      }

      if (newItemsCount === 0) {
        newTypeData.previewUrl = null
      }

      dispatch(actions.updateType(newTypeData))


    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      dispatch(actions.setDocumentItemsActionStatus(DOCUMENT_ITEMS_ACTION_STATUSES.IDLE))
    }
  }
)

export const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    settings: !!sessionStorage.getItem(sessionStorageKeys.USER_SETTING) && JSON.parse(sessionStorage.getItem(sessionStorageKeys.USER_SETTING)),
    masterKey: !!sessionStorage.getItem(sessionStorageKeys.MASTER_KEY) && JSON.parse(sessionStorage.getItem(sessionStorageKeys.MASTER_KEY)),
    algorithms: !!sessionStorage.getItem(sessionStorageKeys.ALGORITHMS) && JSON.parse(sessionStorage.getItem(sessionStorageKeys.ALGORITHMS)),
    previewSizes: !!sessionStorage.getItem(sessionStorageKeys.PREVIEW_SIZE) && JSON.parse(sessionStorage.getItem(sessionStorageKeys.PREVIEW_SIZE)),
    types: !!sessionStorage.getItem(sessionStorageKeys.TYPES) && JSON.parse(sessionStorage.getItem(sessionStorageKeys.TYPES)) || [],
    status: DOCUMENTS_WALLET_STATUSES.FIRST_LOADING,
    signInErrorData: {
      error: '',
      seconds: null
    },

    documentItems: documentItemsAdapter.getInitialState(),
    selectedDocumentItemsIds: [],
    documentItemsLoadingStatus: LOADING_STATUSES.LOADING,
    documentItemsActionsStatus: DOCUMENT_ITEMS_ACTION_STATUSES.IDLE,
    currentDocumentTypeId: '',
    notificationModalText: '',

    typeModal: '',
    isShowCreateModal: false,
    isDocumentNameEditing: false,
    totalItemsCountDocument: 0,

    isDocumentTypesLoadingDuringPersonChange: false,
  } as State,
  reducers: {
    setUserSettings: (state, action) => {
      state.settings = action.payload
    },
    setAlgorithms: (state, action) => {
      state.algorithms = action.payload
    },
    setLoadingStatus: (state, action) => {
      state.status = action.payload
    },
    setSignInErrorData: (state, action) => {
      state.signInErrorData = action.payload
    },
    resetUserState: (state) => {
      state.status = DOCUMENTS_WALLET_STATUSES.FIRST_LOADING
      state.masterKey = null
    },
    setTypes: (state, action) => {
      state.types = [...action.payload.map((item) => {
        return { ...item, selected: false }
      })]
    },
    addType: (state, action) => {
      state.types = [...state.types, { ...action.payload, selected: false }]
    },
    removeTypes: (state, action) => {
      const array = state.types.map(item => {
        if (!action.payload.some(el => el === item.id)) return item
      }).filter(item => !!item)
      state.types = [...array]
    },
    setTypeModal: (state, action) => {
      state.typeModal = action.payload
    },
    setShowCreateModal: (state, action) => {
      state.isShowCreateModal = action.payload
    },
    updateType: (state, action) => {
      const indexOfType = state.types.findIndex(type => type.id === action.payload.id)

      state.types = [...state.types.slice(0, indexOfType), { ...state.types[indexOfType], ...action.payload }, ...state.types.slice(indexOfType + 1)]
    },
    increaseTypeDocumentsCount: (state, action) => {
      const indexOfType = state.types.findIndex(type => type.id === action.payload)

      state.types = [...state.types.slice(0, indexOfType), { ...state.types[indexOfType], documents_count: state.types[indexOfType].documents_count + 1 }, ...state.types.slice(indexOfType + 1)]
    },
    setPreviewSizes: (state, action) => {
      state.previewSizes = action.payload
    },
    setMasterKey: (state, action) => {
      state.masterKey = action.payload
    },

    setCurrentDocumentTypeId: (state, action) => {
      state.currentDocumentTypeId = action.payload
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedDocumentItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedDocumentItemsIds = [...state.selectedDocumentItemsIds, id]
      } else {
        state.selectedDocumentItemsIds = [...state.selectedDocumentItemsIds.slice(0, indexOfItemId), ...state.selectedDocumentItemsIds.slice(indexOfItemId + 1)]
      }
    },
    addItem: (state, action) => {
      const items = documentItemsSelectors.selectAll(state.documentItems)
      documentItemsAdapter.setAll(state.documentItems, [action.payload, ...items])
    },
    addItems: (state, action) => {
      documentItemsAdapter.addMany(state.documentItems, action.payload)
    },
    updateItem: (state, action) => {
      documentItemsAdapter.updateOne(state.documentItems, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    updateItemType: (state, action) => {
      const index = state.types.findIndex( item => item.id === action.payload.id)
      state.types[index].selected = action.payload.selected
    },
    unselectAllTypes: (state) => {
      state.types.forEach(item => item.selected = false)
    },
    toggleDocumentNameEditStatus: (state, action) => {
      state.isDocumentNameEditing = action.payload
    },
    setTotalItemsCountDocument: (state, action) => {
      state.totalItemsCountDocument = action.payload
    },
    setAllItems: (state, action) => {
      documentItemsAdapter.setAll(state.documentItems, action.payload)
    },
    unselectAll: (state) => {
      state.selectedDocumentItemsIds = []
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      documentItemsAdapter.removeMany(state.documentItems, action.payload)
    },
    setDocumentItemsLoadingStatus: (state, action) => {
      state.documentItemsLoadingStatus = action.payload
    },
    setDocumentItemsActionStatus: (state, action) => {
      state.documentItemsActionsStatus = action.payload
    },
    setNotificationModalText: (state, action) => {
      state.notificationModalText = action.payload
    },
    resetDocumentItemsState: (state) => {
      documentItemsAdapter.setAll(state.documentItems, [])
      state.selectedDocumentItemsIds = []
      state.documentItemsLoadingStatus = LOADING_STATUSES.LOADING
      state.currentDocumentTypeId = ''
    },
    logoutFromDocuments: (state) => {
      state.masterKey = null
      documentItemsAdapter.setAll(state.documentItems, [])
      state.selectedDocumentItemsIds = []
      state.documentItemsLoadingStatus = LOADING_STATUSES.LOADING
      state.currentDocumentTypeId = ''
      state.status = DOCUMENTS_WALLET_STATUSES.FIRST_LOADING

      state.settings = null
      state.algorithms = null
      state.previewSizes = null
      state.types = []
      state.isShowCreateModal = false
    },
    setIsDocumentTypesLoadingDuringPersonChange: (state, action) => {
      state.isDocumentTypesLoadingDuringPersonChange = action.payload
    }
  }
})

const {
  reducer, actions
} = documentsSlice

export { reducer as documentsReducer, actions as documentsActions }
