import { formatImgUrl } from 'constants/configs/theme.config'

import React, { useEffect, useMemo, useRef, useState } from 'react'

import styled from 'styled-components'
import { PrimaryButton, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { PasswordInput } from 'ui/PasswordInput'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useAppDispatch } from  "store"
import { DialogModal } from 'ui/DialogModal'
import { useTimer } from 'features/common/hooks/useTimer'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { deleteDocumentsWalletThunk, documentsActions, signInDocumentsWalletThunk } from './documentsSlice'
import { getDocumentsWalletSignInErrorDataSelector } from './selectors'
import { DOCUMENTS_SIGN_IN_PASSWORD_REGEX } from './constants'
import { DocumentsBlurredSkeleton } from './DocumentsBlurredSkeleton'

const generateErrorStr = ({ errorData, minutes, seconds, ms, t }) => {
  if (errorData.seconds && ms > 0) {
    return t('l_notification_tryAgainIn', { 0: `${minutes}:${seconds}` })
  }

  return errorData.error
}

export const SignInWallet = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const errorData = getDocumentsWalletSignInErrorDataSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [minLengthError, setMinLengthError] = useState(false)

  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    inputRef.current.focus()
  }, [])

  const {
    ms,
    isTimerActive,
    startTimer,
    formattedData
  } = useTimer(errorData?.seconds * 1000 || 0)

  useEffect(() => {
    if (ms > 0 && !isTimerActive) {
      startTimer()
    }
  }, [isTimerActive, ms])

  const handleOpenRemovingConfirmationModal = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_DOCUMENTS_RESTORE_ACCESS_CLICK)
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleSubmit = async (values) => {
    const password = values.password

    dispatch(signInDocumentsWalletThunk({ password }))
  }

  const handleKeyUp = handleSubmit => (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const passwordValidationSchema = useMemo(() => yup.object().shape({
    password: yup
      .string()
      .required(t('l_notification_invalidPassword'))
      .min(4, t('l_notification_incorrectPassword'))
      .max(64, t('l_notification_invalidPassword'))
      .matches(DOCUMENTS_SIGN_IN_PASSWORD_REGEX, t('l_notification_invalidPassword'))
  }), [])

  const handleDeleteWallet = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_DIALOG_DOCUMENTS_DELETE_WALLET_CLICK)
    dispatch(deleteDocumentsWalletThunk())
  }

  const resetErrors = (setFormikErrors) => {
    setFormikErrors({})

    if (errorData.seconds) {
      return
    }

    dispatch(documentsActions.setSignInErrorData({
      error: '',
      seconds: null
    }))
  }

  return (
    <>
      <DocumentsBlurredSkeleton />

      <CreateWalletBox className={className}>
        <Formik
          initialValues={{ password: '' }}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={passwordValidationSchema}
          validateOnMount={false}
        >
          {({ values, errors, handleChange, handleSubmit, setErrors }) => (
            <>
              <SecurityImg src={formatImgUrl("/assets/placeholders-images/security.svg")} />

              <Title>
                {t('l_dw_singin')}
              </Title>

              <StyledPasswordInput
                placeholder={t('l_common_enterPasswordField')}
                value={values.password}
                onChange={(e) => {
                  const { value } = e.target
                  resetErrors(setErrors)
                  handleChange(e)

                  setMinLengthError(value.length < 4)
                }}
                name="password"
                error={errors.password || generateErrorStr({
                  errorData,
                  ms,
                  t,
                  minutes: formattedData.minutes,
                  seconds: formattedData.seconds
                })}
                onKeyUp={handleKeyUp(handleSubmit)}
                ref={inputRef}
              />

              {(!!errorData.error || (!!errors.password && minLengthError)) && (
                <RestoreAccess onClick={handleOpenRemovingConfirmationModal}>
                  {t('a_common_restoreAccess')}
                </RestoreAccess>
              )}

              <SignInButton
                actionName={t('a_common_signIn')}
                onAction={handleSubmit}
                disabled={!values.password || (!!errors.password && !minLengthError) || ms > 0}
              />
            </>
          )}
        </Formik>

        <DialogModal
          isOpened={removingConfirmationModalOpened}
          title={t('l_dw_accessRecovery')}
          onClose={handleCloseRemovingConfirmationModal}
          okText={t('a_common_delete')}
          onOk={handleDeleteWallet}
          cancelText={t('a_common_cancel')}
          onCancel={handleCloseRemovingConfirmationModal}
          withLongButtons
        >
          <TextInModalBox>
            {t('l_dw_accessRecoveryMessage')}
          </TextInModalBox>
        </DialogModal>
      </CreateWalletBox>
    </>
  )
}

const CreateWalletBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0;
  position: relative;
  background: var(--background-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    width: 480px;
    min-height: 335px;
    border: 1px solid var(--divider-primary);
    border-radius: 4px;
    padding: 40px 40px 60px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  }
`

const SecurityImg = styled.img`
  width: 192px;
  height: 140px;
`

const Title = styled.p`
  margin-top: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--text-primary);
  text-align: center;
`

const SignInButton = styled(PrimaryButton)`
  margin-top: 40px;
  width: 100%;
`

const StyledPasswordInput = styled(PasswordInput)`
  margin-top: 16px;
`

const RestoreAccess = styled.a`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--accent-normal);
  width: 100%;
`

const TextInModalBox = styled.span`
  max-width: 450px;
  font-size: 14px;
`
