import React, { useCallback, useState, useRef, useEffect } from 'react'

import styled from 'styled-components'
import { Virtualizer } from '@tanstack/react-virtual'
import { IDigestMonth, IDigestYear } from 'utils/timelineDigests'

import { getTimelineDigestsSelector } from "./selectors"

interface DigestsScrollbarProps {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  className?: string;
  heightBeforeScroll?: number;
}

export const DigestsScrollbar: React.FC<DigestsScrollbarProps> = ({ virtualizer, className = '', heightBeforeScroll = 0 }) => {
  const digests = getTimelineDigestsSelector()

  const [tooltipText, setTooltipText] = useState('')
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [isDraggingTooltip, setIsDraggingTooltip] = useState(false)
  const [isHoveringScrollbar, setIsHoveringScrollbar] = useState(false)
  const [isHoveringTooltip, setIsHoveringTooltip] = useState(false)
  const scrollbarRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const [thumbPosition, setThumbPosition] = useState(0)
  const [touchStartY, setTouchStartY] = useState<number | null>(null)
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)

  const scrollbarVisibilityTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const showScrollbar = useCallback(() => {
    setIsScrollbarVisible(true)

    if (scrollbarVisibilityTimeoutRef.current) {
      clearTimeout(scrollbarVisibilityTimeoutRef.current)
    }

    scrollbarVisibilityTimeoutRef.current = setTimeout(() => {
      setIsScrollbarVisible(false)
    }, 1500)
  }, [])

  const getScrollInfo = useCallback(() => {
    const totalSize = virtualizer.getTotalSize()
    const scrollElement = virtualizer.options.getScrollElement()
    const viewportHeight = scrollElement?.clientHeight || 0

    const scrollableHeight = totalSize + heightBeforeScroll - viewportHeight
    const scrollbarHeight = scrollbarRef.current?.offsetHeight || 0
    const thumbHeight = thumbRef.current?.offsetHeight || 0
    const maxThumbPosition = scrollbarHeight - thumbHeight

    return {
      totalSize,
      viewportHeight,
      scrollableHeight,
      scrollbarHeight,
      thumbHeight,
      maxThumbPosition
    }
  }, [virtualizer])

  const updatePositions = useCallback(() => {
    const { scrollableHeight, maxThumbPosition } = getScrollInfo()
    const scrollOffset = virtualizer.scrollOffset

    if (scrollOffset < heightBeforeScroll) {
      setIsScrollbarVisible(false)

      if (thumbRef.current) {
        thumbRef.current.style.top = '0px'
      }
      setThumbPosition(0)

      if (!isHoveringScrollbar && !isDraggingTooltip) {
        updateTooltipPosition(0)
      }
      return
    }

    setIsScrollbarVisible(true)

    const adjustedScrollOffset = scrollOffset - heightBeforeScroll
    const adjustedScrollableHeight = scrollableHeight - heightBeforeScroll

    let scrollPercentage = (adjustedScrollOffset / adjustedScrollableHeight) * 100
    scrollPercentage = Math.min(scrollPercentage, 100)

    const newThumbPosition = (maxThumbPosition * scrollPercentage) / 100

    if (thumbRef.current) {
      thumbRef.current.style.top = `${newThumbPosition}px`
    }

    setThumbPosition(newThumbPosition)

    if (!isHoveringScrollbar && !isDraggingTooltip) {
      updateTooltipPosition(newThumbPosition)
    }

    showScrollbar()
  }, [virtualizer, isHoveringScrollbar, isDraggingTooltip, getScrollInfo, showScrollbar])

  useEffect(() => {
    const scrollElement = virtualizer.scrollElement

    if (scrollElement) {
      scrollElement.addEventListener('scroll', updatePositions)
      return () => scrollElement.removeEventListener('scroll', updatePositions)
    }
  }, [virtualizer, updatePositions])

  const updateTooltipContent = useCallback((position: number) => {
    const { maxThumbPosition } = getScrollInfo()
    const percentage = (position / maxThumbPosition) * 100

    let currentYear, currentMonth

    for (const digest of digests) {
      if (percentage >= digest.startPercent && percentage <= digest.startPercent + digest.percentage) {
        currentYear = digest.year
        for (const month of digest.months) {
          if (percentage >= month.startPercent && percentage <= month.startPercent + month.percentage) {
            currentMonth = month.month
            break
          }
        }
        break
      }
    }

    if (currentYear && currentMonth) {
      setTooltipText(`${currentMonth} ${currentYear}`)
      setTooltipVisible(true)
    }
  }, [digests, getScrollInfo])

  const updateTooltipPosition = useCallback((position: number) => {
    if (tooltipRef.current) {
      tooltipRef.current.style.top = `${position}px`
    }
    updateTooltipContent(position)
  }, [updateTooltipContent])

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollbarRef.current) return

    const { top } = scrollbarRef.current.getBoundingClientRect()
    const { scrollableHeight, maxThumbPosition } = getScrollInfo()

    const newPosition = Math.max(0, Math.min(e.clientY - top, maxThumbPosition))

    if (isDraggingTooltip) {
      if (newPosition < (maxThumbPosition * 0.1) && virtualizer.scrollOffset < heightBeforeScroll) {
        virtualizer.scrollToOffset(0, { behavior: 'auto' })
        return
      }

      const scrollPercentage = (newPosition / maxThumbPosition) * 100
      const adjustedScrollableHeight = scrollableHeight - heightBeforeScroll
      let newScrollOffset = (adjustedScrollableHeight * scrollPercentage) / 100 + heightBeforeScroll

      if (scrollPercentage >= 99.5) {
        newScrollOffset = scrollableHeight + heightBeforeScroll
      }

      virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
      updateTooltipPosition(newPosition)
    } else if (isHoveringScrollbar && !isHoveringTooltip) {
      updateTooltipContent(newPosition)
      updateTooltipPosition(newPosition)
    }
  }, [isDraggingTooltip, isHoveringScrollbar, virtualizer, updateTooltipPosition, updateTooltipContent, getScrollInfo])

  const handleMouseEnter = useCallback(() => {
    if (virtualizer.scrollOffset >= heightBeforeScroll) {
      setIsHoveringScrollbar(true)
      showScrollbar()
    }
  }, [showScrollbar, getScrollInfo, virtualizer.scrollOffset])

  const handleMouseLeave = useCallback(() => {
    setIsHoveringScrollbar(false)

    if (!isDraggingTooltip) {
      updateTooltipPosition(thumbRef.current?.offsetTop || 0)
    }
  }, [isDraggingTooltip, updateTooltipPosition])

  const handleTooltipMouseEnter = useCallback(() => {
    setIsHoveringTooltip(true)
  }, [])

  const handleTooltipMouseLeave = useCallback(() => {
    setIsHoveringTooltip(false)
  }, [])

  const handleMouseUp = useCallback(() => {
    setIsDraggingTooltip(false)
  }, [])

  useEffect(() => {
    if (isDraggingTooltip) {
      document.addEventListener('mousemove', handleMouseMove as unknown as EventListener)
      document.addEventListener('mouseup', handleMouseUp)
    } else {
      document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDraggingTooltip, handleMouseMove, handleMouseUp])

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollbarRef.current) return

    const { top } = scrollbarRef.current.getBoundingClientRect()
    const { scrollableHeight, maxThumbPosition } = getScrollInfo()

    const clickPosition = Math.max(0, Math.min(e.clientY - top, maxThumbPosition))

    if (clickPosition < (maxThumbPosition * 0.1) && virtualizer.scrollOffset < heightBeforeScroll) {
      virtualizer.scrollToOffset(0, { behavior: 'auto' })
      return
    }

    const scrollPercentage = (clickPosition / maxThumbPosition) * 100

    const adjustedScrollableHeight = scrollableHeight - heightBeforeScroll
    let newScrollOffset = (adjustedScrollableHeight * scrollPercentage) / 100 + heightBeforeScroll

    if (scrollPercentage >= 99.5) {
      newScrollOffset = scrollableHeight + heightBeforeScroll
    }

    virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
    updateTooltipPosition(clickPosition)
  }, [virtualizer, updateTooltipPosition, getScrollInfo])

  const isElementHidden = useCallback((elementPosition: number, elementHeight: number) => {
    const thumbHeight = thumbRef.current?.offsetHeight || 0
    const elementEnd = elementPosition + elementHeight
    const thumbEnd = thumbPosition + thumbHeight

    return (
      (elementPosition >= thumbPosition && elementPosition < thumbEnd) ||
      (elementEnd > thumbPosition && elementEnd <= thumbEnd) ||
      (elementPosition <= thumbPosition && elementEnd >= thumbEnd)
    )
  }, [thumbPosition])

  const handleMouseWheel = useCallback((e: React.WheelEvent<HTMLDivElement>) => {
    virtualizer.scrollToOffset(virtualizer.scrollOffset + e.deltaY, { behavior: 'auto' })
  }, [virtualizer])

  const handleMonthDotClick = (e: React.MouseEvent<HTMLDivElement>, month: IDigestMonth) => {
    e.stopPropagation()
    e.preventDefault()

    const { scrollableHeight } = getScrollInfo()
    const scrollPercentage = month.startPercent

    const adjustedScrollableHeight = scrollableHeight - heightBeforeScroll
    let newScrollOffset = (adjustedScrollableHeight * scrollPercentage) / 100 + heightBeforeScroll

    if (scrollPercentage >= 99.5) {
      newScrollOffset = scrollableHeight + heightBeforeScroll
    }

    virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
  }

  const handleYearClick = (e: React.MouseEvent<HTMLDivElement>, year: IDigestYear) => {
    e.stopPropagation()
    e.preventDefault()

    const { scrollableHeight } = getScrollInfo()
    const scrollPercentage = year.startPercent
    const newScrollOffset = (scrollableHeight * scrollPercentage) / 100

    virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
  }

  const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDraggingTooltip(true)
    setTouchStartY(e.touches[0].clientY)
  }, [])

  const handleTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    if (!isDraggingTooltip || touchStartY === null || !scrollbarRef.current) return

    const { top } = scrollbarRef.current.getBoundingClientRect()
    const { scrollableHeight, maxThumbPosition } = getScrollInfo()

    const newPosition = Math.max(0, Math.min(e.touches[0].clientY - top, maxThumbPosition))

    const scrollPercentage = (newPosition / maxThumbPosition) * 100
    let newScrollOffset = (scrollableHeight * scrollPercentage) / 100

    if (scrollPercentage >= 99.5) {
      newScrollOffset = scrollableHeight + heightBeforeScroll
    }

    virtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' })
    updateTooltipPosition(newPosition)
  }, [isDraggingTooltip, touchStartY, thumbPosition, getScrollInfo, virtualizer, updateTooltipPosition])

  const handleTouchEnd = useCallback(() => {
    setIsDraggingTooltip(false)
    setTouchStartY(null)
  }, [])

  useEffect(() => {
    if (isDraggingTooltip) {
      document.addEventListener('mousemove', handleMouseMove as unknown as EventListener)
      document.addEventListener('mouseup', handleMouseUp)
      document.addEventListener('touchmove', handleTouchMove as unknown as EventListener, { passive: false })
      document.addEventListener('touchend', handleTouchEnd)
    } else {
      document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('touchmove', handleTouchMove as unknown as EventListener)
      document.removeEventListener('touchend', handleTouchEnd)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('touchmove', handleTouchMove as unknown as EventListener)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [isDraggingTooltip, handleMouseMove, handleMouseUp, handleTouchMove, handleTouchEnd])

  return (
    <SDigestsScrollbar
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={scrollbarRef}
      onWheel={handleMouseWheel}
      className={className}
      isVisible={isScrollbarVisible || isHoveringScrollbar}
    >
      {digests.map((digest) => {
        const scrollbarHeight = scrollbarRef.current?.offsetHeight || 0
        const yearPosition = (digest.startPercent / 100) * scrollbarHeight
        const yearHeight = 12
        const isYearHidden = isElementHidden(yearPosition, yearHeight)

        return (
          <React.Fragment key={digest.year}>
            {!isYearHidden && !digest.isHidden && (
              <SYear top={digest.startPercent}
                onClick={(e) => handleYearClick(e, digest)}
              >
                {digest.year}
              </SYear>
            )}

            {digest.months.map((month) => {
              if (month.isDotHidden) return null

              const monthPosition = (month.startPercent / 100) * scrollbarHeight
              const monthHeight = 5
              const isMonthHidden = isElementHidden(monthPosition, monthHeight)

              return !isMonthHidden && (
                <SMonthDot key={`${digest.year}-${month.month}`}
                  top={month.startPercent}
                  onClick={(e) => handleMonthDotClick(e, month)}
                />
              )
            })}
          </React.Fragment>
        )
      })}

      <SScrollbarThumb ref={thumbRef} />

      {tooltipVisible && (
        <STooltip
          onMouseDown={(e) => {
            e.preventDefault()
            setIsDraggingTooltip(true)
          }}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
          ref={tooltipRef}

          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {tooltipText}
        </STooltip>
      )}
    </SDigestsScrollbar>
  )
}

const SDigestsScrollbar = styled.div<{ isVisible: boolean }>`
    width: 36px;
    height: calc(100% - 70px);
    position: absolute;
    top: 60px;
    right: -5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    z-index: 10;
    opacity: ${props => props.isVisible ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
    /* pointer-events: ${props => props.isVisible ? 'auto' : 'none'}; */
`

const SYear = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    text-align: left;
    color: var(--text-secondary);
    position: absolute;
    top: ${props => props.top}%;
`

const SMonthDot = styled.div`
    width: 5px;
    height: 5px;
    background: var(--icon-disable);
    border-radius: 50%;
    position: absolute;
    top: ${props => props.top}%;
`

const STooltip = styled.div`
    position: absolute;
    right: 100%;
    transform: translateY(-50%);
    background: var(--background-primary);
    color: var(--text-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    pointer-events: auto;
    cursor: grab;
    user-select: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    will-change: transform;
    transform: translateZ(0) translateY(-50%);
    text-transform: capitalize;
    touch-action: none;

    &:active {
      cursor: grabbing;
    }
`

const SScrollbarThumb = styled.div`
  width: 22px;
  height: 4px;
  background: var(--accent-normal);
  border-radius: 100px;
  position: absolute;
  pointer-events: none;
  will-change: transform;
  transform: translateZ(0);
`
