import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { ManageFamilyMembers } from "features/family/ManageFamilyMembers"
import { useAppDispatch } from "store"
import {
  familyActions,
  fetchFamilyThunk,
  subscribeFamilyToWSThunk,
  unsubscribeFamilyFromWSThunk
} from "features/family/familySlice"
import { FamilyInvite } from "features/family/FamilyInvite"
import { getFamilyLoadingStatusSelector, getSelectedMembersSelector } from "features/family/selectors"
import { useNavigate } from "react-router-dom"
import styled from 'styled-components'
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import classNames from "classnames"
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"

import { withCreateFamilyPlaceholder } from "../../../features/family/CreateFamilyPlaceholder"
import { Onboarding } from "../../../features/family/Onboarding"
import { useMobileDetection } from "../../../features/common/hooks"

const ManageFamily = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedItems = getSelectedMembersSelector()
  const selectedItemsCount = selectedItems.length
  const isMobile = useMobileDetection()

  const status = getFamilyLoadingStatusSelector()

  usePageTitle('l_familycloud_manageFamily')

  useEffect(() => {
    dispatch(fetchFamilyThunk())
    dispatch(subscribeFamilyToWSThunk({ navigate }))

    return () => {
      dispatch(unsubscribeFamilyFromWSThunk())
    }
  }, [])

  const handleCancelSelection = () => {
    dispatch(familyActions.unselectAll())
  }

  return (
    <>
      <Onboarding />

      <StyledPageTopbar
        title={t('l_family_manageFamilyTitle')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
        className={classNames({ 'with-bigger-height': !!selectedItemsCount })}
      />

      <PageBox>
        {
          status !== LOADING_STATUSES.LOADING && (
            <>
              <ManageFamilyMembers />

              <FamilyInvite />
            </>
          )}
      </PageBox>

      <MobileToolbarMenuToggle />
    </>
  )
}

const PageBox = styled.div`
  padding: 0 16px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding: 0;
  }
`

const StyledPageTopbar = styled(PageTopbar)`
  && {
    min-height: 40px;
  }
`

export default withCreateFamilyPlaceholder(ManageFamily, 'l_family_manageFamilyTitle')
