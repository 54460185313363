import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'

import { AlbumType } from '@cloudike/web_photos'
import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getAlbumsSdkByType } from 'sdk/albums'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { AlbumsPaginator } from "@cloudike/web_photos/dist/types/lib/Paginator/AlbumsPaginator"
import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { showGlobalProgressLoader , hideGlobalProgressLoader } from 'features/common/app-progress-bar'
import { showNotification , NOTIFICATION_TYPES } from 'features/common/notifications'
import i18n from 'i18n'
import { getErrorData } from 'utils/getErrorData'
import { getPhotosWS } from 'sdk/photo'
import { RootState } from 'store'
import { photoPreviewActions } from 'features/photo/photo-preview/photoPreviewSlice'
import { photoPreviewDuplicateActions } from 'features/photo/photo-preview-duplicate/photoPreviewDuplicateSlice'

import { TOTAL_COUNT_HEADER } from "../../../constants/headers"


export type IExtendedAlbumPlacesSchema =  IAlbumSchema

const adapter = createEntityAdapter<IExtendedAlbumPlacesSchema>()
const adapterCurrentAlbumItems = createEntityAdapter<IItemSchema>()

export const albumsMemoriesSelectors = adapter.getSelectors()
export const currentAlbumItemsSelectors = adapterCurrentAlbumItems.getSelectors()

let paginator: AlbumsPaginator | null = null
let firstMemoriesAlbumsLoaded = false
let allMemoriesLoaded = false
let subscribedToWS = false

export const initPaginator = (type:SDK_TYPES , pageSize) => {
  const albumsSdk = getAlbumsSdkByType(type)
  paginator = albumsSdk.getAlbumsPaginator(pageSize, { offset: 0, total_count: true, preview_jwt: true, type: [AlbumType.MEMORIES], covers_count: 3 })

  return paginator
}

export const subscribeMemoriesAlbumsToWSThunk = createAsyncThunk(
  'albumsMemories/subscribeMemoriesAlbumsToWSThunk',
  async function (_, { dispatch, getState }) {
    if (subscribedToWS) {
      return
    }

    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_OPERATION_DONE, ({ action, output }) => {
      const state = getState() as RootState
      const currentAlbumData = state.albumsMemories.currentAlbumData

      if (!currentAlbumData) {
        return
      }

      if (action === 'add_items') {
        dispatch(loadMemoriesAlbumItemsThunk({ id: currentAlbumData?.id }))
      }

      if (action === 'delete_items') {
        dispatch(actions.removeCurrentAlbumItems(output.map(item => item.detail.item_id)))

        hideGlobalProgressLoader()
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED, ({ output }) => {
      const state = getState() as RootState
      const currentAlbumData = state.albumsMemories.currentAlbumData

      if (output.type === AlbumType.MEMORIES) {
        allMemoriesLoaded = false
        
        dispatch(loadAllMemoriesAlbumsThunk())
      }

      if (output.id === currentAlbumData?.id) {
        dispatch(actions.setCurrentAlbumData(output))
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED, ({ output }) => {
      const state = getState() as RootState
      const currentEntities = currentAlbumItemsSelectors.selectEntities(state.albumsMemories.currentAlbumItems)

      dispatch(photoPreviewDuplicateActions.updateItem(output))

      if (currentEntities[output.id]) {
        dispatch(actions.updateCurrentAlbumItem(output))
        dispatch(photoPreviewActions.updateItem(output))
      }
    })
    
    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE, ({ action, output }) => {
      if (action === 'delete_items') {
        const state = getState() as RootState
        const currentEntities = currentAlbumItemsSelectors.selectEntities(state.albumsMemories.currentAlbumItems)

        if (currentEntities[output[0].detail.item_id]) {
          dispatch(actions.removeCurrentAlbumItems(output.map(item => item.detail.item_id)))
        }

      }

      if (action === 'add_favorite') {
        output.forEach(item => {
          dispatch(actions.updateCurrentAlbumItem({ id: item.detail.item_id, favorite: true }))
          dispatch(photoPreviewActions.updateItem({ id: item.detail.item_id, favorite: true }))
        })
      }

      if (action === 'delete_favorite') {
        output.forEach(item => {
          dispatch(actions.updateCurrentAlbumItem({ id: item.detail.item_id, favorite: false }))
          dispatch(photoPreviewActions.updateItem({ id: item.detail.item_id, favorite: false }))
        })
      }
    })

    subscribedToWS = true
  }
)

export const fetchMemoriesAlbumDataThunk = createAsyncThunk(
  'albumsMemories/fetchMemoriesAlbumDataThunk',
  async function ({ id }: { id: string }, { dispatch }) {
    const albumsSdk = getAlbumsSdkByType(SDK_TYPES.DEFAULT)

    const response = await albumsSdk.getAlbum(id, {},{ preview_jwt: true })

    dispatch(actions.setCurrentAlbumData(response.data))
  }
)

export const fetchFirstMemoriesAlbumsThunk = createAsyncThunk(
  'albumsMemories/fetchFirstMemoriesAlbumsThunk',
  async function(_, { dispatch }) {
    try {
      if (!firstMemoriesAlbumsLoaded) {
        dispatch(actions.setStatus(LOADING_STATUSES.LOADING))
      }

      dispatch(actions.setStatus(LOADING_STATUSES.LOADING))

      const paginator = initPaginator(SDK_TYPES.DEFAULT, 500)
      const response = await paginator.next()

      firstMemoriesAlbumsLoaded = true

      const albumsWithItems = response.data._embedded.albums.filter(album => !!album.items_count)

      dispatch(actions.setAlbums(albumsWithItems))
      dispatch(actions.setAlbumsTotalCount(parseInt(response.headers[TOTAL_COUNT_HEADER])))
      dispatch(actions.setStatus(LOADING_STATUSES.SUCCEEDED))
    } catch (error) {
      dispatch(actions.setStatus(LOADING_STATUSES.FAILED))
    }
  }
)

export const loadAllMemoriesAlbumsThunk = createAsyncThunk(
  'albumsMemories/loadAllMemoriesAlbumsThunk',
  async function(_, { dispatch }) {
    const albumsSdk = getAlbumsSdkByType(SDK_TYPES.DEFAULT)

    if (!allMemoriesLoaded) {
      dispatch(actions.setStatus(LOADING_STATUSES.LOADING))
    }
    
    const paginator = albumsSdk.getAlbumsPaginator(500, { offset: 0, total_count: true, preview_jwt: true, type: [AlbumType.MEMORIES], covers_count: 3 })
    
    const albums = []
    let finished = false

    while (!finished) {
      const response = await paginator.next()

      albums.push(...response.data._embedded.albums)

      if (response.data._embedded.albums.length < 100) {
        finished = true
      }
    }

    const albumsWithItems = albums.filter(album => !!album.items_count)

    dispatch(actions.setAlbums(albumsWithItems))
    dispatch(actions.setStatus(LOADING_STATUSES.SUCCEEDED))

    allMemoriesLoaded = true
  }
)

export const loadMemoriesAlbumItemsThunk = createAsyncThunk(
  'albumsMemories/loadMemoriesAlbumItemsThunk',
  async function ({ id }: { id: string }, { dispatch }) {
    try {
      const albumsSdk = getAlbumsSdkByType(SDK_TYPES.DEFAULT)

      const paginator = albumsSdk.getAlbumItemsPaginator(id, 500, { total_count: true })

      const response = await paginator.next()

      const filteredItems = response.data._embedded.items.filter(item => !!item?._links?.image_small?.href)

      dispatch(actions.setCurrentAlbumItems(filteredItems))
      dispatch(actions.setItemsLoadingStatus(LOADING_STATUSES.SUCCEEDED))
    } catch (error) {
      dispatch(actions.setItemsLoadingStatus(LOADING_STATUSES.FAILED))
    }
  }
)

export const renameMemoriesAlbumThunk = createAsyncThunk(
  'albumsMemories/renameMemoriesAlbumThunk',
  async function ({ id, name, successCallback }: { id: string, name: string, successCallback?: () => void }, { dispatch }) {
    try {
      const type = SDK_TYPES.DEFAULT
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.renameAlbum(id, name)
      dispatch(actions.updateCurrentAlbumData({ description: name }))

      if (successCallback) {
        successCallback()
      }

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_memoryRenamed')
      })
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const albumsMemoriesSlice = createSlice({
  name: 'albumsMemories',
  initialState: {
    albums: adapter.getInitialState(),
    selectedAlbumsIds: [],
    currentAlbumItems: adapterCurrentAlbumItems.getInitialState(),
    status: LOADING_STATUSES.LOADING,
    itemsLoadingStatus: LOADING_STATUSES.IDLE,
    error: '',
    totalCount: 0,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    currentAlbumData: null,
    currentPhotoIndex: 0,
    isModalOpened: false,
    playerState: {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      finished: false
    },
    savedPlayerStateBeforeAction: {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      finished: false
    }
  },
  reducers: {
    setAlbums: (state, action) => {
      adapter.setAll(state.albums, action.payload)
    },
    addAlbums: (state, action) => {
      adapter.addMany(state.albums, action.payload)
    },
    updateItem: (state, action) => {
      adapter.updateOne(state.albums, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      adapter.removeAll(state.albums)
      adapterCurrentAlbumItems.removeAll(state.currentAlbumItems)
      state.currentPhotoIndex = 0
      state.currentAlbumData = null
      state.playerState = {
        isPlaying: false,
        currentTime: 0,
        duration: 0,
        finished: false
      }
    },
    resetModalState: (state) => {
      state.currentAlbumData = null
      state.currentPhotoIndex = 0
      state.itemsLoadingStatus = LOADING_STATUSES.LOADING
      adapterCurrentAlbumItems.removeAll(state.currentAlbumItems)
      
      state.playerState = {
        isPlaying: false,
        currentTime: 0,
        duration: 0,
        finished: false
      }
    },
    setCurrentAlbumData: (state, action) => {
      state.currentAlbumData = action.payload
    },
    updateCurrentAlbumData: (state, action) => {
      state.currentAlbumData = {
        ...state.currentAlbumData,
        ...action.payload
      }
    },
    setItemsLoadingStatus: (state, action) => {
      state.itemsLoadingStatus = action.payload
    },
    setCurrentPhotoIndex: (state, action) => {
      state.currentPhotoIndex = action.payload
    },
    setCurrentAlbumItems: (state, action) => {
      adapterCurrentAlbumItems.setAll(state.currentAlbumItems, action.payload)
    },
    setPlayerState: (state, action) => {
      state.playerState = { ...state.playerState, ...action.payload }
    },
    setIsModalOpened: (state, action) => {
      state.isModalOpened = action.payload
    },
    setSavedPlayerStateBeforeAction: (state, action) => {
      state.savedPlayerStateBeforeAction = { ...state.savedPlayerStateBeforeAction, ...action.payload }
    },
    selectAlbum: (state, action) => {
      const id = action.payload

      const indexOfItemId = state.selectedAlbumsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedAlbumsIds.push(id)
      } else {
        state.selectedAlbumsIds = [...state.selectedAlbumsIds.slice(0, indexOfItemId), ...state.selectedAlbumsIds.slice(indexOfItemId + 1)]
      }
    },
    unselectAllAlbums: (state) => {
      state.selectedAlbumsIds = []
    },
    setAlbumsTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    removeCurrentAlbumItems: (state, action) => {
      adapterCurrentAlbumItems.removeMany(state.currentAlbumItems, action.payload)
    },
    updateCurrentAlbumItem: (state, action) => {
      adapterCurrentAlbumItems.updateOne(state.currentAlbumItems, {
        id: action.payload.id,
        changes: action.payload,
      })
    }
  }
})

const {
  reducer, actions
} = albumsMemoriesSlice

export { reducer as albumsMemoriesReducer, actions as albumsMemoriesActions }
