import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { documentItemsSelectors } from "./documentsSlice"

export const getUserDocumentsWalletSettingsSelector = () => useAppSelector(state => state.documents.settings)

export const getDocumentsWalletLoadingStatusSelector = () => useAppSelector(state => state.documents.status)

export const getDocumentsWalletAlgorithmsSelector = () => useAppSelector(state => state.documents.algorithms)

export const getDocumentsWalletTypesRawSelector = state => state.documents.types

export const getDocumentsWalletTypesSelector = () => useAppSelector(getDocumentsWalletTypesRawSelector)

export const getDocumentsMasterKeySelector = () => useAppSelector(state => state.documents.masterKey)

export const getDocumentItemsLoadingStatusSelector = () => useAppSelector(state => state.documents.documentItemsLoadingStatus)

export const getDocumentItemsRawSelector = state => documentItemsSelectors.selectAll(state.documents.documentItems)

export const getDocumentItemsSelector = () => useAppSelector(getDocumentItemsRawSelector)

export const getDocumentSelectedItemsIdsRawSelector = state => state.documents.selectedDocumentItemsIds

export const getDocumentSelectedItemsIdsSelector = () => useAppSelector(getDocumentSelectedItemsIdsRawSelector)

export const getDocumentItemsActionStatusSelector = () => useAppSelector(state => state.documents.documentItemsActionsStatus)

export const getDocumentSelectedItemsSelector = () => useAppSelector(createSelector(
  getDocumentItemsRawSelector,
  getDocumentSelectedItemsIdsRawSelector,
  (items, selectedDocumentItemsIds) => items.filter(item => selectedDocumentItemsIds.includes(item.id))
))

export const getDocumentsWalletSignInErrorDataSelector = () => useAppSelector(state => state.documents.signInErrorData)

export const getNotificationModalTextSelector = () => useAppSelector(state => state.documents.notificationModalText)

export const getTypeModalSelector = () => useAppSelector(state => state.documents.typeModal)

export const getShowCreateModalSelector = () => useAppSelector(state => state.documents.isShowCreateModal)

export const getDocumentSelectedTypesSelector = () => useAppSelector(
  createSelector(
    getDocumentsWalletTypesRawSelector,
    (types) => types.filter(item => item.selected)
  )
)

export const getDocumentSelectedTypesCountSelector = () => useAppSelector(createSelector(
  getDocumentsWalletTypesRawSelector,
  (types) => types.filter(item => item.selected).length
))

export const getIsDocumentNameEditingSelector = () => useAppSelector(state => state.documents.isDocumentNameEditing)

export const getDocumentTotalItemsCountSelector = () => useAppSelector(
  state => state.documents.totalItemsCountDocument
)

export const getDocumentTypeTotalItemsCountSelector = (typeId) => useAppSelector(
  state => state.documents.types.find(type => type.id === typeId).documents_count
)

export const getIsDocumentTypesLoadingDuringPersonChangeSelector = () => useAppSelector(state => state.documents.isDocumentTypesLoadingDuringPersonChange)
