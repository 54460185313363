import { v4 as uuidv4 } from 'uuid'
import { template } from 'lodash'
import RandExp from 'randexp'

import { request } from "./request"

export const photosApi = {
  getItemsExtensions(userId:number, itemIds: string[]) {
    const promises = []
    for(const itemId of itemIds) {
      promises.push(photosApi.getItemExtensions(userId, itemId))
    }
    return Promise.all(promises).then((extensions) => {
      return extensions.reduce((acc, { id, extensions }) => {
        acc[id] = extensions
        return acc
      },{})
    })
  },
  async getItemExtensions(userId:number, itemId: string) {
    const { _embedded:{ extensions } }  = await request('GET', `/api/2/users/${userId}/photos/items/${itemId}/extensions`,{})
    return { id: itemId, extensions }
  },
  async getCurrentItemFaceData(userId: number, itemId: string) {
    return request('GET', `/api/2/users/${userId}/photos/items/${itemId}/faces`, {})
  },
  async getLivePhotoPreviewContentDataUrl(contentUrl) {
    const { _links:{ data:{ href } } } = await request( 'GET', contentUrl, {}, { host:null } )
    return href
  },
  getDuplicate: (userId, itemId) => {
    return request('GET', `/api/2/users/${userId}/photos/items/${itemId}/duplicates/`)
  },
  //toDo move to sdk
  getSuggestions: (userId, params) => {
    return request('GET', `/api/2/users/${userId}/photos/suggestions`, params)
  },
  getPhotosTags: (userId, item_id) => {
    return request('GET', `/api/2/users/${userId}/photos/items/${item_id}/raw_tags`)
  },
  getPhotosData: (userId) => {
    return request('GET', `/api/2/users/${userId}/photos/`)
  },
  uploadPhotoByLink: (urlTmp, data) => {
    const uploadId = uuidv4()

    const regex = /[a-z0-9]{5}/
    const bk = new RandExp(regex).gen()

    const delimiter = /{(.+?)}/g
    const dataUrl = {
      upload_id: uploadId,
      bk,
    }

    const url = template(urlTmp, { interpolate: delimiter })(dataUrl)

    return request('PUT', url, data, { host: null })
  },
  getUploadStatusPhotoByLink: (url) => {
    return request('GET', url ,{} ,{ host: null })
  },
}
