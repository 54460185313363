import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { RootState } from 'store'

import { photosApi } from "../../../api/photos"

export enum PREVIEW_TYPES {
    ALBUM = 'ALBUM',
    PLACE_ALBUM = 'PLACE_ALBUM',
    TIMELINE = 'TIMELINE',
    PEOPLE = 'PEOPLE',
    SHARED_ALBUM = 'SHARED_ALBUM',
    FAVORITES = 'FAVORITES',
    DOCUMENTS = 'DOCUMENTS',
    CALENDAR = 'CALENDAR',
    TRASH_BIN = 'TRASH_BIN'
}

interface State {
    type: PREVIEW_TYPES,
    sdkType: SDK_TYPES,
    currentItemIndex: number,
    isModalOpened: boolean,
    loading: boolean,
    loadingMore: boolean,
    totalItemsCount: number,
    hasError: boolean,
    currentItemExtensions: Record<string, any>[],
    currentLivePhotoPreviewContentDataUrl: string,
    isLivePhotoPlaying: boolean,
    isLivePhotoCanPlay: boolean,
    currentItemFacesData: Record<string, any>[],
    isLoadingFaces: boolean,
    areFacesVisible: boolean,
    tags: {
      model_version: number,
      raw_tags: any[]
    }
}

interface EnhancedItemSchema extends Omit<IItemSchema, 'type'> {
    type: 'image' | 'video' | 'pdf'
}

interface PreviewOpenActionType {
    items: IItemSchema[],
    currentItemId: string,
    type: PREVIEW_TYPES,
    sdkType: SDK_TYPES,
    loadMore?: () => void,
    totalItemsCount: number,
    userId?: number
}

const adapter = createEntityAdapter<EnhancedItemSchema>()

export const previewSelectors = adapter.getSelectors()

let loadMoreHandler

export const getCurrentItemExtensionThunk = createAsyncThunk(
  'photoPreview/openPhotoPreviewThunk',
  async function ({ item }: { item: IItemSchema }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id
    const familyUserId = state.user.userData.family_user_id
    const timelineType = state.timeline.type
    const itemId = item.id
    try {
      const { extensions } = await photosApi.getItemExtensions(timelineType === SDK_TYPES.DEFAULT ? userId : familyUserId, itemId)
      dispatch(actions.setCurrentItemExtensions(extensions))
    } catch (error) {
      console.log('Item extension is unavailable', error)
    }
  })

export const getCurrentItemFacesDataThunk = createAsyncThunk(
  'photoPreview/openPhotoPreviewThunk',
  async function ({ item }: { item: IItemSchema }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id
    const timelineType = state.timeline.type
    const familyUserId = state.user.userData.family_user_id
    const itemId = item.id
    dispatch(actions.setLoadingFaces(true))
    try {
      const { _embedded: { faces } }  = await photosApi.getCurrentItemFaceData(timelineType === SDK_TYPES.DEFAULT ? userId : familyUserId, itemId)
      dispatch(actions.setCurrentItemFacesData(faces))
    } catch (error) {
      dispatch(actions.resetCurrentItemFacesData())
      console.log('Item faces is unavailable', error)
    } finally {
      dispatch(actions.setLoadingFaces(false))
    }
  })

export const openPhotoPreviewThunk = createAsyncThunk(
  'photoPreview/openPhotoPreviewThunk',
  async function ({
    items,
    currentItemId,
    type,
    sdkType,
    loadMore,
    totalItemsCount
  }: PreviewOpenActionType, { dispatch }) {
    dispatch(actions.openPreview({ items: items.sort((a, b) => b?.created_original - a?.created_original), currentItemId, type, sdkType, totalItemsCount }))
    if (loadMore) {
      loadMoreHandler = loadMore
    }
  }
)

export const handleNextPhotoThunk = createAsyncThunk(
  'photoPreview/handleNextPhotoThunk',
  async function (_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.photoPreview.currentItemIndex
    const itemsLength = state.photoPreview.ids.length

    dispatch(actions.setError(false))

    if (currentIndex + 1 === itemsLength && loadMoreHandler) {
      loadMoreHandler()
    }

    dispatch(actions.setCurrentItemIndex(currentIndex + 1))
    dispatch(actions.setLoading(true))
  }
)

export const handlePrevPhotoThunk = createAsyncThunk(
  'photoPreview/handlePrevPhotoThunk',
  async function (_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.photoPreview.currentItemIndex

    dispatch(actions.setError(false))
    dispatch(actions.setCurrentItemIndex(currentIndex - 1))
    dispatch(actions.setLoading(true))
  }
)

export const getTagsThunk = createAsyncThunk(
  'photoPreview/getTagsThunk',
  async function ({ id, callback }:{ id: string, callback: () => void }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id

    try {
      const rsp = await photosApi.getPhotosTags( userId, id)
      dispatch(actions.setTags(rsp))
    } catch (e) {
      console.log(e)
    } finally {
      !!callback && callback()
    }
  }
)

export const getLivePhotoPreviewContentDataUrlThunk = createAsyncThunk(
  'photoPreview/getLivePhotoPreviewContent',
  async function (contentUrl: string) {
    return await photosApi.getLivePhotoPreviewContentDataUrl(contentUrl)
  }
)

export const photoPreviewSelectors = adapter.getSelectors()

export const photoPreviewSlice = createSlice({
  name: 'photoPreview',
  initialState: adapter.getInitialState<State>({
    currentItemFacesData: [],
    type: PREVIEW_TYPES.ALBUM,
    sdkType: SDK_TYPES.DEFAULT,
    currentItemIndex: 0,
    isModalOpened: false,
    loading: true,
    totalItemsCount: 0,
    loadingMore: false,
    hasError: false,
    currentItemExtensions: [],
    currentLivePhotoPreviewContentDataUrl: '',
    isLivePhotoPlaying: false,
    isLivePhotoCanPlay: false,
    isLoadingFaces: false,
    areFacesVisible: true,
    tags: {
      model_version: 0,
      raw_tags: []
    }
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    setTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload
    },
    setCurrentItemIndexById: (state, action: PayloadAction<string>) => {
      const ids = adapter.getSelectors().selectIds(state)
      const indexOfItem = ids.indexOf(action.payload)

      state.currentItemIndex = indexOfItem !== -1 ? indexOfItem : state.currentItemIndex
    },
    setCurrentItemFacesData: (state, action) => {
      state.currentItemFacesData = action.payload
    },
    setCurrentItemTransform: (state, action) => {
      const items = [action.payload, ...previewSelectors.selectAll(state)].sort((a, b) => b.created_original - a.created_original)
      adapter.setAll(state, items)
      const ids = items.map(item => item.id)
      const indexOfItem = ids.indexOf(action.payload.id)
      state.currentItemIndex = indexOfItem !== -1 ? indexOfItem : state.currentItemIndex
    },
    resetCurrentItemFacesData: (state) => {
      state.currentItemFacesData = []
    },
    setCurrentItemIndex: (state, action) => {
      state.currentItemIndex = action.payload
    },
    increaseCurrentItemIndex: (state, action) => {
      state.currentItemIndex = state.currentItemIndex + action.payload
    },
    toggleModal: (state, action) => {
      state.isModalOpened = action.payload
    },
    setTags: (state, action) => {
      state.tags.model_version = action.payload.model_version
      state.tags.raw_tags = [...action.payload.raw_tags]
    },
    clearTags:(state) => {
      state.tags = {
        model_version: 0,
        raw_tags: []
      }
    },
    openPreview: (state, action: PayloadAction<PreviewOpenActionType>) => {
      adapter.setAll(state, action.payload.items)
      state.sdkType = action.payload.sdkType
      state.type = action.payload.type
      state.totalItemsCount = action.payload.totalItemsCount
      const ids = action.payload.items.map(item => item.id)
      const indexOfItem = ids.indexOf(action.payload.currentItemId)
      state.currentItemIndex = indexOfItem
      state.isModalOpened = true
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setLoadingFaces: (state, action) => {
      state.isLoadingFaces = action.payload
    },
    setLoadingMore: (state, action: PayloadAction<boolean>) => {
      state.loadingMore = action.payload
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload
    },
    resetState: (state) => {
      adapter.removeAll(state)
      state.currentItemFacesData = []
      state.currentItemIndex = 0
      state.isModalOpened = false
      state.loading = true
      state.isLoadingFaces = false
      state.loadingMore = false
      state.hasError = false
      loadMoreHandler = null
      state.tags = {
        model_version: 0,
        raw_tags: []
      }
    },
    clearLivePhotoPreviewContentDataUrl: (state) => {
      state.currentLivePhotoPreviewContentDataUrl = ''
    },
    toggleLivePhotoPlaying: (state) => {
      state.isLivePhotoPlaying = !state.isLivePhotoPlaying
    },
    pausedLivePhotoPlaying: (state) => {
      state.isLivePhotoPlaying = false
    },
    startLivePhotosPlaying: (state) => {
      state.isLivePhotoPlaying = true
    },
    setLivePhotoCanPlay: (state, action) => {
      state.isLivePhotoCanPlay = action.payload
    },
    setCurrentItemExtensions: (state, action) => {
      state.currentItemExtensions = action.payload
    },
    setAreFacesVisible: (state, action) => {
      state.areFacesVisible = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getLivePhotoPreviewContentDataUrlThunk.pending, (state) => {
      state.currentLivePhotoPreviewContentDataUrl = ''
    })
    builder.addCase(getLivePhotoPreviewContentDataUrlThunk.fulfilled, (state, action) => {
      state.currentLivePhotoPreviewContentDataUrl = action.payload
    })
    builder.addCase(getLivePhotoPreviewContentDataUrlThunk.rejected, (state) => {
      state.currentLivePhotoPreviewContentDataUrl = ''
    })
  }
})

const {
  reducer, actions
} = photoPreviewSlice

export { reducer as photoPreviewReducer, actions as photoPreviewActions }
