import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { AlbumType } from '@cloudike/web_photos'
import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getAlbumsSdkByType } from 'sdk/albums'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { RootState } from 'store'
import { AlbumsPaginator } from "@cloudike/web_photos/dist/types/lib/Paginator/AlbumsPaginator"

import { TOTAL_COUNT_HEADER } from "../../../constants/headers"
import { ALBUMS_COUNT_PER_PAGE } from "../../../constants/pagination"

export type IExtendedAlbumPlacesSchema =  IAlbumSchema

const adapter = createEntityAdapter<IExtendedAlbumPlacesSchema>()

export const albumsMomentsSelectors = adapter.getSelectors()

const getCurrentAlbumsType = (state: RootState) => state.albumsMoments.type

let paginator: AlbumsPaginator | null = null

export const initPaginator = (type:SDK_TYPES , pageSize) => {
  const albumsSdk = getAlbumsSdkByType(type)
  paginator = albumsSdk.getAlbumsMomentsPaginator(pageSize, { offset: 0, total_count: true, preview_jwt: true })

  return paginator
}

export const fetchFirstMomentsAlbumsThunk = createAsyncThunk(
  'albumsMoments/fetchFirstAlbumsMomentsThunk',
  async function(_, { getState }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const paginator = initPaginator(type, ALBUMS_COUNT_PER_PAGE)
    const response = await paginator.next()

    return {
      items: response.data._embedded.albums,
      totalCount: parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0
    }
  }
)

export const loadMoreMomentsAlbumsThunk = createAsyncThunk(
  'albumsMoments/loadMoreMomentsAlbumsThunk',
  async function() {
    const response = await paginator.next()

    return response.data._embedded.albums
  }
)

export const albumsMomentsSlice = createSlice({
  name: 'albumsMoments',
  initialState: adapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    error: '',
    type: SDK_TYPES.DEFAULT,
    typeMoments: AlbumType.MOMENTS,
    totalCount: 0,
    loadingMoreStatus: LOADING_STATUSES.IDLE
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setCurrentAlbumsType: (state, action) => {
      state.type = action.payload
    },
    setMomentsType: (state, action) => {
      state.typeMoments = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      adapter.removeAll(state)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFirstMomentsAlbumsThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchFirstMomentsAlbumsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalCount = action.payload.totalCount
      })
      .addCase(fetchFirstMomentsAlbumsThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(loadMoreMomentsAlbumsThunk.pending, (state) => {
        state.loadingMoreStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadMoreMomentsAlbumsThunk.fulfilled, (state, action) => {
        state.loadingMoreStatus = LOADING_STATUSES.SUCCEEDED
        adapter.addMany(state, action.payload)
      })
  },
})

const {
  reducer, actions
} = albumsMomentsSlice

export { reducer as albumsMomentsReducer, actions as albumsMomentsActions }
