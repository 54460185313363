import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { REDIRECT_TO } from 'constants/searchParams'

import { useState } from 'react'

import { ConfirmationModalTypes, MenuWithActionItems, PrimaryButton, STYLED_VARIABLES, UserMenu as UserMenuForUnauthorized } from '@cloudike/web_ui_components'
import { UserMenu } from 'features/user/UserMenu'
import { getIsUserAuthorizedSelector } from 'features/user/userSlice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { appActions } from 'store/app'
import styled from 'styled-components'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { DialogModal } from 'ui/DialogModal'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { copySharedWithMeAlbumThunk } from 'features/shared-with-me-albums/sharedWithMeAlbumsSlice'
import { useNavigate } from 'react-router-dom'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { fetchAlbumsThunk } from 'features/albums/albums-list/albumsSlice'

import { MobileToolbarCounter } from "../common/mobile-toolbar-counter/MobileToolbarCounter"

import {
  addItemsToPersonalCloudThunk,
  deleteSharedWithMeLinkThunk,
  downloadSharedAlbumItemThunk,
  downloadSharedAlbumThunk,
  publicLinkActions,
  PublicLinksErrorTypes,
  removeSharedAlbumSelectedItemsThunk,
} from './publicLinkSlice'
import {
  getPublicLinkErrorSelector,
  getPublicLinkSelectedItemsSelector,
  getPublicLinkPermissionSelector,
  getPublicLinkSelectedItemsCountSelector,
  getPublicLinkTotalItemsCountSelector,
  getPublicLinkAlbumDataSelector,
  getPublicLinkLoadingStatusSelector
} from './selectors'

interface PublicLinkSidebarProps {
  className?: string,
  withoutMenu?: boolean
}

export const PublicLinkSidebar: React.FC<PublicLinkSidebarProps> = ({ className = '', withoutMenu = false }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const status = getPublicLinkLoadingStatusSelector()

  const isAuthorized = getIsUserAuthorizedSelector()
  const selectedItemsCount = getPublicLinkSelectedItemsCountSelector()
  const selectedItems = getPublicLinkSelectedItemsSelector()
  const permission = getPublicLinkPermissionSelector()
  const error = getPublicLinkErrorSelector()
  const totalItemsCount = getPublicLinkTotalItemsCountSelector()
  const albumData = getPublicLinkAlbumDataSelector()

  const [linkRemovingModalOpened, toggleLinkRemovingModal] = useState(false)
  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const handleSignIn = () => {
    redirectToExternalSSO()
  }

  const handleSignUp = () => {
    redirectToExternalSSO('/signup')
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadAlbum = () => {
    dispatch(downloadSharedAlbumThunk())
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadItem = () => {
    dispatch(downloadSharedAlbumItemThunk({ item: selectedItems[0] }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleAddItemsToPersonalCloud = () => {
    dispatch(addItemsToPersonalCloudThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveItems = () => {
    dispatch(removeSharedAlbumSelectedItemsThunk({ items: selectedItems }))
    handleCloseRemovingConfirmationModal()
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenPublicLinkRemovingModal = () => {
    toggleLinkRemovingModal(true)
  }

  const handleClosePublicLinkRemovingModal = () => {
    toggleLinkRemovingModal(false)
  }

  const handleRemoveLink = () => {
    const callback = () => {
      navigate('/photos/shared-with-me')
    }

    dispatch(deleteSharedWithMeLinkThunk({ id: albumData.id, callback }))

    handleClosePublicLinkRemovingModal()
  }

  const handleAddAlbumToMyCloud = () => {
    dispatch(copySharedWithMeAlbumThunk({ sourceUrl: albumData._links.self.href }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenAddToAlbumModal = () => {
    dispatch(appActions.setSelectedItemsToAddToAlbumModal(selectedItems))
    dispatch(appActions.setAlbumTypeToAddToAlbumModal(SDK_TYPES.DEFAULT))
    dispatch(fetchAlbumsThunk())
    dispatch(appActions.setIsOpenedAddPhotosToAlbumModal(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameAlbum = () => {
    dispatch(publicLinkActions.toggleAlbumNameEditStatus(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleAddPhotos = () => {
    if (!albumData) return

    const redirectTo = `/photos/shared-with-me/albums/${albumData.id}`
    const redirectPathParam = `${REDIRECT_TO}=${redirectTo}`

    navigate(`/photos/albums/${albumData.id}/add?${redirectPathParam}&type=shared-with-me`)

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    return [
      !selectedItemsCount && {
        label: t('a_common_addToMyCloud'),
        iconName: 'add_photo_alternate_1',
        onClickItem: handleAddAlbumToMyCloud,
      },
      !selectedItemsCount && isAuthorized && {
        label: t('a_common_removeLink'),
        iconName: 'unlink',
        onClickItem: handleOpenPublicLinkRemovingModal
      },
      !selectedItemsCount && permission === 'write' && !!totalItemsCount && {
        label: t('a_albums_downloadAlbum'),
        iconName: 'download_empty',
        onClickItem: handleDownloadAlbum
      },
      selectedItemsCount === 1 && {
        label: t('a_common_toolbarAddToMyCloud'),
        iconName: 'add_photo_alternate_1',
        onClickItem: handleAddItemsToPersonalCloud
      },
      !!selectedItemsCount && {
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album',
        onClickItem: handleOpenAddToAlbumModal
      },
      !selectedItemsCount && permission === 'write' && {
        label: t('a_common_renameAlbum'),
        iconName: 'pencil',
        onClickItem: handleRenameAlbum
      },
      !!selectedItemsCount && permission === 'write' && {
        label: t('a_albums_removeFromAlbum'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter(item => !!item)
  })()

  const mobileMenuItems = (() => {
    return [
      !selectedItemsCount && !!totalItemsCount && permission === 'write' && isAuthorized && {
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album',
        onClickItem: handleAddPhotos
      },
      !selectedItemsCount && !!totalItemsCount && {
        label: t('a_albums_downloadAlbum'),
        iconName: 'download_empty',
        onClickItem: handleDownloadAlbum
      },
      !selectedItemsCount && !!totalItemsCount && isAuthorized && {
        label: t('a_common_addToMyCloud'),
        iconName: 'add_photo_alternate_1',
        onClickItem: handleAddAlbumToMyCloud,
      },
      !selectedItemsCount && !!totalItemsCount && isAuthorized && {
        label: t('a_common_removeLink'),
        iconName: 'unlink',
        onClickItem: handleOpenPublicLinkRemovingModal
      },
      !selectedItemsCount && !!totalItemsCount && permission === 'write' && isAuthorized && {
        label: t('a_common_renameAlbum'),
        iconName: 'pencil',
        onClickItem: handleRenameAlbum
      },
      selectedItemsCount === 1 && {
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadItem
      },
      !!selectedItemsCount && isAuthorized && {
        label: t('a_common_addToMyCloud'),
        iconName: 'add_photo_alternate_1',
        onClickItem: handleAddItemsToPersonalCloud,
      },
      !!selectedItemsCount && isAuthorized && {
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album',
        onClickItem: handleOpenAddToAlbumModal
      },
      !!selectedItemsCount && permission === 'write' && {
        label: t('a_albums_removeFromAlbum'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter(item => !!item)
  })()

  if (status === LOADING_STATUSES.LOADING) {
    return null
  }

  return (
    <PublicLinkSidebarBox className={className}>
      {
        !isAuthorized && !withoutMenu && (
          <StyledUserMenuForUnauthorized
            onLogIn={handleSignIn}
            onCreateAccount={handleSignUp}
            texts={{
              logIn: t('a_common_logIn'),
              or: t('l_common_or'),
              createAccount: t('a_common_signUp')
            }}
          />
        )
      }

      {
        !!isAuthorized && !withoutMenu && (
          <StyledUserMenu />
        )
      }

      {
        (!error || error === PublicLinksErrorTypes.EMPTY_ALBUM) && (
          <>
            <ToolbarBox>
              {!selectedItemsCount && permission === 'read' && (
                <StyledPrimaryButton
                  actionName={t('a_common_download')}
                  onAction={handleDownloadAlbum}
                />
              )}

              {!selectedItemsCount && permission === 'write' && !totalItemsCount && (
                <StyledPrimaryButton
                  actionName={t('a_common_download')}
                  onAction={handleDownloadAlbum}
                />
              )}

              {!selectedItemsCount && !!totalItemsCount && permission === 'write' && (
                <StyledPrimaryButton
                  actionName={t('a_common_addToAlbum')}
                  onAction={handleAddPhotos}
                />
              )}

              {selectedItemsCount === 1 && (
                <StyledPrimaryButton
                  actionName={t('a_common_download')}
                  onAction={handleDownloadItem}
                />
              )}

              {selectedItemsCount > 1 && (
                <StyledPrimaryButton
                  actionName={t('a_common_addToMyCloud')}
                  onAction={handleAddItemsToPersonalCloud}
                />
              )}

              {
                isAuthorized && !!menuItems.length &&
                <MenuWithActionItems menuItems={menuItems} />
              }
            </ToolbarBox>

            <MobileToolbar
              items={mobileMenuItems}
              headContent={!!selectedItemsCount && (
                <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
              )}
            />
          </>
        )}

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItemsCount })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={linkRemovingModalOpened}
        title={t('l_publicLink_removeConfirmTitle')}
        icon='warning'
        onClose={handleClosePublicLinkRemovingModal}
        okText={t('a_common_remove')}
        onOk={handleRemoveLink}
        cancelText={t('a_common_cancel')}
        onCancel={handleClosePublicLinkRemovingModal}
        type={ConfirmationModalTypes.danger}
      >
        <TextInModalBox>
          {t('l_publicLink_removeSharedConfirmMessage')}
        </TextInModalBox>
      </DialogModal>
    </PublicLinkSidebarBox>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
  max-width: 560px;
  color: var(--text-secondary);
`

const PublicLinkSidebarBox = styled.div`
  width: 100%;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledUserMenuForUnauthorized = styled(UserMenuForUnauthorized)`
  display: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: block;
    padding: 0 24px;
  }
`

const StyledUserMenu = styled(UserMenu)`
  display: none;
  
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
   display: block;
  }
`

const ToolbarBox = styled.div`
  display: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: block;
    padding: 0 24px;
  }
`