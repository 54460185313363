export enum NOTIFICATION_TYPES {
  CONSTANT_SUCCESS = 'CONSTANT_SUCCESS',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  FEEDBACK = 'FEEDBACK'
}

export type INotification = {
  type: NOTIFICATION_TYPES,
  title: string,
  message?: string,
  typeError?: string,
  isPermanent?: boolean,
  id?: number | string,
  callback?: () => void,
  positiveFeedbackCallback?: () => void,
  negativeFeedbackCallback?: () => void,
  timeoutToHide?: number,
  userCantHide?: boolean,
  withHiddenArrow?: boolean,
  suffixText?: string,
  isCallbackAfterHide?: boolean
}

export type NotificationsListener = (notifications: INotification[]) => void
