import { LOADING_STATUSES } from "constants/loadingStatuses"
import { LEFT_MENU_ACTIVE_ITEMS } from "constants/leftMenu"

import { useEffect } from "react"

import styled from 'styled-components'
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { AlbumSkeleton, PhotosGrid, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import {
  loadPublicLinksItemsFirstTimeThunk,
  PUBLIC_LINKS_TYPES,
  publicLinksActions,
  subscribePublicLinksToWSThunk,
  unsubscribePublicLinksFromWSThunk
} from "features/public-links-albums/publicLinksSlice"
import {
  getPublicLinksLoadingStatusSelector,
  getPublicLinksSelectedItemsSelector
} from "features/public-links-albums/selectors"
import { PublicAlbums } from "features/public-links-albums/PublicLinksAlbums"
import { SearchActiveStatuses, SearchTypes, WithSearch } from "features/photo-search/WithSearch"
import { SDK_TYPES } from "sdk/sdkConstants"
import { useMobileDetection } from "features/common/hooks"
import { appActions } from "store/app"


interface PublicLinksPageProps {
  type: PUBLIC_LINKS_TYPES
}

const PublicLinksAlbumsPage: React.FC<PublicLinksPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isMobile = useMobileDetection()

  const status = getPublicLinksLoadingStatusSelector()
  const selectedItems = getPublicLinksSelectedItemsSelector()

  const selectedItemsCount = selectedItems.length

  usePageTitle('a_nav_publicLinks')

  useEffect(() => {
    dispatch(appActions.setLeftMenuActiveItem(type === PUBLIC_LINKS_TYPES.ALBUMS ? LEFT_MENU_ACTIVE_ITEMS.TIMELINE : LEFT_MENU_ACTIVE_ITEMS.FAMILY_TIMELINE))
    dispatch(loadPublicLinksItemsFirstTimeThunk({ type }))
    dispatch(subscribePublicLinksToWSThunk())

    return () => {
      dispatch(publicLinksActions.resetState())
      dispatch(unsubscribePublicLinksFromWSThunk())
      dispatch(appActions.setLeftMenuActiveItem(null))
    }
  }, [])

  const handleCancelSelection = () => {
    dispatch(publicLinksActions.unselectAll())
  }

  const pageContent = (
    <>
      {
        status === LOADING_STATUSES.LOADING &&
        <Skeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING &&
        (
          <>
            <PublicAlbums type={type} />
          </>
        )
      }

      {!!selectedItems.length && <MobileToolbarMenuToggle />}
    </>
  )

  return (
    <WithSearch
      defaultPageContent={pageContent}
      type={SDK_TYPES.DEFAULT}
      defaultFilters={{
        s_active: SearchActiveStatuses.active,
        s_text: '',
        s_type: SearchTypes.publicLinks
      }}
      isSearchHidden={!!selectedItems.length}
      isChildrenVisible={!!selectedItems.length}
    >
      <SPageTopbar
        title={t('a_nav_publicLinks')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const Skeleton = () => {
  return (
    <PhotosGrid>

      {Array.from({ length: 14 }).map((_, index) => {
        return (
          <AlbumSkeleton key={index} />
        )
      })}

    </PhotosGrid>
  )
}

const SPageTopbar = styled(PageTopbar)`
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    .page-topbar-close-btn {
      margin-left: 0;
    }
  }
`

export default PublicLinksAlbumsPage
