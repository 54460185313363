import { useAppSelector } from "store"

import { albumsMemoriesSelectors, currentAlbumItemsSelectors } from "./albumsMemoriesSlice"

export const getAlbumsMemoriesLoadingStatusSelector = () => useAppSelector(state =>
  state.albumsMemories.status
)

export const getAlbumsMemoriesTotalCountSelector = () => useAppSelector(state =>
  state.albumsMemories.totalCount
)

export const getAlbumsMemoriesAlbumsSelector = () => useAppSelector(state => albumsMemoriesSelectors.selectAll(state.albumsMemories.albums))

export const getCurrentMemoriesAlbumDataSelector = () => useAppSelector(state => state.albumsMemories.currentAlbumData)

export const getCurrentMemoriesAlbumItemsSelector = () => useAppSelector(state => currentAlbumItemsSelectors.selectAll(state.albumsMemories.currentAlbumItems))

export const getCurrentMemoriesAlbumPhotoIndexSelector = () => useAppSelector(state => state.albumsMemories.currentPhotoIndex)

export const getCurrentMemoriesAlbumItemsLoadingStatusSelector = () => useAppSelector(state => state.albumsMemories.itemsLoadingStatus)

export const getCurrentMemoriesAlbumPlayerStateSelector = () => useAppSelector(state => state.albumsMemories.playerState)

export const getCurrentMemoriesAlbumIsModalOpenedSelector = () => useAppSelector(state => state.albumsMemories.isModalOpened)

export const getCurrentMemoriesAlbumSavedPlayerStateBeforeActionSelector = () => useAppSelector(state => state.albumsMemories.savedPlayerStateBeforeAction)

export const getCurrentMemoriesAlbumSelectedAlbumsIdsSelector = () => useAppSelector(state => state.albumsMemories.selectedAlbumsIds)

export const getSelectedMemoriesAlbumsSelector = () => 
  useAppSelector(state => albumsMemoriesSelectors.selectAll(state.albumsMemories.albums).filter(album => state.albumsMemories.selectedAlbumsIds.includes(album.id)))
