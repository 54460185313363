import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { subscribeAlbumsToWSThunk, unsubscribeAlbumsFromWSThunk } from "features/albums/albums-list/albumsSlice"
import { AlbumSkeleton, PhotosGrid } from "@cloudike/web_ui_components"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { useNavigate } from "react-router-dom"
import { AlbumType } from "@cloudike/web_photos"

import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { getAlbumsMomentsLoadingStatusSelector, getAlbumsMomentsItemsSelector } from "../../../features/albums/albums-moments/selectors"
import { albumsMomentsActions, fetchFirstMomentsAlbumsThunk } from "../../../features/albums/albums-moments/albumsMomentsSlice"
import { AlbumsMoments } from "../../../features/albums/albums-moments/AlbumsMoments"
import { AlbumsMomentsPageTopbar } from "../../../features/albums/albums-moments/AlbumsMomentsTopbar"

const AlbumsMomentsPage = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const status = getAlbumsMomentsLoadingStatusSelector()
  const items = getAlbumsMomentsItemsSelector()

  usePageTitle('a_files_uploadPhotoAlbums_title')

  useEffect(() => {
    dispatch(subscribeAlbumsToWSThunk())
    dispatch(albumsMomentsActions.setCurrentAlbumsType(type))
    dispatch(albumsMomentsActions.setMomentsType(AlbumType.MOMENTS))
    dispatch(fetchFirstMomentsAlbumsThunk())

    return () => {
      dispatch(albumsMomentsActions.resetState())
      dispatch(unsubscribeAlbumsFromWSThunk())
    }
  }, [])

  const handleBackButtonClick = () => {
    if(type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums`)
      return
    }
    navigate(`/photos/albums`)
  }

  return (
    <>
      <AlbumsMomentsPageTopbar
        title={t('l_albums_moments')}
        onBack={handleBackButtonClick}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <Skeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && !!items.length && (
          <AlbumsMoments type={type} />
        )
      }
    </>
  )
}

const Skeleton = () => {
  return (
    <PhotosGrid>
      {Array.from({ length: 16 }).map((_, index:number) => <AlbumSkeleton key={index} />)}
    </PhotosGrid>
  )
}

export default AlbumsMomentsPage
