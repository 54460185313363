import _ from "lodash"

export const selectPhotoListItemReducer = (selectors) => (state, action: { payload: string}) => {
  const id = action.payload

  const indexOfItemId = state.selectedItemsIds.indexOf(id)

  if (indexOfItemId === -1) {
    state.selectedItemsIds = [...state.selectedItemsIds, id]
  } else {
    state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
  }
  
  const nodes = selectors.selectAll(state)
  const indexOfNode = nodes.findIndex(node => node.id === id)

  if (state.selectedItemsIds.length) {
    state.lastSelectedIndexes = [indexOfNode]
  } else {
    state.lastSelectedIndexes = []
  }
    
  state.lastSelectedWithShiftIndex = null
}

export const selectPhotoListItemWithPressedShiftReducer = (selectors) => (state, action: { payload: { id: string }}) => {
  const { id } = action.payload

  const nodes = selectors.selectAll(state)
  const indexOfNode = nodes.findIndex(node => node.id === id)
  const lastSelectedIndex = state.lastSelectedIndexes[state.lastSelectedIndexes.length - 1]
  const lastSelectedWithShiftIndex = state.lastSelectedWithShiftIndex

  let itemsIdsToExclude = []
  let newIds = []

  if (lastSelectedWithShiftIndex !== null) {
    if (indexOfNode < lastSelectedWithShiftIndex) {
      itemsIdsToExclude = [
        ...nodes.slice(indexOfNode + 1, lastSelectedIndex),
        ...nodes.slice(lastSelectedIndex + 1, lastSelectedWithShiftIndex + 1)
      ]
        .map(node => node.id)
    } else {
      itemsIdsToExclude = [
        ...nodes.slice(lastSelectedWithShiftIndex, lastSelectedIndex),
        ...nodes.slice(lastSelectedIndex + 1, indexOfNode)
      ]
        .map(node => node.id)
    }
  }

  const newState = state.selectedItemsIds.filter(id => !itemsIdsToExclude.includes(id))

  if (indexOfNode >= lastSelectedIndex) {
    newIds = nodes.slice(lastSelectedIndex, indexOfNode + 1).map(node => node.id)
  } else {
    newIds = nodes.slice(indexOfNode, lastSelectedIndex).map(node => node.id)
  }

  state.selectedItemsIds = _.uniq([...newState, ...newIds])
  state.lastSelectedWithShiftIndex = indexOfNode
}
  