import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { AlbumPlaceCard, AlbumsPlacesGrid, PageSpiner } from '@cloudike/web_ui_components'
import { useInView } from "react-intersection-observer"

import { IExtendedAlbumSchema } from "../albums-list/albumsSlice"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { getPreviewAlbumLink } from "../../../utils/getPreviewAlbumLink"
import { useAppDispatch } from "../../../store"

import {
  getAlbumsMomentsItemsSelector,
  getAlbumsMomentsTotalCountSelector, getEmptyAlbumsCountSelector
} from "./selectors"
import { loadMoreMomentsAlbumsThunk } from "./albumsMomentsSlice"


export const AlbumsMoments = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const albumsMoments = getAlbumsMomentsItemsSelector()
  const totalItemsCount = getAlbumsMomentsTotalCountSelector()
  const emptyAlbumsCount  = getEmptyAlbumsCountSelector()

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const loadMore = () => {
    dispatch(loadMoreMomentsAlbumsThunk())
  }

  useEffect(() => {
    if (albumsMoments.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, albumsMoments])

  const handleCardClick = (album: IExtendedAlbumSchema) => {
    const link = type === SDK_TYPES.FAMILY ? `/family/photos/albums/moments/${album.id}` : `/photos/albums/moments/${album.id}`

    navigate(link)
  }

  return (
    <>
      <AlbumsBox className={className}>
        <AlbumsPlacesGrid>
          {albumsMoments.map(album => (
            <AlbumPlaceCard
              key={album.id}
              id={album.id}
              albumName={album.description || t('l_common_untitledAlbum')}
              albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
              imgUrl={getPreviewAlbumLink(album,'middle')}
              onClick={() => handleCardClick(album)}
            />
          ))}
        </AlbumsPlacesGrid>

        {(albumsMoments.length + emptyAlbumsCount) < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner  />
          </div>
        )}
      </AlbumsBox>
    </>
  )
}

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`


const AlbumsBox = styled.div`
  width: 100%;
  padding-bottom: 40px;
  height: calc(100% - 60px);
  overflow: hidden scroll;


  @supports (-moz-appearance: none) {
    scrollbar-color: var(--scroll-color) transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`
