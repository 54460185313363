import { LOADING_STATUSES } from "constants/loadingStatuses"
import { STORAGE_CONSTANTS } from "constants/storage"

import { useCallback, useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import styled from 'styled-components'
import {
  loadFlashbackAlbumsThunk,
  loadTimelineItemsFirstTimeThunk,
  recalculateDigestsThunk,
  subscribeTimelineToWSThunk,
  TIMELINE_FILTERS,
  timelineActions,
  toggleTimelineAllItemsLoadingInProgress,
  unsubscribeTimelineFromWSThunk
} from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import {
  getSortFormatSelector,
  getTimelineAreAllItemsLoadedSelector,
  getTimelineFilteredItemsRawSelector,
  getTimelineFilterSelector,
  getTimelineLoadingSmartStatusSelector,
  getTimelineLoadingStatusSelector,
  getTimelineRawDigestsSelector,
  getTimelineSelectedItemsCountSelector
} from "features/photo/timeline/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { useCardsPerRow } from "features/photo/timeline/useCardsPerRow"
import { convertItemsToPhotoDigests } from "utils/timelineDigests"
import { appActions } from "store/app"
import { saveNavigationPathname } from "utils/navigationStorage"
import { IItemSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumItem"

import { WithSearch } from "../../features/photo-search/WithSearch"
import { albumsPersonActions, fetchPersonAlbumsThunk } from "../../features/albums/albums-person/albumPersonSlice"
import { useUploadTimelineItemsUploadHandler } from "../../features/photo/timeline/useUploadFilesHandler"
import { albumsActions } from "../../features/albums/albums-list/albumsSlice"
import { albumActions } from "../../features/albums/album/albumSlice"

const TimelinePage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const filter = getTimelineFilterSelector()
  const areAllItemsLoaded = getTimelineAreAllItemsLoadedSelector()

  const status = getTimelineLoadingStatusSelector()
  const loadingSmartStatus = getTimelineLoadingSmartStatusSelector()

  const isMobile = useMobileDetection()

  const sortFormat = getSortFormatSelector()
  const cardsPerRow = useCardsPerRow(sortFormat)

  const rawDigests = getTimelineRawDigestsSelector()
  const items = useAppSelector(getTimelineFilteredItemsRawSelector) as IItemSchema[]

  usePageTitle('a_nav_timeline')

  useEffect(() => {
    saveNavigationPathname(STORAGE_CONSTANTS.BEFORE_PHOTOS_SEARCH_PATHNAME, window.location.pathname)
    dispatch(appActions.setIsMainContainerWithFullWidth(true))
    dispatch(timelineActions.setCurrentTimelineType(SDK_TYPES.DEFAULT))
    dispatch(albumsPersonActions.setCurrentAlbumsType(SDK_TYPES.DEFAULT))
    dispatch(albumsActions.setCurrentAlbumsType(SDK_TYPES.DEFAULT))
    dispatch(albumActions.setCurrentAlbumsType(SDK_TYPES.DEFAULT))
    dispatch(subscribeTimelineToWSThunk())
    dispatch(loadFlashbackAlbumsThunk())
    dispatch(fetchPersonAlbumsThunk({ }))

    return () => {
      dispatch(appActions.setIsMainContainerWithFullWidth(false))
      dispatch(unsubscribeTimelineFromWSThunk())
      dispatch(timelineActions.resetState())
      dispatch(timelineActions.setFilter(TIMELINE_FILTERS.all))
      toggleTimelineAllItemsLoadingInProgress(false)
    }
  }, [])

  useEffect(() => {
    if (cardsPerRow === 0) {
      return
    }

    dispatch(loadTimelineItemsFirstTimeThunk({ cardsPerRow, withDigests: true }))
  }, [cardsPerRow !== 0])

  useEffect(() => {
    dispatch(timelineActions.setRawDigests(convertItemsToPhotoDigests(items)))
  }, [items])

  useEffect(() => {
    if (!cardsPerRow) {
      return
    }

    dispatch(recalculateDigestsThunk({ cardsPerRow, groupBy: sortFormat.toLowerCase() as 'year' | 'month' | 'day', data: rawDigests }))
  }, [cardsPerRow, filter, sortFormat, rawDigests])

  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const handleUploadFiles = useUploadTimelineItemsUploadHandler(SDK_TYPES.DEFAULT, filter)

  const pageContent = (
    <>
      {
        (status === LOADING_STATUSES.LOADING || loadingSmartStatus === LOADING_STATUSES.LOADING || (!areAllItemsLoaded && filter !== TIMELINE_FILTERS.all)) &&
        <StyledSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && loadingSmartStatus !== LOADING_STATUSES.LOADING && (filter === TIMELINE_FILTERS.all || areAllItemsLoaded) && (
          <Timeline
            type={SDK_TYPES.DEFAULT}
            withMemories
            withFilters
            isTimelinePage={true}
            cardsPerRow={cardsPerRow}
            withDigests
          />
        )
      }

      <MobileToolbarMenuToggle
        iconName={!selectedItemsCount ? 'plus' : 'more_horiz'}
        onChange={handleUploadFiles}
        type={!selectedItemsCount ? 'file' : 'button'}

      />
    </>
  )

  return (
    <WithSearch
      isSearchHidden={!!selectedItemsCount}
      defaultPageContent={pageContent}
    >
      <SPageTopbar
        title={t('a_nav_timeline')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const SPageTopbar = styled(PageTopbar)`
  padding-bottom: 0;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    margin-left: -8px;
  }
`

const StyledSkeleton = styled(PageTimelineSkeleton)`
  &&{
    padding: 0;
  } 
`

export default TimelinePage
