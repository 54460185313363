import { LOADING_STATUSES } from "constants/loadingStatuses"
import { LEFT_MENU_ACTIVE_ITEMS } from "constants/leftMenu"

import { useEffect } from "react"

import styled from 'styled-components'
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { AlbumSkeleton, PhotosGrid, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import {
  getSharedWithMeAlbumsLoadingStatusSelector,
  getSharedWithMeAlbumsSelectedItemsSelector
} from "features/shared-with-me-albums/selectors"
import { SearchActiveStatuses, SearchTypes, WithSearch } from "features/photo-search/WithSearch"
import { SDK_TYPES } from "sdk/sdkConstants"
import { SharedWithMeAlbums } from "features/shared-with-me-albums/SharedWithMeAlbums"
import { loadSharedWithMeAlbumsFirstTimeThunk, sharedWithMeAlbumsActions } from "features/shared-with-me-albums/sharedWithMeAlbumsSlice"
import { appActions } from "store/app"
import { useMobileDetection } from "features/common/hooks"

const SharedWithMeAlbumsPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobile = useMobileDetection()

  const status = getSharedWithMeAlbumsLoadingStatusSelector()
  const selectedItems = getSharedWithMeAlbumsSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  usePageTitle('a_nav_publicLinks')

  useEffect(() => {
    dispatch(appActions.setLeftMenuActiveItem(LEFT_MENU_ACTIVE_ITEMS.TIMELINE))
    dispatch(loadSharedWithMeAlbumsFirstTimeThunk())

    return () => {
      dispatch(appActions.setLeftMenuActiveItem(null))
      dispatch(sharedWithMeAlbumsActions.resetState())
    }
  }, [])

  const handleCancelSelection = () => {
    dispatch(sharedWithMeAlbumsActions.unselectAll())
  }

  const pageContent = (
    <>
      {
        status === LOADING_STATUSES.LOADING &&
        <Skeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING &&
        (
          <>
            <SharedWithMeAlbums />
          </>
        )
      }

      {!!selectedItems.length && <MobileToolbarMenuToggle />}
    </>
  )

  return (
    <WithSearch
      defaultPageContent={pageContent}
      type={SDK_TYPES.DEFAULT}
      defaultFilters={{
        s_active: SearchActiveStatuses.active,
        s_text: '',
        s_type: SearchTypes.sharedWithMe
      }}
      isSearchHidden={!!selectedItems.length}
      isChildrenVisible={!!selectedItems.length}
    >
      <SPageTopbar
        title={t('a_nav_publicLinks')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const Skeleton = () => {
  return (
    <PhotosGrid>

      {Array.from({ length: 14 }).map((_, index) => {
        return (
          <AlbumSkeleton key={index} />
        )
      })}

    </PhotosGrid>
  )
}

const SPageTopbar = styled(PageTopbar)`
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    .page-topbar-close-btn {
      margin-left: 0;
    }
  }
`

export default SharedWithMeAlbumsPage
