import React, { useState } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { IAlbumCover } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumCover"

import photoPlaceholderImg from '../../../assets/noPictSeason.svg'

export interface AlbumCardProps {
    className?: string,
    isSelected?: boolean,
    id: string,
    onSelect?: (id: string, isSelected: boolean) => void,
    onClick?: (id: string) => void,
    imgUrls?: IAlbumCover[],
    isCheckboxVisibleWithoutHover?: boolean,
    description?: string
    albumName: string
    albumPhotoCount: string
    onlyTitle?: boolean
    showFullName?: boolean
}

export const AlbumsMemoriesCard: React.FC<AlbumCardProps> = ({
  className = '',
  imgUrls,
  description,
  isSelected,
  onSelect,
  onClick,
  id,
  isCheckboxVisibleWithoutHover,
  albumName,
  albumPhotoCount,
  onlyTitle = false,
  showFullName = false
}) => {
  const [imageLoaded, toggleImageLoading] = useState(false)

  const handleImgLoad = () => {
    toggleImageLoading(true)
  }

  const handleClick = () => {
    if (isCheckboxVisibleWithoutHover) {
            onSelect!(id, !isSelected)
            return
    }

        onClick!(id)
  }

  function handleSelect(e: MouseEvent) {
    e.stopPropagation()
        onSelect!(id, !isSelected)
  }

  const albumNameSeason = albumName.split(' ')[0]
  const albumYearSeason = albumName.split(' ')[1]

  const getHrefImage = (index: number) => {
    return imgUrls && !!imgUrls.length && !!imgUrls[index] ? (imgUrls[index]._links as any)?.image_middle?.href : index > 0 ? null : photoPlaceholderImg
  }

  return (
    <AlbumBox>
      <AlbumCardBox
        className={classNames(className, { selected: isSelected })}
        onClick={handleClick}
      >
        <AlbumImgBox>
          <AlbumImgOne
            src={getHrefImage(0)}
            alt={description}
            onLoad={handleImgLoad}
            className={classNames({ loading: !imageLoaded, oneImage: imgUrls?.length === 1 || !imgUrls })}
            width={imgUrls?.length > 1 ? '140px' : '100%'}
            borderRadius={imgUrls?.length > 1 ? '4px 0 0 4px' : '4px'}
          />

          {!!getHrefImage(1) && (
            <AlbumImgTwo
              src={getHrefImage(1)}
              alt={description}
              onLoad={handleImgLoad}
              className={classNames({ loading: !imageLoaded, twoImage: imgUrls?.length === 2 })}
              borderBottomRightRadius={!getHrefImage(2) ? '4px' : '0'}
              height={!getHrefImage(2) ? '100%' : '81px'}
            />
          )}

          {!!getHrefImage(2) && (
            <AlbumImgThree
              src={getHrefImage(2)}
              alt={description}
              onLoad={handleImgLoad}
              className={classNames({ loading: !imageLoaded })}
            />
          )}
        </AlbumImgBox>

        {!!onSelect && (
          <>
            <CheckCircleIconBox className={classNames({ visible: isCheckboxVisibleWithoutHover })}>
              <SpriteIcon iconName="circle" />
            </CheckCircleIconBox>

            <CheckIconBox className={classNames({ checked: isSelected })}
              onClick={handleSelect}
            >
              <CheckedIcon iconName="circle_checked"
                className={classNames({ checked: isSelected })}
              />
            </CheckIconBox>

            <PhotoOverlay className={classNames({ checked: isSelected })} />
          </>
        )}
      </AlbumCardBox>

      <AlbumName>
        {showFullName ? albumName : albumNameSeason}
      </AlbumName>

      {!onlyTitle && (
        <AlbumDescription>
          <AlbumModified>
            {albumYearSeason}
          </AlbumModified>

          <AlbumPhotoCount>
            {albumPhotoCount}
          </AlbumPhotoCount>
        </AlbumDescription>
      )}
    </AlbumBox>
  )
}

const AlbumBox = styled.div`
`

const AlbumCardBox = styled.div`
  position: relative;
  cursor: pointer;
  background-color: var(--background-tertiary);
  padding-bottom: 100%;
  user-select: none;
  border-radius: 4px;

  &::before {
    content: "";
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transition: opacity 300ms;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    height: 164px;
    padding-bottom: unset;
  }
`

const AlbumImgBox = styled.div`
`

const PhotoOverlay = styled.div`
  background: transparent;
  transition: all 300ms;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  border-radius: 4px;

  ${AlbumCardBox}:hover & {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(255, 255, 255, 0) 50.57%);
  }

  &.checked {
    background: transparent;
  }
`

const CheckCircleIconBox = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 4;
  color: var(--tag-icon);
  transition: all 300ms;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
`

const CheckIconBox = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0;
  z-index: 4;
  color: var(--tag-icon);
  transition: all 300ms;

  &.checked {
    color: var(--accent-normal);
    opacity: 1;

    ${AlbumCardBox}:hover & {
      opacity: 1;
    }
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    ${AlbumCardBox}:hover & {
      opacity: 1;
    }
  }
`

const AlbumImgOne = styled.img`
  object-fit: cover;
  z-index: 2;
  width: 100%;
  transition: all 200ms ease;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  background-color: transparent;
  border-radius: 4px;

  &.loading {
    opacity: 0;
  }

  ${AlbumCardBox}.selected & {
    transform: scale(0.87);
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    border-radius: ${props => props.borderRadius};
    width: ${props => props.width};
    height: 164px;

    ${AlbumCardBox}.selected & {
      transform: scale(1, 0.83) translateX(10%)
    }

    &.oneImage {
      ${AlbumCardBox}.selected & {
        transform: scale(0.9, 0.83) translateX(0)
      }
    }
  }
`

const AlbumImgTwo = styled.img`
  object-fit: cover;
  z-index: 2;
  width: 139px;
  transition: all 200ms ease;
  position: absolute;
  top: 0;
  right: 0;
  height: ${props => props.height};
  background-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: ${props => props.borderBottomRightRadius};
  margin-left: 2px;
  display: unset;

  &.loading {
    opacity: 0;
  }

  ${AlbumCardBox}.selected & {
    transform: scale(0.8, 0.83) translateY(10%);
  }

  &.twoImage {
    ${AlbumCardBox}.selected & {
      transform: scale(0.8, 0.83);
    }
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    display: none;
  }
`

const AlbumImgThree = styled.img`
  object-fit: cover;
  z-index: 2;
  width: 139px;
  transition: all 200ms ease;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 81px;
  background-color: transparent;
  margin-top: 2px;
  border-bottom-right-radius: 4px;
  display: unset;

  &.loading {
    opacity: 0;
  }

  ${AlbumCardBox}.selected & {
    transform: scale(0.8, 0.83) translateY(-10%);
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    display: none;
  }
`


const AlbumName = styled.h1`
  margin-top: 8px;
  font-size: 14px;
  color: var(--text-primary);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const AlbumDescription = styled.div`
  margin-top: 4px;
  font-size: 12px;
  display: flex;
  color: var(--text-secondary);
  flex-wrap: wrap;
`

const AlbumModified = styled.p`
  margin-right: 16px;
`

const AlbumPhotoCount = styled.p`

`

const CheckedIcon = styled(SpriteIcon)`
  &.checked {
    border-radius: 50%;
    background-color: var(--background-tertiary);
  }
`
