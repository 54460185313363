import i18n, { use } from "i18next"
import { initReactI18next } from "react-i18next"
import ICU from 'i18next-icu'
import LngDetector from 'i18next-browser-languagedetector'

import translationEN from './src/i18n/en.json'
import translationRU from './src/i18n/ru.json'
import translationES from './src/i18n/es.json'
import translationTR from './src/i18n/tr.json'
import translationKK from './src/i18n/kk.json'
import translationID from './src/i18n/id.json'

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  es: {
    translation: translationES
  },
  tr: {
    translation: translationTR
  },
  kk: {
    translation: translationKK
  },
  id: {
    translation: translationID
  }
}

use(ICU)
  .use(LngDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "langKey",
      caches: ["localStorage"]
    },
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
