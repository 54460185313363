import { useAppSelector } from "store"

import { photoPreviewTransformerSelectors } from "./photoPreviewTransformerSlice"

export const getPhotoPreviewTransformerItemsSelector = () => useAppSelector(state => photoPreviewTransformerSelectors.selectAll(state.photoPreviewTransformer))

export const getCurrentPhotoPreviewTransformerItemIndexSelector = () => useAppSelector(state => state.photoPreviewTransformer.currentItemIndex)

export const getCurrentPhotoPreviewTransformerModalStatusSelector = () => useAppSelector(state => state.photoPreviewTransformer.isModalOpened)

export const getCurrentPhotoPreviewTransformerSdkTypeSelector = () => useAppSelector(state => state.photoPreviewTransformer.sdkType)

export const getCurrentPhotoPreviewTransformerTypeSelector = () => useAppSelector(state => state.photoPreviewTransformer.type)

export const getPhotoPreviewTransformerLoadingSelector = () => useAppSelector(state => state.photoPreviewTransformer.loadingTransformer)

export const getPhotoPreviewTransformerLoadingMoreSelector = () => useAppSelector(state => state.photoPreviewTransformer.loadingMore)

export const getPhotoPreviewTransformerErrorSelector = () => useAppSelector(state => state.photoPreviewTransformer.hasError)

export const getPhotoPreviewTransformerTotalItemsCountSelector = () => useAppSelector(state => state.photoPreviewTransformer.totalItemsCount)

export const getPhotoPreviewTransformerIsViewedSelector = () => useAppSelector(state => state.photoPreviewTransformer.isViewed)

export const getIndexQueueSelector = () => useAppSelector(state => state.photoPreviewTransformer.indexQueue)

export const getErrorArraySelector = () => useAppSelector(state => state.photoPreviewTransformer.errorArray)

export const getWidgetTransformerVisibleSelector = () => useAppSelector(state => state.photoPreviewTransformer.isWidgetTransformerVisible)

export const getInProgressSelector = () => useAppSelector(state => state.photoPreviewTransformer.inProgress)

export const getStartArraySelector = () => useAppSelector(state => state.photoPreviewTransformer.startArray)

export const getMaxInProgressSelector = () => useAppSelector(state => state.photoPreviewTransformer.maxInProgress)

export const getIntervalIdsArraySelector = () => useAppSelector(state => state.photoPreviewTransformer.intervalIdsArray)

export const getAIEditorActiveSelector = () => useAppSelector(state => state.photoPreviewTransformer.isAIEditorActive)

export const getPreviewTransformerOpenSelector = () => useAppSelector(state => state.photoPreviewTransformer.isPreviewTransformerOpen)

export const getOnbordingModalOpenSelector = () => useAppSelector(state => state.photoPreviewTransformer.isOnbordingModalOpen)

export const getLoadingAITransformationStatusSelector = () => useAppSelector(state => state.photoPreviewTransformer.loadingAITransformationStatus)

export const getUploadLoadingSelector = () => useAppSelector(state => state.photoPreviewTransformer.isUploadLoading)
