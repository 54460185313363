import { LOADING_STATUSES } from "constants/loadingStatuses"
import { STORAGE_CONSTANTS } from "constants/storage"

import React, { useCallback, useEffect, useRef, useState } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "store"
import styled from 'styled-components'
import {
  insertElementsToTimelineThunk,
  loadTimelineItemsFirstTimeThunk,
  recalculateDigestsThunk,
  subscribeTimelineToWSThunk,
  TIMELINE_FILTERS,
  timelineActions,
  toggleTimelineAllItemsLoadingInProgress,
  unsubscribeTimelineFromWSThunk
} from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import {
  getSortFormatSelector,
  getTimelineFilteredItemsRawSelector,
  getTimelineFilterSelector,
  getTimelineLoadingStatusSelector,
  getTimelineRawDigestsSelector,
  getTimelineSelectedItemsCountSelector
} from "features/photo/timeline/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { withCreateFamilyPlaceholder } from "features/family/CreateFamilyPlaceholder"
import { useMobileDetection } from "features/common/hooks"
import {
  familyActions,
  fetchFamilyThunk, joinToFamilyCloudThunk,
  subscribeFamilyToWSThunk,
  unsubscribeFamilyFromWSThunk
} from "features/family/familySlice"
import { getFamilyDataSelector, getFamilyMembersSelector } from "features/family/selectors"
import { hideNotification, INotification, NOTIFICATION_TYPES, showNotification } from "features/common/notifications"
import { useNavigate, useSearchParams } from "react-router-dom"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { getUploadingFilesSelector } from "features/common/files-uploading/selectors"
import { UPLOADER_LIST_TYPES } from "features/common/files-uploading/filesUploadingSlice"
import { useCardsPerRow } from "features/photo/timeline/useCardsPerRow"
import { appActions } from "store/app"
import { saveNavigationPathname } from "utils/navigationStorage"
import { convertItemsToPhotoDigests } from "utils/timelineDigests"
import { IItemSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumItem"

import { WithSearch } from "../../../features/photo-search/WithSearch"
import { albumsPersonActions, fetchPersonAlbumsThunk } from "../../../features/albums/albums-person/albumPersonSlice"
import { useUploadTimelineItemsUploadHandler } from "../../../features/photo/timeline/useUploadFilesHandler"
import { Onboarding } from "../../../features/family/Onboarding"
import { getUserDataSelector, getUserSettingsSelector } from "../../../features/user/selectors"
import { updateUserDataThunk } from "../../../features/user/userSlice"
import { getErrorData } from "../../../utils/getErrorData"
import { FamilyInvitationWarningModal } from "../FamilyInvitation/FamilyInvitationWarningModal"
import { LoadingOverlay } from "../../../ui/LoadingOverlay"
import { albumsActions } from "../../../features/albums/albums-list/albumsSlice"
import { albumActions } from "../../../features/albums/album/albumSlice"

const TimelinePage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const items = useAppSelector(getTimelineFilteredItemsRawSelector) as IItemSchema[]
  const rawDigests = getTimelineRawDigestsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const familyData = getFamilyDataSelector()
  const familyMembers = getFamilyMembersSelector()
  const status = getTimelineLoadingStatusSelector()
  const uploadingFiles = getUploadingFilesSelector()
  const filter = getTimelineFilterSelector()
  const userSettings = getUserSettingsSelector()
  const userData = getUserDataSelector()
  const isOwner = userData?.is_owner_family

  const sortFormat = getSortFormatSelector()
  const cardsPerRow = useCardsPerRow(sortFormat)

  const isMobile = useMobileDetection()

  const notificationRef = useRef<INotification>()

  usePageTitle('a_nav_timeline')

  useEffect(() => {
    saveNavigationPathname(STORAGE_CONSTANTS.BEFORE_PHOTOS_SEARCH_PATHNAME, window.location.pathname)
    dispatch(appActions.setIsMainContainerWithFullWidth(true))
    dispatch(timelineActions.setCurrentTimelineType(SDK_TYPES.FAMILY))
    dispatch(albumsPersonActions.setCurrentAlbumsType(SDK_TYPES.FAMILY))
    dispatch(albumsActions.setCurrentAlbumsType(SDK_TYPES.FAMILY))
    dispatch(albumActions.setCurrentAlbumsType(SDK_TYPES.FAMILY))
    dispatch(subscribeTimelineToWSThunk())
    dispatch(subscribeFamilyToWSThunk({ navigate }))
    dispatch(fetchFamilyThunk())
    dispatch(fetchPersonAlbumsThunk({ }))

    return () => {
      dispatch(appActions.setIsMainContainerWithFullWidth(false))
      dispatch(unsubscribeTimelineFromWSThunk())
      dispatch(unsubscribeFamilyFromWSThunk())

      dispatch(timelineActions.resetState())
      dispatch(timelineActions.setFilter(TIMELINE_FILTERS.all))
      toggleTimelineAllItemsLoadingInProgress(false)
      
      if (notificationRef.current) {
        hideNotification(notificationRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!!searchParams.get('onboarding') && !!userSettings['family.onboarding'] && !userSettings['family.onboarding'].web_finished && !isOwner) {
      dispatch(familyActions.setIsOnboardingActive(true))
    }
  }, [familyData])

  useEffect(() => {
    if ((!familyData?.invite_hash || familyMembers.length > 1) && notificationRef.current) {
      hideNotification(notificationRef.current)

      return
    }

    if (notificationRef.current) {
      return
    }

    const callback = () => {
      dispatch(familyActions.toggleFamilyinviteModal(true))
    }

    if (familyMembers.length === 1 && !!familyData?.invite_hash) {
      notificationRef.current = showNotification({
        type: NOTIFICATION_TYPES.CONSTANT_SUCCESS,
        title: t('l_common_inviteText'),
        message: t('l_notification_familyCloudCreated'),
        isPermanent: true,
        callback
      })
    }
  }, [familyData?.invite_hash, familyMembers.length])

  useEffect(() => {
    const uploadedFamilyCloudFiles = uploadingFiles.filter(item =>
      item.state.isCompleted &&
      [UPLOADER_LIST_TYPES.FAMILY_TIMELINE, UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR].includes(item.uploadingSourceType)
      && item.uploadedItem)
      .map(item => item.uploadedItem)

    if (uploadedFamilyCloudFiles.length) {
      dispatch(insertElementsToTimelineThunk({ items: uploadedFamilyCloudFiles }))
    }
  }, [uploadingFiles])

  useEffect(() => {
    if (cardsPerRow === 0) {
      return
    }

    dispatch(loadTimelineItemsFirstTimeThunk({ cardsPerRow, withDigests: true }))
  }, [cardsPerRow !== 0])

  useEffect(() => {
    dispatch(timelineActions.setRawDigests(convertItemsToPhotoDigests(items)))
  }, [items])

  useEffect(() => {
    if (!cardsPerRow) {
      return
    }

    dispatch(recalculateDigestsThunk({ cardsPerRow, groupBy: sortFormat.toLowerCase() as 'year' | 'month' | 'day', data: rawDigests }))
  }, [cardsPerRow, filter, sortFormat, rawDigests])

  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const handleUploadFiles = useUploadTimelineItemsUploadHandler(SDK_TYPES.FAMILY)

  const pageContent = (
    <>
      <Onboarding />

      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <Timeline type={SDK_TYPES.FAMILY}
            withFilters
            isTimelinePage={true}
            cardsPerRow={cardsPerRow}
            withDigests
          />
        )
      }

      <MobileToolbarMenuToggle
        iconName={!selectedItemsCount ? 'plus' : 'more_horiz'}
        onChange={handleUploadFiles}
        type={!selectedItemsCount ? 'file' : 'button'}
      />
    </>
  )

  return (
    <WithSearch
      isSearchHidden={!!selectedItemsCount}
      defaultPageContent={pageContent}
      type={SDK_TYPES.FAMILY}
    >
      <PageTopbar
        title={t('a_nav_timeline')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const StyledSkeleton = styled(PageTimelineSkeleton)`
  &&{
    padding: 0;
  }
`

const withFamilyJoining = (Component: React.FC) => () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userData = getUserDataSelector()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const needToTryJoining = !!searchParams.get('fchash')

  const [familyJoiningChecked, toggleFamilyJoiningChecked] = useState<boolean>(!needToTryJoining)

  const [warningModalOpened, toggleWarningModal] = useState(false)

  const handleJoinToFamilyCloud = () => {
    const successCallback = (familyUserId: number, ownerId: number) => {
      navigate('/family/photos?onboarding=1', { replace: true })
      dispatch(updateUserDataThunk({ family_user_id: familyUserId, is_owner_family: userData?.id === ownerId }))

      toggleFamilyJoiningChecked(true)
    }

    const errorCallback = (error) => {
      if (error.code) {
        if (error.status === 409) {
          switch (error.code) {
          // if user was accepted
          case 'UserAlreadyMemberOfFamily':
            navigate('/family/photos', { replace: true })
            break
            // if user is 'owner' other family cloud
          case 'UserMemberOfOtherFamily':
            toggleWarningModal(true)
            break
          }
        } else {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_notification_confirmationFailed'),
            message: error.data.description
          })
        }
      } else {
        if(error.toString() === 'NotFoundError') {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_notification_inviteLinkIncorrect'),
          })
        } else {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            ...getErrorData(error),
          })
        }
      }

      toggleFamilyJoiningChecked(true)
    }

    dispatch(joinToFamilyCloudThunk({ hash: searchParams.get('fchash'), successCallback, errorCallback }))
  }

  useEffect(() => {
    if (needToTryJoining) {
      handleJoinToFamilyCloud()
    }
  }, [])

  const handleCloseWarningModal = () => {
    toggleWarningModal(false)
  }

  const handleManageFamilyClick = () => {
    navigate('/family/manage')
  }

  if (!familyJoiningChecked) {
    const pageContent = (
      <>
        <StyledSkeleton />

        <LoadingOverlay
          text={t('l_common_loading')}
          visible={true}
        />
      </>
    )

    return (
      <WithSearch
        isSearchHidden={false}
        defaultPageContent={pageContent}
        type={SDK_TYPES.FAMILY}
      >
        <PageTopbar
          title={t('a_nav_timeline')}
          selectedText={''}
          selectedItemsCount={0}
          onCancelSelection={() => undefined}
        />
      </WithSearch>
    )
  }

  return (
    <>
      <Component />

      <FamilyInvitationWarningModal
        opened={warningModalOpened}
        onOk={handleManageFamilyClick}
        onClose={handleCloseWarningModal}
      />

    </>
  )
}

export default withFamilyJoining(withCreateFamilyPlaceholder(TimelinePage, 'a_nav_timeline'))
