import { STYLED_VARIABLES } from 'constants/styledVariables'
import { getModalsRootElement } from 'constants/modals'

import { useEffect, useState } from 'react'

import { ConfirmationModal, ConfirmationModalTypes, MenuItem, MenuWithActionItems, PrimaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { DialogModal } from 'ui/DialogModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlbumType } from '@cloudike/web_photos'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { appActions } from 'store/app'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import _ from "lodash"
import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"

import { deleteSharedLinkThunk, getPublicLinkAndCopyThunk, sharingActions } from '../../sharing/sharingSlice'
import { getUserDataSelector, getUserSettingsByPathSelector } from "../../user/selectors"
import { addSelectedToFavoritesItemsThunk, removeSelectedFromFavoritesItemsThunk } from "../../favorites/favoritesSlice"
import { openPreviewDuplicateThunk } from "../../photo/photo-preview-duplicate/photoPreviewDuplicateSlice"
import { USER_SETTINGS_PATHS } from "../../user/constants"
import { REDIRECT_TO } from "../../../constants/searchParams"
import { MobileToolbarCounter } from "../../common/mobile-toolbar-counter/MobileToolbarCounter"
import { getIsOpenedAddPhotosToAlbumModal } from "../../common/selectors"
import { fetchAlbumsThunk } from "../albums-list/albumsSlice"

import {
  albumActions,
  copyAlbumItemsToFamilyCloudThunk,
  copyAlbumItemsToPersonalCloudThunk,
  createAndCopyLinkToPublicAlbumThunk,
  createAndShareAlbumThunk,
  downloadAlbumItemsThunk,
  downloadAlbumThunk,
  hideAlbumThunk,
  removeAlbumItemsThunk,
  removeAlbumThunk,
  unhideAlbumThunk
} from './albumSlice'
import {
  getAlbumDataSelector,
  getAlbumItemsSelector,
  getAlbumSelectedItemsSelector,
  getAlbumTotalItemsCountSelector
} from './selectors'

interface AlbumToolbarProps {
  className?: string,
  type: SDK_TYPES,
  isPlace?: boolean,
  isSeason?: boolean,
  isPerson?: boolean,
  isCalendar?: boolean,
  isMoments?: boolean,
  isMemory?: boolean,
  hideWithoutSelection?: boolean
}

export const AlbumToolbar: React.FC<AlbumToolbarProps> = ({
  className = '',
  type,
  isPlace = false,
  isSeason = false,
  isPerson = false,
  isCalendar = false,
  isMoments = false,
  isMemory = false,
  hideWithoutSelection
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const albumData = getAlbumDataSelector()
  const selectedItems = getAlbumSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  const items = getAlbumItemsSelector()
  const userData = getUserDataSelector()
  const isEnabledPhotoDuplicates = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.PHOTOS_DUPLICATES).enabled
  const isOpenedAddPhotosToAlbumModal = getIsOpenedAddPhotosToAlbumModal()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [removingItemsConfirmationModalOpened, toggleRemovingItemsConfirmationModal] = useState(false)
  const [publicLinkDataForDelete, setPublicLinkDataForDelete] = useState(null)
  const [hideAlbumModalOpened, toggleHideAlbumModal] = useState(false)
  const [unhideAlbumModalOpened, toggleUnhideAlbumModal] = useState(false)

  const location = useLocation()

  useEffect(() => {
    if (isOpenedAddPhotosToAlbumModal) {
      dispatch(appActions.setIsSomeSpecialModalOpened(true))
    } else {
      dispatch(appActions.setIsSomeSpecialModalOpened(false))
    }
  }, [isOpenedAddPhotosToAlbumModal])

  const isSelectedItemsHaveOnlyFavorite = () => {
    return selectedItems.some(item => !item.favorite)
  }

  const handleAddPhoto = (sdkType: SDK_TYPES) => {
    if (!albumData) return

    if (isMemory) {
      navigate(`/photos/memories/${albumData.id}/add`, { state: { from: `${window.location.pathname}${window.location.search}` } })

      dispatch(appActions.toggleRightMenuOnMobile(false))

      return
    }

    const redirectToPath = location.pathname
    const familyCloudPart = 'family/photos'
    const toAlbumPart = `albums/${albumData.id}/add`
    const redirectPathParam = `${REDIRECT_TO}=${redirectToPath}`
    const personalTimelinePath = `/photos/${toAlbumPart}`

    const pathsLookup = {
      [SDK_TYPES.DEFAULT]:`/photos/albums/${albumData.id}/add?${redirectPathParam}`,
      [SDK_TYPES.FAMILY]:`/family/photos/albums/${albumData.id}/add?${redirectPathParam}`,
    }

    let path = personalTimelinePath

    if(!_.isNil(redirectToPath.match(familyCloudPart))) {
      path =  sdkType ? pathsLookup[sdkType] : pathsLookup[type] || ''
    }

    if(path) {
      navigate(path)
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveAlbum = () => {
    const callback = () => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate('/photos/albums', { replace: true })

        analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_INSIDE_ALBUM_DELETE)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate('/family/photos/albums', { replace: true })

        analytics.push(ANALYTICS_EVENTS.WEB_FC_INSIDE_ALBUM_DELETE)
      }
    }

    dispatch(removeAlbumThunk(callback))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_REMOVE_CLICK)
  }

  const handleRemoveAlbumItems = () => {
    dispatch(removeAlbumItemsThunk({ items: selectedItems, type, isMemory }))
    toggleRemovingItemsConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameAlbum = () => {
    dispatch(albumActions.toggleAlbumNameEditStatus(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleOpenRemovingItemsConfirmationModal = () => {
    toggleRemovingItemsConfirmationModal(true)
  }

  const handleCloseRemovingItemsConfirmationModal = () => {
    toggleRemovingItemsConfirmationModal(false)
  }

  const handleShare = (albumData: IAlbumSchema | null) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: type,
      sharedAlbumType: AlbumType.SHARED
    }))
    dispatch(sharingActions.setSharedItemData(albumData))
    pushCreateEditLinkEvent(albumData)
  }

  const handleSelectedItemsSharing = (albumData: IAlbumSchema | null) => {
    dispatch(createAndShareAlbumThunk({ type, items: selectedItems }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    pushCreateEditLinkEvent(albumData)
  }

  const pushCreateEditLinkEvent = (albumData: IAlbumSchema | null) => {
    if(!_.isNil(albumData) && albumData?.shared_hash) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_EDIT_LINK)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_CREATE_LINK)
    }
  }

  const handleDownloadAlbum = () => {
    dispatch(downloadAlbumThunk())
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadSelectedItems = () => {
    dispatch(downloadAlbumItemsThunk({ type, items: selectedItems }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCopyToFamily = () => {
    dispatch(copyAlbumItemsToFamilyCloudThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCopyToPersonalCloud = () => {
    dispatch(copyAlbumItemsToPersonalCloudThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleAddToFavoritesItems = () => {
    dispatch(addSelectedToFavoritesItemsThunk({ items: selectedItems, withNotification: true, type }))
    dispatch(albumActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveFromFavoritesItems = () => {
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: selectedItems, type }))
    dispatch(albumActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const totalItemsCount = getAlbumTotalItemsCountSelector()

  const handleDuplicate = () => {
    if (selectedItems.length === 1) {
      dispatch(openPreviewDuplicateThunk({ selectedItem: selectedItems[0], items, totalItemsCount, type }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const setIsOpenedAddPhotosToAlbumModal = (isOpened: boolean) => {
    dispatch(appActions.setIsOpenedAddPhotosToAlbumModal(isOpened))
    if(isOpened){
      if(type === SDK_TYPES.DEFAULT){
        analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_OUTSIDE_ADD_TO_ALBUM)
      } else {
        analytics.push(ANALYTICS_EVENTS.WEB_FC_OUTSIDE_ADD_TO_ALBUM)
      }
    }
  }

  const openAddToAlbumModal = () => {
    dispatch(appActions.setSelectedItemsToAddToAlbumModal(selectedItems))
    dispatch(appActions.setAlbumTypeToAddToAlbumModal(type))
    dispatch(fetchAlbumsThunk())
    setIsOpenedAddPhotosToAlbumModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenPublicLinkRemovingModal = (item) => {
    setPublicLinkDataForDelete(item)
  }

  const handleClosePublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(null)
  }

  const handleCopyPublicLink = (item) => {
    dispatch(getPublicLinkAndCopyThunk({ data: item }))

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCreateAndCopyLinkToPublicAlbum = () => {
    dispatch(createAndCopyLinkToPublicAlbumThunk({ type, items: selectedItems }))

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDeletePublicLink = () => {
    const albumType = albumData?.type
    const isHiddenShared = albumType === AlbumType.HIDDEN_SHARED

    const callbackForHiddenShared = () => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate('/photos/albums', { replace: true })
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate('/family/photos/albums', { replace: true })
      }
    }

    dispatch(deleteSharedLinkThunk({ data: publicLinkDataForDelete, type: SDK_TYPES.SHARED, callback: isHiddenShared ? callbackForHiddenShared : undefined }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    setPublicLinkDataForDelete(null)
  }

  const handleHideAlbum = () => {
    dispatch(hideAlbumThunk())

    analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_MASK_ALBUM_CLICK)

    handleCloseHideAlbumModal()
  }

  const handleUnhideAlbum = () => {
    dispatch(unhideAlbumThunk())

    analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_UNMASK_ALBUM_CLICK)

    handleCloseUnhideAlbumModal()
  }

  const handleCloseHideAlbumModal = () => {
    toggleHideAlbumModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenHideAlbumModal = () => {
    toggleHideAlbumModal(true)
  }

  const handleCloseUnhideAlbumModal = () => {
    toggleUnhideAlbumModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenUnhideAlbumModal = () => {
    toggleUnhideAlbumModal(true)
  }

  const menuItems = (() => {
    if (!selectedItemsCount && hideWithoutSelection) {
      return []
    }

    if (isMemory && !selectedItemsCount) {
      return []
    }

    if (!selectedItemsCount) {
      return [
        !isPerson && !isMoments && {
          label:t('a_common_share'),
          iconName: 'share',
          onClickItem: handleShare.bind(null, albumData)
        },
        _.has(albumData?._links,'share') && {
          label: t('a_common_copyPublicLink'),
          iconName: 'copy_link',
          onClickItem: () => {
            handleCopyPublicLink(albumData)
          }
        },
        _.has(albumData?._links,'share') && {
          label: t('a_common_deleteLink'),
          iconName: 'unlink',
          onClickItem: () => {
            handleOpenPublicLinkRemovingModal(albumData)
          }
        },
        !!items.length && !isPerson && !isMoments && {
          label: t('a_albums_downloadAlbum'),
          iconName: 'download_empty',
          onClickItem: handleDownloadAlbum
        },
        !isSeason && !isPerson && !isMoments && {
          label: t('a_albums_renameAlbum'),
          iconName: 'pencil',
          onClickItem: handleRenameAlbum
        },
        albumData?.hide_items && !isSeason && !isPerson && !isMoments && type === SDK_TYPES.DEFAULT && {
          label: t('a_hidden_unmaskAlbum'),
          iconName: 'visibility_outlined',
          onClickItem: handleOpenUnhideAlbumModal
        },
        !albumData?.hide_items && !isSeason && !isPerson && !isMoments && type === SDK_TYPES.DEFAULT && [AlbumType.SIMPLE, AlbumType.SHARED].includes(albumData?.type as AlbumType) && {
          label: t('a_common_maskAlbum'),
          iconName: 'visibility_off_outlined',
          onClickItem: handleOpenHideAlbumModal
        },
        !isPerson && !isCalendar && !isMoments && {
          label: t('a_common_deleteAlbum'),
          iconName: 'remove_icon',
          onClickItem: handleOpenRemovingConfirmationModal
        }
      ].filter(item => !!item)
    }

    return [
      {
        label: t('a_common_share'),
        iconName: 'share',
        onClickItem: handleSelectedItemsSharing.bind(null, albumData)
      },
      {
        label: t('a_common_copyPublicLink'),
        iconName: 'copy_link',
        onClickItem: handleCreateAndCopyLinkToPublicAlbum
      },
      !!userData?.family_user_id && {
        label: type === SDK_TYPES.FAMILY ? t('a_common_copyToPersonal') : t('a_common_copyToFamily'),
        iconName: type === SDK_TYPES.FAMILY ? 'add_from_personal' : 'add_from_family',
        onClickItem: type === SDK_TYPES.FAMILY ? handleCopyToPersonalCloud : handleCopyToFamily
      },
      {
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album',
        onClickItem: () => openAddToAlbumModal()
      },
      isSelectedItemsHaveOnlyFavorite() && (type === SDK_TYPES.DEFAULT || type === SDK_TYPES.FAMILY) && {
        label: t('a_common_addToFavorites'),
        iconName: 'favorite_border',
        onClickItem: handleAddToFavoritesItems
      },
      !isSelectedItemsHaveOnlyFavorite() && (type === SDK_TYPES.DEFAULT || type === SDK_TYPES.FAMILY) && {
        label: t('a_common_removeFavorite'),
        iconName: 'remove_favorite',
        onClickItem: handleRemoveFromFavoritesItems
      },
      isEnabledPhotoDuplicates && selectedItems.length === 1 && selectedItems[0].type === 'image' &&  {
        label: t('a_common_searchForSimilarPhotos'),
        iconName: 'search_duplicate',
        onClickItem: handleDuplicate
      },
      !isPlace && !isCalendar && {
        label: t(isMemory ? 'a_common_removeFromMemory' : 'a_albums_removeFromAlbum'),
        iconName: 'remove_icon',
        onClickItem: handleOpenRemovingItemsConfirmationModal
      },
    ].filter(item => !!item)
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []
    if (!selectedItemsCount) {
      !isSeason && items.push({
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album',
        onClickItem: handleAddPhoto.bind(null, SDK_TYPES.DEFAULT)
      })
    } else {
      items.push({
        label: t('a_common_download'),
        iconName: 'download_empty',
        onClickItem: handleDownloadSelectedItems
      })
    }

    items = [...items, ...menuItems].filter((item) => !!item)

    return items
  })()

  return (
    <>
      <AlbumToolbarBox
        isPlace={isPlace}
        selectedItemsCount={selectedItemsCount}
        className={className}
      >
        {
          !selectedItemsCount && !isPlace && !isSeason && !isMoments && (
            <>
              {(type === SDK_TYPES.DEFAULT || type === SDK_TYPES.FAMILY) && !isPerson && !hideWithoutSelection && (
                <StyledPrimaryButton
                  actionName={t(isMemory ? 'a_common_addPhotos' : 'a_common_addToAlbum')}
                  onAction={handleAddPhoto.bind(null, SDK_TYPES.DEFAULT)}
                />
              )}
            </>
          )
        }

        {
          selectedItemsCount > 0 && (
            <StyledPrimaryButton
              actionName={t('a_common_download')}
              onAction={handleDownloadSelectedItems}
            />
          )
        }

        <MenuWithActionItems menuItems={menuItems}
          isBorder={(isSeason || hideWithoutSelection || isMemory) ? !!selectedItemsCount : true}
        />
      </AlbumToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={!!selectedItemsCount && (
          <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
        )}
      />

      <ConfirmationModal title={t('l_publicLink_deleteConfirmTitle')}
        isOpened={!!publicLinkDataForDelete}
        onClose={handleClosePublicLinkRemovingModal}
        parentBlock={getModalsRootElement()}
        onOk={handleDeletePublicLink}
        okText={t('a_common_delete')}
        onCancel={handleClosePublicLinkRemovingModal}
        cancelText={t('a_common_cancel')}
        type={ConfirmationModalTypes.danger}
      >
        <SRemovingLinkConfirmationModalText>
          {t('l_publicLink_deleteConfirmMessage')}
        </SRemovingLinkConfirmationModalText>
      </ConfirmationModal>

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_albums_albumDeleteTitle', { number: 1 })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_delete')}
        onOk={handleRemoveAlbum}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_albums_albumDeleteMsg', { number: 1 })}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={removingItemsConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItems.length })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_remove')}
        onOk={handleRemoveAlbumItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingItemsConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_removePhotosMsg', { number: selectedItems.length })}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={hideAlbumModalOpened}
        title={t('l_hidden_maskAlbum')}
        onClose={handleCloseHideAlbumModal}
        okText={t('a_common_mask')}
        onOk={handleHideAlbum}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseHideAlbumModal}
      >
        <TextInModalBox>
          {t('l_hidden_maskAlbumMsg')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={unhideAlbumModalOpened}
        title={t('l_hidden_umaskAlbum')}
        onClose={handleCloseUnhideAlbumModal}
        okText={t('a_common_unmask')}
        onOk={handleUnhideAlbum}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseUnhideAlbumModal}
      >
        <TextInModalBox>
          {t('l_hidden_unmaskAlbumMsg')}
        </TextInModalBox>
      </DialogModal>
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const AlbumToolbarBox = styled.div`
  padding-right: 24px;
  display: ${(props) => ((props.isPlace && (!!props.selectedItemsCount ? 'block' : 'none')))};
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  padding: 8px 12px;
`

const SRemovingLinkConfirmationModalText = styled.p`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 572px;
`
