import { formatImgUrl } from "./configs/theme.config"

const imagesForPreload = {
  noInternetConnectionBlack: formatImgUrl("/assets/placeholders-images-black/no-internet-connection.svg")
}

const PRELOADED_IMAGES: Partial<Record<keyof typeof imagesForPreload, string>> = {}

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

Object.keys(imagesForPreload).forEach(async key => {
  PRELOADED_IMAGES[key] = await toDataURL(imagesForPreload[key])
})

export { PRELOADED_IMAGES }