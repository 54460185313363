import { IFsNodeSchema } from "@cloudike/web_files"

import { request } from "./request"

interface ISharedWithMeNodesParams {
  embedded?: boolean;
  cursor_next?: string;
  limit?: number;
  total_count: boolean;
  preview?: boolean;
  preview_jwt?: boolean;
}

//toDo move to SDK
export const publicLinksApi = {
  getMyPublicLinksNodes: async (userId: string, params: ISharedWithMeNodesParams, delay = 300): Promise<IFsNodeSchema[]> => {
    const nodes: IFsNodeSchema[] = []

    const fetchNodes: typeof request = async (method, url, params, options) => {
      const { _embedded, _links } = await request(method, url, params, options)

      nodes.push(..._embedded.nodes)

      const nextLink = _links?.next?.href

      if (nextLink) {
        await new Promise<void>((resolve) => setTimeout(() => resolve(), delay))

        await fetchNodes('GET', nextLink, null, { host: null })
      }
    }

    await fetchNodes('GET',`/api/3/users/${userId}/fs/root/shared_nodes`, params)

    return nodes
  },
  getPublicLinksWithMeNodes: async (userId: string, params: ISharedWithMeNodesParams, delay = 300): Promise<IFsNodeSchema[]> => {
    const nodes: IFsNodeSchema[] = []

    const fetchNodes: typeof request = async (method, url, params, options) => {
      const { _embedded, _links } = await request(method, url, params, options)

      nodes.push(..._embedded.roots)

      const nextLink = _links?.next?.href

      if (nextLink) {
        await new Promise<void>((resolve) => setTimeout(() => resolve(), delay))

        await fetchNodes('GET', nextLink, null, { host: null })
      }
    }

    await fetchNodes('GET',`/api/3/users/${userId}/shared_with_me/fs/`, params)

    return nodes
  },
  removeSharedWithMeLink: async (userId: string, nodeId: string) => {
    const response = await request('DELETE', `/api/3/users/${userId}/shared_with_me/fs/${nodeId}`,)

    return response
  },
  getSharedWithMeNodesTotalCount: async (userId: string) => {
    const response = await request('GET', `/api/3/users/${userId}/fs/root/shared_nodes`, { limit: 1, total_count: true })

    return response
  }
}