import React from 'react'

import styled from 'styled-components'
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"
import classNames from 'classnames'

export interface IAlbumsGrid {
    className?: string;
    children: React.ReactNode
    withOnlyOneRow?: boolean
}

export const AlbumsMemoriesGrid = ({ className = '', children, withOnlyOneRow = false }: IAlbumsGrid) => {
  return (
    <AlbumsGridBox className={classNames(className, { 'with-only-one-row': withOnlyOneRow })}>
      {children}
    </AlbumsGridBox>
  )
}

const AlbumsGridBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(135px, 1fr));
  grid-template-rows: auto;
  grid-column-gap: 19px;
  grid-row-gap: 24px;

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    grid-template-columns: repeat(auto-fit, 281px);
    grid-gap: 24px;
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.DESKTOP}) {
    grid-template-columns: repeat(auto-fit, 281px);
    padding: 0;
  }

  &.with-only-one-row {
    height: 195px;
    overflow: hidden;
  }
`
