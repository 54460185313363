import { IFsNodeSchema } from '@cloudike/web_files'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

export enum PREVIEW_TYPES {
  ALBUM = 'ALBUM',
  PLACE_ALBUM = 'PLACE_ALBUM',
  TIMELINE = 'TIMELINE',
  SHARED_ALBUM = 'SHARED_ALBUM',
  FAVORITES = 'FAVORITES',
  DOCUMENTS = 'DOCUMENTS',
  SHARED_FILES = 'SHARED_FILES',
  FILES = 'FILES',
  FILES_PUBLIC_LINKS = 'FILES_PUBLIC_LINKS',
  TRASH_BIN = 'TRASH_BIN'
}

interface State {
  currentItemIndex: number,
  isModalOpened: boolean,
  loading: boolean,
  loadingMore: boolean,
  totalItemsCount: number,
  hasError :boolean,
  type: PREVIEW_TYPES
}

export interface EnhancedItemSchema extends Omit<IFsNodeSchema, 'type'> {
  type: 'image' | 'video' | 'pdf'
}

interface PreviewOpenActionType {
  items: EnhancedItemSchema[],
  currentItemId: string,
  loadMore?: () => void,
  totalItemsCount: number,
  type?: PREVIEW_TYPES
}

const adapter = createEntityAdapter<EnhancedItemSchema>()

let loadMoreHandler

export const openFilesPreviewThunk = createAsyncThunk(
  'filesPreview/openFilesPreviewThunk',
  async function({ items, currentItemId, totalItemsCount, type } : PreviewOpenActionType, { dispatch }) {
    dispatch(actions.openPreview({ items, currentItemId, totalItemsCount, type }))
  }
)

export const handleNextPhotoThunk = createAsyncThunk(
  'filesPreview/handleNextPhotoThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.filesPreview.currentItemIndex
    const itemsLength = state.filesPreview.ids.length

    dispatch(actions.setError(false))

    if (currentIndex + 1 === itemsLength && loadMoreHandler) {
      loadMoreHandler()
    }

    dispatch(actions.setCurrentItemIndex(currentIndex + 1))
    dispatch(actions.setLoading(true))
  }
)

export const handlePrevPhotoThunk = createAsyncThunk(
  'filesPreview/handlePrevPhotoThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState
    const currentIndex = state.filesPreview.currentItemIndex

    dispatch(actions.setError(false))
    dispatch(actions.setCurrentItemIndex(currentIndex - 1))
    dispatch(actions.setLoading(true))
  }
)

export const filesPreviewSelectors = adapter.getSelectors()

export const filesPreviewSlice = createSlice({
  name: 'filesPreview',
  initialState: adapter.getInitialState<State>({
    currentItemIndex: 0,
    isModalOpened: false,
    loading: true,
    totalItemsCount: 0,
    loadingMore: false,
    hasError: false,
    type: PREVIEW_TYPES.FILES
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    setTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload
    },
    setCurrentItemIndexById: (state, action: PayloadAction<string>) => {
      const ids = adapter.getSelectors().selectIds(state)
      const indexOfItem = ids.indexOf(action.payload)

      state.currentItemIndex = indexOfItem
    },
    setCurrentItemIndex: (state, action) => {
      state.currentItemIndex = action.payload
    },
    increaseCurrentItemIndex: (state, action) => {
      state.currentItemIndex = state.currentItemIndex + action.payload
    },
    toggleModal: (state, action) => {
      state.isModalOpened = action.payload
    },
    openPreview: (state, action: PayloadAction<PreviewOpenActionType>) => {
      adapter.setAll(state, action.payload.items)
      state.totalItemsCount = action.payload.totalItemsCount

      if (action.payload.type) {
        state.type = action.payload.type
      }


      const ids = action.payload.items.map(item => item.id)
      const indexOfItem = ids.indexOf(action.payload.currentItemId)

      state.currentItemIndex = indexOfItem
      state.isModalOpened = true
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setLoadingMore: (state, action: PayloadAction<boolean>) => {
      state.loadingMore = action.payload
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload
    },
    resetState: (state) => {
      adapter.removeAll(state)
      state.currentItemIndex = 0
      state.isModalOpened = false
      state.loading = true
      state.loadingMore = false
      state.hasError = false

      loadMoreHandler = null
    }
  }
})

const {
  reducer, actions
} = filesPreviewSlice

export { reducer as filesPreviewReducer, actions as filesPreviewActions }
