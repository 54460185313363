import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { isMobileDevice } from "utils/isMobileDevice"

export const usePhotoSelection = (onSelectItem, onSelectItemWithPressedShift, enabled = false) => {
  const isMouseLeftKeyHoldingRef = useRef(false)
  const mouseEnteredCardIdRef = useRef<string | null>(null)
  const withPressedShiftRef = useRef(false)
  const longTapTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const initialTouchPositionRef = useRef<{ x: number, y: number } | null>(null)
  const initialSelectedIdRef = useRef<string | null>(null)
  const [isContainerScrollDisabled, setIsContainerScrollDisabled] = useState(false)
  const isMobile = useMemo(() => isMobileDevice(), [])
    
  const handleItemSelection = useCallback((id) => {
    if (withPressedShiftRef.current) {
      onSelectItemWithPressedShift(id)
    
      return
    }
    
    onSelectItem(id)
  }, [])
    
  const handleMouseEnter = useCallback((id) => {
    if (isMouseLeftKeyHoldingRef.current && mouseEnteredCardIdRef.current !== id && !isMobile) {
      handleItemSelection(id)
    }

    mouseEnteredCardIdRef.current = id
  }, [])
    
  const handleMouseLeave = useCallback(() => {
    mouseEnteredCardIdRef.current = null
  }, [])

  useEffect(() => {
    function downHandler({ key }) {
      if (key === 'Shift') {
        withPressedShiftRef.current = true
      }
    }
      
    function upHandler({ key }) {
      if (key === 'Shift') {
        withPressedShiftRef.current = false
      }
    }
      
    function blurWindow() {
      withPressedShiftRef.current = false
    }
    
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    window.addEventListener('blur', blurWindow)
    
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
      window.removeEventListener('blur', blurWindow)
    }
  }, [])
    
  useEffect(() => {
    if (!enabled) {
      mouseEnteredCardIdRef.current = null
      isMouseLeftKeyHoldingRef.current = false
      withPressedShiftRef.current = false
      initialSelectedIdRef.current = null

      return
    }

    const preventDefault = (e: TouchEvent) => e.preventDefault()

    const disableScroll = () => {
      document.addEventListener('touchmove', preventDefault, { passive: false })
      setIsContainerScrollDisabled(true)
    }

    const enableScroll = () => {
      document.removeEventListener('touchmove', preventDefault)
      setIsContainerScrollDisabled(false)
    }

    const handleMouseDown = (event) => {
      if (mouseEnteredCardIdRef.current) {
        handleItemSelection(mouseEnteredCardIdRef.current)

        event.preventDefault()
        event.stopPropagation()
      }
    
      isMouseLeftKeyHoldingRef.current = event.buttons === 1
    }
    
    const handleMouseUp = () => {
      isMouseLeftKeyHoldingRef.current = false
    }

    const handleTouchStart = (event: TouchEvent) => {
      const touch = event.touches[0]
      initialTouchPositionRef.current = { x: touch.clientX, y: touch.clientY }

      longTapTimeoutRef.current = setTimeout(() => {
        const element = document.elementFromPoint(touch.clientX, touch.clientY)
        const photoCard = element?.closest('[data-photo-card-id]')
        
        if (photoCard) {
          const id = photoCard.getAttribute('data-photo-card-id')

          if (id) {
            disableScroll()
            isMouseLeftKeyHoldingRef.current = true
            initialSelectedIdRef.current = id
            mouseEnteredCardIdRef.current = id
            handleItemSelection(id)
          }
        }
      }, 500)
    }
    
    const handleTouchMove = (event: TouchEvent) => {
      if (!initialTouchPositionRef.current) return

      const touch = event.touches[0]
      const deltaX = Math.abs(touch.clientX - initialTouchPositionRef.current.x)
      const deltaY = Math.abs(touch.clientY - initialTouchPositionRef.current.y)

      if (deltaX > 10 || deltaY > 10) {
        if (longTapTimeoutRef.current) {
          clearTimeout(longTapTimeoutRef.current)
          longTapTimeoutRef.current = null
        }
      }

      if (!isMouseLeftKeyHoldingRef.current) return

      const element = document.elementFromPoint(touch.clientX, touch.clientY)
      const photoCard = element?.closest('[data-photo-card-id]')
      
      if (photoCard) {
        const id = photoCard.getAttribute('data-photo-card-id')

        if (id && id !== mouseEnteredCardIdRef.current) {
          if (initialSelectedIdRef.current && id !== initialSelectedIdRef.current) {
            initialSelectedIdRef.current = null
          }
          
          mouseEnteredCardIdRef.current = id
          
          handleItemSelection(id)
        }
      }
    }
    
    const handleTouchEnd = () => {
      if (longTapTimeoutRef.current) {
        clearTimeout(longTapTimeoutRef.current)
        longTapTimeoutRef.current = null
      }
      
      enableScroll()

      initialTouchPositionRef.current = null
      isMouseLeftKeyHoldingRef.current = false
      mouseEnteredCardIdRef.current = null
      initialSelectedIdRef.current = null
    }
    
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchend', handleTouchEnd)
    window.addEventListener('touchmove', handleTouchMove)
    
    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchend', handleTouchEnd)
      window.removeEventListener('touchmove', handleTouchMove)
      
      enableScroll()
      
      if (longTapTimeoutRef.current) {
        clearTimeout(longTapTimeoutRef.current)
      }
    }
  }, [enabled])

  return {
    handleMouseEnter,
    handleMouseLeave,
    isMouseLeftKeyHoldingRef,
    mouseEnteredCardIdRef,
    withPressedShiftRef,
    isContainerScrollDisabled
  }
}