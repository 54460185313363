import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'
import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { USER_DATA_KEY_IN_STORAGE, USER_DATA_KEY_TIMEZONE_SENT } from 'features/user/constants'
import CLPhotos from "@cloudike/web_photos"
import { getPhotosWS } from 'sdk/photo'
import { userApi } from 'api/userApi'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import i18n from 'i18n'
import { t } from 'i18next'
import _ from 'lodash'
import { API_KEY_NAME } from "@cloudike/api-switcher"

import { NOTIFICATION_TYPES, showNotification, } from '../common/notifications'
import { getErrorData } from '../../utils/getErrorData'
import { APP_CONFIG } from '../../constants/configs/app.config'
import { RootState, useAppSelector } from "../../store/index"
import redirectAfterLogout from "../../utils/redirectAfterLogout"
import { request } from "../../api/request"
import * as Electron from "../../utils/electron"

const userDataFromLocalStorage = localStorage.getItem(USER_DATA_KEY_IN_STORAGE)

export const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')

export enum THEME {
    LIGHT = 'light',
    DARK = 'dark',
    SYSTEM = 'system'
}

export const setDarkClass = () => {
  document.body.classList.remove('theme-white')
  document.body.classList.add('theme-dark')
}

export const setWhiteClass = () => {
  document.body.classList.add('theme-white')
  document.body.classList.remove('theme-dark')
}

export const removeAllClass = () => {
  document.body.classList.remove('theme-dark')
  document.body.classList.remove('theme-white')
}

function getThemeFromStorage() {
  try {
    const THEME_KEY = 'theme'
    if(Electron.isElectronStoreSupported()) {
      const currentTheme = Electron.getThemeFromStore()
      if(!currentTheme) return null
      localStorage.setItem(THEME_KEY, currentTheme)
      return currentTheme
    }
    return localStorage.getItem(THEME_KEY)
  } catch (e) {
    return null
  }
}

const initialState = {
  userSettings: {},
  userData: userDataFromLocalStorage
    ? JSON.parse(userDataFromLocalStorage)?.CurrentUser || {}
    : {},
  basicAuth: null,
  subscriptions: {
    items: [],
    status: LOADING_STATUSES.LOADING
  },
  promocodes: {
    items: [],
    status: LOADING_STATUSES.LOADING
  },
  tokens: {
    items: [],
    status: LOADING_STATUSES.LOADING
  },
  statistics: {
    items: [],
    status: LOADING_STATUSES.LOADING
  },
  events: {
    items: [],
    status: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    moreItemsAvailable: true,
    currentType: ''
  },
  credentials: [],
  userNameModalOpened: false,
  userPasswordModalOpened: false,
  maxPhotoSize: 0,
  maxFileSize: 0,
  theme: getThemeFromStorage() || null,
  uiTheme: null,
  telegramBot: {
    enable: false,
    link: '',
    status: LOADING_STATUSES.IDLE
  }
}

export const subscribeUserToWSThunk = createAsyncThunk(
  'user/subscribeUserToWSThunk',
  async function (_, { dispatch }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.STORAGE_INFO, ({ type, output }) => {
      if (type === 'storage_info') {
        dispatch(updateUserDataThunk({
          hard_quota_size: output.hard_quota_size,
          quota_size: output.quota_size,
          storage_size: output.home_storage_size
        }))
      }
    })
  }
)

export const setMaxSizeThunk = createAsyncThunk(
  'user/setMaxSizeThunk',
  async function (_, { getState, dispatch }) {
    const state = getState() as RootState
    try {
      const { _links: { upload_item } } = await userApi.getPhotoUrls(state.user.userData.id)
      const uploadPhotoUrl = new URL(upload_item.href)
      const rspPhoto = await userApi.getHeadersUploadPhoto(state.user.userData.id, uploadPhotoUrl.origin)
      const { _links: { upload_file } } = await userApi.getFileUrls(state.user.userData.id)
      const uploadFileUrl = new URL(upload_file.href)
      const rspFile = await userApi.getHeadersUploadFile(state.user.userData.id, uploadFileUrl.origin)
      dispatch(actions.setMaxPhotoSize(Number(rspPhoto.headers['tus-max-size'])))
      dispatch(actions.setMaxFileSize(Number(rspFile.headers['tus-max-size'])))
    } catch (error) {
      console.error(error)
    }
  }
)

export const fetchUserSettingsThunk = createAsyncThunk(
  'user/fetchUserSettingsThunk',
  async function (_, { dispatch, getState }) {
    const state = getState() as RootState
    const response = await userApi.getUserSettings(state.user.userData.id)
    const rspCredentials = await userApi.getCredentials(state.user.userData.id)

    const options = response._embedded.values

    const userSettings = options.reduce((r, e) => ({ ...r, [e.path]: e.value }), {})

    dispatch(actions.setCredentials(rspCredentials._embedded.credentials))
    dispatch(actions.setUserSettings(userSettings))
  }
)

export const setUserTimezoneThunk = createAsyncThunk(
  'user/setUserTimezoneThunk',
  async function (_, { getState }) {
    const state = getState() as RootState
    const timezoneSent = localStorage.getItem(USER_DATA_KEY_TIMEZONE_SENT)

    if (timezoneSent) {
      return
    }

    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      await userApi.patchUserData(state.user.userData.id, { timezone })

      localStorage.setItem(USER_DATA_KEY_TIMEZONE_SENT, '1')
    } catch (error) {
      console.error(error)
    }
  }
)

export const changeLanguageThunk = createAsyncThunk(
  'user/changeLanguageThunk',
  async function ({ lang }: { lang: string }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id
    showGlobalProgressLoader()

    try {
      await userApi.updateUserData(userId, { lang })
      dispatch(updateUserDataThunk({ lang }))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_languageChanged')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error),
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const changePasswordThunk = createAsyncThunk(
  'user/changePasswordThunk',
  async function ({ password, currentPassword, currentPasswordErrorCallback, tooManyAttemptsCallback }:
                    { password: string, currentPassword: string, currentPasswordErrorCallback: () => void, tooManyAttemptsCallback: () => void },
  { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id

    showGlobalProgressLoader()

    try {
      await userApi.updateUserData(userId, { password, current_password: currentPassword })

      dispatch(actions.toggleUserPasswordModal(false))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_passChanged')
      })
    } catch (error) {
      if (error?.detail?.current_password) {
        currentPasswordErrorCallback()

        return
      }

      if (error?.code === 'TooManyIncorrectAttempts') {
        tooManyAttemptsCallback()

        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_passwordUpdateFail')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const updateUserDataThunk = createAsyncThunk(
  'user/updateUserDataThunk',
  async function (data: any, { dispatch }) {
    const userDataFromLocalStorage = localStorage.getItem(USER_DATA_KEY_IN_STORAGE)

    const parsedDataFromLocalStorage = JSON.parse(userDataFromLocalStorage)
    const parsedUserData = parsedDataFromLocalStorage.CurrentUser

    const updatedData = { ...parsedDataFromLocalStorage, CurrentUser: { ...parsedUserData, ...data } }

    localStorage.setItem(USER_DATA_KEY_IN_STORAGE, JSON.stringify(updatedData))

    if (data.lang) {
      localStorage.setItem('langKey', data.lang)
      i18n.changeLanguage(data.lang)
    }

    localStorage.setItem('CLIENT', JSON.stringify(parsedUserData))

    dispatch(actions.updateUserData(updatedData.CurrentUser))
  }
)


export const changeUserNameThunk = createAsyncThunk(
  'user/changeUserNameThunk',
  async function ({ name }: { name: string }, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id

    showGlobalProgressLoader()

    try {
      await userApi.updateUserData(userId, { name })
      dispatch(updateUserDataThunk({ name }))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_nameChanged')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error),
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const removeAccountThunk = createAsyncThunk(
  'user/removeAccountThunk',
  async function (_, { dispatch, getState }) {
    const state = getState() as RootState
    const userId = state.user.userData.id

    showGlobalProgressLoader()

    try {
      await userApi.removeAccount(userId)

      dispatch(logoutThunk())
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error),
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const fetchSubscriptionsThunk = createAsyncThunk(
  'user/fetchSubscriptionsThunk',
  async function (_, { dispatch, getState }) {
    const state = getState() as RootState
    const response = await userApi.getSubscriptions(state.user.userData.id)

    dispatch(actions.setSubscriptions({
      items: response.content,
      status: LOADING_STATUSES.SUCCEEDED
    }))
  }
)

export const fetchPromocodesThunk = createAsyncThunk(
  'user/fetchPromocodesThunk',
  async function (_, { dispatch, getState }) {
    const state = getState() as RootState
    const response = await userApi.getPromocodes(state.user.userData.id)

    dispatch(actions.setPromocodes({
      items: response.content,
      status: LOADING_STATUSES.SUCCEEDED
    }))
  }
)

export const fetchTokensThunk = createAsyncThunk(
  'user/fetchTokensThunk',
  async function (_, { dispatch, getState }) {
    try {
      const state = getState() as RootState
      const response = await userApi.getTokens(state.user.userData.id)

      dispatch(actions.setTokens({
        items: response._embedded.tokens,
        status: LOADING_STATUSES.SUCCEEDED
      }))
    } catch (error) {
      console.error(error)
    }
  }
)

export const fetchBasicAuthThunk = createAsyncThunk(
  'user/fetchBasicAuthThunk',
  async function (_, { dispatch }) {
    const response = await userApi.getBasicAuth()

    dispatch(actions.setBasicAuth({ ...response, url: APP_CONFIG.webdav_url }))
  }
)

export const checkTelegramBotThunk = createAsyncThunk(
  'user/checkTelegramBotThunk',
  async function (_, { dispatch, getState }) {

    const state = getState() as RootState
    try {
      await userApi.checkTelegramBot(state.user.userData.id)
      dispatch(actions.setTelegramBotEnable(true))
    } catch (error) {
      dispatch(actions.setTelegramBotEnable(false))
    }
  }
)

export const getTelegramLinkThunk = createAsyncThunk(
  'user/getTelegramTokenThunk',
  async function (_, { dispatch, getState }) {

    const state = getState() as RootState
    try {
      dispatch(actions.setTelegramBotStatus(LOADING_STATUSES.LOADING))
      const response = await userApi.getTelegramLink(state.user.userData.id)
      dispatch(actions.setTelegramBotLink(response._links.auth.href))
      window.open(response._links.auth.href, '_blank')
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      dispatch(actions.setTelegramBotStatus(LOADING_STATUSES.IDLE))
    }
  }
)


type EventsRequestParams = { skip: number, limit: number, type?: string }

export const fetchEventsThunk = createAsyncThunk(
  'user/fetchEventsThunk',
  async function (_, { dispatch, getState }) {
    dispatch(actions.setEventsData({ status: LOADING_STATUSES.LOADING }))

    const state = (getState() as RootState).user.events

    const params: EventsRequestParams = {
      skip: state.items.length,
      limit: 50
    }

    if (state.currentType) {
      showGlobalProgressLoader()
      params.type = state.currentType
    }

    const response = await userApi.getEvents(params)

    if (response.length < 50) {
      dispatch(actions.setEventsData({ moreItemsAvailable: false }))
    } else {
      dispatch(actions.setEventsData({ moreItemsAvailable: true }))
    }

    dispatch(actions.setEvents(response))
    dispatch(actions.setEventsData({ status: LOADING_STATUSES.IDLE }))

    hideGlobalProgressLoader()
  }
)

export const loadMoreEventsThunk = createAsyncThunk(
  'user/loadMoreEventsThunk',
  async function (_, { dispatch, getState }) {
    const state = (getState() as RootState).user.events

    const params: EventsRequestParams = {
      skip: state.items.length,
      limit: 50
    }

    if (state.currentType) {
      params.type = state.currentType
    }

    const response = await userApi.getEvents(params)

    if (response.length < 50) {
      dispatch(actions.setEventsData({ moreItemsAvailable: false }))
    }

    dispatch(actions.pushEvents(response))
  }
)

export const fetchStatisticsThunk = createAsyncThunk(
  'user/fetchStatisticsThunk',
  async function (__, { dispatch }) {
    const userLinks = await request('GET', '/api/2/users')
    const userLink = userLinks?._links?.current_user?.href

    const response = await request('GET', userLink, { embedded: true }, { host: null })
    const data = response._embedded.fs

    const fileTypesCount = data.storage_stat?.file_types_count
    const fileTypesSize = data.storage_stat?.file_types_size

    let stats = []

    if (!!fileTypesCount && !_.isEmpty(fileTypesCount) && !!fileTypesSize && !_.isEmpty(fileTypesSize)) {
      const acc = [[], []]

      stats = _.chain(Object.keys(fileTypesSize))
        .reduce(([items, all], key) => {
          const item = {
            name: key,
            count: fileTypesCount[key],
            size: fileTypesSize[key]
          }

          if (key === 'all') {
            all.push(item)
          } else {
            items.push(item)
          }

          return [items, all]
        }, acc)
        .flatten()
        .value()
    }

    dispatch(actions.setStatistics({
      items: stats,
      status: LOADING_STATUSES.SUCCEEDED
    }))
  }
)

export const removeTokenThunk = createAsyncThunk(
  'user/removeTokenThunk',
  async function ({ tokenId }: { tokenId: string }, { dispatch, getState }) {
    const state = getState() as RootState

    showGlobalProgressLoader()

    try {
      await userApi.removeToken(state.user.userData.id, tokenId)
      dispatch(fetchTokensThunk())
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_authTokenDeleted')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const disableWebDavThunk = createAsyncThunk(
  'user/disableWebDavThunk',
  async function ({ login }: { login: string }, { dispatch }) {
    try {
      await userApi.removeLogin(`basic:${login}`)
      dispatch(actions.setBasicAuth(null))
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    }
  }
)

export const enableWebDavThunk = createAsyncThunk(
  'user/enableWebDavThunk',
  async function (_, { dispatch }) {
    try {
      await userApi.createBasicAuth({})
      dispatch(fetchBasicAuthThunk())
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    }
  }
)

export const setWebDavPasswordThunk = createAsyncThunk(
  'user/setWebDavPasswordThunk',
  async function ({
    login,
    password,
    callback
  }: { login: string, password: string, callback: () => void }, { dispatch }) {
    showGlobalProgressLoader()

    try {
      await userApi.createBasicAuth({ login, password })
      dispatch(fetchBasicAuthThunk())
      callback()
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const applyPromocodeThunk = createAsyncThunk(
  'user/applyPromocodeThunk',
  async function ({ code, callback, errorCallback }: { code: string, callback: () => void, errorCallback?: () => void }, { dispatch, getState }) {
    showGlobalProgressLoader()

    try {
      const state = getState() as RootState
      await userApi.applyPromocode(state.user.userData.id, code)

      dispatch(fetchPromocodesThunk())
      dispatch(fetchSubscriptionsThunk())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_common_promoCodeApplied')
      })

      callback()
    } catch (error) {
      if (errorCallback) {
        errorCallback()
      } else {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          ...getErrorData(error)
        })
      }
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const logoutThunk = createAsyncThunk(
  'user/logout',
  async function (_, { getState }) {
    const state = getState() as RootState
    const token = state.user.userData.token.split(':')[0]
    const userId = state.user.userData.id

    try {
      await userApi.logout(token, userId)
    } finally {
      const lang = localStorage.getItem('langKey')
      CLPhotos.destroy()
      localStorage.clear()
      sessionStorage.clear()
      localStorage.setItem('langKey', lang)
      redirectAfterLogout()
    }
  }
)

export const removeAllTokensThunk = createAsyncThunk(
  'user/removeAllTokensThunk',
  async function (_, { getState }) {

    const state = getState() as RootState
    const userId = state.user.userData.id

    try {
      await userApi.removeAllTokens(userId)
    } finally {
      const lang = localStorage.getItem('langKey')
      const env = localStorage.getItem(API_KEY_NAME)
      CLPhotos.destroy()
      localStorage.clear()
      sessionStorage.clear()
      localStorage.setItem('langKey', lang)
      localStorage.setItem(API_KEY_NAME, env)
      redirectAfterLogout()
    }
  }
)

export const changeElectronThemeThunk = createAsyncThunk(
  'user/changeElectronThemeThunk',
  async (theme: string) => {
    await Electron.handleChangeTheme(theme)
  })


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.userData = action.payload
    },
    updateUserData: (state, action) => {
      state.userData = action.payload
    },
    clearUserData: (state) => {
      state.userData = null
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = { ...state.subscriptions, ...action.payload }
    },
    setTelegramBotLink: (state, action) => {
      state.telegramBot.link = action.payload
    },
    setTelegramBotEnable: (state, action) => {
      state.telegramBot.enable = action.payload
    },
    resetTelegramBot: (state) => {
      state.telegramBot = {
        enable: false,
        link: '',
        status: LOADING_STATUSES.IDLE
      }
    },
    setTelegramBotStatus: (state, action) => {
      state.telegramBot.status = action.payload
    },
    setPromocodes: (state, action) => {
      state.promocodes = { ...state.promocodes, ...action.payload }
    },
    setTokens: (state, action) => {
      state.tokens = { ...state.tokens, ...action.payload }
    },
    setBasicAuth: (state, action) => {
      state.basicAuth = action.payload
    },
    setStatistics: (state, action) => {
      state.statistics = { ...state.statistics, ...action.payload }
    },
    setEventsData: (state, action) => {
      state.events = { ...state.events, ...action.payload }
    },
    setEvents: (state, action) => {
      state.events.items = action.payload
    },
    pushEvents: (state, action) => {
      state.events.items = [...state.events.items, ...action.payload]
    },
    toggleUserNameModal: (state, action) => {
      state.userNameModalOpened = action.payload
    },
    toggleUserPasswordModal: (state, action) => {
      state.userPasswordModalOpened = action.payload
    },
    setUserSettings: (state, action) => {
      state.userSettings = action.payload
    },
    setMaxPhotoSize: (state, action) => {
      state.maxPhotoSize = action.payload
    },
    setMaxFileSize: (state, action) => {
      state.maxFileSize = action.payload
    },
    setCredentials: (state, action) => {
      state.credentials = action.payload
    },
    setUiTheme: (state, action) => {

      const handleThemeChange = (e) => {
        if (e.matches && action.payload === THEME.SYSTEM) {
          setDarkClass()
        } else {
          setWhiteClass()
        }
      }

      const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')

      switch (action.payload) {
      case THEME.LIGHT:
        darkModeQuery.removeListener(handleThemeChange)
        setWhiteClass()
        break
      case THEME.DARK:
        darkModeQuery.removeListener(handleThemeChange)
        setDarkClass()
        break
      }

      state.uiTheme = action.payload
    },
    setTheme: (state, action) => {
      localStorage.setItem('theme', action.payload)
      state.theme = action.payload
    }
  },
})

const { reducer, actions } = userSlice

export { reducer as userReducer, actions as userActions }

export const getUserDataSelector = () => useAppSelector(state => state.user.userData)

export const getIsUserAuthorizedSelector = () => useAppSelector(state => !!state.user.userData.id && !!state.user.userData.token)
