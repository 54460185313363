import { IItemSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumItem"
import { IPhotoDigestSchema } from "@cloudike/web_photos/dist/types/intarfaces/IPhotoDigestSchema"
import { SORT_FORMATS } from "features/photo/timeline/timelineSlice"
import moment from 'moment'

interface IDay {
  day: string;
  count: number;
}

export interface IDigestMonth {
  month: string;
  count: number;
  days: IDay[];
  percentage?: number;
  startPercent?: number;
  isDotHidden?: boolean;
  rowCount?: number;
  height?: number;
}

export interface IDigestYear {
  year: number;
  count: number;
  percentage: number;
  months: IDigestMonth[];
  startPercent?: number;
  isHidden?: boolean;
  rowCount?: number;
  height?: number;
}

export const formatTimelineDigests = ({ data, cardsPerRow, groupBy = 'day', rowHeight = 172, headerHeight = 60 }: 
  { data: IPhotoDigestSchema[], cardsPerRow: number, groupBy?: 'year' | 'month' | 'day', rowHeight?: number, headerHeight?: number }): IDigestYear[] => {
  const calculateRowCount = (photoCount: number) => Math.ceil(photoCount / cardsPerRow)
  const calculatePercentage = (value: number, total: number) => (value / total) * 100
  const calculateHeight = (rowCount: number) => rowCount * rowHeight + (rowCount - 1) * 8

  const dotHeight = 5
  const yearHeight = 12

  const scrollbarHeight = document.documentElement.clientHeight - 100

  const percentForDot = (dotHeight + 5) / (scrollbarHeight / 100)
  const percentForYear = (yearHeight + 5) / (scrollbarHeight / 100)

  const result: IDigestYear[] = data.map(yearData => {
    const yearCount = yearData.counts.reduce((sum, count) => sum + count, 0)
    const months: IDigestMonth[] = []
    let yearHeight = 0

    for (let i = 0; i < 12; i++) {
      const monthMoment = moment().year(yearData.year).month(i)
      const monthName = monthMoment.format('MMMM')
      const daysInMonth = monthMoment.daysInMonth()

      const monthStart = monthMoment.startOf('month').dayOfYear()
      const monthEnd = monthStart + daysInMonth
      const monthCounts = yearData.counts.slice(monthStart, monthEnd)
      const monthCount = monthCounts.reduce((sum, count) => sum + count, 0)

      if (monthCount > 0) {
        const days: IDay[] = monthCounts.map((count, index) => ({
          day: (index + 1).toString(),
          count
        })).filter(day => day.count > 0)

        let monthHeight = 0
        let monthRowCount = 0

        if (groupBy === 'day') {
          days.forEach(day => {
            const dayRowCount = calculateRowCount(day.count)
            monthHeight += calculateHeight(dayRowCount) + headerHeight
            monthRowCount += dayRowCount
          })
        } else {
          monthRowCount = calculateRowCount(monthCount)
          monthHeight = calculateHeight(monthRowCount) + (groupBy !== 'year' ? headerHeight : 0)
        }

        yearHeight += monthHeight

        months.push({
          month: monthName,
          count: monthCount,
          days,
          rowCount: monthRowCount,
          height: monthHeight,
          percentage: 0
        })
      }
    }

    if (groupBy === 'year') {
      yearHeight = calculateHeight(calculateRowCount(yearCount))
      yearHeight += headerHeight
    }

    return {
      year: yearData.year,
      count: yearCount,
      height: yearHeight,
      percentage: 0,
      months: months.reverse()
    }
  }).filter(year => year.count > 0)

  result.sort((a, b) => b.year - a.year)

  const totalHeight = result.reduce((sum, year) => sum + year.height, 0)

  result.forEach(year => {
    year.months.forEach(month => {
      month.percentage = calculatePercentage(month.height, totalHeight)
    })
    if (groupBy === 'year') {
      year.percentage = calculatePercentage(year.height, totalHeight)
    } else {
      year.percentage = year.months.reduce((sum, month) => sum + month.percentage, 0)
    }
  })

  let cumulativePercentage = 0
  result.forEach((year, index) => {
    const prevYear = result[index - 1]

    let prevVisibleYear: any = null

    for (let i = index - 1 ; i >= 0; i--) {
      if (!result[i].isHidden) {
        prevVisibleYear = result[i]

        break
      }
    }

    year.startPercent = cumulativePercentage
    year.isHidden = index > 0 && year.startPercent - (prevVisibleYear?.startPercent || 0) <= percentForYear

    if (prevYear?.months.length) {
      prevYear.months.forEach(month => {
        if (year.startPercent - month.startPercent < percentForDot && !month.isDotHidden) {
          month.isDotHidden = true
        }
      })
    }

    year.months.forEach((month, monthIndex) => {
      if (monthIndex === 0) {
        month.startPercent = year.startPercent
        month.isDotHidden = true
      } else {
        const prevMonth = year.months[monthIndex - 1]
        let prevVisibleMonth: any = null

        for (let i = monthIndex - 1; i >= 0; i--) {
          if (!year?.months[i]?.isDotHidden) {
            prevVisibleMonth = year.months[i]

            break
          }
        }

        month.startPercent = prevMonth.startPercent + prevMonth.percentage
        month.isDotHidden = (month.startPercent - (prevVisibleMonth?.startPercent || 0) <= percentForDot) 
        || (month.startPercent - (prevVisibleYear?.startPercent || 0) <= percentForYear) 
        || (month.startPercent - year.startPercent <= percentForYear)
      }
    })

    cumulativePercentage += year.percentage
  })

  return result
}

export interface IPhotoCardPlaceholder {
  type: 'placeholder';
  created_original: number;
  id: number;
}

export const createTimelinePlaceholders = (digestYears: IDigestYear[]): IPhotoCardPlaceholder[] => {
  const placeholders: IPhotoCardPlaceholder[] = []
  let counter = 0

  digestYears.forEach(year => {
    year.months.forEach(month => {
      month.days.forEach(day => {
        const date = moment(`${year.year}-${month.month}-${day.day}`, 'YYYY-MMMM-D')
        const timestamp = date.startOf('day').valueOf()

        for (let i = 0; i < day.count; i++) {
          placeholders.push({
            type: 'placeholder',
            created_original: timestamp,
            id: counter++
          })
        }
      })
    })
  })

  return placeholders.sort((a, b) => b.created_original - a.created_original)
}

export const calculateRowItemsCountByMainWidth = (cardWidth = 172) => {
  const mainElement = document.querySelector('main')

  if (!mainElement) {
    return 6
  }

  if (window.innerWidth < 320) {
    return 2
  }

  if (window.innerWidth < 768) {
    return 3
  }

  // if (window.innerWidth < 1024) {
  //   return 4
  // }

  const mainWidth = mainElement?.clientWidth
  const computedStyle = getComputedStyle(mainElement)

  const cardsPerRow = Math.floor((mainWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight)) / (cardWidth + 8))

  return cardsPerRow
}

export function convertItemsToPhotoDigests(items: IItemSchema[]): IPhotoDigestSchema[] {
  const digestMap = new Map<number, number[]>()

  items.forEach(item => {
    const date = moment(item.created_original)
    const year = date.year()
    const dayOfYear = date.dayOfYear() - 1 // Индексация с 0

    if (!digestMap.has(year)) {
      digestMap.set(year, new Array(366).fill(0))
    }

    digestMap.get(year)[dayOfYear]++
  })

  return Array.from(digestMap.entries()).map(([year, counts]) => ({
    year,
    counts
  }))
}

export const getTimelineCardWidth = (sortFormat: string) => {
  if (window.innerWidth < 320) {
    return (window.innerWidth - 32 - 8) / 2
  }

  if (window.innerWidth < 768) {
    return (window.innerWidth - 32 - 8 * 2) / 3
  }

  if (window.innerWidth < 1024) {
    return (window.innerWidth - 32 - 8 * 3) / 4
  }
  
  if (sortFormat === SORT_FORMATS.sortFormat.month) {
    return 120
  }

  if (sortFormat === SORT_FORMATS.sortFormat.year) {
    return 92
  }
  
  return 172
}
