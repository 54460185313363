import { SelectType } from "@cloudike/web_ui_components"
import _ from "lodash"

export enum CheckboxVisibilityType {
  VISIBLE = 'visible',
  HIDDEN = 'hidden'
}

const isSelectable = (item) => !!item.isSelectable
const getItemById = (id, item) => item.id === id

export const changeFilesListCheckboxState = (selectItemsLength: number, itemsLength: number, state: any) => {
  state.checkboxVisibility = CheckboxVisibilityType.VISIBLE

  if (selectItemsLength === 0) {
    state.checkboxVisibility = CheckboxVisibilityType.HIDDEN
    state.selectType = SelectType.NONE
    return
  } else {
    state.checkboxVisibility = CheckboxVisibilityType.VISIBLE
  }

  if (itemsLength > selectItemsLength) {
    state.selectType = SelectType.SOME
    return
  }

  state.selectType = SelectType.ALL
}

export const selectMembersListItemReducer = (selectors) => (state, action: { payload: { id: string, items: any }}) => {
  const { id } = action.payload
  const allSelectable = selectors.selectAll(state).filter(isSelectable)

  const indexOfItemId = state.selectedItemsIds.indexOf(id)
  const indexOfNode = allSelectable.findIndex(getItemById.bind(null, id))

  if (state.selectedItemsIds.length < 2) {
    if (indexOfItemId === -1) {
      state.selectedItemsIds = [id]
    } else {
      state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
    }
  } else {
    state.selectedItemsIds = [id]
  }

  if (state.selectedItemsIds.length) {
    state.lastSelectedIndex = indexOfNode
  } else {
    state.lastSelectedIndex = null
  }

  state.lastSelectedWithShiftIndex = null

  changeFilesListCheckboxState(state.selectedItemsIds.length, allSelectable.length, state)
}

export const selectMembersListItemWithPressedCtrlReducer = (selectors) => (state, action: { payload: { id: string, items: any }}) => {
  const { id } = action.payload

  const allSelectable = selectors.selectAll(state).filter(isSelectable)

  const indexOfItemId = state.selectedItemsIds.indexOf(id)
  const indexOfNode = allSelectable.findIndex(getItemById.bind(null, id))

  if (indexOfItemId === -1) {
    state.selectedItemsIds = [...state.selectedItemsIds, id]
  } else {
    state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
  }

  if (state.selectedItemsIds.length) {
    state.lastSelectedIndex = indexOfNode
  } else {
    state.lastSelectedIndex = null
  }

  state.lastSelectedWithShiftIndex = null

  changeFilesListCheckboxState(state.selectedItemsIds.length, allSelectable.length, state)
}

export const selectMembersListItemWithPressedShiftReducer = (selectors) => (state, action: { payload: { id: string, items: any }}) => {
  const { id } = action.payload

  const allSelectable = selectors.selectAll(state).filter(isSelectable)
  const indexOfNode = allSelectable.findIndex(getItemById.bind(null, id))
  const lastSelectedIndex = state.lastSelectedIndex
  const lastSelectedWithShiftIndex = state.lastSelectedWithShiftIndex

  let itemsIdsToExclude = []
  let newIds = []

  if (lastSelectedWithShiftIndex !== null) {
    if (indexOfNode < lastSelectedWithShiftIndex) {
      itemsIdsToExclude = [
        ...allSelectable.slice(indexOfNode + 1, lastSelectedIndex),
        ...allSelectable.slice(lastSelectedIndex + 1, lastSelectedWithShiftIndex + 1)
      ]
        .map(node => node.id)
    } else {
      itemsIdsToExclude = [
        ...allSelectable.slice(lastSelectedWithShiftIndex, lastSelectedIndex),
        ...allSelectable.slice(lastSelectedIndex + 1, indexOfNode)
      ]
        .map(node => node.id)
    }
  }

  const newState = state.selectedItemsIds.filter(id => !itemsIdsToExclude.includes(id))

  if (indexOfNode >= lastSelectedIndex) {
    newIds = allSelectable.slice(lastSelectedIndex + 1, indexOfNode + 1).map(node => node.id)
  } else {
    newIds = allSelectable.slice(indexOfNode, lastSelectedIndex).map(node => node.id)
  }

  state.selectedItemsIds = _.uniq([...newState, ...newIds])
  state.lastSelectedWithShiftIndex = indexOfNode

  changeFilesListCheckboxState(state.selectedItemsIds.length, allSelectable.length, state)
}

export const selectAllMembersListItemsReducer = (state, action) => {
  state.selectedItemsIds = action.payload.filter(item => item.isSelectable).map(item => item.id)
  if(state.selectedItemsIds.length === 0) return
  state.selectType = SelectType.ALL
  state.checkboxVisibility = CheckboxVisibilityType.VISIBLE
}

export const unselectAllMembersListItemsReducer = (state) => {
  state.selectType = SelectType.NONE
  state.checkboxVisibility = CheckboxVisibilityType.HIDDEN
  state.selectedItemsIds = []
}

export const isMemberSelectablePredicate = (userId, member) => member.role !== 'owner' && member.user_id !== userId

export const mapIsSelectable = (userId, members, isUserOwner) => {

  return members.map(member => {
    const isMemberSelectable = isMemberSelectablePredicate(userId, member)
    if(isUserOwner) {
      return { ...member, isSelectable: isMemberSelectable }
    }
    return { ...member, isSelectable: false }
  })

}

