import React, { CSSProperties, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import { SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import classNames from 'classnames'

import { INotification } from './types'


interface FeedbackNotificationProps {
    className?: string,
    notification: INotification,
    style?: CSSProperties,
    state: TransitionStatus,
    isPreviewOpen?: boolean,
    onSetHeight: (height: number) => void,
    onPositiveFeedback: (notification: INotification) => void,
    onNegativeFeedback: (notification: INotification) => void
}

export const FeedbackNotification: React.FC<FeedbackNotificationProps> = ({
  className = '',
  notification,
  style,
  state,
  isPreviewOpen,
  onSetHeight,
  onPositiveFeedback,
  onNegativeFeedback
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)

  const userClicked = useRef(false)

  const [titleWidth, setTitleWidth] = useState<number>(0)
  const [resultMessage, setResultMessage] = useState<string>('')

  useEffect(() => {
    onSetHeight(ref.current.getBoundingClientRect().height)
  }, [])

  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.getBoundingClientRect().width)
    }
  }, [titleRef.current])

  useEffect(() => {
    return () => {
      if (notification.isCallbackAfterHide && notification.callback && !userClicked.current) {
        notification.callback()
      }
    }
  }, [])

  const handlePositiveFeedback = () => {
    setResultMessage(notification.message)
    userClicked.current = true

    onPositiveFeedback(notification)
  }

  const handleNegativeFeedback = () => {
    setResultMessage(notification.message)
    userClicked.current = true

    onNegativeFeedback(notification)
  }

  return (
    <SNotificationBox
      zIndex={isPreviewOpen ? '18' :  '15'}
      position={isPreviewOpen ? 'absolute' : 'unset'}
      className={className}
      style={style}
      state={state}
      ref={ref}
    >
      <STitle className={classNames({ hidden: !!resultMessage })}
        ref={titleRef}
      >
        {notification.title}
      </STitle>

      <STitle className={classNames({ hidden: !resultMessage })}
        style={{ width: titleWidth + 'px' }}
      >
        {resultMessage}
      </STitle>

      {!resultMessage && (
        <SButtonsContainer>
          <SButtonsWrapper>
            <SButton 
              iconName="like"
              onClick={handlePositiveFeedback}
            />
        
            <SButton 
              iconName="dislike"
              onClick={handleNegativeFeedback}
            />
          </SButtonsWrapper>
        </SButtonsContainer>
      )
      }

      {
        !!resultMessage && (
          <SButtonsContainer>
            <SHeartIconWrapper>
              <SHeartIcon iconName="heart" />
            </SHeartIconWrapper>
          </SButtonsContainer>
        )
      }
    
    </SNotificationBox>
  )
}

const SNotificationBox = styled.div<{zIndex:string, position: string}>`
  background-color: var(--transformer-background-primary);
  color: var(--text-toast-primary);
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 8%);
  max-width: unset;
  position: ${(props) => props.position};
  cursor: pointer;
  user-select: none;
  transition: all ease .5s;
  margin: 0 8px 8px;
  bottom: 0;
  order: 1;
  z-index: ${(props) => props.zIndex};
  
  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    left: 24px;
    bottom: 24px;
    /* width: 440px; */
    transform: translateY(100%);
  }

  @media screen and (max-width:${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: calc(100% - 16px);
  }
`

const STitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  min-width: 200px;
  color: #FFFFFF;

  &.hidden {
    display: none;
  }
`

const SButtonsContainer = styled.div`
  margin-left: 16px;
  width: 88px;
  display: flex;
  justify-content: flex-end;
`

const SButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 88px;
  height: 40px;
  border-radius: 100px;
  background-color: var(--transformer-background-secondary);
`

const SButton = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  color: var(--transformer-text-primary);
`

const SHeartIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--transformer-background-secondary);
`

const SHeartIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  color: #F2999A;
`
