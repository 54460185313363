import React, { useState } from 'react'

import { ConfirmationModal, ConfirmationModalTypes, SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from "store"
import { useTranslation } from 'react-i18next'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { DialogModal } from 'ui/DialogModal'
import { toMb } from 'utils/utils'
import moment from 'moment'
import classNames from 'classnames'
import { deleteSharedLinkThunk, getPublicLinkAndCopyThunk, sharingActions } from 'features/sharing/sharingSlice'
import { downloadNodesThunk as downloadPublicLinksNodesThunk } from 'features/public-links-files/publicLinksFilesSlice'
import { IFsNodeSchema } from "@cloudike/web_files"
import { Tooltip } from "react-tooltip"
import { useNavigate } from 'react-router-dom'
import { appActions } from 'store/app'
import { getFilesPublicLinkShareIdSelector } from 'features/public-link-files/selectors'
import { deleteFilesTrashBinItemsThunk, restoreFilesTrashBinSelectedItemsThunk } from 'features/trash-bin/files/trashBinFilesSlice'

import { deleteNodesThunk, downloadNodesThunk, filesActions } from '../filesSlice'
import { generateRemovingText, pushCreateEditItemPublicLink } from '../filesUtils'
import { fileVersionsActions } from '../file-versions/fileVersionsSlice'
import {
  deleteFilesPublicLinkNodesThunk,
  downloadSharedFilesArchiveThunk,
  downloadSharedWithMeFilesThunk,
  removeSharedWithMeLink
} from "../../public-link-files/publicLinkFilesSlice"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { InfoModalPreview } from "../../common/info-modal/InfoModalPreview"
import { useMobileDetection } from "../../common/hooks"
import { getModalsRootElement } from "../../../constants/modals"
import { DropMenuPreview } from "../../photo/photo-preview/DropMenuPreview"
import { getSearchTypeSelector } from '../selectors'
import { CopyMoveModal, CopyMoveModalType as FilesCopyMoveModalTypes } from '../copy-move-modal/CopyMoveModal'

import { filesPreviewActions, PREVIEW_TYPES } from './filesPreviewSlice'
import {
  getCurrentFilesPreviewItemIndexSelector,
  getFilesPreviewItemsSelector,
  getFilesPreviewTypeSelector
} from './selectors'



export const FilesPreviewToolbar = ({ className = '', permission = null }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isMobile = useMobileDetection(767)
  const isTablet = useMobileDetection(1280)
  const navigate = useNavigate()

  const items = getFilesPreviewItemsSelector()
  const currentIndex = getCurrentFilesPreviewItemIndexSelector()
  const type = getFilesPreviewTypeSelector()
  const searchType = getSearchTypeSelector()
  const shareId = getFilesPublicLinkShareIdSelector()
  const isActiveSearchSharedWithMe = searchType?.type === 'shared_with_me'

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [isItemsHiddenOnMobile, toggleMobileItemsVisibility] = useState(false)
  const [isOpenedInfoModal, setOpenedInfoModal] = useState(false)
  const [publicLinkDataForDelete, setPublicLinkDataForDelete] = useState(null)
  const [copyModalOpened, toggleCopyModal] = useState(false)
  const [restoreConfirmationModalOpened, toggleRestoreConfirmationModal] = useState(false)
  const [trashBinRemovingConfirmationModalOpened, toggleTrashBinRemovingConfirmationModal] = useState(false)

  const currentItem = items[currentIndex]

  const handleClose = () => {
    dispatch(filesPreviewActions.resetState())
  }

  const handleCloseClick = () => {
    handleClose()
    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_CLOSE)
  }

  const handleRemoveSelected = () => {
    if (type === PREVIEW_TYPES.SHARED_FILES) {
      dispatch(deleteFilesPublicLinkNodesThunk({ ids: [currentItem.id] }))
    } else {
      dispatch(deleteNodesThunk({ ids: [currentItem.id] }))
    }

    handleCloseRemovingConfirmationModal()

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(filesPreviewActions.setCurrentItemIndex(currentIndex - 1))

      return
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DELETE)
  }

  const handleShare = (item: IFsNodeSchema) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES
    }))
    dispatch(sharingActions.setSharedItemData(currentItem))
    pushCreateEditItemPublicLink(item)
    analytics.push(ANALYTICS_EVENTS.WEB_FILES_CREATE_LINK)
  }

  const handleDownload = () => {
    if (type === PREVIEW_TYPES.SHARED_FILES) {
      dispatch(downloadSharedFilesArchiveThunk({ ids: [currentItem.id] }))

      return
    }

    if (type === PREVIEW_TYPES.FILES_PUBLIC_LINKS) {
      dispatch(downloadPublicLinksNodesThunk({ ids: [currentItem.id] }))

      return
    }

    dispatch(downloadNodesThunk({ ids: [currentItem.id] }))

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DOWNLOAD)
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleShowHiddenItemsOnMobile = () => {
    toggleMobileItemsVisibility(prevState => !prevState)
  }

  const handleOpenRestoreModal = () => {
    dispatch(fileVersionsActions.setNodeId(currentItem.id))

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_VERSIONS)
  }

  const handleInfo = () => {
    setOpenedInfoModal(true)
    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_INFO)
  }

  const onCloseInfoModal = () => {
    setOpenedInfoModal(false)
  }

  const handleCopyPublicLink = () => {
    dispatch(getPublicLinkAndCopyThunk({ data: currentItem }))
  }

  const handleOpenPublicLinkRemovingModal = () => {
    dispatch(appActions.toggleRightMenuOnMobile(false))
    setPublicLinkDataForDelete(currentItem)
  }

  const handleCallback = () => {
    dispatch(filesActions.unselectAll())
    dispatch(filesActions.deleteItems([currentItem.id]))
  }

  const handleDeletePublicLink = () => {
    isActiveSearchSharedWithMe ? dispatch(removeSharedWithMeLink({ nodeId: publicLinkDataForDelete.id, successCallback: handleCallback })) 
      : dispatch(deleteSharedLinkThunk({ data: publicLinkDataForDelete, type: SDK_TYPES.FILES }))
    setPublicLinkDataForDelete(null)
  }

  const handleClosePublicLinkRemovingModal = () => {
    setPublicLinkDataForDelete(null)
  }

  const handleDownloadSelectedSharedWithMe = () => {
    dispatch(downloadSharedWithMeFilesThunk({ item: currentItem, navigate }))
  }

  const handleOpenCopyToPersonalModal = () => {
    toggleCopyModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseCopyToPersonalModal = () => {
    toggleCopyModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRestoreConfirmationModal = () => {
    toggleRestoreConfirmationModal(true)
  }

  const handleCloseRestoreConfirmationModal = () => {
    toggleRestoreConfirmationModal(false)
  }

  const handleOpenTrashBinRemovingConfirmationModal = () => {
    toggleTrashBinRemovingConfirmationModal(true)
  }

  const handleCloseTrashBinRemovingConfirmationModal = () => {
    toggleTrashBinRemovingConfirmationModal(false)
  }

  const handleRestore = () => {
    handleCloseRestoreConfirmationModal()

    dispatch(restoreFilesTrashBinSelectedItemsThunk({ ids: [currentItem.id] }))

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(filesPreviewActions.setCurrentItemIndex(currentIndex - 1))

      return
    }
  }

  const handleDeleteFromTrashBin = () => {
    handleCloseTrashBinRemovingConfirmationModal()

    dispatch(deleteFilesTrashBinItemsThunk({ ids: [currentItem.id] }))

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(filesPreviewActions.setCurrentItemIndex(currentIndex - 1))

      return
    }
  }

  const actionItems = (() => {
    let  items = []

    if (type === PREVIEW_TYPES.TRASH_BIN) {
      return [
        {
          handler: handleOpenRestoreConfirmationModal,
          title: t('a_common_restore'),
          label: t('a_common_restore'),
          iconName: 'restore',
          hiddenOnMobile: false,
          orderOnMobile: 1,
          tooltipOff: isTablet
        },
        {
          handler: handleOpenTrashBinRemovingConfirmationModal,
          title: t('a_common_remove'),
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          hiddenOnMobile: false,
          orderOnMobile: 2,
          tooltipOff: isTablet
        },
        {
          handler: handleClose,
          title: t('a_common_close'),
          label: t('a_common_close'),
          iconName: 'close',
          orderOnMobile: 3,
          tooltipOff: isTablet
        },
      ]
    }

    if (isActiveSearchSharedWithMe) {
      items = [
        {
          label: t('a_common_download'),
          iconName: 'download_empty',
          handler: handleDownloadSelectedSharedWithMe,
          hiddenOnMobile: false,
          hiddenOnTablet: false,
          tooltipOff: isTablet,
          placeTooltip: 'bottom-end',
          order: 1
        },
        {
          label: t('a_common_addToMyCloud'),
          iconName: isTablet ? 'add_photo_alternate_1' : 'add_photo_alternate_1_filled',
          handler: handleOpenCopyToPersonalModal,
          hiddenOnMobile: true,
          hiddenOnTablet: true,
          tooltipOff: isTablet,
          placeTooltip: 'bottom-end',
          order: 2
        },
        {
          label: t('a_common_removeLink'),
          iconName: 'unlink',
          handler: handleOpenPublicLinkRemovingModal,
          hiddenOnMobile: true,
          hiddenOnTablet: true,
          tooltipOff: isTablet,
          placeTooltip: 'bottom-end',
          order: 3
        },
        {
          handler: handleInfo,
          label: t('l_common_info'),
          iconName: 'info_preview',
          popoverContent:
          !isTablet && (
            <>
              {t('l_common_size')}

            :
              {' '}

              {toMb(currentItem?.file_info.size)}

              {' '}

              <br />

              {t('l_common_added', { DATE: moment(currentItem?.created).format("DD.MM.YYYY, HH:mm") })}
            </>
          ),
          tooltipOff: isTablet,
          hiddenOnMobile: true,
          hiddenOnTablet: true,
          order: 4
        },
        {
          handler: handleShowHiddenItemsOnMobile,
          label: t('l_common_showMore'),
          iconName: 'action_container',
          tooltipOff: isTablet,
          hiddenOnMobile: false,
          hiddenOnTablet: false,
          hiddenOnDesktop: true
        },
        {
          handler: handleCloseClick,
          label: t('a_common_close'),
          iconName: 'close',
          tooltipOff: isTablet,
          placeTooltip: 'bottom-end',
          hiddenOnMobile: false,
          hiddenOnTablet: false,
          order: 5
        },
      ].filter(item => !!item)
    } else {
      items = [
        {
          handler: handleDownload,
          label: t('a_common_download'),
          iconName: 'download_empty',
          tooltipOff: isTablet,
          hiddenOnMobile: false,
          hiddenOnTablet: false,
        },
        type !== PREVIEW_TYPES.SHARED_FILES && type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && {
          handler: handleShare.bind(null, currentItem),
          label: t('a_common_share'),
          iconName: 'share',
          tooltipOff: isTablet,
          hiddenOnMobile: true,
          hiddenOnTablet: false,
          order: 1
        },
        type === PREVIEW_TYPES.SHARED_FILES && {
          label: t('a_common_addToMyCloud'),
          iconName: isTablet ? 'add_photo_alternate_1' : 'add_photo_alternate_1_filled',
          handler: handleOpenCopyToPersonalModal,
          hiddenOnMobile: true,
          hiddenOnTablet: true,
          tooltipOff: isTablet,
          placeTooltip: 'bottom-end',
          order: 2
        },
        type !== PREVIEW_TYPES.SHARED_FILES && type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && {
          handler: handleCopyPublicLink,
          label: t('a_common_copyPublicLink'),
          iconName: 'copy_link',
          tooltipOff: isTablet,
          hiddenOnMobile: true,
          hiddenOnTablet: false,
          order: 2
        },
        type !== PREVIEW_TYPES.SHARED_FILES && type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && currentItem.is_shared && {
          label: t('a_common_deleteLink'),
          iconName: 'unlink',
          handler: handleOpenPublicLinkRemovingModal,
          tooltipOff: isTablet,
          hiddenOnMobile: true,
          hiddenOnTablet: true,
          order: 2
        },
        type !== PREVIEW_TYPES.SHARED_FILES && type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && {
          handler: handleOpenRestoreModal,
          label: t('a_common_file_history'),
          iconName: 'file_history',
          tooltipOff: isTablet,
          hiddenOnMobile: true,
          hiddenOnTablet: true,
          order: 3
        },
        {
          handler: handleInfo,
          label: t('l_common_info'),
          iconName: 'info_preview',
          popoverContent:
          !isTablet && (
            <>
              {t('l_common_size')}

            :
              {' '}

              {toMb(currentItem?.file_info.size)}

              {' '}

              <br />

              {t('l_common_added', { DATE: moment(currentItem?.created).format("DD.MM.YYYY, HH:mm") })}
            </>
          ),
          tooltipOff: isTablet,
          hiddenOnMobile: true,
          hiddenOnTablet: true,
          order: 4
        },
        (!!permission ? permission === 'write' : true) && type !== PREVIEW_TYPES.FILES_PUBLIC_LINKS && {
          handler: handleOpenRemovingConfirmationModal,
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          tooltipOff: isTablet,
          hiddenOnMobile: true,
          hiddenOnTablet: false,
          order: 5
        },
        {
          handler: handleShowHiddenItemsOnMobile,
          label: t('l_common_showMore'),
          iconName: 'action_container',
          tooltipOff: isTablet,
          hiddenOnMobile: false,
          hiddenOnTablet: false,
          hiddenOnDesktop: true
        },
        {
          handler: handleCloseClick,
          label: t('a_common_close'),
          iconName: 'close',
          tooltipOff: isTablet,
          placeTooltip: 'bottom-end',
          hiddenOnMobile: false,
          hiddenOnTablet: false
        },

      ].filter(item => !!item)
    }

    return items
  })()

  const mobileActionItem = () => {
    if (isMobile) {
      return actionItems.filter(item => item.hiddenOnMobile)
    }
    if (isTablet) {
      return actionItems.filter(item => item.hiddenOnTablet)
    }
    return actionItems.filter(item => !item.hiddenOnDesktop)
  }

  return (
    <FilesPreviewToolbarBox className={className}>
      {
        actionItems.map(item => (
          <ActionItem
            key={item.label}
            item={item}
          />
        ))
      }

      {isItemsHiddenOnMobile && (
        <DropMenuPreview
          isMobile={isMobile}
          menuItems={mobileActionItem()}
          handleClick={handleShowHiddenItemsOnMobile}
        />
      )}

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={generateRemovingText([currentItem], t).title}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveSelected}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
        type={ConfirmationModalTypes.danger}
      >
        <TextInModalBox>
          {generateRemovingText([currentItem], t).text}
        </TextInModalBox>
      </DialogModal>

      <InfoModalPreview
        titleText={t('a_info_file_modal_window', { name: currentItem?.name })}
        isOpened={isOpenedInfoModal}
        handleCloseModal={onCloseInfoModal}
        size={toMb(currentItem?.file_info.size)}
        date={moment(currentItem?.created).format("DD.MM.YYYY, HH:mm")}
      />

      <ConfirmationModal title={isActiveSearchSharedWithMe ? t('l_publicLink_removeConfirmTitle') : t('l_publicLink_deleteConfirmTitle')}
        isOpened={!!publicLinkDataForDelete}
        onClose={handleClosePublicLinkRemovingModal}
        parentBlock={getModalsRootElement()}
        onOk={handleDeletePublicLink}
        okText={isActiveSearchSharedWithMe ? t('a_publicLinks_remove') : t('a_common_delete')}
        onCancel={handleClosePublicLinkRemovingModal}
        cancelText={t('a_common_cancel')}
        type={ConfirmationModalTypes.danger}
      >
        <SRemovingLinkConfirmationModalText>
          {isActiveSearchSharedWithMe ? t('l_publicLink_removeSharedConfirmMessage') : t('l_publicLink_deleteConfirmMessage')}
        </SRemovingLinkConfirmationModalText>
      </ConfirmationModal>

      {copyModalOpened && (
        <CopyMoveModal
          selectedItems={[currentItem]}
          type={FilesCopyMoveModalTypes.COPY_TO_PERSONAL}
          currentFileFolder={''}
          onClose={handleCloseCopyToPersonalModal}
          isSharedWithMeToDrive={isActiveSearchSharedWithMe}
          shareId={shareId}
        />
      )}

      <DialogModal
        isOpened={restoreConfirmationModalOpened}
        title={t('l_trash_restoreConfirmation', { number: 1 })}
        onClose={handleCloseRestoreConfirmationModal}
        okText={t('a_common_restore')}
        onOk={handleRestore}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRestoreConfirmationModal}
        withLongButtons
      />

      <DialogModal
        isOpened={trashBinRemovingConfirmationModalOpened}
        title={t('l_common_deleteItemsConfirmation', { number: 1 })}
        onClose={handleCloseTrashBinRemovingConfirmationModal}
        okText={t('a_trash_deleteSelectedItems')}
        onOk={handleDeleteFromTrashBin}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseTrashBinRemovingConfirmationModal}
        withLongButtons
      />

    </FilesPreviewToolbarBox>
  )
}

const ActionItem = ({ className = '', item }) => {
  const [popoverOpened, togglePopover] = useState(false)

  const handleClick = (event: MouseEvent) => {
    item.handler(event)
  }

  return (
    <ActionIconBox
      onClick={handleClick}
      onMouseEnter={() => togglePopover(true)}
      onMouseLeave={() => togglePopover(false)}
      data-tooltip-id={item.label}
      data-tooltip-content={item.label}
      className={classNames(className, { 'hidden-on-desktop':  item.hiddenOnDesktop, 'hidden-on-tablet': item.hiddenOnTablet, 'hidden-on-mobile': item.hiddenOnMobile })}
      orderOnMobile={item.orderOnMobile}
    >
      <ActionIcon iconName={item.iconName} />

      {!!item.popoverContent && popoverOpened ? (
        <Popover>
          {item.popoverContent}
        </Popover>
      ) :
        !item.tooltipOff && (
          <Tooltip
            place={item.placeTooltip}
            id={item.label}
            style={{
              backgroundColor: "var(--background-primary)",
              color: "var(--text-primary)",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%"
            }}
          />
        )}
    </ActionIconBox>
  )
}

const SRemovingLinkConfirmationModalText = styled.p`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 572px;
`

const TextInModalBox = styled.span`
  max-width: 500px;
  font-size: 14px;
  word-wrap: anywhere;
`

const FilesPreviewToolbarBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ActionIconBox = styled.button`
  width: 44px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .3s ease;
  position: relative;
  z-index: 2;
  color: var(--tag-icon);
  order: ${props => props.orderOnMobile};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background-color: rgba(255, 255, 255, .34);
  }
  
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.hidden-on-mobile {
      display: none;
    }
  }
  
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    &.hidden-on-tablet {
      display: none;
    }

    &:hover {
      background-color: unset;
    }
  }
  
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    
    &.hidden-on-tablet {
      display: flex;
    }
    
    &.hidden-on-desktop{
      display: none;
    }
  }
`

const ActionIcon = styled(SpriteIcon)`
  && {
    path {
      fill: white;
    }
  }
`

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: auto;
  right: 0;
  border-radius: 6px;
  width: 180px;
  font-size: 12px;
  line-height: 1.4;
  padding: 9px 14px;
  text-align: left;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  background-color: var(--background-primary);
  color: var(--text-primary);
  
  &.active {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: var(--background-primary);
    border-style: solid;
    left: auto;
    right: 11px;
    top: -21px;
    bottom: auto;
    margin-left: -11px;
    border-width: 11px;
  }
`
