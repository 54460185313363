import { IFsNodeSchema } from "@cloudike/web_files"
import { AlbumType, ShareAccessTypes, SharePermissionType } from "@cloudike/web_photos"
import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"
import { ILinks } from "@cloudike/web_photos/dist/types/intarfaces/ILinks"
import { IMyShareSchema } from "@cloudike/web_photos/dist/types/intarfaces/IMyShareSchema"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { filesApi } from "api/filesApi"
import { request } from "api/request"
import { hideGlobalProgressLoader, showGlobalProgressLoader } from "features/common/app-progress-bar"
import { NOTIFICATION_TYPES, showNotification } from "features/common/notifications"
import { t } from "i18next"
import _ from "lodash"
import { getAlbumsSdkByType } from "sdk/albums"
import { getFilesSdk } from "sdk/files"
import { getPhotoSdk } from "sdk/photo"
import { SDK_TYPES } from "sdk/sdkConstants"
import { RootState } from "store"

import { filesActions, setCurrentFolderThunk } from "../files/filesSlice"
import APP_CONFIG from "../../constants/configs/app.config"
import i18n from "../../i18n"
import { appActions } from "../../store/app"
import { getErrorData } from "../../utils/getErrorData"

export interface ICreateMyShareSchema {
  access_type?: ShareAccessTypes.ALL | ShareAccessTypes.PASSWORD | ShareAccessTypes.COLLABORATORS;
  password?: string;
  expires?: string;
  permission?: SharePermissionType.READ | SharePermissionType.WRITE;
}

export interface ICollaboratorSchema {
  created: string;
  updated: string;
  id: string;
  phone_or_email: string;
  permission: SharePermissionType.READ | SharePermissionType.WRITE;
  first_opened: string | null;
  _links?: ILinks;
}

type Config = {
  sdkType: SDK_TYPES,
  sharedAlbumType?: AlbumType,
  isNewAlbum?: boolean,
  albumElementsCount?: number
} | null

interface SharingState {
  sharedItemData: IAlbumSchema & IFsNodeSchema,
  sharingData: IMyShareSchema | null
  config: Config,
  collaborators: ICollaboratorSchema[],
  availableTypesPublicLink: string[],
  isBusy: boolean
}

const initialState: SharingState = {
  sharedItemData: null,
  sharingData: null,
  config: null,
  collaborators: [],
  availableTypesPublicLink: [],
  isBusy: false
}

export const fetchShareDataThunk = createAsyncThunk(
  'sharing/fetchShareDataThunk',
  async function (__, { dispatch, getState }) {
    const state = getState() as RootState
    const sharedItemData = state.sharing.sharedItemData
    const sdkType = state.sharing.config.sdkType
    dispatch(actions.setIsBusy(true))

    if (sdkType === SDK_TYPES.FILES) {
      const filesSdk = getFilesSdk()
      const filesPublicLinksService = filesSdk.publicLinksService

      if (sharedItemData.is_shared) {
        let nodeData = sharedItemData

        if (!sharedItemData?._links?.share) {
          const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
          const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
          const url = urlTemplate({ node_id: sharedItemData.id })

          nodeData = await request('GET', url, {}, { host: null })
        }

        const filesResponse = await filesPublicLinksService.getShare(nodeData as any)

        let collaborators = []

        if (filesResponse.data.collaborators_count) {
          try {
            const collaboratorsResponse = await filesPublicLinksService.getCollaborators(nodeData)

            collaborators = collaboratorsResponse.data._embedded.collaborators
          } catch (error) {
            collaborators = []
          }
        }

        dispatch(actions.setCollaborators(collaborators))
        dispatch(actions.setSharingData(filesResponse.data))
        dispatch(actions.setSharedItemData(nodeData))
        dispatch(actions.setIsBusy(false))

        const currentFolderId = state.files.currentFolderId

        if (currentFolderId === nodeData.id) {
          dispatch(filesActions.setCurrentFolder(nodeData))
        }

        return
      }


      const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
      const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
      const url = urlTemplate({ node_id: sharedItemData.id })

      const nodeData = await request('GET', url, {}, { host: null })

      const filesResponse = await filesPublicLinksService.createShare(nodeData, { access_type: ShareAccessTypes.ALL })

      const nodeDataWithShareLink = await request('GET', url, {}, { host: null })

      dispatch(actions.setSharingData(filesResponse.data))
      dispatch(actions.setSharedItemData({ ...nodeDataWithShareLink, is_shared: true }))
      dispatch(actions.setIsBusy(false))

      return
    }

    const publicLinksService = getPhotoSdk().publicLinksService

    if (sharedItemData.shared_hash) {
      const response = await publicLinksService.getShare(sharedItemData)

      let collaborators = []

      if (response.data.collaborators_count) {
        try {
          const collaboratorsResponse = await publicLinksService.getCollaborators(sharedItemData)

          collaborators = collaboratorsResponse.data._embedded.collaborators
        } catch (error) {
          collaborators = []
        }
      }

      dispatch(actions.setCollaborators(collaborators))
      dispatch(actions.setSharingData(response.data))
      dispatch(actions.setIsBusy(false))

      return
    }

    const response = await publicLinksService.createShare(sharedItemData, { access_type: ShareAccessTypes.ALL })

    const albumsSdk = getAlbumsSdkByType(state.sharing.config.sdkType)
    const albumDataResponse = await albumsSdk.getAlbum(sharedItemData.id)

    dispatch(actions.setSharingData(response.data))
    dispatch(actions.setSharedItemData(albumDataResponse.data))
    dispatch(actions.setIsBusy(false))
  }
)

export const getPublicLinkAndCopyThunk = createAsyncThunk(
  'sharing/getPublicLinkAndCopyThunk',
  async function ({ data } : { data: any }, { getState, dispatch }) {
    const state = getState() as RootState
    const sharedItemData = data

    const filesSdk = getFilesSdk()
    const filesPublicLinksService = filesSdk.publicLinksService

    if (sharedItemData.shared_hash) {
      navigator.clipboard.writeText(`${window.location.origin}/links/pa/${sharedItemData.shared_hash}`)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_linkCopied'),
        suffixText: i18n.t('a_notification_manageLink'),
        callback: () => {
          dispatch(actions.setSharingAlbumConfig({
            sdkType: SDK_TYPES.DEFAULT,
            sharedAlbumType: AlbumType.SHARED
          }))

          dispatch(actions.setSharedItemData(data))
          dispatch(appActions.toggleRightMenuOnMobile(false))
        },
      })

      return
    }

    if (sharedItemData.is_shared) {
      let nodeData = sharedItemData

      if (!sharedItemData?._links?.share) {
        const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
        const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
        const url = urlTemplate({ node_id: sharedItemData.id })

        nodeData = await request('GET', url, {}, { host: null })
      }

      const filesResponse = await filesPublicLinksService.getShare(nodeData as any)

      navigator.clipboard.writeText(filesResponse.data._links.public_url.href)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_linkCopied'),
        suffixText: i18n.t('a_notification_manageLink'),
        callback: () => {
          dispatch(actions.setSharingAlbumConfig({
            sdkType: SDK_TYPES.FILES
          }))

          dispatch(actions.setSharedItemData(nodeData))
          dispatch(appActions.toggleRightMenuOnMobile(false))
        },
      })

      return
    }


    const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
    const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
    const url = urlTemplate({ node_id: sharedItemData.id })

    const nodeData = await request('GET', url, {}, { host: null })


    const filesResponse = await filesPublicLinksService.createShare(nodeData, { access_type: ShareAccessTypes.ALL })

    navigator.clipboard.writeText(filesResponse.data._links.public_url.href)

    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: i18n.t('l_notification_linkCopied'),
      suffixText: i18n.t('a_notification_manageLink'),
      callback: () => {
        dispatch(actions.setSharingAlbumConfig({
          sdkType: SDK_TYPES.FILES
        }))

        dispatch(actions.setSharedItemData(nodeData))
        dispatch(appActions.toggleRightMenuOnMobile(false))
      },
    })

    return
  }
)

export const getPublicLinkAndCopyForPhotoThunk = createAsyncThunk(
  'sharing/getPublicLinkAndCopyThunk',
  async function ({ items, type, callback } : { items: any, type: SDK_TYPES, callback: () => void }, { dispatch }) {
    const publicLinksService = getPhotoSdk().publicLinksService

    const album = {
      type: AlbumType.TMP,
      description: ''
    }

    try {
      showGlobalProgressLoader()

      const albumSdk = getAlbumsSdkByType(type)

      const response = await albumSdk.createAlbumWithItems(album, items)

      const shareResponse = await publicLinksService.createShare(response.data, { access_type: ShareAccessTypes.ALL })

      const link = shareResponse.data._links.public_url.href
      navigator.clipboard.writeText(link)

    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_linkCopied'),
        suffixText: i18n.t('a_notification_manageLink'),
        callback: () => {
          callback()
          dispatch(appActions.toggleRightMenuOnMobile(false))
        },
      })
      hideGlobalProgressLoader()
    }
  }
)

export const deleteSharedLinkThunk = createAsyncThunk(
  'sharing/deleteSharedLinkThunk',
  async function ({ data, type, callback }: { data?: any, type?: SDK_TYPES, callback?: () => void }, { dispatch, getState }) {
    try {
      showGlobalProgressLoader()
      dispatch(actions.setIsBusy(true))
      const state = getState() as RootState
      const sharedItemData = data || state.sharing.sharedItemData
      const sdkType = type || state.sharing.config.sdkType
      const currentFolderId = state.files.currentFolderId

      const publicLinksService = sdkType === SDK_TYPES.FILES ?
        getFilesSdk().publicLinksService :
        getPhotoSdk().publicLinksService

      await publicLinksService.deleteShare(sharedItemData)

      if (currentFolderId === sharedItemData.id) {
        dispatch(setCurrentFolderThunk({ nodeId: sharedItemData.id }))
      }

      dispatch(actions.resetState())
      dispatch(actions.setIsBusy(false))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_linkDeleted')
      })

      if (callback) {
        callback()
      }
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const updateSharedDataThunk = createAsyncThunk(
  'sharing/updateSharedDataThunk',
  async function ({ data, closeAfterUpdate }: { data: ICreateMyShareSchema, closeAfterUpdate?: boolean }, { dispatch, getState }) {
    const state = getState() as RootState
    const sharedItemData = state.sharing.sharedItemData
    const sdkType = state.sharing.config.sdkType

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    const response = await publicLinksService.updateShare(sharedItemData, data as any)

    if (!closeAfterUpdate) {
      dispatch(actions.setSharingData(response.data))

      return
    }

    dispatch(actions.resetState())

    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      title: t('l_notification_changesSaved')
    })
  }
)

export const saveSharedDataThunk = createAsyncThunk(
  'sharing/saveSharedDataThunk',
  async function ({ data, closeAfterUpdate }: { data: ICreateMyShareSchema, closeAfterUpdate?: boolean }, { dispatch, getState }) {
    dispatch(actions.setIsBusy(true))
    const state = (getState() as RootState).sharing
    const sharedItemData = state.sharedItemData
    const sdkType = state.config.sdkType

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    try {
      showGlobalProgressLoader()

      const response = await publicLinksService.updateShare(sharedItemData, data as any)

      if (!closeAfterUpdate) {
        dispatch(actions.setSharingData(response.data))
        return
      }

      dispatch(actions.resetState())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_changesSaved')
      })
    } catch (error){
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_common_wentWrong')
      })
    } finally {
      dispatch(actions.setIsBusy(false))
      hideGlobalProgressLoader()
    }
  }
)

export const addCollaboratorsThunk = createAsyncThunk(
  'sharing/addCollaboratorsThunk',
  async function ({ collaborators, successCallback, errorCallback }: { collaborators: ICollaboratorSchema[], successCallback: () => void, errorCallback: (error: string) => void }, { dispatch, getState }) {
    const state = (getState() as RootState).sharing
    const sharedItemData = state.sharedItemData
    const sharingData = state.sharingData
    const currentCollaborators = state.collaborators
    const sdkType = state.config.sdkType

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    try {
      if (sharingData.access_type !== 'collaborators') {
        const response = await publicLinksService.updateShare(sharedItemData, { access_type: 'collaborators' } as any)

        dispatch(actions.setSharingData(response.data))
      }

      const response = await publicLinksService.addCollaborator(sharedItemData, collaborators[0] as any)

      dispatch(actions.setCollaborators([...currentCollaborators, response.data]))

      successCallback()
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      if (error?.cause?.cause?.code === 'CollaboratorsCountExceededError') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_usersListLimit')
        })

        return
      }

      if (error?.cause?.cause?.code === 'InvalidParameters') {
        errorCallback(APP_CONFIG.user_login_type === 'email' ? t('l_notification_incorrectEmailError') : t('l_common_incorrectPhoneNumberError'))

        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_common_wentWrong')
      })
    }
  }
)

export const removeCollaboratorsThunk = createAsyncThunk(
  'sharing/removeCollaboratorsThunk',
  async function ({ phone_or_email }: { phone_or_email: ICollaboratorSchema['phone_or_email'] }, { dispatch, getState }) {
    const state = (getState() as RootState).sharing
    const collaborators = state.collaborators
    const sdkType = state.config.sdkType

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    try {
      const collaboratorIndex = collaborators.findIndex(collaborator => collaborator.phone_or_email === phone_or_email)
      const collaboratorExistedInCollaborators = collaborators[collaboratorIndex]

      await publicLinksService.deleteCollaborator(collaboratorExistedInCollaborators)

      dispatch(actions.setCollaborators([...collaborators.slice(0, collaboratorIndex), ...collaborators.slice(collaboratorIndex + 1)]))
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_common_wentWrong')
      })
    }
  }
)

export const updateCollaboratorsThunk = createAsyncThunk(
  'sharing/updateCollaboratorsThunk',
  async function ({ phone_or_email, permission }: { phone_or_email: ICollaboratorSchema['phone_or_email'], permission: SharePermissionType }, { dispatch, getState }) {
    const state = (getState() as RootState).sharing
    const collaborators = state.collaborators
    const sdkType = state.config.sdkType

    const publicLinksService = sdkType === SDK_TYPES.FILES ?
      getFilesSdk().publicLinksService :
      getPhotoSdk().publicLinksService

    try {
      const collaboratorIndex = collaborators.findIndex(collaborator => collaborator.phone_or_email === phone_or_email)
      const collaboratorExistedInCollaborators = collaborators[collaboratorIndex]

      const response = await publicLinksService.updateCollaborator(collaboratorExistedInCollaborators, { permission })

      dispatch(actions.setCollaborators([...collaborators.slice(0, collaboratorIndex), response.data, ...collaborators.slice(collaboratorIndex + 1)]))
    } catch (error) {
      if (!navigator.onLine) {
        return
      }

      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_common_wentWrong')
      })
    }
  }
)

export const addAvailableTypesPublicLinkThunk = createAsyncThunk(
  'sharing/addAvailableTypesPublicLinkThunk',
  async function (_, { dispatch }) {

    const userSettingsService = getPhotoSdk().userSettingsService

    const userSharingData = await userSettingsService.getValue('user_sharing')

    const accessTypes = userSharingData.data['access_types']

    if (!!accessTypes) dispatch(actions.setAvailableTypesPublicLink([...accessTypes]))
  }
)

export const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    setSharingData: (state, action) => {
      state.sharingData = action.payload
    },
    setSharedItemData: (state, action) => {
      state.sharedItemData = action.payload
    },
    setSharingAlbumConfig: (state, action: PayloadAction<Config>) => {
      state.config = action.payload
    },
    setCollaborators: (state, action) => {
      state.collaborators = action.payload
    },
    setAvailableTypesPublicLink: (state, action) => {
      state.availableTypesPublicLink = action.payload
    },
    resetState: (state) => {
      state.sharedItemData = null
      state.sharingData = null
      state.config = null
      state.collaborators = []
    },
    setIsBusy: (state, action) => {
      state.isBusy = action.payload
    }
  }
})

const {
  reducer, actions
} = sharingSlice

export { reducer as sharingReducer, actions as sharingActions }
