import {  THEME_COLORS_DARK } from 'constants/configs/theme.config'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import React from 'react'

import styled from 'styled-components'

interface EmptyMemoriesAlbumPlaceholderProps {
    className?: string,
    imageSrc: string,
    texts: {
      title: string,
      description: string,
      addPhotosBtn: string
    },
    onButtonClick: () => void
}

export const EmptyMemoriesAlbumPlaceholder: React.FC<EmptyMemoriesAlbumPlaceholderProps> = ({
  className = '',
  texts,
  onButtonClick,
  imageSrc
}) => {

  return (
    <SEmptyAlbumPlaceholderBox
      className={className}
    >
      <SContainerImg src={imageSrc} />

      <SEmptyTitle>
        {texts.title}
      </SEmptyTitle>

      <SEmptyText>
        {texts.description}
      </SEmptyText>

      <SBtn onClick={onButtonClick}>
        {texts.addPhotosBtn}
      </SBtn>
    </SEmptyAlbumPlaceholderBox>
  )
}

const SEmptyAlbumPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

const SContainerImg = styled.img`
  width: 292px;
  height: 186px;
  pointer-events: none;
`

const SEmptyTitle = styled.h2`
  margin-top: 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(225, 225, 226, 1);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const SEmptyText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: rgba(225, 225, 226, 1);
  max-width: 520px;
  display: flex;
`

const SBtn = styled.button`
  margin-top: 40px;
  height: 40px;
  padding: 0 16px;
  background: ${THEME_COLORS_DARK['button-primary-default']};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  color: ${THEME_COLORS_DARK['button-primary-text']};
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background: ${THEME_COLORS_DARK['button-primary-hover']};
  }
`
