import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { publicLinkSelectors } from "./publicLinkSlice"

export const getPublicLinkPasswordModalStatusSelector = () => useAppSelector(state => state.publicLink.passwordModalOpened)

export const getPublicLinkErrorSelector = () => useAppSelector(state => state.publicLink.error)

export const getIsSizeQuotaExceedErrorSelector = () => useAppSelector(state => state.publicLink.isSizeQuotaExceedError)

export const getPublicLinkSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getPublicLinkItemsRawSelector,
    getPublicLinkSelectedItemsIdsRawSelector,
    (items, selectedItemsIds) =>
      items.filter(item => selectedItemsIds.includes(item.id))
  ))

export const getPublicLinkSelectedItemsCountSelector = () => useAppSelector(state =>
  state.publicLink.selectedItemsIds.length
)

export const getPublicLinkSelectedItemsIdsRawSelector = state => state.publicLink.selectedItemsIds

export const getPublicLinkSelectedItemsIdsSelector = () => useAppSelector(getPublicLinkSelectedItemsIdsRawSelector)

export const getPublicLinkLoadingStatusSelector = () => useAppSelector(state =>
  state.publicLink.itemsLoadingStatus
)

export const getPublicLinkItemsRawSelector = state => publicLinkSelectors.selectAll(state.publicLink)

export const getPublicLinkItemsSelector = () => useAppSelector(getPublicLinkItemsRawSelector)

export const getPublicLinkTotalItemsCountSelector = () => useAppSelector(state => state.publicLink.totalItemsCount)

export const getPublicLinkAlbumDataSelector = () => useAppSelector(state => state.publicLink.albumData)

export const getPublicLinkPermissionSelector = () => useAppSelector(state => state.publicLink.permission)

export const getPublicLinksTokenSelector = () => useAppSelector(state => state.publicLink.token)

export const getPublicLinksNotMyResourceHrefSelector = () => useAppSelector(state => state.publicLink.notMyResourseHref)

export const getPublicLinkIsAlbumNameEditingSelector = () => useAppSelector(state => state.publicLink.isAlbumNameEditing)
