import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { LEFT_MENU_ACTIVE_ITEMS } from 'constants/leftMenu'
import { formatImgUrl } from 'constants/configs/theme.config'

import { useCallback, useEffect, useRef } from 'react'

import styled from 'styled-components'
import { PublicLinkPasswordModal } from 'features/public-link/PublicLinkPasswordModal'
import { fetchPublicLinkInfoThunk, 
  loadJustUploadedSharedAlbumItemsThunk, 
  publicLinkActions, 
  PublicLinksErrorTypes, 
  renamePublicAlbumThunk, 
  unSubscribePublicLinkToWsThunk 
} from 'features/public-link/publicLinkSlice'
import { useAppDispatch } from  "store"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PublicLinkPlaceholder, PublicLinkPlaceholderBtnTypes } from 'features/public-link/PublicLinkPlaceholder'
import { getIsUserAuthorizedSelector, THEME } from 'features/user/userSlice'
import {
  getPublicLinkErrorSelector,
  getPublicLinkAlbumDataSelector,
  getPublicLinkLoadingStatusSelector,
  getPublicLinkSelectedItemsCountSelector,
  getPublicLinkTotalItemsCountSelector,
  getIsSizeQuotaExceedErrorSelector,
  getPublicLinkIsAlbumNameEditingSelector,
  getPublicLinkPermissionSelector
} from 'features/public-link/selectors'
import { useTranslation } from 'react-i18next'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { PageTopbar } from 'features/common/page-topbar'
import { useMobileDetection } from 'features/common/hooks'
import { PublicAlbum } from 'features/public-link/PublicAlbum'
import { getAlbumDateStr } from 'features/albums/utils/albumDate'
import { AlbumPageTopbar, PhotosGrid } from '@cloudike/web_ui_components'
import { useUploadingFinishDetection } from 'features/common/files-uploading/useUploadingFinishDetection'
import { usePageTitle } from 'features/common/hooks/usePageTitle'
import { appActions } from 'store/app'
import { getUiTheme } from 'features/user/selectors'

import { hideNotification, NOTIFICATION_TYPES, showNotification } from "../../../features/common/notifications"
import { goToSubscriptionsPage } from "../../../utils/subscriptions"

interface IPublicAlbumPageProps {
  type?: 'default' | 'standalone'
}

export const PublicAlbumPage: React.FC<IPublicAlbumPageProps> = ({ type = 'standalone' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { id } = useParams()
  const isMobile = useMobileDetection()

  const isAuthorized = getIsUserAuthorizedSelector()
  const error = getPublicLinkErrorSelector()
  const selectedItemsCount = getPublicLinkSelectedItemsCountSelector()
  const status = getPublicLinkLoadingStatusSelector()
  const totalItemsCount = getPublicLinkTotalItemsCountSelector()
  const albumData = getPublicLinkAlbumDataSelector()
  const theme = getUiTheme()
  const isAlbumNameEditing = getPublicLinkIsAlbumNameEditingSelector()
  const permission = getPublicLinkPermissionSelector()

  usePageTitle('l_common_pagePhotoAlbum')

  useEffect(() => {
    dispatch(fetchPublicLinkInfoThunk({ id, navigate }))
    dispatch(appActions.setLeftMenuActiveItem(LEFT_MENU_ACTIVE_ITEMS.TIMELINE))

    return () => {
      dispatch(unSubscribePublicLinkToWsThunk())
      dispatch(publicLinkActions.resetState())
      dispatch(appActions.setLeftMenuActiveItem(null))
    }
  }, [])

  useUploadingFinishDetection(() => dispatch(loadJustUploadedSharedAlbumItemsThunk()))

  const handleCancelSelection = useCallback(() => {
    dispatch(publicLinkActions.unselectAll())
  }, [])

  const handleGoToSharedWithMe = () => {
    navigate('/photos/shared-with-me')
  }

  const handleStartEditing = () => {
    dispatch(publicLinkActions.toggleAlbumNameEditStatus(true))
  }

  const handleFinishEditing = (name: string) => {
    dispatch(renamePublicAlbumThunk({ name: name.trim() }))
    dispatch(publicLinkActions.toggleAlbumNameEditStatus(false))
  }

  const sizeQuotaExceedNotification = useRef(null)
  const isSizeQuotaExceedError = getIsSizeQuotaExceedErrorSelector()

  useEffect(() => {
    if (isSizeQuotaExceedError && !sizeQuotaExceedNotification.current) {
      const notificationOptions = {
        isPermanent: true,
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_spaceError'),
        message: t('l_notification_copyError'),
        typeError: 'SizeQuotaExceeded',
        callback: () => {
          hideNotification(sizeQuotaExceedNotification.current)
          sizeQuotaExceedNotification.current = null
          goToSubscriptionsPage()
        }
      }

      sizeQuotaExceedNotification.current = showNotification(notificationOptions)
    }
  }, [isSizeQuotaExceedError])

  const getPlaceholderProps = (error) => {
    switch (error) {
    case PublicLinksErrorTypes.NOT_EXIST:
      return {
        texts: {
          title: t('l_common_linkNotExist'),
          description: t('l_common_linkExpiredMessage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn')
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO(),
      }
    case PublicLinksErrorTypes.EMPTY_ALBUM:
      return {
        texts: {
          title: t('l_albums_noPhotosTitle'),
          description: '',
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn')
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? (type === 'default' ? () => handleBack() : () => window.location.href = '/') : () => redirectToExternalSSO()
      }
    case PublicLinksErrorTypes.SOMETHING_WENT_WRONG:
      return {
        texts: {
          title: t('l_error_dataLoadTitile'),
          description: t('l_error_dataLoadMsg'),
          btn: isAuthorized ? t('l_common_backToShWithMe') : t('a_common_logIn'),
          createAccount: !isAuthorized && t('a_common_signUp'),
        },
        btnType: PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => handleGoToSharedWithMe() : () => redirectToExternalSSO(),
        onCreateAccount: isAuthorized ? null : () => redirectToExternalSSO('/signup'),
        imgSrc: theme === THEME.LIGHT ? formatImgUrl('/assets/placeholders-images/no-internet-connection.svg') : formatImgUrl('/assets/placeholders-images-black/no-internet-connection.svg')
      }
    case PublicLinksErrorTypes.NO_ACCESS:
      return {
        texts: {
          title: t('l_common_noAccessTitle'),
          description: !isAuthorized ? t('l_common_logInToSee') : t('l_common_noAccessMesage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn'),
          createAccount: !isAuthorized && t('a_common_signUp'),
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO(),
        onCreateAccount: isAuthorized ? null : () => redirectToExternalSSO('/signup'),
      }
    }
  }

  const getTexts = () => ({
    dates: getAlbumDateStr(albumData),
    photosCount: !!totalItemsCount ? t('l_common_countElements', { number: totalItemsCount }) : ''
  })

  const handleBack = () => {
    const from = (location?.state as any)?.from

    if (from) {
      navigate(from, { replace: true })

      return
    }

    navigate('/photos/shared-with-me')
  }
  

  return (
    <>
      {status !== LOADING_STATUSES.LOADING && !error && (
        <>
          { type === 'standalone' && (
            <PageTopbar
              title={albumData.description}
              selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
              selectedItemsCount={selectedItemsCount}
              onCancelSelection={handleCancelSelection}
              description={!!albumData && `${getAlbumDateStr(albumData)} ${t('l_common_countElements', { number: totalItemsCount })}`}
            />
          )}

          {
            type === 'default' && (
              <AlbumPageTopbar
                title={albumData.description || t('l_common_untitledAlbum')}
                selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
                selectedItemsCount={selectedItemsCount}
                placeholder={isMobile ? t('l_albums_enterAlbumNameShort') : t('l_albums_enterAlbumName')}
                isEditing={isAlbumNameEditing}
                onCancelSelection={handleCancelSelection}
                onStartEdit={permission === 'write' ? handleStartEditing : () => false}
                onFinishEdit={permission === 'write' ? handleFinishEditing : () => false}
                onBack={handleBack}
                texts={getTexts()}
              />
            )
          }
        </>
      )}

      {!!error && (
        <PublicLinkPlaceholder {...getPlaceholderProps(error)} />
      )}

      {
        status !== LOADING_STATUSES.LOADING && !error && (
          <PublicAlbum type={type} 
            onBack={handleBack}
          />
        )
      }

      {
        status === LOADING_STATUSES.LOADING && !error && (
          <Skeleton />
        )
      }

      <PublicLinkPasswordModal />
    </>
  )
}

const Skeleton = () => {
  return (
    <>
      <PageTopbarTopSkeleton />

      <PageTopbarBottomSkeleton />

      <PhotosGrid>
        {Array.from({ length: 20 }).map((_, index) => {
          return (
            <SkeletonCard key={index} />
          )
        })}
      </PhotosGrid>
    </>
  )
}

const SkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

const PageTopbarTopSkeleton = styled.div`
  margin-top: 30px;
  background: var(--background-tertiary);
  height: 25px;
  width: 250px;
`

const PageTopbarBottomSkeleton = styled.div`
  margin-top: 8px;
  margin-bottom: 25px;
  background: var(--background-tertiary);
  height: 25px;
  width: 150px;
`
